<template>
  <v-container fluid>
    <v-row>
      <v-col class="od-label text-right" cols="2"> Icon Slug </v-col>
      <v-col cols="2">
        {{ lesson.attributes.iconSlug }}
      </v-col>
    </v-row>
    <v-row>
      <v-col class="od-label text-right" cols="2"> Tag </v-col>
      <v-col cols="2">
        {{ lesson.attributes.tag }}
      </v-col>
    </v-row>
    <v-row>
      <v-col class="od-label text-right" cols="2"> Deep Link </v-col>
      <v-col cols="4">
        {{ lesson.attributes.deepLink }}
      </v-col>
    </v-row>
    <v-row>
      <v-col class="od-label text-right" cols="2"> Meta Data </v-col>
      <v-col cols="4">
        {{ lesson.attributes.metaData }}
      </v-col>
    </v-row>
    <v-row>
      <v-col class="od-label text-right" cols="2"> Hero Image URL </v-col>
      <v-col cols="4">
        {{ lesson.attributes.heroImageUrl }}
      </v-col>
      <v-col cols="2">
        <div class="mb-4">
          <v-img
            :src="lesson.attributes.heroImageUrl"
            :title="lesson.attributes.heroImageUrl"
            max-height="100"
            max-width="200"
            contain
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'LessonDetail',
  props: ['lesson'],
};
</script>

<style scoped></style>
