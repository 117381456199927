<template>
  <v-container flat>
    <v-progress-linear indeterminate color="primary" :active="loading" />
    <v-row>
      <v-col cols="4">
        <v-layout flat>
          <v-list-item-title>Codes</v-list-item-title>
          <v-spacer />
          <v-btn
            v-if="hasWritePerm()"
            color="primary"
            icon
            class="mb-2"
            @click="onContentEdit()"
          >
            <v-icon title="Edit"> mdi-pencil </v-icon>
          </v-btn>
        </v-layout>
        <v-list dense>
          <template v-if="items && items.length > 0">
            <v-list-item v-for="item in items" :key="item" dense>
              <v-list-item-content>{{ item }}</v-list-item-content>
            </v-list-item>
          </template>
          <div v-else>No Codes</div>
        </v-list>
      </v-col>
      <v-col cols="8" />
    </v-row>
    <v-dialog v-model="editDialog" max-width="50%">
      <v-card>
        <v-card-title>
          <span class="headline">Partner Codes</span>
        </v-card-title>

        <v-card-text>
          <v-progress-linear indeterminate color="primary" :active="loading" />
          <v-combobox
            v-model="selectedItems"
            :items="items"
            chips
            multiple
            deletable-chips
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            :disabled="loading"
            @click="onCancel"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="loading || hasNoChanges()"
            @click="onSave"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { OneDropApi } from '@/onedrop-api';
import { ConsoleLog } from '@/onedrop-utils';

export default {
  name: 'PartnerCodes',
  props: ['partnerId'],
  data: function () {
    return {
      items: null,
      loading: false,
      editDialog: false,
      selectedItems: null,
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'partner-write']);
    },
    refresh() {
      this.loading = true;
      this.items = null;
      this.selectedItems = null;
      OneDropApi.get(
        `/v3/partner/${this.partnerId}/codes`,
        (resp) => {
          this.items = resp.data.map((x) => x.id);
          this.selectedItems = resp.data.map((x) => x.id);
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    onContentEdit() {
      this.editDialog = true;
    },
    onSave() {
      let data = JSON.parse(JSON.stringify(this.selectedItems));
      data = data.map((d) => {
        return { id: d, type: 'partner-code' };
      });
      let body = { data: data };
      this.loading = true;
      OneDropApi.patch(
        `/v3/partner/${this.partnerId}/codes`,
        body,
        (resp) => {
          this.items = resp.data.map((x) => x.id);
          this.selectedItems = resp.data.map((x) => x.id);
          this.loading = false;
          this.editDialog = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    onCancel() {
      this.editDialog = false;
    },
    hasNoChanges() {
      return JSON.stringify(this.selectedItems) === JSON.stringify(this.items);
    },
  },
};
</script>

<style scoped></style>
