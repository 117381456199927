<template>
  <v-data-table
    :loading="loading"
    :headers="headers"
    :items="items"
    :search="search"
    sort-by="code"
    show-expand
    single-expand
  >
    <template #expanded-item="{ item, headers }">
      <td :colspan="headers.length">
        <v-container fluid>
          <v-toolbar-title>Messages</v-toolbar-title>
          <message-list :items="item.messages" />
        </v-container>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import MessageList from '@/components/content/MessageList';

export default {
  name: 'CodeList',
  components: { MessageList },
  props: ['items'],
  data: function () {
    return {
      loading: false,
      search: '',
      headers: [
        {
          text: 'ID',
          value: 'codeId',
        },
        {
          text: 'Code',
          value: 'code',
        },
        { text: '', value: 'data-table-expand' },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped></style>
