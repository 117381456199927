<template>
  <v-container fluid>
    <v-card flat>
      <v-data-table :items="data" :headers="computedHeaders" :loading="loading">
        <template #top>
          <v-toolbar flat color="white">
            <span class="title">Options</span>
            <v-spacer />
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading"
              class="mb-2 mr-2"
              @click="onSelectOptions"
            >
              Select Options
            </v-btn>
            <v-btn
              v-if="hasWritePerm()"
              color="primary"
              :loading="loading"
              :disabled="!hasChanges"
              class="mb-2 mr-2"
              @click="onSaveChanges"
            >
              Save
            </v-btn>
            <v-btn
              v-if="hasWritePerm()"
              color="primary"
              :loading="loading"
              :disabled="!hasChanges"
              class="mb-2 mr-2"
              @click="onCancelChanges"
            >
              Cancel
            </v-btn>
          </v-toolbar>
        </template>
        <template #item.attributes.optionOrder="{ item }">
          <div>
            {{ item.attributes.optionOrder }}
            <v-icon
              v-if="hasWritePerm()"
              class="mr-2 ml-4"
              :disabled="upDisabled(item)"
              @click.stop="onOrderUp(item)"
            >
              mdi-arrow-up-drop-circle-outline
            </v-icon>
            <v-icon
              v-if="hasWritePerm()"
              class="mr-2"
              :disabled="downDisabled(item)"
              @click.stop="onOrderDown(item)"
            >
              mdi-arrow-down-drop-circle-outline
            </v-icon>
          </div>
        </template>
        <template #item.attributes.isRecommended="{ item }">
          <v-switch
            v-model="item.attributes.isRecommended"
            :readonly="!hasWritePerm()"
          />
        </template>
        <template #item.action="{ item }">
          <v-icon
            v-if="hasWritePerm()"
            class="mr-2"
            @click.stop="onEditItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon v-if="hasWritePerm()" @click.stop="onDeleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
        <template #no-data>
          <v-btn color="primary" @click="refreshList"> Reset </v-btn>
        </template>
      </v-data-table>
      <div>
        <v-dialog v-model="selectOptionsDialog">
          <OptionSelector
            :type="config.attributes.configType"
            :current-selections="currentOptions()"
            :save="onSaveOptions"
            :close="close"
            :saving="loading"
            :is-sub-options="isSubOptions"
          />
        </v-dialog>
        <v-dialog v-model="editItemDialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Edit Option</span>
            </v-card-title>

            <v-card-text v-if="editItem">
              <div v-if="editItem.attributes.groupId" class="pb-2">
                <span class="od-label">Group:</span
                ><span class="pl-2">{{ editItem.attributes.groupId }}</span>
              </div>
              <div class="pb-2">
                <span class="od-label">Option:</span
                ><span class="pl-2">{{ optionName(editItem) }}</span>
              </div>
              <v-text-field
                v-model="editItem.attributes.optionOrder"
                type="number"
                outlined
                label="Order"
              />
              <v-switch
                v-if="!isSubOptions"
                v-model="editItem.attributes.isRecommended"
                label="Recommended"
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteItemDialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Delete Group</span>
            </v-card-title>

            <v-card-text v-if="editItem">
              <div v-if="!isSubOptions" class="pb-2">
                <span class="od-label">Group:</span
                ><span class="pl-2">{{ editItem.attributes.groupId }}</span>
              </div>
              <div class="pb-2">
                <span class="od-label">Option:</span
                ><span class="pl-2">{{ optionName(editItem) }}</span>
              </div>
              <v-text-field
                v-model="editItem.attributes.name"
                outlined
                label="Name"
                readonly
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="doDelete">
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { OneDropApi } from '../../onedrop-api';
import { ConsoleLog } from '../../onedrop-utils';
import OptionSelector from './OptionSelector';

export default {
  name: 'ConfigGroupMaps',
  components: { OptionSelector },
  props: ['groupId', 'config', 'isSubOptions'],
  data: function () {
    return {
      loading: false,
      search: '',
      expanded: [],
      selectOptionsDialog: false,
      editItemDialog: false,
      deleteItemDialog: false,
      headers: [
        {
          text: 'Option Id',
          value: 'attributes.optionId',
        },
        {
          text: 'Name',
          value: 'attributes.name',
        },
        {
          text: 'Slug',
          value: 'attributes.slug',
        },
        {
          text: 'Order',
          value: 'attributes.optionOrder',
        },
        {
          text: 'Recommended',
          value: 'attributes.isRecommended',
        },
        { text: 'Actions', value: 'action', sortable: false },
      ],
      data: [],
      options: [],
      oldData: null,
      editedIndex: -1,
      editItem: null,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex == -1 ? 'New Option Map' : `Edit Option Map`;
    },
    hasChanges() {
      if (this.oldData) {
        for (let i in this.data) {
          if (
            this.data[i].id !== this.oldData[i].id ||
            this.data[i].attributes.isRecommended !==
              this.oldData[i].attributes.isRecommended
          )
            return true;
        }
      }
      return false;
    },
    computedHeaders() {
      return this.headers.filter((header) =>
        this.isSubOptions && header.text === 'Option Id'
          ? (header.value = 'attributes.childOptionId')
          : this.isSubOptions
          ? header.text !== 'Recommended'
          : header
      );
    },
  },
  watch: {
    config: function () {
      this.refreshList();
    },
    groupId: function () {
      this.refreshList();
    },
  },
  created() {
    this.refreshList();
  },
  methods: {
    findDataIndex(item) {
      return this.data.findIndex((v) =>
        this.isSubOptions
          ? v.attributes.childOptionId === item.attributes.childOptionId
          : v.id === item.id
      );
    },
    optionName(item) {
      return item && this.isSubOptions
        ? item.attributes.name
        : item
        ? `${item.attributes.name}: (${item.attributes.optionId})`
        : item;
    },
    refreshList: function () {
      this.loading = true;
      OneDropApi.get(
        this.isSubOptions
          ? `v3.1/admin/config/option-sub-option/${this.groupId}`
          : `v3.1/admin/config/group-maps/${this.groupId}`,
        (response) => {
          this.data = response.data;
          this.oldData = JSON.parse(JSON.stringify(this.data));
          ConsoleLog(`Got ${this.data.length} Configuration Group Maps`);
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    currentOptions: function () {
      return this.data.map((item) =>
        this.isSubOptions
          ? item.attributes.childOptionId
          : item.attributes.optionId
      );
    },
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'config-write']);
    },
    created: function () {
      this.refreshList();
    },
    close: function () {
      this.loading = false;
      this.editedIndex = -1;
      this.editItem = null;
      this.editItemDialog = false;
      this.deleteItemDialog = false;
      this.selectOptionsDialog = false;
    },
    doSave: function () {
      let attrs = this.editItem.attributes;
      let body = {
        data: [
          this.isSubOptions
            ? {
                type: 'configuration-option-sub-option',
                attributes: {
                  childOptionId: attrs.childOptionId,
                  optionOrder: Number(attrs.optionOrder),
                  parentOptionId: attrs.parentOptionId,
                },
              }
            : {
                type: 'configuration-option-group-map',
                attributes: {
                  groupId: this.groupId,
                  optionId: attrs.optionId,
                  optionOrder: Number(attrs.optionOrder),
                  isRecommended: attrs.isRecommended,
                },
              },
        ],
      };
      ConsoleLog(`Save ${this.editedIndex}, ${JSON.stringify(this.editItem)}`);
      this.loading = true;
      OneDropApi.patch(
        this.isSubOptions
          ? `v3.1/admin/config/option-sub-option`
          : `v3.1/admin/config/group-map`,
        body,
        () => {
          this.refreshList();
          this.toast(`Group updated successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.onApiError(error);
          this.loading = false;
        }
      );
    },
    doDelete: function () {
      ConsoleLog(
        `Delete ${this.editedIndex}, ${JSON.stringify(this.editItem)}`
      );
      this.loading = false;
      let body = {
        data: [
          {
            type: 'configuration-option-sub-option',
            attributes: {
              childOptionId: this.editItem.attributes.childOptionId,
              optionOrder: Number(this.editItem.attributes.optionOrder),
              parentOptionId: this.editItem.attributes.parentOptionId,
            },
          },
        ],
      };
      let groupId = this.editItem.attributes.groupId;
      let optionId = this.editItem.attributes.optionId;
      OneDropApi.del(
        this.isSubOptions
          ? `v3.1/admin/config/option-sub-option`
          : `v3.1/admin/config/group-map?groupId=${groupId}&optionId=${optionId}`,
        this.isSubOptions ? body : null,
        () => {
          this.refreshList();
          this.toast(`Group deleted successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.onApiError(error);
          this.loading = false;
        }
      );
    },
    onSaveOptions: function (options) {
      let max = 0;
      this.data.map((item) => {
        if (item.attributes.optionOrder > max)
          max = item.attributes.optionOrder;
      });
      let data = options.map((opt) => {
        max = max + 1;
        return this.isSubOptions
          ? {
              attributes: {
                childOptionId: opt,
                optionOrder: max,
                parentOptionId: this.groupId,
              },
              type: 'configuration-option-sub-option',
            }
          : {
              type: 'configuration-option-group-map',
              attributes: {
                groupId: this.groupId,
                optionId: opt,
                optionOrder: max,
                isRecommended: true,
              },
            };
      });
      const body = { data: data };
      ConsoleLog(`Saving options: ${JSON.stringify(body)}`);
      this.loading = true;
      this.isSubOptions
        ? OneDropApi.patch(
            `v3.1/admin/config/option-sub-option`,
            body,
            () => {
              this.refreshList();
              this.toast(`Sub Option added successfully`);
              this.close();
            },
            (error) => {
              ConsoleLog(error);
              this.onApiError(error);
              this.loading = false;
            }
          )
        : OneDropApi.post(
            `v3.1/admin/config/group-map`,
            body,
            () => {
              this.refreshList();
              this.toast(
                `${
                  this.isSubOptions ? 'Sub Option' : 'Group'
                } added successfully`
              );
              this.close();
            },
            (error) => {
              ConsoleLog(error);
              this.onApiError(error);
              this.loading = false;
            }
          );
    },
    onSelectOptions: function () {
      this.selectOptionsDialog = true;
    },
    onEditItem: function (item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.editedIndex = this.findDataIndex(item);
      this.editItemDialog = true;
    },
    onDeleteItem: function (item) {
      this.editItem = item;
      this.editedIndex = this.findDataIndex(item);
      this.deleteItemDialog = true;
    },
    onOrderUp(item) {
      let index = this.findDataIndex(item);
      ConsoleLog(`Order Up: ${index}`);
      if (index > 0) {
        let prev = this.data[index - 1];
        item.attributes.optionOrder = item.attributes.optionOrder - 1;
        prev.attributes.optionOrder = prev.attributes.optionOrder + 1;
        this.data.splice(index - 1, 1, item);
        this.data.splice(index, 1, prev);
      }
    },
    onOrderDown(item) {
      let index = this.findDataIndex(item);
      ConsoleLog(`Order Down: ${index}: ${this.data.length}`);
      if (index < this.data.length) {
        let next = this.data[index + 1];
        item.attributes.optionOrder = item.attributes.optionOrder + 1;
        next.attributes.optionOrder = next.attributes.optionOrder - 1;
        this.data.splice(index, 1, next);
        this.data.splice(index + 1, 1, item);
      }
    },
    upDisabled(item) {
      let index = this.findDataIndex(item);
      return index <= 0;
    },
    downDisabled(item) {
      let index = this.findDataIndex(item);
      return index >= this.data.length - 1;
    },
    onSaveChanges() {
      const data = [];
      if (this.isSubOptions) {
        for (let i in this.data) {
          if (
            this.data[i].attributes.childOptionId !==
            this.oldData[i].attributes.childOptionId
          ) {
            const d = this.data[i];
            const bd = {
              type: 'configuration-option-sub-option',
              attributes: {
                parentOptionId: this.groupId,
                childOptionId: d.attributes.childOptionId,
                optionOrder: d.attributes.optionOrder,
              },
            };
            data.push(bd);
          }
        }
      } else {
        for (let i in this.data) {
          if (
            this.data[i].id !== this.oldData[i].id ||
            this.data[i].attributes.isRecommended !==
              this.oldData[i].attributes.isRecommended
          ) {
            const d = this.data[i];
            const bd = {
              type: 'configuration-option-group-map',
              attributes: {
                groupId: this.groupId,
                optionId: d.attributes.optionId,
                optionOrder: d.attributes.optionOrder,
                isRecommended: d.attributes.isRecommended,
              },
            };
            data.push(bd);
          }
        }
      }
      ConsoleLog(`Save: ${JSON.stringify(data)}`);
      if (data.length > 0) {
        this.loading = true;
        const body = { data: data };
        OneDropApi.patch(
          this.isSubOptions
            ? `v3.1/admin/config/option-sub-option`
            : `v3.1/admin/config/group-map`,
          body,
          () => {
            this.refreshList();
            this.toast(`Options updated successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.onApiError(error);
            this.loading = false;
          }
        );
      }
    },
    onCancelChanges() {
      this.data = JSON.parse(JSON.stringify(this.oldData));
    },
  },
};
</script>

<style></style>
