<template>
  <v-container fluid>
    <v-row>
      <v-col cols="3">
        <v-list>
          <v-list-item-title>User</v-list-item-title>
          <v-list-item-content>
            <v-row dense>
              <v-col class="od-heading"> Id </v-col>
              <v-col>{{ coach.attributes.userId }}</v-col>
            </v-row>
            <v-row dense>
              <v-col class="od-heading"> Name </v-col>
              <v-col>{{ userName }}</v-col>
            </v-row>
            <v-row dense>
              <v-col class="od-heading"> Email </v-col>
              <v-col>{{ userEmail }}</v-col>
            </v-row>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title>Partner</v-list-item-title>
            <p class="text--secondary">
              {{ getPartnerName() }} ({{ coach.attributes.partnerId }})
            </p>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title>Time Zone</v-list-item-title>
            <p class="text--secondary">
              {{ coach.attributes.timeZone }}
            </p>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title>Time Zone User Facing</v-list-item-title>
            <p class="text--secondary">
              {{ coach.attributes.timeZoneUserFacing }}
            </p>
          </v-list-item-content>
        </v-list>
      </v-col>
      <v-col cols="5">
        <v-list>
          <v-list-item-content>
            <v-list-item-title>Image URL</v-list-item-title>
            <p class="text--secondary">
              {{ coach.attributes.imageUrl }}
            </p>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title>Info URL</v-list-item-title>
            <a
              :href="coach.attributes.infoUrl"
              target="_blank"
              rel="noopener noreferrer"
              >{{ coach.attributes.infoUrl }}</a
            >
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title>Schedule URL</v-list-item-title>
            <a
              :href="coach.attributes.scheduleUrl"
              target="_blank"
              rel="noopener noreferrer"
              >{{ coach.attributes.scheduleUrl }}</a
            >
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title>Biography</v-list-item-title>
            <p class="text--secondary" style="white-space: pre-line">
              {{ coach.attributes.biography }}
            </p>
          </v-list-item-content>
        </v-list>
      </v-col>
      <v-col cols="4">
        <v-list>
          <v-list-item-content>
            <v-list-item-title>Created At</v-list-item-title>
            <p class="text--secondary">
              {{ formatDate() }}
            </p>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title>Legacy Role</v-list-item-title>
            <p class="text--secondary" :title="coach.attributes.userRole">
              {{ userRole }}
            </p>
          </v-list-item-content>
          <UserRole :user-id="coach.attributes.userId">
            <template #default>
              <v-list-item-title>Roles</v-list-item-title>
            </template>
            <template #empty>
              <div>No roles</div>
            </template>
          </UserRole>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { OneDropUtils } from '../onedrop-utils';
import UserRole from '@/components/UserRole';

export default {
  name: 'CoachDetail',
  components: { UserRole },
  props: { coach: Object },
  computed: {
    joinedAt: function () {
      return OneDropUtils.displayDate(this.coach.attributes.createdAt);
    },
    userName: function () {
      const user = this.coach.attributes.coachUser;
      return user ? (user.firstName + ' ' + user.lastName).trim() : null;
    },
    userEmail: function () {
      const user = this.coach.attributes.coachUser;
      return user ? user.email.trim() : null;
    },
    userRole: function () {
      return this.coach.attributes.userRoleName
        ? this.coach.attributes.userRoleName
        : this.coach.attributes.userRole;
    },
  },
  methods: {
    getPartnerById: function (id) {
      return this.$store.getters.partnerById(id);
    },
    getPartnerName: function () {
      const id = this.coach.attributes.partnerId;
      if (id) {
        const p = this.getPartnerById(id);
        return p ? p.attributes.name : id;
      }
      return null;
    },
    formatDate: function () {
      const str = this.coach.attributes.createdAt;
      return str ? OneDropUtils.displayDate(str) : str;
    },
  },
};
</script>

<style scoped></style>
