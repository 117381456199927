<template>
  <v-card flat>
    <v-data-table
      :items="groupQuestions"
      :headers="headers"
      :loading="loading"
      group-by="groupId"
      :expanded.sync="expanded"
      show-expand
      single-expand
    >
      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-container fluid>
            <v-toolbar flat class="pa-0 ma-0">
              <v-toolbar-title class="pa-0 ma-0">
                Answer Choices
              </v-toolbar-title>
            </v-toolbar>
            <AnswerChoice
              v-if="item"
              :question-object-id="item.questionObjectId"
              :question-id="item.questionId"
              :questions="[]"
              read-only="true"
            />
          </v-container>
        </td>
      </template>
      <template #no-data>
        <v-btn color="primary" @click="refreshList"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { OneDropApi } from '../../onedrop-api';
import { ConsoleLog } from '../../onedrop-utils';
import AnswerChoice from './AnswerChoice';

export default {
  name: 'GroupQuestion',
  components: { AnswerChoice },
  props: ['partnerId'],
  data: function () {
    return {
      loading: false,
      expanded: [],
      headers: [
        {
          text: 'Group Id',
          value: 'groupId',
        },
        {
          text: 'Group Name',
          value: 'groupTitle',
        },
        {
          text: 'Group Slug',
          value: 'groupSlug',
        },
        {
          text: 'Question Id',
          value: 'questionId',
        },
        {
          text: 'Question',
          value: 'question',
        },
        {
          text: 'Question Slug',
          value: 'questionSlug',
        },
        {
          text: 'Sequence',
          value: 'questionSequence',
        },
        {
          text: 'Multi-choice',
          value: 'isMultiChoice',
        },
        {
          text: 'Split Answer',
          value: 'isSplitAnswer',
        },
        {
          text: 'External',
          value: 'groupIsExternal',
        },
        { text: '', value: 'data-table-expand' },
      ],
      groupQuestions: [],
    };
  },
  created() {
    this.refreshList();
  },
  methods: {
    refreshList: function () {
      ConsoleLog('Fetching group-questions');
      this.loading = true;
      OneDropApi.getAllQuestionsAndGroups(
        this.partnerId,
        (response) => {
          this.groupQuestions = response.data.map((r) => {
            let attrs = r.attributes;
            attrs.id = `${r.id}.${r.attributes.questionId}`;
            return attrs;
          });
          ConsoleLog(`Got ${this.groupQuestions.length} questions`);
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    created: function () {
      this.refreshList();
    },
  },
};
</script>

<style></style>
