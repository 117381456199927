<template>
  <v-autocomplete
    v-model="valueLocal"
    v-debounce:500ms="initSearch"
    :items="items"
    :loading="isLoading"
    hide-no-data
    hide-selected
    :item-text="fullName"
    item-value="id"
    label="User"
    placeholder="Start typing to Search"
    chips
    deletable-chips
    clearable
    :hint="hint"
    :readonly="readonly"
    @input="inputChanged"
  >
    <template #item="data">
      <v-list-item-content>
        <v-list-item-title>
          {{ data.item.attributes.firstname }}
          {{ data.item.attributes.lastname }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ data.item.attributes.email }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>{{ data.item.id }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
import { OneDropApi } from '../onedrop-api';
import { ConsoleLog } from '../onedrop-utils';

export default {
  name: 'UserSelect',
  props: {
    value: String,
    hint: String,
    initialItems: Array,
    readonly: Boolean,
  },
  data: function () {
    return {
      descriptionLimit: 60,
      items: [],
      isLoading: false,
      search: null,
      selectedUser: null,
      searchString: null,
    };
  },
  computed: {
    valueLocal: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        if (!val) {
          this.reset();
        }
        this.$emit('input', val);
      },
    },
  },
  mounted() {
    this.reset();
  },
  methods: {
    fullName: (item) => {
      let fullName = `${item.attributes.email}`;
      if (item.attributes?.lastname && item.attributes?.firstname)
        fullName = `${item.attributes.firstname} ${item.attributes.lastname} (${item.attributes.email})`;
      else if (item.attributes.lastname)
        fullName = `${item.attributes.lastname} (${item.attributes.email})`;
      else if (item.attributes.firstname)
        fullName = `${item.attributes.firstname} (${item.attributes.email})`;
      return fullName;
    },
    reset() {
      this.items = this.initialItems
        ? JSON.parse(JSON.stringify(this.initialItems))
        : [];
      this.searchString = null;
    },
    initSearch(val) {
      ConsoleLog(`Init search for ${val}`);
      if (val && val.length > 3) {
        // Start a fresh search if no search was conducted
        // or the current search is not an expansion of the previous
        // search
        if (this.searchString == null || !val.includes(this.searchString))
          this.fetchUsers(val);
      } else {
        this.selectedUser = null;
        this.items = [];
      }
    },
    fetchUsers(val) {
      ConsoleLog(`Searching for ${val}`);
      this.isLoading = true;
      OneDropApi.searchUsers(
        val,
        null,
        null,
        (response) => {
          this.searchString = val;
          this.items = response.data;
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.onApiError(error);
        }
      );
    },
    inputChanged(userId) {
      let item = this.items.find((tem) => tem.id == userId);
      if (item) this.$emit('item-changed', item);
    },
  },
};
</script>
