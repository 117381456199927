import { format, isBefore } from 'date-fns';
import jwtDecode from 'jwt-decode';
import store from './store/index';

export const OneDropUtils = {
  displayDate: function (dateStr) {
    if (!dateStr) return dateStr;
    const date = new Date(dateStr);
    return format(date, 'MMM d, yyyy h:mm a');
  },

  displayDay: function (date) {
    return format(date, 'MMM d, yyy');
  },

  crashReportURIForUser: function (userId) {
    return `https://console.firebase.google.com/project/one-drop-android/crashlytics/app/android:today.onedrop.android/search?time=last-ninety-days&type=all&q=${userId}`;
  },

  parseSessionTokenFromJwt: function (jwtToken) {
    const jwt = jwtDecode(jwtToken);
    return `r:${jwt.sub}`;
  },

  isJwtValid: function () {
    const jwtString = store.state.jwtToken;
    if (jwtString) {
      // check if the jwt has expired or will expired in the next 60 seconds:
      const jwt = jwtDecode(jwtString);
      const expirationDate = new Date(jwt.exp * 1000 - 60000);
      return isBefore(new Date(), expirationDate);
    } else {
      return false;
    }
  },

  syntaxHighlightJSON: function (json) {
    if (typeof json != 'string') {
      json = JSON.stringify(json, undefined, 2);
    }
    json = json
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
    return json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
      function (match) {
        var cls = 'number';
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key';
          } else {
            cls = 'string';
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean';
        } else if (/null/.test(match)) {
          cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
      }
    );
  },
};

// eslint-disable-next-line
export const ConsoleLog = function (args) {
  // console.log(args)
};

export const OneDropConstants = {
  templatePartnerId: 'TAijpdcPhC',
  coachDefaultPartnerId: '7txQU8miJK',
  trackTagOptions: [
    'type-1',
    'type-2',
    'pre-diabetes',
    'high-blood-pressure',
    'high-cholesterol',
    'gestational',
    'cv-prevent',
  ],
  trackLanguageOptions: {
    en: 'English',
    ar: 'Arabic',
    it: 'Italian',
    es: 'Spanish',
    ru: 'Russian',
    de: 'German',
    fr: 'French',
    pt: 'Portuguese',
    zh: 'Chinese',
  },
};
