<!-- prettier-ignore -->
<!-- The CreatePartner component is designed as a dialog that allows a user to build an entire new Partner contract 
  in a step by step process rather then having to visit multiple pages in monorail. Previously when a new partner 
  needed to be created a user would copy Moose Industries and replace all the information. The donorId
  is where we set that donor to always be Moose. Step 1 adds the basic Partner info such as name and 
  icon image, which normally happens on the Manage Partners page as part of the copy action. Step 2 is what normally
  happens on the Partner Program page. In Step 3 we get the list of Tracks they want for the partner based on the 
  language and condition selection. In Step 4 we return that list and the user has the opinion to un select values.
  In Step 5 the user can add pricing items which normally would need to be done on the Program Pricing page. Finally
  on Step 6 is a summary of all the information that has been added and that is saved to the four different endpoints
  in onSaveChanges when the dialog is saved successfully and closed. One requirement for this to work is saving the 
  new partner after the user goes from Step 1 to Step 2. That new Partner id is required to save information later
  in Step 6. If a user cancels then we call the deleteClient to remove that partner that was created but not finished.
  Most of the complex logic in createPartner is to account for all the different states the Partner id could be in,
  it is trying to account for the cases of a user revisiting Step 1 and the changes they might make.
-->

<template>
  <v-dialog
    v-model="showPartnerEditor"
    max-width="80%"
    @click:outside="onCancel"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Create Partner</span>
      </v-card-title>

      <v-card-text>
        <v-progress-linear v-if="isSaving" indeterminate color="primary" />
        <v-stepper v-model="mode" non-linear>
          <v-stepper-header>
            <v-stepper-step step="1"> Client </v-stepper-step>
            <v-divider />
            <v-stepper-step step="2"> Program </v-stepper-step>
            <v-divider />
            <v-stepper-step step="3"> Conditions </v-stepper-step>
            <v-divider />
            <v-stepper-step step="4"> Tracks </v-stepper-step>
            <v-divider />
            <v-stepper-step step="5"> Pricing </v-stepper-step>
            <v-divider />
            <v-stepper-step step="6"> Summary </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-row dense align="center">
                <v-col cols="5">
                  <v-text-field
                    v-model="clientInfo.partnerName"
                    label="Partner Name"
                    outlined
                    hide-details
                  />
                </v-col>
                <v-col cols="1" />
                <v-col cols="5">
                  <v-combobox
                    v-model="clientInfo.partnerCodes"
                    :items="[]"
                    chips
                    multiple
                    label="Partner Code (Optional)"
                    deletable-chips
                  />
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="5">
                  <v-text-field
                    v-model="clientInfo.primaryIconURL"
                    label="Primary Icon URL (Optional)"
                    outlined
                    hide-details
                    append-outer-icon="mdi-paperclip"
                    @click:append-outer="editPrimaryIconDialog = true"
                  />
                  <amazon-s3-uploader
                    v-model="editPrimaryIconDialog"
                    :edit-item="clientInfo"
                    image-field="primaryIconURL"
                    @setUrlAttribute="setUrlAttribute"
                  />
                </v-col>
                <v-col cols="1" />
                <v-col cols="5">
                  <v-text-field
                    v-model="clientInfo.primaryIconURLSettings"
                    label="Primary Icon URL Settings (Optional)"
                    outlined
                    hide-details
                    append-outer-icon="mdi-paperclip"
                    @click:append-outer="editPrimaryIconSettingsDialog = true"
                  />
                  <amazon-s3-uploader
                    v-model="editPrimaryIconSettingsDialog"
                    :edit-item="clientInfo"
                    image-field="primaryIconURLSettings"
                    @setUrlAttribute="setUrlAttribute"
                  />
                </v-col>
              </v-row>
              <v-row align="center" class="mb-3">
                <v-col cols="5">
                  <v-text-field
                    v-model="clientInfo.settingsPrimaryIconURL"
                    label="Settings Primary Icon URL (Optional)"
                    outlined
                    hide-details
                    append-outer-icon="mdi-paperclip"
                    @click:append-outer="editSettingsDialog = true"
                  />
                  <amazon-s3-uploader
                    v-model="editSettingsDialog"
                    :edit-item="clientInfo"
                    image-field="settingsPrimaryIconURL"
                    @setUrlAttribute="setUrlAttribute"
                  />
                </v-col>
              </v-row>
              <v-divider />
              <v-card-actions class="mt-4">
                <v-row>
                  <v-col align="start">
                    <v-btn
                      large
                      depressed
                      color="primary"
                      class="mr-2"
                      text
                      @click="onCancel"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                  <v-col align="end">
                    <v-btn
                      large
                      depressed
                      color="primary"
                      text
                      :disabled="clientNextButtonDisabled"
                      @click="createPartner"
                    >
                      Next
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-row dense align="center">
                <v-col cols="5">
                  <v-text-field
                    v-model="programInfo.totalEligible"
                    label="Total Eligible"
                    outlined
                    hide-details
                  />
                </v-col>
                <v-col cols="1" />
                <v-col cols="5" class="pt-8">
                  <v-menu
                    ref="dateMenu"
                    v-model="programGoLiveDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="290px"
                    max-width="290px"
                    offset-y
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="programInfo.goLiveDate"
                        label="Start Date"
                        prepend-inner-icon="mdi-calendar"
                        hint="YYYY-MM-DD"
                        persistent-hint
                        outlined
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="programInfo.goLiveDate"
                      no-title
                      @input="programGoLiveDateMenu = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-col cols="5">
                  <v-menu
                    ref="dateMenu"
                    v-model="programEndDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="290px"
                    max-width="290px"
                    offset-y
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="programInfo.endDate"
                        label="End Date"
                        prepend-inner-icon="mdi-calendar"
                        hint="YYYY-MM-DD"
                        persistent-hint
                        outlined
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="programInfo.endDate"
                      no-title
                      @input="programEndDateMenu = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>
              <v-divider />
              <v-card-actions class="mt-4">
                <v-row>
                  <v-col align="start">
                    <v-btn
                      large
                      depressed
                      color="primary"
                      class="mr-2"
                      text
                      @click="onCancel"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                  <v-col align="end">
                    <v-btn
                      large
                      depressed
                      color="primary"
                      class="mr-2"
                      text
                      @click="mode = '1'"
                    >
                      Previous
                    </v-btn>
                    <v-btn
                      large
                      depressed
                      color="primary"
                      text
                      :disabled="programNextButtonDisabled"
                      @click="mode = '3'"
                    >
                      Next
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-row dense align="center">
                <v-col cols="5">
                  <v-autocomplete
                    v-model="conditionsInfo.conditions"
                    :items="conditionOptions"
                    label="Conditions"
                    hide-selected
                    chips
                    deletable-chips
                    multiple
                  />
                </v-col>
                <v-col cols="1" />
                <v-col cols="5">
                  <v-select
                    v-model="conditionsInfo.languageServed"
                    :items="Object.entries(languageOptions)"
                    label="Language Served"
                    item-text="[1]"
                    item-value="[0]"
                    hide-selected
                    chips
                    deletable-chips
                    multiple
                  />
                </v-col>
              </v-row>
              <v-divider />
              <v-card-actions class="mt-4">
                <v-row>
                  <v-col align="start">
                    <v-btn
                      large
                      depressed
                      color="primary"
                      class="mr-2"
                      text
                      @click="onCancel"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                  <v-col align="end">
                    <v-btn
                      large
                      depressed
                      color="primary"
                      class="mr-2"
                      text
                      @click="mode = '2'"
                    >
                      Previous
                    </v-btn>
                    <v-btn
                      large
                      depressed
                      color="primary"
                      text
                      :disabled="conditionsNextButtonDisabled"
                      @click="getConditionTracks"
                    >
                      Next
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-stepper-content>
            <v-stepper-content step="4">
              <v-data-table
                v-model="selectedConditionTracks"
                :items="allConditionTracks"
                :headers="conditionTrackHeaders"
                show-select
              >
              </v-data-table>
              <v-divider />
              <v-card-actions class="mt-4">
                <v-row>
                  <v-col align="start">
                    <v-btn
                      large
                      depressed
                      color="primary"
                      class="mr-2"
                      text
                      @click="onCancel"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                  <v-col align="end">
                    <v-btn
                      large
                      depressed
                      color="primary"
                      class="mr-2"
                      text
                      @click="mode = '3'"
                    >
                      Previous
                    </v-btn>
                    <v-btn
                      large
                      depressed
                      color="primary"
                      text
                      :disabled="conditionsNextButtonDisabled"
                      @click="mode = '5'"
                    >
                      Next
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-stepper-content>
            <v-stepper-content step="5">
              <v-container fluid>
                <v-data-table
                  :headers="pricingHeaders"
                  :items="pricingItems"
                  multi-sort
                >
                  <template #top>
                    <v-container fluid>
                      <v-toolbar flat color="white">
                        <v-spacer />
                        <v-spacer />
                        <v-btn
                          color="primary"
                          class="mr-2"
                          @click="onPricingNewItem"
                        >
                          New Employer program pricing
                        </v-btn>
                      </v-toolbar>
                    </v-container>
                  </template>
                  <template #item.action="{ item }">
                    <v-icon class="mr-2" @click.stop="onPricingEditItem(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon @click.stop="onPricingDeleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
                <v-dialog v-model="editPricingItemDialog" max-width="60%">
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text v-if="editPricingItem">
                      <div v-if="editPricingItem.id" class="pb-2">
                        <span class="od-label">ID:</span
                        ><span class="pl-2">{{ editPricingItem.id }}</span>
                      </div>
                      <v-container fluid>
                        <v-row>
                          <v-col>
                            <v-combobox
                              v-model="editPricingItem.attributes.sku"
                              :items="skus"
                              label="SKU"
                              hide-selected
                              chips
                              deletable-chips
                            />
                          </v-col>
                          <v-col>
                            <v-combobox
                              v-model="editPricingItem.attributes.dependentSku"
                              :items="skus"
                              label="Dependent SKU (Optional)"
                              hide-selected
                              chips
                              deletable-chips
                            />
                          </v-col>
                          <v-col>
                            <v-combobox
                              v-model="editPricingItem.attributes.feeType"
                              :items="feeTypes"
                              label="Fee Type"
                              hide-selected
                              chips
                              deletable-chips
                            />
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="editPricingItem.attributes.price"
                              type="number"
                              step="any"
                              outlined
                              label="Price"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-menu
                              ref="dateMenu"
                              v-model="pricingStartDateMenu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              min-width="290px"
                              max-width="290px"
                              offset-y
                            >
                              <template #activator="{ on }">
                                <v-text-field
                                  v-model="editPricingItem.attributes.startDate"
                                  label="Start Date"
                                  prepend-inner-icon="mdi-calendar"
                                  hint="YYYY-MM-DD"
                                  persistent-hint
                                  outlined
                                  v-on="on"
                                />
                              </template>
                              <v-date-picker
                                v-model="editPricingItem.attributes.startDate"
                                no-title
                                @input="pricingStartDateMenu = false"
                              />
                            </v-menu>
                          </v-col>
                          <v-col>
                            <v-menu
                              ref="dateMenu"
                              v-model="pricingEndDateMenu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              min-width="290px"
                              max-width="290px"
                              offset-y
                            >
                              <template #activator="{ on }">
                                <v-text-field
                                  v-model="editPricingItem.attributes.endDate"
                                  label="End Date"
                                  prepend-inner-icon="mdi-calendar"
                                  hint="YYYY-MM-DD"
                                  persistent-hint
                                  outlined
                                  v-on="on"
                                />
                              </template>
                              <v-date-picker
                                v-model="editPricingItem.attributes.endDate"
                                no-title
                                @input="pricingEndDateMenu = false"
                              />
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="onPricingCancel"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="onPricingAddItem"
                      >
                        Add
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-container>
              <v-divider />
              <v-card-actions class="mt-4">
                <v-row>
                  <v-col align="start">
                    <v-btn
                      large
                      depressed
                      color="primary"
                      class="mr-2"
                      text
                      @click="onCancel"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                  <v-col align="end">
                    <v-btn
                      large
                      depressed
                      color="primary"
                      class="mr-2"
                      text
                      @click="mode = '4'"
                    >
                      Previous
                    </v-btn>
                    <v-btn
                      large
                      depressed
                      color="primary"
                      text
                      :disabled="pricingNextButtonDisabled"
                      @click="mode = '6'"
                    >
                      Next
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-stepper-content>
            <v-stepper-content step="6">
              <div
                v-for="(values, title, index) in summaryFields"
                :key="`title-${index}`"
                class="mb-3"
              >
                <h2 class="od-label mb-2">{{ title }}</h2>
                <div v-for="item in values" :key="item.name" class="mb-2">
                  <span class="od-label mr-4" width="145px">
                    {{ item.name }}
                  </span>
                  <a
                    v-if="item.prefix"
                    target="_blank"
                    rel="noopener noreferrer"
                    :href="
                      getUrlValue(
                        item.prefix,
                        item.field,
                        item.parentId,
                        item.suffix
                      )
                    "
                    >{{
                      getUrlValue(
                        item.prefix,
                        item.field,
                        item.parentId,
                        item.suffix
                      )
                    }}</a
                  >
                  <span v-else>
                    {{ getFieldValue(item.field, item.parentId) }}
                  </span>
                </div>
              </div>
              <v-divider />
              <v-card-actions class="mt-4">
                <v-row>
                  <v-col align="start">
                    <v-btn
                      large
                      depressed
                      color="primary"
                      class="mr-2"
                      text
                      @click="onCancel"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                  <v-col align="end">
                    <v-btn
                      large
                      depressed
                      color="primary"
                      text
                      @click="mode = '5'"
                    >
                      Previous
                    </v-btn>
                    <v-btn
                      large
                      depressed
                      color="primary"
                      text
                      @click="onSaveChanges"
                    >
                      Save
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { OneDropApi } from '@/onedrop-api';
import { OneDropConstants } from '../onedrop-utils';
import AmazonS3Uploader from '@/components/AmazonS3Uploader';

export default {
  name: 'CreatePartner',
  components: { AmazonS3Uploader },
  props: {
    value: Boolean,
    refreshPartners: Function,
  },
  data: function () {
    return {
      loading: false,
      mode: '1',
      isSaving: false,
      partnerId: '',
      apiSlug: '',
      clientInfo: {
        partnerName: '',
        partnerCodes: '',
        primaryIconURL: '',
        primaryIconURLSettings: '',
        settingsPrimaryIconURL: '',
      },
      editPrimaryIconDialog: false,
      editPrimaryIconSettingsDialog: false,
      editSettingsDialog: false,
      clientInfoCopy: {},
      programInfo: {
        totalEligible: '',
        goLiveDate: '',
        endDate: '',
      },
      programGoLiveDateMenu: false,
      programEndDateMenu: false,
      conditionsInfo: {
        conditions: [],
        languageServed: [],
      },
      languageOptions: OneDropConstants.trackLanguageOptions,
      conditionOptions: OneDropConstants.trackTagOptions,
      selectedConditionTracks: [],
      allConditionTracks: [],
      conditionTrackHeaders: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Schedule Version ID',
          value: 'attributes.scheduleVersionId',
        },
        {
          text: 'Schedule Version',
          value: 'attributes.scheduleVersion',
        },
        {
          text: 'Tags',
          value: 'attributes.tags',
        },
        {
          text: 'Hidden',
          value: 'attributes.hidden',
        },
        {
          text: 'Language',
          value: 'attributes.language',
        },
      ],
      pricingStartDateMenu: false,
      pricingEndDateMenu: false,
      selectedPartners: [],
      feeTypes: ['Enrollment Fee', 'Monthly Utilization Fee'],
      pricingHeaders: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Partner ID',
          value: 'attributes.partnerId',
        },
        {
          text: 'Partner Name',
          value: 'attributes.partnerName',
        },
        {
          text: 'SKU',
          value: 'attributes.sku',
        },
        {
          text: 'Dependent SKU (Optional)',
          value: 'attributes.dependentSku',
        },
        {
          text: 'Fee Type',
          value: 'attributes.feeType',
        },
        {
          text: 'Start Date',
          value: 'attributes.startDate',
        },
        {
          text: 'End Date',
          value: 'attributes.endDate',
        },
        {
          text: 'Price',
          value: 'attributes.price',
        },
        { text: 'Actions', value: 'action', sortable: false, width: '100px' },
      ],
      pricingItems: [],
      skus: [],
      search: '',
      editPricingItemDialog: false,
      deletePricingItemDialog: false,
      editPricingItem: '',
      summaryFields: {
        Overview: [
          {
            name: 'Partner Name',
            field: 'partnerName',
            parentId: 'clientInfo',
          },
          {
            name: 'Conditions',
            field: 'conditions',
            parentId: 'conditionsInfo',
          },
          {
            name: 'Contract Start Date',
            field: 'goLiveDate',
            parentId: 'programInfo',
          },
          {
            name: 'Contract End Date',
            field: 'endDate',
            parentId: 'programInfo',
          },
          {
            name: 'Total Eligible',
            field: 'totalEligible',
            parentId: 'programInfo',
          },
        ],
        'Enrollment URLs': [
          {
            name: 'Dev URL',
            prefix:
              'https://onedrop-enrollment-dev.s3.amazonaws.com/index.html?partnerId=',
            field: 'partnerId',
            suffix: '#/',
          },
          {
            name: 'Production URL',
            prefix: 'https://enrollment.onedrop.today/index.html?partnerId=',
            field: 'partnerId',
            suffix: '#/',
          },
          {
            name: 'Spanish URL',
            prefix: 'https://enrollment.onedrop.today/index.html?partnerId=',
            field: 'partnerId',
            suffix: '&lang=es#/',
          },
          {
            name: 'Text Me The App URL',
            prefix: 'https://onedrop-today.app.link/G4Nij7tPwgb?partnerCode=[',
            field: 'partnerCodes',
            parentId: 'clientInfo',
            suffix: ']&locale=en_us',
          },
        ],
        'Please Copy This Information in the Employer Client Tracker': [
          { name: 'Partner Id', field: 'partnerId' },
          { name: 'API Slug', field: 'apiSlug' },
        ],
      },
    };
  },
  computed: {
    showPartnerEditor: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    formTitle() {
      return this.editPricingItem?.id
        ? 'Edit Employer program pricing'
        : 'New Employer program pricing';
    },
    clientNextButtonDisabled() {
      return this.clientInfo?.partnerName === '';
    },
    programNextButtonDisabled() {
      return !(
        this.programInfo?.totalEligible !== '' &&
        this.programInfo?.goLiveDate !== '' &&
        this.programInfo?.endDate !== ''
      );
    },
    conditionsNextButtonDisabled() {
      return (
        this.conditionsInfo?.conditions?.length < 1 ||
        this.conditionsInfo?.languageServed?.length < 1
      );
    },
    pricingNextButtonDisabled() {
      return this.pricingItems.length < 1;
    },
  },
  mounted() {
    this.getPricingSkus();
    this.getTemplatePricingItems();
  },
  methods: {
    getFieldValue(field, parentId) {
      return parentId ? this[parentId][field] : this?.[field] ?? field;
    },
    getUrlValue(prefix, field, parentId, suffix) {
      return (
        prefix +
        (parentId && this[parentId][field] !== ''
          ? this[parentId][field]
          : this[field] ?? 'ENTER-ID') +
        suffix
      );
    },
    setUrlAttribute(attributeKey, newVal) {
      this.clientInfo[attributeKey] = newVal;
    },
    onCancel() {
      this.deleteClient();
      this.mode = '1';
      this.partnerId = '';
      this.apiSlug = '';
      this.clientInfo = {};
      this.clientInfoCopy = {};
      this.programInfo = {};
      this.conditionsInfo = {};
      this.showPartnerEditor = false;
      this.pricingItems = [];
      this.loading = false;
      this.isSaving = false;
    },
    deleteClient() {
      if (this.partnerId !== '' && this.mode !== '6') {
        OneDropApi.deletePartner(
          this.partnerId,
          () => {
            this.$store.commit('deletePartner', this.partnerId);
            this.$emit('refreshPartners');
          },
          (error) => {
            this.onApiError(error);
          }
        );
      }
      return;
    },
    createPartner() {
      const attrs = {
        name: this.clientInfo.partnerName,
        primaryIconURL: this.clientInfo.primaryIconURL,
        primaryIconURLSettings: this.clientInfo.primaryIconURLSettings,
        settingsPrimaryIconURL: this.clientInfo.settingsPrimaryIconURL,
      };
      // user has returned to client step but has made no changes
      if (
        JSON.stringify(Object.values(attrs)) ===
        JSON.stringify(Object.values(this.clientInfoCopy))
      ) {
        this.mode = '2';
        return;
      }
      // check if we need to delete old partner just incase user returned to client step and made changes
      this.deleteClient();
      this.clientInfoCopy = { ...attrs };
      const body = {
        data: [
          {
            type: 'partner',
            attributes: attrs,
          },
        ],
      };
      const donorId = OneDropConstants.templatePartnerId;
      let url = `v3/admin/resource/clone-partner/${donorId}`;
      OneDropApi.post(
        url,
        body,
        (response) => {
          const partner = response.data[0];
          this.$store.commit('addPartner', partner);
          this.partnerId = partner.id;
          this.apiSlug = partner.attributes.apiSlug;
          this.mode = '2';
          this.pricingItems = this.pricingItems.map((item) => {
            item.attributes.partnerId = partner.id;
            item.attributes.partnerName = this.clientInfo.partnerName;
            return item;
          });
        },
        (error) => {
          this.onApiError(error + ' Please close Create Partner and try again');
        }
      );
    },
    getConditionTracks() {
      OneDropApi.get(
        `/v3/admin/resource/partner/${OneDropConstants.templatePartnerId}/tracks?diagnosis-types=${this.conditionsInfo.conditions}&language=${this.conditionsInfo.languageServed}`,
        (response) => {
          const tracks = response.data.map((v) => {
            return {
              ...v,
              attributes: {
                ...v.attributes,
                tags: JSON.stringify(v.attributes.tags),
              },
            };
          });
          this.selectedConditionTracks = tracks;
          this.allConditionTracks = tracks;
          this.mode = '4';
        },
        (error) => {
          this.onApiError(error + ' Please close Create Partner and try again');
        }
      );
    },
    getPricingSkus() {
      OneDropApi.get(
        '/admin/v3/employer/employer-program-pricing/skus',
        (resp) => {
          this.skus = resp.data.map((sku) => sku.id);
        },
        (error) => {
          this.onApiError(error);
        }
      );
    },
    getTemplatePricingItems() {
      OneDropApi.get(
        '/admin/v3/employer/employer-program-pricing',
        (resp) => {
          this.pricingItems = resp.data.filter(
            (items) =>
              items.attributes.partnerId === OneDropConstants.templatePartnerId
          );
        },
        (error) => {
          this.onApiError(error);
        }
      );
    },
    onPricingNewItem() {
      this.editPricingItem = {
        attributes: {
          endDate: '',
          partnerName: this.clientInfo.partnerName,
          partnerId: this.partnerId,
          sku: '',
          dependentSku: '',
          feeType: '',
          price: '',
          startDate: '',
        },
        type: 'employer-program-pricing',
      };
      this.editPricingItemDialog = true;
    },
    onPricingEditItem(item) {
      this.editPricingItem = JSON.parse(JSON.stringify(item));
      this.editPricingItemDialog = true;
    },
    onPricingDeleteItem(item) {
      this.editPricingItem = item;
      const index = this.pricingItems.findIndex((v) => v.id === item.id);
      if (index > -1) this.pricingItems.splice(index, 1);
    },
    onPricingCancel() {
      this.editPricingItem = null;
      this.editPricingItemDialog = false;
      this.deletePricingItemDialog = false;
    },
    onPricingAddItem() {
      const index = this.pricingItems.findIndex(
        (val) => val.id == this.editPricingItem.id
      );
      if (index < 0) {
        let price = this.editPricingItem.attributes.price;
        this.editPricingItem.attributes.price = price ? Number(price) : price;
        this.editPricingItem.id = String(
          Math.floor(100000000000 + Math.random() * 900000000000)
        );
        this.pricingItems.push(this.editPricingItem);
      } else this.pricingItems.splice(index, 1, this.editPricingItem);
      this.editPricingItemDialog = false;
    },
    onSaveChanges() {
      this.isSaving = true;
      let prom1 = new Promise((resolve, reject) => {
        if (this.clientInfo?.partnerCodes === '') resolve(0);
        else {
          let data = JSON.parse(JSON.stringify(this.clientInfo.partnerCodes));
          data = data.map((d) => {
            return { id: d, type: 'partner-code' };
          });
          let body = { data: data };
          OneDropApi.patch(
            `/v3/partner/${this.partnerId}/codes`,
            body,
            () => {
              resolve(0);
            },
            (error) => {
              reject(error);
            }
          );
        }
      });
      let prom2 = new Promise((resolve, reject) => {
        const programInfoItem = {
          attributes: {
            endDate: this.programInfo.endDate,
            isCurrentContract: false,
            partnerId: this.partnerId,
            partnerName: this.clientInfo.partnerName,
            rank: 1,
            startDate: this.programInfo.goLiveDate,
            totalEligible: this.programInfo.totalEligible,
          },
          type: 'partner-contract',
        };
        let body = { data: [programInfoItem] };
        OneDropApi.patch(
          '/admin/v3/employer/partner-contract',
          body,
          () => {
            resolve(0);
          },
          (error) => {
            reject(error);
          }
        );
      });
      let prom3 = new Promise((resolve, reject) => {
        const employerPricing = this.pricingItems.map((v) => {
          delete v.id;
          return v;
        });
        let body = { data: employerPricing };
        OneDropApi.patch(
          '/admin/v3/employer/employer-program-pricing',
          body,
          () => {
            resolve(0);
          },
          (error) => {
            reject(error);
          }
        );
      });
      let prom4 = new Promise((resolve, reject) => {
        let body = { data: this.selectedConditionTracks };
        OneDropApi.patch(
          `/v3/admin/resource/partner/${this.partnerId}/tracks`,
          body,
          () => {
            resolve(0);
          },
          (error) => {
            reject(error);
          }
        );
      });
      Promise.all([prom1, prom2, prom3, prom4])
        .then(() => {
          this.toast(`Contract ${this.partnerId} has been saved successfully`);
          this.onCancel();
          this.$emit('refreshPartners');
        })
        .catch((error) => {
          this.onApiError(error);
          this.isSaving = false;
        });
    },
  },
};
</script>

<style scoped>
.v-file-input {
  flex-direction: row-reverse;
}
</style>
