<!-- Show track details -->
<template>
  <v-container fluid>
    <v-progress-circular v-if="loading" indeterminate color="primary" />
    <v-row v-else>
      <v-col cols="5">
        <v-list-item-title>Fields</v-list-item-title>
        <v-simple-table dense>
          <template #default>
            <tbody>
              <tr>
                <td class="od-label">ID</td>
                <td>{{ track.id }}</td>
              </tr>
              <tr v-for="item in textFields" :key="item">
                <td v-if="track.attributes[item]" class="od-label">
                  {{ item }}
                </td>
                <td v-if="track.attributes[item]">
                  {{ track.attributes[item] }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="2">
        <v-list-item-title class="mb-1"> Hero Image </v-list-item-title>
        <div class="mb-4">
          <v-img
            :src="track.attributes.heroImageUrl"
            :title="track.attributes.heroImageUrl"
            max-height="100"
            max-width="200"
            contain
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-list-item-title>Topics Page Content</v-list-item-title>
        <ContentResourceView
          resource-type="track"
          :resource-id="track.id"
          :default-values="defaultContent"
          @changed="onChanged"
        >
          <template #resources="resources">
            <v-list v-for="(group, key) in resources.resources" :key="key">
              <v-list-item-title>Group {{ key }}</v-list-item-title>
              <v-data-table
                :items="getDetails(group)"
                :headers="resourceHeaders"
                dense
              />
            </v-list>
          </template>
        </ContentResourceView>
      </v-col>
      <v-col cols="6">
        <v-list-item-title>Track Assignment Content</v-list-item-title>
        <ContentResourceView
          resource-type="employer-enrollment"
          :resource-id="track.id"
        >
          <template #resources="resources">
            <v-list v-for="(group, key) in resources.resources" :key="key">
              <v-list-item-title>Group {{ key }}</v-list-item-title>
              <v-data-table
                :items="getDetails(group)"
                :headers="resourceHeaders"
                dense
              />
            </v-list>
          </template>
        </ContentResourceView>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ConsoleLog } from '../../onedrop-utils';
import ContentResourceView from '@/components/content/ContentResourceView';

export default {
  name: 'TrackDetail',
  components: { ContentResourceView },
  props: ['track', 'contentFields', 'prefix', 'allTracks'],
  data: function () {
    return {
      coaches: null,
      loading: false,
      textFields: ['partner', 'slug', 'tags', 'sortOrder', 'userCount'],
      resourceHeaders: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Partner',
          value: 'partner',
        },
      ],
    };
  },
  computed: {
    defaultContent: function () {
      let values = {};
      if (this.track) {
        this.contentFields.map((f) => {
          values[f] = { defaultValue: this.track.attributes[f] };
        });
      }
      return values;
    },
  },
  methods: {
    onChanged(changes) {
      ConsoleLog(`onChanged: changes: ${JSON.stringify(changes)}`);
      let p = JSON.parse(JSON.stringify(this.track));
      let attrs = p.attributes;
      for (let k in changes) {
        ConsoleLog(`onChanged: k: ${k}, ${attrs[k]}, ${changes[k]}`);
        attrs[k] = changes[k].defaultValue;
      }
      this.$emit('changed', p);
    },
    getTrack(trackId) {
      return this.allTracks
        ? this.allTracks.find((t) => t.id == trackId)
        : null;
    },
    getDetails(group) {
      return group.map((id) => {
        return {
          id: id,
          name: this.getTrackName(id),
          partner: this.getPartnerName(id),
        };
      });
    },
    getPartnerById: function (id) {
      return this.$store.getters.partnerById(id);
    },
    getPartnerName: function (trackId) {
      if (trackId) {
        const t = this.getTrack(trackId);
        if (t) {
          const p = this.getPartnerById(t.attributes.partner);
          return p ? p.attributes.name : null;
        }
      }
      return null;
    },
    getTrackName(trackId) {
      if (trackId) {
        let t = this.getTrack(trackId);
        if (t) return t.attributes.title;
      }
      return trackId;
    },
  },
};
</script>

<style scoped></style>
