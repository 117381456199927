<template>
  <v-card>
    <v-card-title><span class="headline">Delete User</span></v-card-title>
    <v-container>
      <v-row>
        <v-col class="od-heading" cols="2"> User Id: </v-col>
        <v-col>{{ userId }}</v-col>
      </v-row>
      <v-row>
        <v-col class="od-heading" cols="2"> Name: </v-col>
        <v-col>{{ getName(user) }}</v-col>
      </v-row>
      <v-row>
        <v-col class="od-heading" cols="2"> Email: </v-col>
        <v-col>{{ user.attributes.email }}</v-col>
      </v-row>
    </v-container>
    <v-card-actions>
      <v-spacer />
      <v-btn color="blue darken-1" text @click="$emit('cancel')">
        Cancel
      </v-btn>
      <v-btn color="blue darken-1" text @click="onSubmitClicked()">
        Delete
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { ConsoleLog } from '../onedrop-utils';

export default {
  name: 'DeleteUser',
  props: ['userId', 'user', 'url'],
  data: function () {
    return {
      deleting: false,
      done: false,
    };
  },
  methods: {
    getName(user) {
      if (user) {
        let name = user.attributes.firstname + ' ' + user.attributes.lastname;
        return name.trim();
      }
      return user;
    },
    onSubmitClicked: function () {
      ConsoleLog(`Deleting user ${this.userId}`);
      this.deleting = true;
      this.results = [[], [], [], []];
      OneDropApi.deleteUser(
        this.userId,
        (resp) => {
          ConsoleLog(resp);
          let jobId = resp.data[0].id;
          ConsoleLog(`Submitted deleteUser ${this.userId} with jobId=${jobId}`);
          this.$router.push({
            path: `/job-status/${jobId}`,
            query: { url: this.url },
          });
          // setTimeout(function() { self.getStatus(jobId) }, 500)
        },
        (error) => {
          ConsoleLog('Received error', error);
          this.onApiError(`Error deleting: ${error}`);
          this.done = false;
          this.deleting = false;
          this.results = null;
        }
      );
    },
    onDoneClicked: function () {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
