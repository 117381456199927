<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Fields</v-list-item-title>
            <v-simple-table dense>
              <template #default>
                <tbody>
                  <tr>
                    <td class="od-label">ID</td>
                    <td>{{ partner.id }}</td>
                  </tr>
                  <tr v-for="item in textFields" :key="item.field">
                    <td v-if="partner.attributes[item.field]" class="od-label">
                      {{ item.field }}
                    </td>
                    <td v-if="partner.attributes[item.field]">
                      {{ partner.attributes[item.field] }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <partner-codes :partner-id="partner.id" />
            <PartnerGroup :partner-id="partner.id" />
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="2">
        <div v-for="item in imageFields" :key="item.field">
          <div v-if="partner.attributes[item.field]">
            <div class="od-label mb-2">
              {{ item.name }}
            </div>
            <div class="mb-4">
              <a
                :href="partner.attributes[item.field]"
                target="_blank"
                rel="noopener noreferrer"
              >
                <v-img
                  :src="partner.attributes[item.field]"
                  :title="partner.attributes[item.field]"
                  max-height="30"
                  max-width="100"
                  contain
                />
              </a>
            </div>
          </div>
        </div>
        <div v-for="item in booleanFields" :key="item.field">
          <!-- eslint-disable vue/no-mutating-props -->
          <v-switch
            v-model="partner.attributes[item.field]"
            :label="item.name"
            readonly
            dense
            class="mt-0 mb-0 pt-0 pb-0 od-label"
          />
          <!-- eslint-enable -->
        </div>
      </v-col>
      <v-col>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Localized Content</v-list-item-title>
            <ContentResourceView
              resource-type="partner"
              :resource-id="partner.id"
              :default-values="defaultContent"
              @changed="onChanged"
            >
              <template #resources="resources">
                <v-list v-for="(group, key) in resources.resources" :key="key">
                  <v-list-item-title>Group {{ key }}</v-list-item-title>
                  <v-simple-table dense>
                    <template #default>
                      <tbody>
                        <tr>
                          <th class="text-left">ID</th>
                          <th class="text-left">Name</th>
                        </tr>
                        <tr v-for="(item, i) in group" :key="i">
                          <td class="text-left">
                            {{ item }}
                          </td>
                          <td class="text-left">
                            {{ getPartnerName(item) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-list>
              </template>
            </ContentResourceView>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ConsoleLog } from '../onedrop-utils';
import ContentResourceView from '@/components/content/ContentResourceView';
import PartnerCodes from '@/components/PartnerCodes';
import PartnerGroup from '@/components/PartnerGroup';

export default {
  name: 'PartnerDetail',
  components: { ContentResourceView, PartnerCodes, PartnerGroup },
  props: ['partner'],
  data: function () {
    return {
      textFields: [
        { name: 'Name', field: 'name' },
        { name: 'API Slug', field: 'apiSlug' },
        { name: 'Tag', field: 'tag' },
        { name: 'Support Email', field: 'supportEmail' },
        { name: 'Partner Group Tag', field: 'partnerGroupTag' },
        { name: 'Signup Code Prefix', field: 'signupCodePrefix' },
        { name: 'Terms and Conditions', field: 'termsAndConditions' },
        {
          name: 'Coaching Terms and Conditions',
          field: 'coachingTermsAndConditions',
        },
        { name: 'Default Chat Language', field: 'defaultChatLanguage' },
        {
          name: 'Coaching Logo Onboarding Custom',
          field: 'coachingLogoOnboardingCustom',
        },
        { name: 'Coaching Privacy Policy', field: 'coachingPrivacyPolicy' },
        { name: 'Coaching Dashboard Title', field: 'coachingDashboardTitle' },
        {
          name: 'Coaching Dashboard Subtitle',
          field: 'coachingDashboardSubtitle',
        },
        {
          name: 'Chat ReadOnly Shopify Slug',
          field: 'chatReadOnlyShopifySlug',
        },
        {
          name: 'Chat ReadOnly External Url',
          field: 'chatReadOnlyExternalUrl',
        },
        { name: 'Coach Privacy Policy Url', field: 'coachPrivacyPolicyUrl' },
        {
          name: 'Coaching App Additional Fields',
          field: 'coachingAppAdditionalFields',
        },
      ],
      booleanFields: [
        { name: 'Employer', field: 'employer' },
        { name: 'Archived', field: 'archived' },
        { name: 'Coaching Enabled', field: 'coachingEnabled' },
        { name: 'Premium Allowed', field: 'premiumAllowed' },
        { name: 'Tracks Enabled', field: 'tracksEnabled' },
        { name: 'First Name Enabled', field: 'firstNameEnabled' },
        { name: 'Last Name Enabled', field: 'lastNameEnabled' },
        { name: 'Required Email Signin', field: 'requiredEmailSignin' },
        { name: 'Is Test Partner', field: 'isTestPartner' },
        { name: 'Check Eligibility', field: 'checkEligibility' },
        {
          name: 'Allow Dependent Eligibility',
          field: 'allowDependentEligibility',
        },
        { name: 'Include European Union', field: 'includeEuropeanUnion' },
        { name: 'Reorder Full SKU', field: 'reorderFullSku' },
      ],
      imageFields: [
        { name: 'Primary Icon URL', field: 'primaryIconURL' },
        { name: 'Secondary Icon URL', field: 'secondaryIconURL' },
        { name: 'Primary Icon URL Settings', field: 'primaryIconURLSettings' },
        {
          name: 'Secondary Icon URL Settings',
          field: 'secondaryIconURLSettings',
        },
        { name: 'Settings Primary Icon URL', field: 'settingsPrimaryIconURL' },
        {
          name: 'Settings Secondary Icon URL',
          field: 'settingsSecondaryIconURL',
        },
      ],
      contentFields: [
        { field: 'validationSuccessDetailInfo' },
        { field: 'validationFailureDuplicateTitle' },
        { field: 'validationFailureDuplicateDetail' },
        { field: 'coachCustom1' },
        { field: 'coachCustom2' },
        { field: 'coachCustom3' },
        { field: 'primaryMotivationCustom' },
        { field: 'buttonMeetCoachCustom' },
        { field: 'successTitle' },
        { field: 'coachingDashboardTitle' },
        { field: 'coachingDashboardSubtitle' },
        { field: 'termsOfServiceText' },
        { field: 'termsOfServiceHTML' },
        { field: 'coachingWelcomeIntro' },
        { field: 'coachingWelcomeBulletTwoTitle' },
        { field: 'coachingWelcomeBulletOneText' },
        { field: 'coachingWelcomeBulletOneTitle' },
        { field: 'coachingWelcomeBulletTwoText' },
        { field: 'coachingWelcomeBulletThreeText' },
        { field: 'coachingWelcomeBulletThreeTitle' },
        { field: 'chatReadOnlyButtonText' },
        { field: 'chatReadOnlyCallToAction' },
        { field: 'chatReadOnlyMessage' },
        { field: 'coachingFormText' },
      ],
    };
  },
  computed: {
    defaultContent: function () {
      ConsoleLog(`defaultContent`);
      let p = this.partner;
      if (p) {
        let values = {};
        this.contentFields.map((f) => {
          values[f.field] = { defaultValue: p.attributes[f.field] };
        });
        return values;
      } else return null;
    },
  },
  methods: {
    getPartnerById: function (id) {
      return this.$store.getters.partnerById(id);
    },
    getPartnerName: function (id) {
      if (id) {
        const p = this.getPartnerById(id);
        return p ? p.attributes.name : null;
      }
      return null;
    },
    getPrefix: function (p) {
      return `partner.${p.id}.`;
    },
    onChanged(changes) {
      ConsoleLog(`onChanged: changes: ${JSON.stringify(changes)}`);
      let p = JSON.parse(JSON.stringify(this.partner));
      let attrs = p.attributes;
      for (let k in changes) {
        ConsoleLog(`onChanged: k: ${k}, ${attrs[k]}, ${changes[k]}`);
        attrs[k] = changes[k].defaultValue;
      }
      this.$emit('changed', p);
    },
  },
};
</script>

<style scoped></style>
