<template>
  <v-container fluid>
    <v-layout class="display-1 py-4"> Manage Amazon S3 </v-layout>
    <input id="linkurl" ref="linkurl" type="hidden" />
    <v-toolbar flat>
      <v-text-field v-model="search" label="Search" hide-details outlined />
      <v-spacer />
      <v-btn
        color="primary"
        :disabled="loading"
        dark
        class="mb-2 mr-2"
        @click="onRefresh"
      >
        Reload
      </v-btn>
      <v-btn
        v-if="hasWritePerm()"
        color="primary"
        dark
        :disabled="loading"
        class="mb-2"
        @click="onNewItem"
      >
        New Item
      </v-btn>
      <amazon-s3-uploader
        v-if="hasWritePerm()"
        v-model="editItemDialog"
        :edit-item="editItem"
        @refreshContent="refreshContent"
      />
    </v-toolbar>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
      class="align-center"
    />
    <v-treeview
      v-if="items && items.length > 0"
      :items="items"
      :search="search"
      :open.sync="openNodes"
    >
      <template #prepend="{ item, open }">
        <v-icon v-if="item.itemType !== 'file'">
          {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
        </v-icon>
        <v-icon v-else>
          {{ fileIcon(item) }}
        </v-icon>
      </template>
      <template #label="{ item }">
        <v-row dense>
          <v-col cols="8">
            <a
              v-if="item.url"
              :href="item.url"
              :title="item.url"
              target="_blank"
              rel="noopener noreferrer"
              class="text-sm-body-2"
              >{{ item.name }}</a
            >
            <span v-else :title="item.path" class="text-body-2">{{
              item.name
            }}</span>
          </v-col>
          <v-col cols="2">
            <span
              v-if="item.url"
              class="text-start text-body-2"
              title="Last Modified"
              >{{ formatDate(item.lastModified) }}</span
            >
          </v-col>
        </v-row>
      </template>
      <template #append="{ item }">
        <template v-if="isEditable(item)">
          <v-btn icon title="Copy Link" @click="onCopyLink(item)">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
          <v-btn icon title="Edit Item" @click="onEditItem(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon title="Delete Item" @click="onDeleteItem(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-else>
          <v-btn icon>
            <v-icon>mdi-blank</v-icon>
          </v-btn>
          <v-btn icon>
            <v-icon>mdi-blank</v-icon>
          </v-btn>
          <v-btn icon title="Add Item" @click="onAddItem(item)">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </template>
    </v-treeview>
    <div>
      <v-dialog v-model="deleteItemDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Delete Item</span>
          </v-card-title>

          <v-card-text v-if="editItem">
            <div v-if="editItem.keyName" class="pb-2">
              <span class="od-label">KeyName:</span
              ><span class="pl-2">{{ editItem.keyName }}</span>
            </div>
            <v-text-field
              v-model="editItem.name"
              outlined
              label="Description"
              readonly
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              @click="doCancel"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              @click="doDelete"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import { OneDropApi } from '@/onedrop-api';
import { ConsoleLog, OneDropUtils } from '@/onedrop-utils';
import AmazonS3Uploader from '@/components/AmazonS3Uploader';

export default {
  name: 'AmazonS3Editor',
  components: { AmazonS3Uploader },
  data: function () {
    return {
      loading: false,
      items: [],
      files: {
        html: 'mdi-language-html5',
        js: 'mdi-nodejs',
        json: 'mdi-code-json',
        md: 'mdi-language-markdown',
        pdf: 'mdi-file-pdf',
        png: 'mdi-file-image',
        gif: 'mdi-file-image',
        txt: 'mdi-file-document-outline',
        xls: 'mdi-file-excel',
        jpg: 'mdi-file-image',
      },
      search: null,
      editItemDialog: false,
      deleteItemDialog: false,
      editItem: null,
      openNodes: [],
      attempt: 0,
    };
  },
  computed: {
    filter() {
      return (item, search) => {
        let result = item.itemType != 'file' || item.name.indexOf(search) > -1;
        if (result)
          ConsoleLog(`Search: ${JSON.stringify(item)}, ${search}, ${result}`);
        return result;
      };
    },
  },
  mounted() {
    this.refreshContent();
  },
  methods: {
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 's3-write']);
    },
    formatDate: function (str) {
      return str ? OneDropUtils.displayDate(str) : str;
    },
    isEditable(item) {
      return !!item.url;
    },
    refreshContent() {
      this.loading = true;
      const nodes = this.openNodes;
      OneDropApi.get(
        'v3/admin/s3/list/one-drop-static',
        (response) => {
          this.items = response.meta;
          this.loading = false;
          this.attempt += 1;
          this.openNodes = nodes;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    fileIcon(item) {
      if (item) {
        const matches = [...item.name.matchAll(/.*[.]([^.]*)/gm)];
        if (matches[0]) {
          const type = matches[0][1].trim();
          const icon = this.files[type];
          return icon ?? 'mdi-file';
        }
      }
      return 'mdi-unknown';
    },
    onRefresh() {
      this.refreshContent();
    },
    onNewItem() {
      this.editItem = {
        id: null,
        name: '',
        parent: '',
        file: null,
        keyName: null,
      };
      this.editItemDialog = true;
    },
    onAddItem(item) {
      const parent = item.parent ? item.parent + '/' + item.name : item.name;
      this.editItem = {
        id: null,
        name: '',
        parent: parent,
        file: null,
        keyName: null,
      };
      this.editItemDialog = true;
    },
    onEditItem(item) {
      this.editItem = {
        id: item.id,
        name: item.name,
        parent: item.parent,
        file: null,
        keyName: item.keyName,
      };
      this.editItemDialog = true;
    },
    onCopyLink(item) {
      this.$refs.linkurl.value = item.url;
      const copiedText = this.copyToClipboard('linkurl');
      this.toast('Copied: ' + copiedText);
    },
    doCancel() {
      this.editItemDialog = false;
      this.deleteItemDialog = false;
      this.editItem = {
        id: null,
        name: '',
        parent: '',
        file: null,
        keyName: null,
      };
    },
    onDeleteItem(item) {
      this.editItem = item;
      this.deleteItemDialog = true;
    },
    doDelete() {
      let path = this.editItem.keyName
        ? this.editItem.keyName
        : this.editItem.parent
        ? `${this.editItem.parent.trim()}/${this.editItem.name.trim()}`
        : this.editItem.name.trim();
      this.loading = true;
      path = encodeURI(`v3/admin/s3/file/one-drop-static/${path}`);
      OneDropApi.del(
        path,
        null,
        (response) => {
          ConsoleLog(`response=${JSON.stringify(response)}`);
          this.toast(`Content deleted successfully`);
          this.refreshContent();
          this.loading = false;
          this.deleteItemDialog = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style scoped></style>
