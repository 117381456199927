<template>
  <v-card flat>
    <v-data-table
      :items="data"
      :headers="headers"
      :loading="loading"
      :expanded.sync="expanded"
      show-expand
      single-expand
      multi-sort
    >
      <template #item.attributes.createdAt="{ item }">
        <div style="cursor: pointer">
          {{ displayDate(item.attributes.createdAt) }}
        </div>
      </template>
      <template #item.action="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <span v-on="on">
              <v-icon
                v-if="hasWritePerm()"
                class="mr-2"
                @click.stop="onCopyItem(item)"
              >
                mdi-content-duplicate
              </v-icon>
            </span>
          </template>
          <span>Copy</span>
        </v-tooltip>
        <v-tooltip top>
          <template #activator="{ on }">
            <span v-on="on">
              <v-icon
                v-if="hasWritePerm()"
                class="mr-2"
                @click.stop="onEditItem(item)"
              >
                mdi-pencil
              </v-icon>
            </span>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip top>
          <template #activator="{ on }">
            <span v-on="on">
              <v-icon
                v-if="hasWritePerm()"
                :disabled="!canDelete(item)"
                @click.stop="onDeleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </span>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-container fluid>
            <TrackScheduleItem
              :track="track"
              :version-id="item.id"
              :all-tracks="allTracks"
            />
          </v-container>
        </td>
      </template>
    </v-data-table>
    <div>
      <v-dialog v-model="copyItemDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Copy Version</span>
          </v-card-title>

          <v-card-text v-if="copiedVersion">
            <v-row v-if="copiedVersion.id" label="Object ID">
              <v-col>Source ID: {{ copiedVersion.id }}</v-col>
            </v-row>
            <v-text-field
              v-model="copiedVersion.attributes.version"
              label="Version"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="doCopy"> Copy </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="editItemDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Copy Version</span>
          </v-card-title>

          <v-card-text v-if="editedVersion">
            <v-row v-if="editedVersion.id" label="Object ID">
              <v-col>ID: {{ editedVersion.id }}</v-col>
            </v-row>
            <v-text-field
              v-model="editedVersion.attributes.version"
              label="Version"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteItemDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Delete Version</span>
          </v-card-title>

          <v-card-text v-if="deletedVersion">
            <div class="pb-2">
              <span class="od-label">ID:</span
              ><span class="pl-2">{{ deletedVersion.id }}</span>
            </div>
            <div class="pb-2">
              <span class="od-label">Version:</span
              ><span class="pl-2">{{ deletedVersion.attributes.version }}</span>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="doDelete"> Delete </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import { OneDropApi } from '../../onedrop-api';
import { ConsoleLog, OneDropUtils } from '../../onedrop-utils';
import TrackScheduleItem from '@/components/track/TrackScheduleItem';

export default {
  name: 'TrackScheduleVersion',
  components: { TrackScheduleItem },
  props: ['track', 'allTracks'],
  data: function () {
    return {
      data: [],
      search: '',
      loading: false,
      copyItemDialog: false,
      editItemDialog: false,
      deleteItemDialog: false,
      expanded: [],
      headers: [
        {
          text: 'Id',
          value: 'id',
        },
        {
          text: 'Version',
          value: 'attributes.version',
        },
        {
          text: 'Created At',
          value: 'attributes.createdAt',
        },
        {
          text: '# Partners',
          value: 'attributes.numPartners',
        },
        {
          text: '# users',
          value: 'attributes.numUsers',
        },
        { text: 'Actions', value: 'action', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      copiedVersion: null,
      editedVersion: null,
      deletedVersion: null,
    };
  },
  computed: {
    trackId() {
      return this.track ? this.track.id : this.track;
    },
  },
  watch: {
    trackId: function () {
      this.refreshVersions();
    },
  },
  mounted() {
    this.refreshVersions();
  },
  methods: {
    displayDate: function (isoDate) {
      return OneDropUtils.displayDate(isoDate);
    },
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'track-write']);
    },
    partnerChanged: function () {
      this.refreshVersions();
    },
    refreshVersions: function () {
      this.data = [];
      if (this.trackId) {
        this.loading = true;
        ConsoleLog(`Loading versions for track ${this.trackId}`);
        OneDropApi.get(
          `/v3/admin/track-schedule-versions-enhanced?track-id=${this.trackId}`,
          (response) => {
            this.data = response.data;
            this.loaded();
          },
          (error) => {
            this.onApiError(error);
            ConsoleLog(error);
            this.loaded();
          }
        );
      }
    },
    loaded: function () {
      this.loading = false;
    },
    close: function () {
      this.loading = false;
      this.editedVersion = null;
      this.copiedVersion = null;
      this.deletedVersion = null;
      this.copyItemDialog = false;
      this.editItemDialog = false;
      this.deleteItemDialog = false;
    },
    doCopy: function () {
      const sourceVersionId = this.copiedVersion.id;
      const destVersion = this.copiedVersion.attributes.version;
      OneDropApi.post(
        `/v3/admin/track-schedule-version/clone?source-version-id=${sourceVersionId}&dest-version=${destVersion}`,
        null,
        () => {
          this.refreshVersions();
          this.toast(`Version copied successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.onApiError(error);
          this.close();
        }
      );
    },
    doSave: function () {
      let body = { data: [this.editedVersion] };
      OneDropApi.patch(
        `/v3/admin/track-schedule-versions?track-id=${this.trackId}`,
        body,
        () => {
          this.refreshVersions();
          this.toast(`Version saved successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.onApiError(error);
          this.close();
        }
      );
    },
    doDelete: function () {
      const versionId = this.deletedVersion.id;
      OneDropApi.del(
        `/v3/admin/track-schedule-version/${versionId}`,
        null,
        () => {
          this.refreshVersions();
          this.toast(`Version deleted successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.onApiError(error);
          this.close();
        }
      );
    },
    onCopyItem: function (item) {
      this.copiedVersion = {
        id: item.id,
        type: 'track-schedule-version',
        attributes: {
          version: null,
        },
      };
      this.copyItemDialog = true;
    },
    onEditItem: function (item) {
      this.editedVersion = {
        id: item.id,
        type: 'track-schedule-version',
        attributes: {
          version: item.attributes.version,
        },
      };
      this.editItemDialog = true;
    },
    canDelete(item) {
      return (
        this.data &&
        this.data.length > 1 &&
        item &&
        item.attributes.numPartners == 0
      );
    },
    onDeleteItem: function (item) {
      this.deletedVersion = {
        id: item.id,
        type: 'track-schedule-version',
        attributes: {
          version: item.attributes.version,
        },
      };
      this.deleteItemDialog = true;
    },
  },
};
</script>

<style scoped></style>
