var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("Track Migration")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_vm._v(" Source Partner: "+_vm._s(_vm.getPartnerName(_vm.source))+" ("+_vm._s(_vm.source)+") ")]),_c('v-col',[_vm._v(" Destination Partner: "+_vm._s(_vm.getPartnerName(_vm.dest))+" ("+_vm._s(_vm.dest)+") ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"label":"Destination Partner Id"},model:{value:(_vm.dest),callback:function ($$v) {_vm.dest=$$v},expression:"dest"}})],1),_c('v-col',{attrs:{"align":"center"}},[_c('v-switch',{attrs:{"label":"Test Mode"},model:{value:(_vm.testMode),callback:function ($$v) {_vm.testMode=$$v},expression:"testMode"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"label":"Source Track Id","hint":"Leave empty for all tracks or specify a track id"},model:{value:(_vm.sourceTrackId),callback:function ($$v) {_vm.sourceTrackId=$$v},expression:"sourceTrackId"}})],1)],1),_c('v-layout',{staticClass:"headline"},[_vm._v(" Output "),(_vm.textOutput)?_c('v-icon',{staticClass:"ml-2",attrs:{"title":"Copy Output"},on:{"click":function($event){return _vm.onCopySearchClicked()}}},[_vm._v(" mdi-content-copy ")]):_vm._e()],1),_c('input',{attrs:{"id":"textOutput","type":"hidden"},domProps:{"value":_vm.textOutput}}),_c('v-data-table',{attrs:{"items":_vm.enhancedOutput,"headers":_vm.headers,"loading":_vm.saving,"expanded":_vm.expanded,"dense":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.source.objectId",fn:function(ref){
var item = ref.item;
return [(item.source && item.source.hidden)?_c('span',[_c('v-icon',{staticClass:"ml-2",attrs:{"title":"Hidden","small":""}},[_vm._v("mdi-lock-outline")])],1):_vm._e(),_c('span',[_c('router-link',{attrs:{"to":{
              path: '/content/trackeditor',
              query: { query: item.source.objectId, activeOnly: false },
            },"target":"_blank"}},[_vm._v(" "+_vm._s(item.source.objectId)+" ")])],1)]}},{key:"item.destination.objectId",fn:function(ref){
            var item = ref.item;
return [(item.destination && item.destination.hidden)?_c('span',[_c('v-icon',{staticClass:"ml-2",attrs:{"title":"Hidden","small":""}},[_vm._v("mdi-lock-outline")])],1):_vm._e(),(item && item.destination)?_c('span',[_c('router-link',{attrs:{"to":{
              path: '/content/trackeditor',
              query: { query: item.destination.objectId, activeOnly: false },
            },"target":"_blank"}},[_vm._v(" "+_vm._s(item.destination.objectId)+" ")])],1):_vm._e()]}},{key:"item.source.tags",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(JSON.stringify(item.source.tags)))])]}},{key:"expanded-item",fn:function(ref){
            var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('v-container',{attrs:{"fluid":""}},[(item.detail)?_c('v-tabs',[_c('v-tab',{key:"1"},[_vm._v(" Source to Destination ")]),_c('v-tab',{key:"2"},[_vm._v(" Destination to Source ")]),_c('v-tab-item',{key:"1"},[_c('migrate-track-diff',{attrs:{"items":item.detail.sourceToDest}})],1),_c('v-tab-item',{key:"2"},[_c('migrate-track-diff',{attrs:{"items":item.detail.destToSource}})],1)],1):_vm._e()],1)],1)]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","disabled":_vm.saving,"text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","disabled":_vm.saving,"text":""},on:{"click":_vm.migrate}},[_vm._v(" "+_vm._s(_vm.getButtonTitle())+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }