<template>
  <v-row no-gutters>
    <v-col v-if="subscription">
      <v-row no-gutters>
        <v-col cols="4" class="od-label"> Name </v-col>
        <v-col cols="8">
          {{ subscription.subscriptionName }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="4" class="od-label"> Meter Readings </v-col>
        <v-col cols="8">
          {{ subscription.totalMeterReadings }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="4" class="od-label"> Strips Used </v-col>
        <v-col cols="8">
          {{ subscription.totalStripsOrdered }} of
          {{ subscription.totalStrips }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="4" class="od-label"> Avg Daily Strips </v-col>
        <v-col cols="8">
          {{ subscription.avgDailyStripUsage7day }}
        </v-col>
      </v-row>
      <v-row v-if="track" no-gutters>
        <v-col cols="4" class="od-label"> Track Id </v-col>
        <v-col cols="8">
          <router-link
            :to="{ path: '/content/trackeditor', query: { query: track.id } }"
            target="_blank"
          >
            {{ track.id }}
          </router-link>
        </v-col>
      </v-row>
      <v-row v-if="track" no-gutters>
        <v-col cols="4" class="od-label"> Track </v-col>
        <v-col cols="8">
          {{ track.attributes.title }}
        </v-col>
      </v-row>
      <v-row v-if="trackError" no-gutters>
        <v-col cols="4" class="od-label"> Track Id </v-col>
        <v-col cols="8">
          {{ subscription.trackId }}
        </v-col>
      </v-row>
      <v-row v-if="trackError" no-gutters>
        <v-col cols="4" class="od-label"> Track </v-col>
        <v-col cols="8" class="error">
          {{ trackError }}
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else class="grey--text"> No Subscription </v-col>
  </v-row>
</template>

<script>
import { OneDropUtils, ConsoleLog } from '../onedrop-utils';
import { OneDropApi } from '../onedrop-api';

export default {
  name: 'SubscriptionDetails',
  props: ['subscription'],
  data: function () {
    return {
      track: null,
      trackError: null,
    };
  },

  created: function () {
    if (this.subscription && this.subscription.trackId) {
      OneDropApi.getTrack(
        this.subscription.trackId,
        (result) => {
          this.track = result.data[0];
        },
        (error) => {
          this.trackError = error;
          ConsoleLog(error);
        }
      );
    }
  },

  methods: {
    displayDate: function (isoDate) {
      return OneDropUtils.displayDate(isoDate);
    },

    expirationDateForEntitlement: function (entitlement) {
      if (entitlement.expiration === null) {
        return 'never expires';
      } else {
        return `expires ${this.displayDate(entitlement.expiration)}`;
      }
    },
  },
};
</script>

<style scoped></style>
