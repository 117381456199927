<template>
  <v-card flat>
    <v-data-table
      v-model="multiSelect"
      :items="schedule"
      :headers="headers"
      :loading="loading"
      show-select
      multi-sort
      :search="search"
      :expanded.sync="expanded"
      :sort-by="['attributes.day', 'attributes.dayOrder']"
      show-expand
      single-expand
    >
      <template #top>
        <v-dialog v-model="editItemDialog" max-width="70%">
          <template #activator="{ on }">
            <v-toolbar flat>
              <v-toolbar-title>Schedule</v-toolbar-title>
              <v-text-field
                v-model="search"
                label="Search"
                hide-details
                class="ml-10"
              />
              <v-spacer />
              <v-btn
                color="primary"
                :loading="loading"
                :disabled="commonButtonDisabled"
                class="mr-2"
                @click="editCommon"
              >
                {{ multiButtonName('Edit') }}
              </v-btn>
              <v-btn
                v-if="hasWritePerm()"
                color="primary"
                dark
                class="mr-2"
                @click="onImport"
              >
                Import
              </v-btn>
              <v-btn
                v-if="hasWritePerm()"
                color="primary"
                dark
                class="mr-2"
                v-on="on"
                @click="onNewItem"
              >
                New
              </v-btn>
              <v-btn
                v-if="hasWritePerm()"
                color="primary"
                :loading="loading"
                :disabled="!hasChanges"
                class="mr-2"
                @click="cancelChanges"
              >
                Cancel
              </v-btn>
              <v-btn
                v-if="hasWritePerm()"
                color="primary"
                :loading="loading"
                :disabled="!hasChanges"
                @click="saveChanges"
              >
                Save
              </v-btn>
            </v-toolbar>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ dialogTitle() }}</span>
            </v-card-title>
            <v-container v-if="editedItem" fluid>
              <v-row v-if="editedItem.id" label="Object ID">
                <v-col>Object ID: {{ editedItem.id }}</v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="editedItem.attributes.day"
                    label="Day"
                    type="number"
                    @input="dayChanged"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="editedItem.attributes.dayOrder"
                    label="Day Order"
                    type="number"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="editedItem.attributes.itemType"
                    label="Item Type"
                    :items="itemTypes"
                  />
                </v-col>
                <v-col>
                  <v-select
                    v-model="editedItem.attributes.destination"
                    label="Destination"
                    :items="destinationTypes"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div v-if="editedItem.attributes.itemType === 'lesson'">
                    <v-autocomplete
                      v-model="editedItem.attributes.itemContent"
                      :items="allLessons"
                      label="Lesson"
                      :item-text="lessonName"
                      item-value="id"
                      chips
                      hide-selected
                    >
                      <template #selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                        >
                          {{ lessonName(data.item) }}
                        </v-chip>
                      </template>
                      <template #item="data">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ data.item.id }}:
                            {{ data.item.attributes.lessonName }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ data.item.attributes.lessonDescription }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            {{ data.item.attributes.url }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </div>
                  <div
                    v-else-if="
                      editedItem.attributes.itemType === 'inbox-message'
                    "
                  >
                    <v-autocomplete
                      v-model="editedItem.attributes.itemContent"
                      :items="allInboxMessages"
                      label="Inbox Message"
                      :item-text="inboxMessageName"
                      item-value="id"
                      chips
                      hide-selected
                    >
                      <template #selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                        >
                          {{ inboxMessageName(data.item) }}
                        </v-chip>
                      </template>
                      <template #item="data">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ inboxMessageName(data.item) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </div>
                  <div v-else-if="editedItem.attributes.itemType === 'message'">
                    <v-textarea
                      v-model="editedItem.attributes.itemContent"
                      label="Message"
                      outlined
                    />
                  </div>
                  <div
                    v-else-if="editedItem.attributes.itemType === 'transition'"
                  >
                    <v-autocomplete
                      v-model="editedItem.attributes.itemContent"
                      :items="filteredTracks"
                      label="Tracks"
                      :item-text="trackName"
                      item-value="id"
                      chips
                      hide-selected
                    >
                      <template #selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                        >
                          {{ trackName(data.item) }}
                        </v-chip>
                      </template>
                      <template #item="data">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ data.item.attributes.title }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ data.item.attributes.longDescription }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </div>
                  <div v-else>
                    <v-text-field
                      v-model="editedItem.attributes.itemContent"
                      label="Item Content"
                    />
                  </div>
                </v-col>
              </v-row>
              <v-col>
                <v-textarea
                  v-model="editedItem.attributes.conditional"
                  label="Conditional"
                  outlined
                />
              </v-col>
              <v-row />
            </v-container>
            <v-card-actions>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-spacer />
              <template v-if="editedIndex == -1">
                <v-btn color="blue darken-1" text @click="onAddNew">
                  Add Another
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                  color="blue darken-1"
                  text
                  :disabled="!hasPrev"
                  @click="onPrev"
                >
                  Prev
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :disabled="!hasNext"
                  @click="onNext"
                >
                  Next
                </v-btn>
              </template>
              <v-btn color="blue darken-1" text @click="onSave"> Done </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showCommonDialog" max-width="70%">
          <v-card>
            <v-card-title>
              <span class="headline">Edit Many</span>
            </v-card-title>

            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="commonContent.day"
                    label="Day"
                    type="number"
                    @input="dayChanged"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="commonContent.startOnOrder"
                    label="Start on Day Order"
                    type="number"
                    hint="Required if changing Day"
                    persistent-hint
                    :rules="[rules.min]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select
                    v-model="commonContent.destination"
                    label="Destination"
                    :items="destinationTypes"
                  />
                </v-col>
              </v-row>
            </v-container>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="onSaveCommon">
                Done
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="clearScheduleDialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Confirm Clear Schedule</span>
            </v-card-title>
            <v-card-text>
              <v-alert
                v-if="track.attributes.userCount > 0"
                type="warning"
                outlined
              >
                This operation will affect
                {{ track.attributes.userCount }} users for this track.
              </v-alert>
              <v-alert v-else type="info" outlined>
                This operation will affect
                {{ track.attributes.userCount }} users for this track.
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                :disabled="loading"
                :loading="loading"
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="loading"
                :loading="loading"
                @click="clearSchedule"
              >
                Clear
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="sendItemDialog" max-width="500px">
          <v-card v-if="editedItem">
            <v-card-title>
              <span class="headline">Send</span>
            </v-card-title>
            <v-card-text>
              <span class="od-label">Track</span
              ><span class="pl-2">{{ trackName(track) }}</span>
            </v-card-text>
            <v-card-text>
              <span class="od-label">Day</span
              ><span class="pl-2">{{ editedItem.attributes.day }}</span>
            </v-card-text>
            <v-card-text>
              <v-row align="center" class="pa-0 ma-0">
                <v-checkbox v-model="sendDayOrder" />
                <span class="od-label">Day Order</span
                ><span class="pl-2">{{ editedItem.attributes.dayOrder }}</span>
              </v-row>
              <span class="od-label"
                >Select dayOrder to send this message only or send the whole
                day</span
              >
            </v-card-text>
            <v-card-text>
              <v-select
                v-model="selectedLanguage"
                :items="Object.entries(languages)"
                label="Language"
                item-text="[1]"
                item-value="[0]"
                hide-selected
                chips
                deletable-chips
              />
            </v-card-text>
            <v-card-text>
              <UserSelect
                v-model="selectedUser"
                hint="User must have a coach"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                :disabled="loading"
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="!selectedUser"
                :loading="loading"
                @click="doSend"
              >
                Send
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="importDialog" max-width="60%">
          <v-card v-if="importItem">
            <v-card-title>
              <span class="headline">Import</span>
            </v-card-title>
            <v-card-text>
              <div class="align-content-center">
                <v-progress-circular
                  v-if="importInProgress"
                  indeterminate
                  color="primary"
                />
              </div>
              <div>
                <span class="od-label">Track</span
                ><span class="pl-2">{{ trackName(track) }}</span>
              </div>
              <div>
                <span class="od-label">Version</span
                ><span class="pl-2">{{ versionId }}</span>
              </div>
              <v-text-field v-model="importItem.url" label="URL" type="url" />
              <div v-if="!!importItem.errors">
                <span class="od-heading">Errors</span>
                <v-simple-table dense>
                  <template #default>
                    <tbody>
                      <tr>
                        <th>Row</th>
                        <th>Error</th>
                      </tr>
                      <tr v-for="item in importItem.errors" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>{{ item.detail }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div
                v-if="importItem.validated"
                class="green--text text--darken-3 text-lg-center"
              >
                Validated!
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                :loading="importInProgress"
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="!importItem.url"
                :loading="importInProgress"
                @click="doImport(true)"
              >
                Validate
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="!importItem.url"
                :loading="importInProgress"
                @click="doImport(false)"
              >
                Import
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template #item.attributes.itemContent="{ item }">
        <div
          v-if="item.attributes.itemType === 'lesson'"
          :set="(lesson = lessonFromId(item.attributes.itemContent))"
        >
          <span class="font-weight-medium mr-2">
            <router-link
              :to="{
                path: '/content/lessoneditor',
                query: { query: item.attributes.itemContent },
              }"
              target="_blank"
            >
              {{ item.attributes.itemContent }}:
            </router-link>
          </span>
          <span class="light-blue lighten-4">{{
            lessonNameFromId(item.attributes.itemContent)
          }}</span>
        </div>
        <div v-else-if="item.attributes.itemType === 'inbox-message'">
          <span class="font-weight-medium mr-2"
            >{{ item.attributes.itemContent }}:</span
          >
          <span class="light-blue lighten-4">{{
            inboxMessageNameFromId(item.attributes.itemContent)
          }}</span>
        </div>
        <div v-else-if="item.attributes.itemType === 'transition'">
          <span class="font-weight-medium mr-2"
            >{{ item.attributes.itemContent }}:</span
          >
          <span class="light-blue lighten-4">{{
            trackNameFromId(item.attributes.itemContent)
          }}</span>
        </div>
        <div v-else>
          {{ item.attributes.itemContent }}
        </div>
      </template>
      <template #item.attributes.createdAt="{ item }">
        <div style="cursor: pointer">
          {{ displayDate(item.attributes.createdAt) }}
        </div>
      </template>
      <template #item.action="{ item }">
        <v-icon
          v-if="hasWritePerm()"
          class="mr-2"
          title="Send"
          @click.stop="onSendItem(item)"
        >
          mdi-send
        </v-icon>
        <v-icon
          v-if="hasWritePerm()"
          class="mr-2"
          title="Edit"
          @click.stop="onEditItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="hasWritePerm()"
          title="delete"
          @click.stop="onDeleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-container fluid>
            <v-row>
              <v-col cols="2" class="od-heading" align="center">
                Conditional:
              </v-col>
              <v-col cols="10">
                {{ item.attributes.conditional }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card flat>
                  <v-card-title>Content</v-card-title>
                  <v-card-text>
                    <ContentResourceView
                      ref="contentList"
                      resource-type="track-schedule-item"
                      :resource-id="item.id"
                      :default-values="defaultContent"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { OneDropApi } from '../../onedrop-api';
import { ConsoleLog, OneDropUtils } from '../../onedrop-utils';
import UserSelect from '../UserSelect';
import ContentResourceView from '@/components/content/ContentResourceView';

export default {
  name: 'TrackScheduleItem',
  components: { UserSelect, ContentResourceView },
  props: ['track', 'versionId', 'allTracks'],
  data: function () {
    return {
      schedule: [],
      origSchedule: [],
      allLessons: [],
      allInboxMessages: [],
      loading: false,
      editItemDialog: false,
      deleteItemDialog: false,
      sendItemDialog: false,
      clearScheduleDialog: false,
      selectedUser: null,
      sendDayOrder: true,
      search: '',
      expanded: [],
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Day',
          value: 'attributes.day',
          align: 'start',
        },
        {
          text: 'Order',
          value: 'attributes.dayOrder',
          align: 'start',
        },
        {
          text: 'Type',
          value: 'attributes.itemType',
          align: 'start',
        },
        {
          text: 'Content',
          value: 'attributes.itemContent',
          width: '30%',
          align: 'start',
        },
        {
          text: 'Destination',
          value: 'attributes.destination',
          align: 'start',
        },
        {
          text: 'Created At',
          value: 'attributes.createdAt',
        },
        { text: 'Actions', value: 'action', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      itemTypes: ['pause', 'transition', 'lesson', 'message', 'inbox-message'],
      destinationTypes: [
        'pubnub',
        'push-notification',
        'inbox',
        'none',
        'getting-started-list',
        'featured-topics',
      ],
      editedIndex: -1,
      languages: {
        en: 'English',
        ar: 'Arabic',
        it: 'Italian',
        es: 'Spanish',
        ru: 'Russian',
        de: 'German',
        fr: 'French',
        pt: 'Portuguese',
        zh: 'Chinese',
      },
      selectedLanguage: 'en',
      importItem: {
        url: null,
        errors: null,
        validated: false,
      },
      importDialog: false,
      importInProgress: false,
      defaultContent: {
        item_content: { defaultValue: null },
      },
      dialog: false,
      deleteDialog: false,
      saving: false,
      sortBy: '',
      sortDesc: false,
      origContent: [],
      contentList: [],
      editedItem: null,
      editTab: null,
      showCommonDialog: false,
      commonContent: { day: null, destination: null, startOnOrder: null },
      multiSelect: [],
      dayOrderCount: 0,
      rules: {
        min: (value) => !value || value > 0 || 'Must be greater than 0',
      },
    };
  },
  computed: {
    filteredTracks: function () {
      if (this.track) {
        return this.allTracks.filter((c) => {
          return c.id != this.track.id && c.attributes.hidden !== true;
        });
      } else return [];
    },
    trackId() {
      return this.track ? this.track.id : this.track;
    },
    formTitle() {
      return this.editedIndex == -1 ? 'New Content' : `Edit Content`;
    },
    commonButtonDisabled() {
      return this.loading || this.multiSelect.length < 1;
    },
    deleteDisabled() {
      return this.loading || this.multiSelect.length == 0;
    },
    hasChanges() {
      const changes = this.getChanges();
      return changes.upsert.length + changes.delete.length > 0;
    },
    sortedList() {
      if (this.schedule && this.sortBy) {
        let newList = this.schedule.map((x) => x);
        newList.sort((a, b) => {
          const a1 = this.objByPath(a, this.sortBy);
          const b1 = this.objByPath(b, this.sortBy);
          const res = this.sortDesc
            ? b1.localeCompare(a1)
            : a1.localeCompare(b1);
          return res;
        });
        return newList;
      }
      return this.schedule;
    },
    hasNext() {
      return (
        this.editedIndex != null &&
        this.editedIndex >= 0 &&
        this.editedIndex < this.sortedList.length - 1
      );
    },
    hasPrev() {
      return this.editedIndex != null && this.editedIndex > 0;
    },
  },
  watch: {
    track: function () {
      this.refreshSchedule();
    },
    versionId: function () {
      this.refreshSchedule();
    },
    selectedUser: function (val) {
      ConsoleLog(`Selected user ${val}`);
    },
    dialog(val) {
      val || this.close();
    },
    deleteDialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.refreshSchedule();
  },
  methods: {
    displayDate: function (isoDate) {
      return isoDate ? OneDropUtils.displayDate(isoDate) : isoDate;
    },
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'track-write']);
    },
    dialogTitle: function () {
      return this.editedIndex == -1
        ? 'New Schedule Item'
        : 'Edit Schedule Item';
    },
    lessonName: (item) =>
      item
        ? `${item.id}: ${item.attributes.lessonName} (${item.attributes.url})`
        : '',
    lessonNameFromId: function (id) {
      if (id) {
        let l = this.lessonFromId(id);
        return l ? `${l.attributes.lessonName} (${l.attributes.url})` : l;
      } else return id;
    },
    trackName: (item) => `${item.id}: ${item.attributes.title}`,
    trackNameFromId: function (trackId) {
      if (this.allTracks) {
        for (let k in this.allTracks) {
          const trk = this.allTracks[k];
          if (trk.id === trackId) return trk.attributes.title;
        }
      }
      return trackId;
    },
    lessonFromId: function (lessonId) {
      if (this.allLessons) {
        for (let k in this.allLessons) {
          if (this.allLessons[k].id === lessonId) return this.allLessons[k];
        }
      }
      return null;
    },
    inboxMessageName: (item) =>
      item ? `${item.id}: ${item.attributes.description}` : '',
    inboxMessageNameFromId: function (id) {
      if (id) {
        let l = this.inboxMessageFromId(id);
        return l ? `${l.attributes.description}` : l;
      } else return id;
    },
    inboxMessageFromId: function (inboxMessageId) {
      if (this.allInboxMessages) {
        for (let k in this.allInboxMessages) {
          if (this.allInboxMessages[k].id === inboxMessageId)
            return this.allInboxMessages[k];
        }
      }
      return null;
    },
    dayChanged: function () {
      if (this.editedItem && !this.editedItem.id) {
        // This is a new item so whenever the day changes
        // set the dayOrder
        const day = this.editedItem.attributes.day;
        let maxDayOrder = 0;
        for (let k in this.schedule) {
          const item = this.schedule[k];
          if (
            day == item.attributes.day &&
            Number(item.attributes.dayOrder) > maxDayOrder
          )
            maxDayOrder = Number(item.attributes.dayOrder);
        }
        this.editedItem.attributes.dayOrder = String(maxDayOrder + 1);
      }
    },
    refreshSchedule: function () {
      this.schedule = [];
      this.loading = true;
      OneDropApi.get(
        `/v3/admin/track-schedule-items?version-id=${this.versionId}`,
        (response) => {
          this.origSchedule = response.data;
          this.schedule = JSON.parse(JSON.stringify(response.data));
          this.fetchLessons();
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loaded();
        }
      );
    },
    fetchLessons: function () {
      OneDropApi.getLessons(
        (response) => {
          this.allLessons = response.data;
          ConsoleLog(`Got ${this.allLessons.length} lessons`);
          this.fetchInboxMessages();
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loaded();
        }
      );
    },
    fetchInboxMessages: function () {
      OneDropApi.getAllResources(
        'inbox-message',
        (response) => {
          this.allInboxMessages = response.data;
          ConsoleLog(`Got ${this.allInboxMessages.length} inbox messages`);
          this.loaded();
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loaded();
        }
      );
    },
    loaded: function () {
      this.loading = false;
    },
    close: function () {
      this.loading = false;
      this.editedIndex = -1;
      this.editItemDialog = false;
      this.deleteItemDialog = false;
      this.sendItemDialog = false;
      this.importDialog = false;
      this.importInProgress = false;
      this.clearScheduleDialog = false;
      this.dialog = false;
      this.deleteDialog = false;
      this.showCommonDialog = false;
      this.commonContent = {};
      this.multiSelect = [];
      this.dayOrderCount = 0;
    },
    doSave: function () {
      let attrs = this.editedItem.attributes;
      attrs.day = Number(attrs.day);
      attrs.dayOrder = Number(attrs.dayOrder);
      if (attrs.conditional == '') attrs.conditional = null;
      let body = { data: [this.editedItem] };
      this.loading = true;
      OneDropApi.patch(
        `/v3/admin/track-schedule-items?version-id=${this.versionId}`,
        body,
        () => {
          this.refreshSchedule();
          this.toast(`Schedule item updated successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.onApiError(error);
          this.loading = false;
        }
      );
    },
    doDelete: function () {
      ConsoleLog(
        `Delete ${this.editedIndex}, ${JSON.stringify(this.editedItem)}`
      );
      let body = { data: [this.editedItem] };
      this.loading = true;
      OneDropApi.del(
        '/v3/admin/track-schedule-items',
        body,
        () => {
          this.schedule.splice(this.editedIndex, 1);
          this.toast(`Schedule item deleted successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.onApiError(error);
          this.loading = false;
        }
      );
    },
    onNewItem: function () {
      this.editedItem = {
        type: 'track-schedule-item',
        attributes: {
          day: 0,
          dayOrder: 1,
          itemType: '',
          itemContent: '',
          destination: '',
          conditional: null,
          scheduleVersionId: this.versionId,
        },
      };
      this.dayChanged();
      this.editedIndex = -1;
      this.editItemDialog = true;
    },
    onDeleteItem: function (item) {
      const index = this.schedule.findIndex((v) => v.id === item.id);
      if (index > -1) this.schedule.splice(index, 1);
    },
    onSendItem: function (item) {
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.sendItemDialog = true;
    },
    onClearSchedule: function () {
      this.clearScheduleDialog = true;
    },
    onImport: function () {
      this.importItem = {
        url: null,
        errors: null,
        validated: false,
      };
      this.importDialog = true;
    },
    doSend() {
      const user = this.selectedUser;
      const trackId = this.trackId;
      const versionId = this.versionId;
      const day = this.editedItem.attributes.day;
      const dayOrder = this.sendDayOrder
        ? this.editedItem.attributes.dayOrder
        : null;
      const lang = this.selectedLanguage;
      ConsoleLog(
        `Sending track ${trackId} to ${user} for day=${day}, order=${dayOrder}, lang=${lang}`
      );
      this.loading = true;
      OneDropApi.testSendTrackMessage(
        user,
        trackId,
        versionId,
        day,
        dayOrder,
        lang,
        (response) => {
          ConsoleLog(`response=${response}`);
          this.toast(`Message sent to ${this.selectedUser}`);
          this.close();
        },
        (error) => {
          ConsoleLog(`error: ${error}`);
          this.onApiError(error);
          this.close();
        }
      );
    },
    doImport(validateOnly) {
      const versionId = this.versionId;
      this.importInProgress = true;
      this.importItem.errors = null;
      this.importItem.validated = false;
      const url = encodeURIComponent(this.importItem.url);
      OneDropApi.patch(
        `/v3/admin/track-schedule-items/import?version-id=${versionId}&validate=${validateOnly}&url=${url}`,
        null,
        (response) => {
          if (validateOnly) {
            this.importItem.validated = true;
            this.importInProgress = false;
          } else {
            const dl = response.data ? response.data.length : 0;
            this.toast(`Imported ${dl} items for version ${versionId}`);
            this.refreshSchedule();
            this.close();
          }
        },
        (error) => {
          ConsoleLog(`errors=${JSON.stringify(error)}`);
          if (error.response) {
            const data = error.response.data;
            if (data.errors) {
              ConsoleLog(`${JSON.stringify(data.errors)}`);
              if (data.errors[0].code === 'import-error')
                this.importItem.errors = data.errors;
              else this.onApiError(JSON.stringify(data.errors[0]));
            } else this.onApiError(error);
          } else this.onApiError(error);
          this.importInProgress = false;
        }
      );
    },
    deleteCommon() {
      ConsoleLog(`Deleted Items: ${JSON.stringify(this.multiSelect)}`);
      let body = { data: this.multiSelect };
      this.loading = true;
      OneDropApi.del(
        '/v3/admin/track-schedule-items',
        body,
        () => {
          this.refreshSchedule();
          this.toast(`${this.multiSelect.length} items deleted successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.onApiError(error);
          this.loading = false;
        }
      );
    },
    clearSchedule() {
      const trackId = this.trackId;
      ConsoleLog(`Clear schedule: ${trackId}`);
      this.loading = true;
      OneDropApi.del(
        `/v3/admin/resource/track/${trackId}/schedule`,
        null,
        () => {
          this.toast(`Schedule cleared successfully`);
          this.refreshSchedule();
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.onApiError(error);
          this.close();
        }
      );
    },
    objByPath(obj, path) {
      let ret = obj;
      if (path) {
        const params = `${path}`.split('.');
        for (let k in params) ret = ret[params[k]];
      }
      return ret ? ret : '';
    },
    multiButtonName(button) {
      return this.multiSelect.length > 0
        ? `${button} ${this.multiSelect.length}`
        : button;
    },
    onEditItem: function (item) {
      this.editedItem = JSON.parse(JSON.stringify(item));
      const index = this.sortedList.findIndex((val) => val.id == item.id);
      this.editItemAt(item, index);
    },
    editItemAt: function (item, index) {
      this.editedIndex = index;
      this.editedItem = item;
      if (this.editedItem.attributes) {
        this.editTab = 1;
      } else this.editTab = null;
      // this.dialog = true;
      this.editItemDialog = true;
    },
    applyChange: function () {
      if (this.editedIndex == -1 && this.editedItem.attributes) {
        this.schedule.push(this.editedItem);
        this.schedule = this.schedule
          .sort((a, b) => {
            if (a.attributes.day == b.attributes.day) {
              return a.attributes.dayOrder > b.attributes.dayOrder ? 1 : -1;
            }
            return a.attributes.day > b.attributes.day ? 1 : -1;
          })
          .map((v, i, a) => {
            if (a[i - 1]?.attributes.day != v.attributes.day) {
              this.dayOrderCount = 1;
            } else {
              this.dayOrderCount += 1;
            }
            return {
              ...v,
              id: v.id ?? Math.floor(Math.random() * 1000) + i,
              attributes: {
                ...v.attributes,
                dayOrder: this.dayOrderCount,
              },
            };
          });
      }
    },
    onPrev: function () {
      this.applyChange();
      if (this.editedIndex != null && this.editedIndex > 0) {
        const item = this.sortedList[this.editedIndex - 1];
        this.editItemAt(item, this.editedIndex - 1);
      }
    },
    onNext: function () {
      this.applyChange();
      if (
        this.editedIndex != null &&
        this.editedIndex >= 0 &&
        this.editedIndex < this.schedule.length
      ) {
        const item = this.sortedList[this.editedIndex + 1];
        this.editItemAt(item, this.editedIndex + 1);
      }
    },
    onAddNew() {
      this.applyChange();
      this.editedItem = JSON.parse(JSON.stringify(this.editedItem));
      this.editedItem.attributes.content = null;
    },
    deleteItem: function (item) {
      ConsoleLog('deleteItem', item);
      this.editedIndex = this.schedule.indexOf(item);
      this.editedItem = item;
      this.deleteDialog = true;
    },
    getChanges() {
      const changes = {
        upsert: [],
        delete: [],
      };
      for (let k in this.schedule) {
        let item = this.schedule[k];
        if (!item.id || Number.isInteger(item.id)) {
          changes.upsert.push(item);
        } else {
          let orig = this.origSchedule.find((t) => t.id == item.id);
          if (
            orig &&
            (item.attributes.day != orig.attributes.day ||
              item.attributes.dayOrder != orig.attributes.dayOrder ||
              item.attributes.itemType != orig.attributes.itemType ||
              item.attributes.itemContent != orig.attributes.itemContent ||
              item.attributes.destination != orig.attributes.destination ||
              item.attributes.conditional != orig.attributes.conditional)
          )
            changes.upsert.push(item);
        }
      }
      for (let k in this.origSchedule) {
        let orig = this.origSchedule[k];
        let item = this.schedule.find((t) => t.id == orig.id);
        if (!item) changes.delete.push(orig);
      }
      return changes;
    },
    onSave() {
      this.applyChange();
      this.close();
    },
    onDelete() {
      if (this.editedIndex > -1) this.schedule.splice(this.editedIndex, 1);
      this.close();
    },
    cancelChanges() {
      this.schedule = JSON.parse(JSON.stringify(this.origSchedule));
    },
    saveChanges() {
      const changes = this.getChanges();
      this.saving = true;
      this.upsertItems(
        changes,
        () => {
          this.deleteItems(
            changes,
            () => {
              this.toast(`Schedule updated successfully`);
              this.refreshSchedule();
              this.close();
            },
            (error) => {
              ConsoleLog(error);
              this.onApiError(error);
              this.close();
            }
          );
        },
        (error) => {
          ConsoleLog(error);
          this.saving = false;
          this.onApiError(error);
        }
      );
    },
    upsertItems: function (changes, onSuccess, onError) {
      if (changes.upsert.length > 0) {
        const data = changes.upsert.map((d) => {
          let attrs = d.attributes;
          attrs.day = Number(attrs.day);
          attrs.dayOrder = Number(attrs.dayOrder);
          if (attrs.conditional == '') attrs.conditional = null;
          d.id = Number.isInteger(d.id) ? undefined : d.id;
          return d;
        });
        let body = { data: data };
        this.loading = true;
        OneDropApi.patch(
          `/v3/admin/track-schedule-items?version-id=${this.versionId}`,
          body,
          (response) => {
            changes.upsert = [];
            onSuccess(response);
          },
          onError
        );
      } else onSuccess(null);
    },
    deleteItems(changes, onSuccess, onError) {
      if (changes.delete.length > 0) {
        let body = { data: changes.delete };
        this.loading = true;
        OneDropApi.del(
          `/v3/admin/track-schedule-items?version-id=${this.versionId}`,
          body,
          (response) => {
            onSuccess(response);
          },
          onError
        );
      } else onSuccess(null);
    },
    editCommon: function () {
      let day = this.multiSelect[0].attributes.day;
      let dest = this.multiSelect[0].attributes.destination;
      for (let k = 1; k < this.multiSelect.length; k++) {
        let p = this.multiSelect[k].attributes;
        if (p.destination !== dest) dest = null;
        if (p.day !== day) day = null;
      }
      this.commonContent = {
        day: day,
        destination: dest,
      };
      this.showCommonDialog = true;
    },
    onSaveCommon() {
      const numberStartOnOrder = Number(this.commonContent.startOnOrder);
      const multiSelectIds = this.multiSelect.map((v) => v.id);
      const sortSchedule = (data) =>
        data.sort((a, b) => {
          if (a.attributes.day == b.attributes.day) {
            return a.attributes.dayOrder > b.attributes.dayOrder ? 1 : -1;
          }
          return a.attributes.day > b.attributes.day ? 1 : -1;
        });
      for (let k = 0; k < this.multiSelect.length; k++) {
        let p = this.multiSelect[k].attributes;
        p.day = this.commonContent.day ? this.commonContent.day : p.day;
        p.destination = this.commonContent.destination
          ? this.commonContent.destination
          : p.destination;
        p.dayOrder = numberStartOnOrder ? numberStartOnOrder + +k : p.dayOrder;
      }
      // setting day and dayOrder correctly each time before updating the schedule
      const nonSelected = sortSchedule(
        this.schedule.filter((v) => !multiSelectIds.includes(v.id))
      ).map((v, i, a) => {
        if (a[i - 1]?.attributes.day != v.attributes.day) {
          this.dayOrderCount = 1;
        } else {
          this.dayOrderCount += 1;
        }
        return {
          ...v,
          attributes: {
            ...v.attributes,
            dayOrder: this.dayOrderCount,
          },
        };
      });
      this.schedule = sortSchedule(
        [...this.multiSelect, ...nonSelected].map((v, i, a) => {
          // reset count when reaching nonSelected
          if (i == this.multiSelect.length) {
            this.dayOrderCount = 0;
          }
          // take the selected dayOrder
          if (multiSelectIds?.includes(v.id)) {
            this.dayOrderCount = v.attributes.dayOrder;
          } else if (
            v.attributes.day == this.commonContent.day &&
            v.attributes.dayOrder == numberStartOnOrder
          ) {
            this.dayOrderCount =
              v.attributes.dayOrder + this.multiSelect.length;
          } else if (a[i - 1].attributes.day != v.attributes.day) {
            this.dayOrderCount = 1;
          } else {
            this.dayOrderCount += 1;
          }
          return {
            ...v,
            attributes: {
              ...v.attributes,
              dayOrder: this.dayOrderCount,
            },
          };
        })
      );
      this.close();
    },
  },
};
</script>

<style scoped></style>
