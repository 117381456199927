<template>
  <v-card flat>
    <input id="searchUrl" type="hidden" :value="searchUrl()" />
    <v-data-table
      :items="questionGroups"
      :headers="headers"
      :search="search"
      :loading="loading"
      :expanded.sync="expanded"
      show-expand
      single-expand
    >
      <template #top>
        <v-layout>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="search"
                label="Search"
                hide-details
                outlined
              />
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="selectedPartners"
                :items="partnerList"
                label="Partner"
                :item-text="partnerName"
                item-value="id"
                hide-selected
                chips
                deletable-chips
                multiple
              />
            </v-col>
            <v-col cols="4" />
            <v-col align="right">
              <v-btn
                color="primary"
                dark
                :loading="loading"
                :disabled="loading"
                class="mb-2 mr-2"
                @click="refreshList"
              >
                Reload
              </v-btn>
              <v-btn
                color="primary"
                dark
                :loading="loading"
                :disabled="loading"
                class="mb-2 mr-2"
                @click="onNewGroup"
              >
                New Group
              </v-btn>
            </v-col>
          </v-row>
        </v-layout>
      </template>
      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-container fluid>
            <v-tabs>
              <v-tab key="1"> Questions </v-tab>
              <v-tab key="2"> Partners </v-tab>
              <v-tab key="3"> Audit Events </v-tab>
              <v-tab-item key="1">
                <Question
                  :group-id="item.id"
                  :read-only="item ? item.attributes.isExternal : false"
                />
              </v-tab-item>
              <v-tab-item key="2">
                <GroupPartner :group-id="item.id" />
              </v-tab-item>
              <v-tab-item key="3">
                <audit-log-viewer
                  event-type="question-group"
                  :object-id="item.id"
                />
              </v-tab-item>
            </v-tabs>
          </v-container>
        </td>
      </template>
      <template #no-data>
        <v-btn color="primary" @click="refreshList"> Reset </v-btn>
      </template>
      <template #item.action="{ item }">
        <v-icon
          v-if="hasWritePerm() && item.attributes.isExternal == false"
          class="mr-2"
          title="Edit"
          @click.stop="onEditGroup(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="hasWritePerm() && item.attributes.isExternal == false"
          class="mr-2"
          title="Copy"
          @click.stop="onCopyGroup(item)"
        >
          mdi-content-duplicate
        </v-icon>
        <v-icon
          v-if="hasWritePerm() && item.attributes.isExternal == false"
          title="Delete"
          @click.stop="onDeleteGroup(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="editGroupDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Question Group</span>
        </v-card-title>

        <v-card-text v-if="selectedGroup">
          <div class="pb-2">
            <span class="od-label">Group:</span
            ><span class="pl-2">{{ selectedGroup.id }}</span>
          </div>
          <v-text-field
            v-model="selectedGroup.attributes.title"
            outlined
            label="Title"
          />
          <v-text-field
            v-model="selectedGroup.attributes.slug"
            outlined
            label="Slug"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
          <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="copyGroupDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Copy Question Group</span>
        </v-card-title>

        <v-card-text v-if="selectedGroup">
          <div class="pb-2">
            <span class="od-label">Donor Group:</span
            ><span class="pl-2">{{ selectedGroup.id }}</span>
          </div>
          <v-text-field
            v-model="selectedGroup.attributes.title"
            outlined
            label="Title"
          />
          <v-text-field
            v-model="selectedGroup.attributes.slug"
            outlined
            label="Slug"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
          <v-btn color="blue darken-1" text @click="doCopy"> Copy </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteGroupDialog" max-width="600px" open-delay="1000">
      <v-card>
        <v-card-title>
          <span class="headline">Delete Question Group</span>
        </v-card-title>

        <v-card-text v-if="selectedGroup">
          <div class="pb-2">
            <span class="od-label">Group:</span
            ><span class="pl-2">{{ selectedGroup.id }}</span>
          </div>
          <v-text-field
            v-model="selectedGroup.attributes.title"
            outlined
            label="Title"
            readonly
          />
          <v-text-field
            v-model="selectedGroup.attributes.slug"
            outlined
            label="Slug"
            readonly
          />
          <v-alert v-if="groupHasPartners" type="warning">
            <b>Warning:</b> This question group is still associated to partners.
            Deleting this group will also delete the partners associated with
            it.
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
          <v-btn color="blue darken-1" text @click="doDelete"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { OneDropApi } from '../../onedrop-api';
import { ConsoleLog } from '../../onedrop-utils';
import Question from '@/components/question/Question';
import GroupPartner from '@/components/question/GroupPartner';
import AuditLogViewer from '@/components/AuditLogViewer';

export default {
  name: 'QuestionGroupList',
  components: { Question, GroupPartner, AuditLogViewer },
  data: function () {
    return {
      loading: false,
      search: '',
      expanded: [],
      headers: [
        {
          text: 'Group Id',
          value: 'id',
          filter: (value) =>
            this.selectedPartners.length == 0 || this.groupIds.includes(value),
        },
        {
          text: 'Group Title',
          value: 'attributes.title',
        },
        {
          text: 'Group Slug',
          value: 'attributes.slug',
        },
        {
          text: 'External',
          value: 'attributes.isExternal',
        },
        { text: 'Actions', value: 'action', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      questionGroups: [],
      editGroupDialog: false,
      copyGroupDialog: false,
      deleteGroupDialog: false,
      selectedGroup: null,
      groupIds: [],
      selectedPartners: [],
      groupHasPartners: false,
    };
  },
  computed: {
    currentUrl: function () {
      let q = {};
      if (this.search) q.query = encodeURIComponent(this.search);
      return this.$router.resolve({ path: '/setup/questioneditor', query: q })
        .route.fullPath;
    },
    partnerList() {
      return this.$store.getters.partnerList;
    },
  },
  watch: {
    selectedPartners() {
      this.refreshPartnerList();
    },
    deleteGroupDialog(visible) {
      if (visible) {
        OneDropApi.getQuestionPartners(
          this.selectedGroup.id,
          (response) => {
            response.data.length < 1
              ? (this.groupHasPartners = false)
              : (this.groupHasPartners = true);
          },
          (error) => {
            this.onApiError(error);
            ConsoleLog(error);
          }
        );
      }
    },
  },
  mounted() {
    if (this.$route.query.query) {
      this.search = decodeURIComponent(this.$route.query.query).trim();
    }
    this.refreshList();
  },
  methods: {
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'question-write']);
    },
    partnerName: (item) => `${item.name} (${item.id})`,
    getPartnerById(id) {
      this.$store.getters.partnerById(id);
    },
    getPartnerName(id) {
      this.getPartnerById(id) ? this.getPartnerById(id).attributes.name : null;
    },
    refreshList() {
      ConsoleLog('Fetching group-questions');
      this.loading = true;
      OneDropApi.getQuestionGroups(
        (response) => {
          this.questionGroups = response.data;
          ConsoleLog(`Got ${this.questionGroups.length} questions`);
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    refreshPartnerList() {
      this.loading = true;
      OneDropApi.getAllQuestionsAndGroups(
        this.selectedPartners,
        (response) => {
          this.groupIds = [...new Set(response.data.map((r) => r.id))];
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    created: function () {
      this.refreshList();
    },
    searchUrl() {
      let path = this.currentUrl;
      const protocol = window.location.protocol;
      const host = window.location.host;
      return `${protocol}//${host}/#${path}`;
    },
    onCopySearchClicked: function () {
      const copiedText = this.copyToClipboard('searchUrl');
      this.toast('Copied: ' + copiedText);
    },
    onNewGroup() {
      this.selectedGroup = {
        id: null,
        type: 'question-group',
        attributes: { title: null, slug: null, isExternal: false },
      };
      this.editGroupDialog = true;
    },
    onEditGroup(item) {
      ConsoleLog(`Editing ${JSON.stringify(item)}`);
      this.selectedGroup = JSON.parse(JSON.stringify(item));
      this.editGroupDialog = true;
    },
    onCopyGroup(item) {
      ConsoleLog(`Copying ${JSON.stringify(item)}`);
      this.selectedGroup = JSON.parse(JSON.stringify(item));
      this.copyGroupDialog = true;
    },
    onDeleteGroup(item) {
      ConsoleLog(`Deleting ${JSON.stringify(item)}`);
      this.selectedGroup = item;
      this.deleteGroupDialog = true;
    },
    doSave() {
      let id = this.selectedGroup.id;
      this.loading = true;
      let body = { data: [this.selectedGroup] };
      ConsoleLog(`doCopy: ${JSON.stringify(body)}`);
      if (id) {
        OneDropApi.patch(
          `/v3/admin/question-group`,
          body,
          () => {
            this.toast(`Saved group successfully`);
            this.loading = false;
            this.refreshList();
            this.close();
          },
          (error) => {
            this.onApiError(error);
            ConsoleLog(error);
            this.loading = false;
          }
        );
      } else {
        OneDropApi.post(
          `/v3/admin/question-group`,
          body,
          () => {
            this.toast(`Saved group successfully`);
            this.loading = false;
            this.refreshList();
            this.close();
          },
          (error) => {
            this.onApiError(error);
            ConsoleLog(error);
            this.loading = false;
          }
        );
      }
    },
    doCopy() {
      let id = this.selectedGroup.id;
      this.loading = true;
      let payload = JSON.parse(JSON.stringify(this.selectedGroup));
      let body = { data: [payload] };
      ConsoleLog(`doCopy: ${JSON.stringify(body)}`);
      OneDropApi.post(
        `/v3/admin/question-group/copy/${id}`,
        body,
        (response) => {
          this.toast(`Copied group successfully ${response.data[0].id}`);
          this.loading = false;
          this.refreshList();
          this.close();
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    doDelete() {
      ConsoleLog(`doCopy: ${JSON.stringify(this.selectedGroup)}`);
      ConsoleLog('Fetching group-questions');
      let id = this.selectedGroup.id;
      this.loading = true;
      OneDropApi.del(
        `/v3/admin/question-group/${id}`,
        null,
        (response) => {
          this.toast(`Copied group successfully ${response.data[0].id}`);
          this.loading = false;
          this.refreshList();
          this.close();
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    close() {
      this.selectedGroup = null;
      this.copyGroupDialog = false;
      this.deleteGroupDialog = false;
      this.editGroupDialog = false;
    },
  },
};
</script>

<style></style>
