<template>
  <v-card>
    <v-card-title>Track Migration</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          Source Partner: {{ getPartnerName(source) }} ({{ source }})
        </v-col>
        <v-col>
          Destination Partner: {{ getPartnerName(dest) }} ({{ dest }})
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
          <v-text-field v-model="dest" label="Destination Partner Id" />
        </v-col>
        <v-col align="center">
          <v-switch v-model="testMode" label="Test Mode" />
        </v-col>
        <v-col cols="5">
          <v-text-field
            v-model="sourceTrackId"
            label="Source Track Id"
            hint="Leave empty for all tracks or specify a track id"
          />
        </v-col>
        <!--        <v-col>-->
        <!--          <v-switch-->
        <!--            v-model="matchByDay"-->
        <!--            label="Match By Day and DayOrder"-->
        <!--            hint="Match by day and dayOrder fields"-->
        <!--          />-->
        <!--        </v-col>-->
        <!--        <v-col>-->
        <!--          <v-switch-->
        <!--            v-model="errorOnSource"-->
        <!--            label="Error on source mismatch"-->
        <!--            hint="Error if the source has tracks that don't match destination"-->
        <!--          />-->
        <!--        </v-col>-->
        <!--        <v-col>-->
        <!--          <v-switch-->
        <!--            v-model="errorOnDest"-->
        <!--            label="Error on destination mismatch"-->
        <!--            hint="Error if the destination has tracks that don't match source"-->
        <!--          />-->
        <!--        </v-col>-->
      </v-row>
      <v-layout class="headline">
        Output
        <v-icon
          v-if="textOutput"
          class="ml-2"
          title="Copy Output"
          @click="onCopySearchClicked()"
        >
          mdi-content-copy
        </v-icon>
      </v-layout>
      <input id="textOutput" type="hidden" :value="textOutput" />
      <v-data-table
        :items="enhancedOutput"
        :headers="headers"
        :loading="saving"
        :expanded.sync="expanded"
        dense
      >
        <template #item.source.objectId="{ item }">
          <span v-if="item.source && item.source.hidden">
            <v-icon class="ml-2" title="Hidden" small>mdi-lock-outline</v-icon>
          </span>
          <span>
            <router-link
              :to="{
                path: '/content/trackeditor',
                query: { query: item.source.objectId, activeOnly: false },
              }"
              target="_blank"
            >
              {{ item.source.objectId }}
            </router-link>
          </span>
        </template>
        <template #item.destination.objectId="{ item }">
          <span v-if="item.destination && item.destination.hidden">
            <v-icon class="ml-2" title="Hidden" small>mdi-lock-outline</v-icon>
          </span>
          <span v-if="item && item.destination">
            <router-link
              :to="{
                path: '/content/trackeditor',
                query: { query: item.destination.objectId, activeOnly: false },
              }"
              target="_blank"
            >
              {{ item.destination.objectId }}
            </router-link>
          </span>
        </template>
        <template #item.source.tags="{ item }">
          <span>{{ JSON.stringify(item.source.tags) }}</span>
        </template>
        <template #expanded-item="{ item }">
          <td :colspan="headers.length">
            <v-container fluid>
              <v-tabs v-if="item.detail">
                <v-tab key="1"> Source to Destination </v-tab>
                <v-tab key="2"> Destination to Source </v-tab>
                <v-tab-item key="1">
                  <migrate-track-diff :items="item.detail.sourceToDest" />
                </v-tab-item>
                <v-tab-item key="2">
                  <migrate-track-diff :items="item.detail.destToSource" />
                </v-tab-item>
              </v-tabs>
            </v-container>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="blue darken-1" :disabled="saving" text @click="close">
        Cancel
      </v-btn>
      <v-btn color="blue darken-1" :disabled="saving" text @click="migrate">
        {{ getButtonTitle() }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { OneDropApi } from '@/onedrop-api';
import { ConsoleLog } from '@/onedrop-utils';
import { OneDropUtils } from '@/onedrop-utils';
import MigrateTrackDiff from '@/components/track/MigrateTrackDiff';

export default {
  name: 'MigrateTrack',
  components: { MigrateTrackDiff },
  props: ['source', 'visible'],
  data: function () {
    return {
      dest: this.source,
      sourceTrackId: null,
      testMode: true,
      matchByDay: false,
      errorOnSource: false,
      errorOnDest: false,
      saving: false,
      output: [],
      expanded: [],
      headers: [
        {
          text: 'Source',
          value: 'source.objectId',
          width: '150px',
        },
        {
          text: 'Source Title',
          value: 'source.title',
          width: '15%',
        },
        {
          text: 'Language',
          value: 'source.language',
        },
        {
          text: 'Tags',
          value: 'source.tags',
        },
        {
          text: 'Destination',
          value: 'destination.objectId',
        },
        {
          text: 'Destination Title',
          value: 'destination.title',
          width: '15%',
        },
        {
          text: 'Num Users',
          value: 'numUsers',
        },
        {
          text: 'Summary',
          value: 'summary',
        },
        { text: '', value: 'data-table-expand' },
      ],
    };
  },
  computed: {
    textOutput() {
      return this.output && this.output.length > 0
        ? JSON.stringify(this.output)
        : null;
    },
    enhancedOutput() {
      if (this.output && this.output.length > 0) {
        return this.output.map((item) => {
          item.id = item.source.objectId;
          return item;
        });
      } else return this.output;
    },
  },
  watch: {
    source() {
      this.reset();
    },
    visible() {
      this.reset();
    },
  },
  mounted() {
    this.reset();
  },
  methods: {
    reset: function () {
      this.output = [];
      this.testMode = true;
      this.matchByDay = false;
      this.errorOnSource = true;
      this.errorOnDest = false;
      this.expanded = [];
      this.dest = this.source;
      this.sourceTrackId = null;
    },
    getPartnerById: function (id) {
      return this.$store.getters.partnerById(id);
    },
    getPartnerName: function (id) {
      if (id) {
        const p = this.getPartnerById(id);
        return p ? p.attributes.name : null;
      }
      return null;
    },
    getButtonTitle: function () {
      return this.testMode ? 'Test Migrate' : 'Migrate';
    },
    migrate: function () {
      let url = `/v3/admin/track/migrate-track?source=${this.source}&dest=${this.dest}&test=${this.testMode}&byDay=${this.matchByDay}&errorOnDest=${this.errorOnDest}&errorOnSource=${this.errorOnSource}`;
      if (this.sourceTrackId) url = url + `&track=${this.sourceTrackId}`;
      this.output = [];
      this.expanded = [];
      this.saving = true;
      OneDropApi.post(
        url,
        null,
        (response) => {
          this.output = response.meta.output;
          this.saving = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.saving = false;
        }
      );
    },
    close: function () {
      this.output = [];
      this.$emit('close');
    },
    rowClicked: function (item, options) {
      options.expand(!options.isExpanded);
    },
    onCopySearchClicked: function () {
      this.toast('Copied Output');
    },
    formatDetail: function (detail) {
      return detail ? OneDropUtils.syntaxHighlightJSON(detail) : detail;
    },
  },
};
</script>

<style>
pre .string {
  color: green;
}

.number {
  color: darkorange;
}

.boolean {
  color: blue;
}

.null {
  color: magenta;
}

.key {
  color: red;
}
</style>
