import {format} from "date-fns";
<template>
  <v-container fluid>
    <v-layout class="display-1 py-4">
      Manage Localization
      <v-icon
        class="ml-2"
        title="Copy Search Link"
        @click="onCopySearchClicked()"
      >
        mdi-content-copy
      </v-icon>
    </v-layout>
    <div class="text-center">
      <v-progress-circular v-if="saving" indeterminate color="primary" />
    </div>
    <input id="searchUrl" type="hidden" :value="searchUrl()" />
    <v-data-table
      v-model="multiSelect"
      :items="contentList"
      :headers="headers"
      :loading="loading"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-select
    >
      <template #top>
        <v-dialog v-model="dialog" width="70%">
          <template #activator="{ on }">
            <v-container fluid>
              <v-row>
                <v-col cols="4">
                  <v-toolbar flat>
                    <v-text-field
                      ref="searchText"
                      v-model="search"
                      v-intersect="onIntersect"
                      label="Search"
                      hide-details
                      outlined
                      @keyup.enter="searchClicked"
                      @focus="$event.target.select()"
                    />
                    <v-btn
                      color="primary"
                      class="ml-2"
                      :disabled="!search"
                      @click="searchClicked"
                    >
                      Search
                    </v-btn>
                  </v-toolbar>
                </v-col>
                <v-col cols="2">
                  <v-select
                    v-model="selectedLanguage"
                    :items="Object.entries(languages)"
                    label="Language"
                    item-text="[1]"
                    item-value="[0]"
                    hide-selected
                  />
                </v-col>
                <v-col cols="6">
                  <v-toolbar v-if="hasWritePerm()" flat>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      :loading="loading"
                      :disabled="deleteDisabled"
                      class="mb-2 mr-2"
                      @click="onMultiDelete"
                    >
                      {{ multiButtonName('Delete') }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      :loading="loading"
                      :disabled="cloneDisabled"
                      class="mb-2 mr-2"
                      @click="onClone"
                    >
                      {{ multiButtonName('Clone') }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      :loading="loading"
                      :disabled="commonButtonDisabled"
                      class="mb-2 mr-2"
                      @click="editCommon"
                    >
                      {{ multiButtonName('Edit') }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      :loading="loading"
                      class="mb-2 mr-2"
                      @click.stop="onNewItem"
                      v-on="on"
                    >
                      New
                    </v-btn>
                    <v-btn
                      color="primary"
                      :loading="loading"
                      :disabled="!hasChanges"
                      class="ml-2 mb-2 mr-2"
                      @click="cancelChanges"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="primary"
                      :loading="loading"
                      :disabled="!hasChanges"
                      class="mb-2"
                      @click="saveChanges"
                    >
                      Save
                    </v-btn>
                  </v-toolbar>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text v-if="editedItem">
              <v-row label="Object ID">
                <v-col>Object ID: {{ editedItem.id }}</v-col>
              </v-row>
              <v-text-field
                v-model="editedItem.attributes.code"
                outlined
                label="Code"
                :rules="[rules.required, rules.code]"
                hint="Code can only contain lowercase letters, numbers, and -"
              />
              <v-tabs v-model="editTab">
                <v-tab key="1"> Plain Text </v-tab>
                <v-tab key="2"> Deep Link </v-tab>
                <v-tab-item key="1">
                  <v-container fluid>
                    <v-textarea
                      v-model="editedItem.attributes.content"
                      outlined
                      label="Content"
                      :rules="[rules.content]"
                      hint=" Use two single-quotes ('') to represent a single-quote ('). Text enclosed in {} is used for substitution. To escape substitution enclose the content in single-quote like '{foo}'."
                    />
                  </v-container>
                </v-tab-item>
                <v-tab-item key="2">
                  <DeepLinkEncoder
                    v-model="editedItem.attributes.content"
                    class="mb-4"
                  />
                </v-tab-item>
              </v-tabs>
              <v-row justify="center" align-content="center" align="center">
                <v-col cols="6">
                  <v-select
                    v-model="editedItem.attributes.language"
                    :items="Object.entries(languages)"
                    label="Language"
                    item-text="[1]"
                    item-value="[0]"
                    hide-selected
                    chips
                    deletable-chips
                  />
                </v-col>
                <v-col cols="2" />
                <v-col cols="4">
                  <v-switch
                    v-model="editedItem.attributes.trackUsage"
                    label="Track Usage"
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-spacer />
              <template v-if="editedIndex == -1">
                <v-btn
                  color="blue darken-1"
                  text
                  :disabled="isDisabled"
                  @click="onAddNew"
                  >Add Another</v-btn
                >
              </template>
              <template v-else>
                <v-btn
                  color="blue darken-1"
                  text
                  :disabled="!hasPrev"
                  @click="onPrev"
                >
                  Prev
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :disabled="!hasNext"
                  @click="onNext"
                >
                  Next
                </v-btn>
              </template>
              <v-btn
                color="blue darken-1"
                text
                :disabled="isDisabled"
                @click="onSave"
                >Done</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showCommonDialog" max-width="70%">
          <v-card>
            <v-card-title>
              <span class="headline">Edit Content</span>
            </v-card-title>

            <v-card-text>
              <v-tabs v-model="editTab">
                <v-tab key="1"> Plain Text </v-tab>
                <v-tab key="2"> Deep Link </v-tab>
                <v-tab-item key="1">
                  <v-container fluid>
                    <v-textarea
                      v-model="commonContent"
                      outlined
                      label="Content"
                      hint=" Use two single-quotes ('') to represent a single-quote ('). Text enclosed in {} is used for substitution. To escape substitution enclose the content in single-quote like '{foo}'."
                    />
                  </v-container>
                </v-tab-item>
                <v-tab-item key="2">
                  <DeepLinkEncoder v-model="commonContent" class="mb-4" />
                </v-tab-item>
              </v-tabs>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="onSaveCommon">
                Done
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="cloneDialog" max-width="50%">
          <v-card>
            <v-card-title>
              <span class="headline">Edit Content</span>
            </v-card-title>

            <v-card-text>
              <v-text-field v-model="clonedCode" outlined label="Common Code" />
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="onSaveClone">
                Done
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Delete Content</span>
            </v-card-title>

            <v-card-text v-if="editedItem">
              <v-row label="Object ID">
                <v-col>Object ID: {{ editedItem.id }}</v-col>
              </v-row>
              <v-text-field
                v-model="editedItem.attributes.code"
                outlined
                label="Code"
                readonly
              />
              <v-textarea
                v-model="editedItem.attributes.content"
                outlined
                label="Content"
                readonly
              />
              <v-select
                v-model="editedItem.attributes.language"
                :items="Object.entries(languages)"
                label="Language"
                item-text="[1]"
                item-value="[0]"
                hide-selected
                chips
                readonly
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="onDelete"> OK </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="changeReqDialog" max-width="50%">
          <v-card>
            <v-card-title>
              <span class="headline">Change Request</span>
            </v-card-title>

            <v-card-text>
              <v-text-field
                v-model="regulatedCRCode"
                outlined
                label="Change Request Code"
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="!regulatedCRCode"
                @click="saveWithCRCode"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template #header.action="{ header }">
        <span v-if="hasWritePerm()">{{ header.text }}</span>
      </template>
      <template #item.attributes.content="{ item }">
        <span class="wrap">{{ item.attributes.content }}</span>
      </template>
      <template #item.attributes.language="{ item }">
        <span>{{ formatLanguage(item.attributes.language) }}</span>
      </template>
      <template #item.action="{ item }">
        <v-icon v-if="hasWritePerm()" class="mr-2" @click.stop="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon v-if="hasWritePerm()" @click.stop="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { ConsoleLog, OneDropUtils } from '../onedrop-utils';
import DeepLinkEncoder from '@/components/DeepLinkEncoder';

export default {
  name: 'GenericContentEditor',
  components: { DeepLinkEncoder },
  data: function () {
    return {
      dialog: false,
      deleteDialog: false,
      loading: false,
      saving: false,
      search: '',
      sortBy: '',
      sortDesc: false,
      dateFormat: 'yyyy-MM-dd',
      timeFormat: 'HH:mm',
      headers: [
        {
          text: 'Code',
          value: 'attributes.code',
        },
        {
          text: 'Content',
          value: 'attributes.content',
          width: '60%',
        },
        {
          text: 'Language',
          value: 'attributes.language',
        },
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Track Usage',
          value: 'attributes.trackUsage',
        },
        { text: 'Actions', value: 'action', sortable: false },
      ],
      origContent: [],
      contentList: [],
      editedIndex: -1,
      editedItem: null,
      editTab: null,
      languages: {
        default: 'Default',
        ar: 'Arabic',
        it: 'Italian',
        es: 'Spanish',
        ru: 'Russian',
        de: 'German',
        fr: 'French',
        pt: 'Portuguese',
        zh: 'Chinese',
      },
      selectedLanguage: 'default',
      showCommonDialog: false,
      commonContent: '',
      multiSelect: [],
      rules: {
        required: (value) => !!value || 'Required.',
        code: (value) => {
          // forces kabob case
          const pattern = /^([a-z][a-z0-9]*)(-[a-z0-9]+)*$/;
          return pattern.test(value) || 'Invalid code.';
        },
        content: (value) => {
          const pattern1 = /([^'}])'([^{'])/;
          const pattern2 =
            /(^|[^'])[{]([^}]*)[}]([^']|$)|['][{]([^}]*)[}]($|[^'])|(^|[^'])[{]([^}]*)[}][']/;
          if (pattern1.test(value)) return 'Unescaped single quote';
          else if (pattern2.test(value)) return 'Unescaped variable';
          else return true;
        },
      },
      cloneDialog: false,
      changeReqDialog: false,
      clonedContent: null,
      commonCode: null,
      clonedCode: null,
      regulatedPrefixes: null,
      regulatedCRCode: null,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex == -1 ? 'New Content' : `Edit Content`;
    },
    commonButtonDisabled() {
      return this.loading || this.multiSelect.length < 2;
    },
    cloneDisabled() {
      return this.loading || this.multiSelect.length == 0;
    },
    deleteDisabled() {
      return this.loading || this.multiSelect.length == 0;
    },
    currentUrl: function () {
      let q = {};
      if (this.search) q.query = encodeURIComponent(this.search);
      if (this.selectedPartners && this.selectedPartners.length > 0)
        q.partners = encodeURIComponent(JSON.stringify(this.selectedPartners));
      return this.$router.resolve({ path: '/content/localization', query: q })
        .route.fullPath;
    },
    hasChanges() {
      const changes = this.getChanges();
      return (
        changes.insert.length + changes.update.length + changes.delete.length >
        0
      );
    },
    sortedList() {
      if (this.contentList && this.sortBy) {
        let newList = this.contentList.map((x) => x);
        newList.sort((a, b) => {
          const a1 = this.objByPath(a, this.sortBy);
          const b1 = this.objByPath(b, this.sortBy);
          const res = this.sortDesc
            ? b1.localeCompare(a1)
            : a1.localeCompare(b1);
          return res;
        });
        return newList;
      }
      return this.contentList;
    },
    hasNext() {
      return (
        this.editedIndex != null &&
        this.editedIndex >= 0 &&
        this.editedIndex < this.sortedList.length - 1
      );
    },
    hasPrev() {
      return this.editedIndex != null && this.editedIndex > 0;
    },
    isDisabled() {
      return this.editedItem?.attributes.code ? false : true;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    deleteDialog(val) {
      val || this.close();
    },
    search(val) {
      if (!val) this.refreshContent();
    },
    selectedLanguage() {
      this.refreshContent();
    },
  },
  mounted() {
    if (this.$route.query.query) {
      this.search = decodeURIComponent(this.$route.query.query).trim();
    }
    this.refreshRegulatedContent();
    this.refreshContent();
  },
  methods: {
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'content-write']);
    },
    formatDate: function (str) {
      return str ? OneDropUtils.displayDate(str) : str;
    },
    onIntersect() {
      this.$refs.searchText.focus();
    },
    formatLanguage: function (str) {
      if (str in this.languages) return this.languages[str];
      else return str;
    },
    searchClicked: function () {
      this.refreshContent();
    },
    refreshRegulatedContent: function () {
      OneDropApi.get(
        '/v3/admin/resource/regulated-prefixes',
        (response) => {
          this.regulatedPrefixes = response.data.map((data) => data.id);
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    isRegulatedContent: function (code) {
      let result = this.regulatedPrefixes.findIndex((prefix) =>
        code.startsWith(prefix)
      );
      // ConsoleLog(`result: ${code}: ${result}`)
      return result > -1;
    },
    refreshContent: function () {
      this.multiSelect = [];
      this.regulatedCRCode = null;
      if (this.search && this.search.length > 0) {
        ConsoleLog(`Fetching content for ${this.search}`);
        this.loading = true;
        let language = null;
        if (this.selectedLanguage) language = this.selectedLanguage;
        OneDropApi.getContentForPrefixAndLang(
          encodeURIComponent(this.search),
          language,
          (response) => {
            this.origContent = response.data;
            this.contentList = JSON.parse(JSON.stringify(this.origContent));
            ConsoleLog(`Got ${this.contentList.length} content`);
            this.loading = false;
          },
          (error) => {
            this.onApiError(error);
            ConsoleLog(error);
            this.loading = false;
          }
        );
      } else this.contentList = [];
    },
    created: function () {
      this.refreshContent();
    },
    onNewItem() {
      this.editedIndex = -1;
      this.editedItem = {
        type: 'content',
        attributes: {
          code: '',
          content: '',
          language: 'default',
          trackUsage: false,
        },
      };
      this.editTab = null;
    },
    objByPath(obj, path) {
      let ret = obj;
      if (path) {
        const params = `${path}`.split('.');
        for (let k in params) ret = ret[params[k]];
      }
      return ret ? ret : '';
    },
    multiButtonName(button) {
      return this.multiSelect.length > 0
        ? `${button} ${this.multiSelect.length}`
        : button;
    },
    editItem: function (item) {
      let index = item.id
        ? this.sortedList.findIndex((val) => val.id == item.id)
        : this.sortedList.findIndex(
            (val) => val.attributes.code == item.attributes.code
          );
      this.editItemAt(item, index);
    },
    editItemAt: function (item, index) {
      this.editedIndex = index;
      this.editedItem = item; //JSON.parse(JSON.stringify(item))
      ConsoleLog(
        `editItem: ${this.editedIndex}, ${JSON.stringify(this.editedItem)}`
      );
      const re1 = /onedrop:\/\/(.*)[=]([^=]*)$/gm;
      if (
        this.editedItem.attributes.content &&
        re1.test(this.editedItem.attributes.content)
      ) {
        this.editTab = 1;
      } else this.editTab = null;
      this.dialog = true;
    },
    applyChange: function () {
      if (
        this.editedIndex == -1 &&
        this.editedItem.attributes &&
        this.editedItem.attributes.code
      ) {
        this.contentList.push(this.editedItem);
      }
    },
    onPrev: function () {
      this.applyChange();
      if (this.editedIndex != null && this.editedIndex > 0) {
        const item = this.sortedList[this.editedIndex - 1];
        this.editItemAt(item, this.editedIndex - 1);
      }
    },
    onNext: function () {
      this.applyChange();
      if (
        this.editedIndex != null &&
        this.editedIndex >= 0 &&
        this.editedIndex < this.contentList.length
      ) {
        const item = this.sortedList[this.editedIndex + 1];
        this.editItemAt(item, this.editedIndex + 1);
      }
    },
    onAddNew() {
      this.applyChange();
      this.editedItem = JSON.parse(JSON.stringify(this.editedItem));
      this.editedItem.attributes.content = null;
    },
    deleteItem: function (item) {
      ConsoleLog('deleteItem', item);
      this.editedIndex = this.contentList.indexOf(item);
      this.editedItem = item;
      this.deleteDialog = true;
    },
    close() {
      this.editedItem = null;
      this.dialog = false;
      this.deleteDialog = false;
      this.cloneDialog = false;
      this.editedIndex = -1;
      this.showCommonDialog = false;
      this.changeReqDialog = false;
      this.commonContent = '';
      this.clonedContent = null;
      this.multiSelect = [];
    },
    getChanges() {
      const changes = {
        insert: [],
        update: [],
        delete: [],
      };
      for (let k in this.contentList) {
        let item = this.contentList[k];
        if (!item.id) {
          changes.insert.push(item);
        } else {
          let orig = this.origContent.find((t) => t.id == item.id);
          if (
            orig &&
            (item.attributes.content != orig.attributes.content ||
              item.attributes.code != orig.attributes.code ||
              item.attributes.language != orig.attributes.language ||
              item.attributes.trackUsage != orig.attributes.trackUsage)
          )
            changes.update.push(item);
        }
      }
      for (let k in this.origContent) {
        let orig = this.origContent[k];
        let item = this.contentList.find((t) => t.id == orig.id);
        if (!item) changes.delete.push(orig);
      }
      return changes;
    },
    onSave() {
      this.applyChange();
      this.close();
    },
    onDelete() {
      if (this.editedIndex > -1) this.contentList.splice(this.editedIndex, 1);
      this.close();
    },
    cancelChanges() {
      this.regulatedCRCode = null;
      this.contentList = JSON.parse(JSON.stringify(this.origContent));
    },
    needsCRCode(changes) {
      // Return true if any of the codes is regulated
      const regulatedInsert = changes.insert.findIndex((item) =>
        this.isRegulatedContent(item.attributes.code)
      );
      const regulatedUpdate = changes.update.findIndex((item) =>
        this.isRegulatedContent(item.attributes.code)
      );
      const regulatedDelete = changes.delete.findIndex((item) =>
        this.isRegulatedContent(item.attributes.code)
      );
      return (
        regulatedInsert > -1 || regulatedUpdate > -1 || regulatedDelete > -1
      );
    },
    saveChanges() {
      const changes = this.getChanges();
      // Check if we need a regulated CR code and open the dialog
      if (this.needsCRCode(changes)) {
        this.changeReqDialog = true;
      } else this.finalSave();
    },
    saveWithCRCode() {
      this.changeReqDialog = false;
      this.finalSave();
    },
    finalSave() {
      const changes = this.getChanges();
      this.saving = true;
      this.insertContent(
        changes,
        () => {
          this.updateContent(
            changes,
            () => {
              this.deleteContent(
                changes,
                () => {
                  this.saving = false;
                  this.toast('Changes saved');
                  this.regulatedCRCode = null;
                  this.refreshContent();
                },
                (error) => {
                  ConsoleLog(error);
                  this.saving = false;
                  this.onApiError(error);
                }
              );
            },
            (error) => {
              ConsoleLog(error);
              this.saving = false;
              this.onApiError(error);
            }
          );
        },
        (error) => {
          ConsoleLog(error);
          this.saving = false;
          this.onApiError(error);
        }
      );
    },
    getApiUrl() {
      const basePath = 'v3/admin/resource/content';
      return this.regulatedCRCode
        ? `${basePath}?cr-id=${this.regulatedCRCode}`
        : basePath;
    },
    insertContent(changes, onSuccess, onError) {
      let body = {
        data: changes.insert,
      };
      if (changes.insert.length > 0) {
        ConsoleLog(`Inserting items: ${JSON.stringify(body)}`);
        OneDropApi.post(
          this.getApiUrl(),
          body,
          (response) => {
            changes.insert = [];
            onSuccess(response);
          },
          onError
        );
      } else onSuccess(null);
    },
    updateContent(changes, onSuccess, onError) {
      let body = {
        data: changes.update,
      };
      if (changes.update.length > 0) {
        ConsoleLog(`Updating items: ${JSON.stringify(body)}`);
        OneDropApi.patch(
          this.getApiUrl(),
          body,
          (response) => {
            changes.update = [];
            onSuccess(response);
          },
          onError
        );
      } else onSuccess(null);
    },
    deleteContent(changes, onSuccess, onError) {
      let body = {
        data: changes.delete,
      };
      if (changes.delete.length > 0) {
        ConsoleLog(`Deleting items: ${JSON.stringify(body)}`);
        OneDropApi.del(
          this.getApiUrl(),
          body,
          (response) => {
            changes.delete = [];
            onSuccess(response);
          },
          onError
        );
      } else onSuccess(null);
    },
    editCommon: function () {
      ConsoleLog(`Common content: ${this.multiSelect}`);
      let c = this.multiSelect[0].attributes.content;
      for (let k = 1; k < this.multiSelect.length; k++) {
        let p = this.multiSelect[k].attributes.content;
        if (p !== c) c = '';
      }
      const re1 = /onedrop:\/\/(.*)[=]([^=]*)$/gm;
      this.commonContent = c;
      if (this.commonContent && re1.test(this.commonContent)) {
        this.editTab = 1;
      } else this.editTab = null;
      this.showCommonDialog = true;
    },
    onSaveCommon() {
      this.close();
    },
    onClone() {
      let cloned = [];
      for (let k in this.multiSelect) {
        let item = JSON.parse(JSON.stringify(this.multiSelect[k]));
        delete item.id;
        cloned.push(item);
      }
      let code = cloned[0].attributes.code;
      for (let k = 1; k < cloned.length; k++) {
        // Find the longest set of consecutive characters
        let str2 = cloned[k].attributes.code;
        let common = '';
        for (
          let i = 0;
          i < code.length && i < str2.length && code[i] == str2[i];
          i++
        ) {
          common += code[i];
        }
        code = common;
      }
      ConsoleLog(`Code: ${code}, Cloned: ${JSON.stringify(cloned)}`);
      if (code && code.length > 0) {
        this.clonedContent = cloned;
        this.commonCode = code;
        this.clonedCode = code;
        this.cloneDialog = true;
      } else this.onApiError('Failed to find a common code');
    },
    onSaveClone() {
      let errorCodes = [];
      for (let k = 0; k < this.clonedContent.length; k++) {
        let newCode = new String(this.clonedContent[k].attributes.code);
        newCode = newCode.replace(this.commonCode, this.clonedCode);
        if (
          this.contentList.findIndex((val) => val.attributes.code == newCode) >
          -1
        )
          errorCodes.push(newCode);
      }
      if (errorCodes.length > 0) {
        this.onApiError(`These codes already exist ${errorCodes}`);
        this.cloneDialog = false;
        return;
      }
      for (let k = 0; k < this.clonedContent.length; k++) {
        let code = this.clonedContent[k].attributes.code;
        this.clonedContent[k].attributes.code = code.replace(
          this.commonCode,
          this.clonedCode
        );
        this.contentList.push(this.clonedContent[k]);
      }
      this.close();
    },
    onMultiDelete() {
      for (let k in this.multiSelect) {
        let index = this.contentList.indexOf(this.multiSelect[k]);
        if (index >= 0) this.contentList.splice(index, 1);
      }
      this.close();
    },
    searchUrl() {
      let path = this.currentUrl;
      const protocol = window.location.protocol;
      const host = window.location.host;
      return `${protocol}//${host}/#${path}`;
    },
    onCopySearchClicked: function () {
      const copiedText = this.copyToClipboard('searchUrl');
      this.toast('Copied: ' + copiedText);
    },
  },
};
</script>

<style>
.wrap {
  overflow-wrap: anywhere;
}
</style>
