<template>
  <v-app>
    <v-main style="background: white">
      <v-snackbar
        v-model="$store.state.isInfoMessageVisible"
        :timeout="15000"
        :top="true"
      >
        {{ $store.state.infoMessage }}
        <v-btn text @click="$store.commit('setIsInfoMessageVisible', false)">
          Close
        </v-btn>
      </v-snackbar>
      <v-snackbar
        v-model="$store.state.isErrorMessageVisible"
        :timeout="-1"
        :top="true"
        color="red"
      >
        {{ $store.state.errorMessage }}
        <v-btn text @click="$store.commit('setIsErrorMessageVisible', false)">
          Close
        </v-btn>
      </v-snackbar>
      <ModalIdle v-if="isIdle" />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { ConsoleLog, OneDropUtils } from './onedrop-utils';
import ModalIdle from './components/IdleModal.vue';

export default {
  components: { ModalIdle },
  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
  },
  created() {
    if (!OneDropUtils.isJwtValid() && this.$route.path !== '/login') {
      ConsoleLog(`App.vue: JWT is invalid, signing out`);
      this.signOut(this.$route.path);
    } else {
      // reset "semi-transient" states:
      this.$store.commit('setIsInfoMessageVisible', false);
      this.$store.commit('setIsErrorMessageVisible', false);
    }
  },
  mounted: function () {
    window.addEventListener('api-error', this.handleApiError);
  },
  methods: {
    handleApiError(event) {
      ConsoleLog(`App.vue: Received API error ${event.detail}`);
      this.signOut(this.$route.fullPath, event.detail);
    },
  },
};
</script>
<style>
/*left-justify pagination in data tables*/
.v-data-footer {
  webkit-box-pack: unset !important;
  justify-content: left !important;
}

.v-data-footer__pagination {
  margin: 0px !important;
}
</style>
