<template>
  <v-container fluid>
    <v-layout class="display-1 py-4"> Manage Questions </v-layout>
    <v-tabs>
      <v-tab key="1" to="/setup/questioneditor"> Groups </v-tab>
      <v-tab key="2" to="/setup/questioneditor/questions"> Questions </v-tab>
    </v-tabs>
    <router-view />
  </v-container>
</template>

<script>
export default {
  name: 'QuestionView',
};
</script>

<style></style>
