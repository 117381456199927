<template>
  <v-dialog v-model="dialog" persistent max-width="290">
    <v-card>
      <v-card-title class="text-h5"> Session Expired </v-card-title>

      <v-card-text> You have been logged out due to inactivity </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text @click="dialog = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  created() {
    if (this.$store.state.idleVue.isIdle && this.$route.path !== '/login') {
      this.dialog = true;
      this.signOut(this.$route.path);
    }
  },
};
</script>

<style scoped></style>
