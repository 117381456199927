<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-progress-circular v-if="loading" indeterminate color="primary" />
      <v-row v-else no-gutters>
        <v-col v-if="history.length > 0" cols="12">
          <v-row>
            <v-col cols="4" class="od-heading"> Last Session </v-col>
            <v-col cols="8">
              {{ lastSession }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <ActivityPlot :history="history" :height="160" :width="340" />
          </v-row>
        </v-col>
        <v-col v-else cols="12" class="grey--text"> No Activity </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { ConsoleLog, OneDropUtils } from '../onedrop-utils';
import ActivityPlot from './ActivityPlot';
import { parseISO } from 'date-fns';

export default {
  name: 'ActivityDetails',
  components: { ActivityPlot },
  props: ['userId'],
  data: function () {
    return {
      history: [],
      loading: false,
    };
  },

  computed: {
    joinedAt: function () {
      return OneDropUtils.displayDate(this.coach.attributes.createdAt);
    },

    lastSession: function () {
      const lastSessionDate = this.history[this.history.length - 1].date;
      return OneDropUtils.displayDay(lastSessionDate);
    },
  },

  watch: {
    userId: function (newUserId) {
      this.onUserChange(newUserId);
    },
  },

  created: function () {
    this.onUserChange(this.userId);
  },

  methods: {
    onUserChange: function (userId) {
      if (
        userId &&
        OneDropApi.hasOneOfPerms(['admin-read', 'admin-user-read'])
      ) {
        var _toDate = new Date();
        var _fromDate = new Date();
        _fromDate.setDate(_toDate.getDate() - 180);
        var fromDate = _fromDate.toISOString();
        this.loading = true;
        OneDropApi.getPingHistogram(
          this.userId,
          fromDate,
          _toDate.toISOString(),
          (response) => {
            ConsoleLog('Got ping histogram');
            this.history = response.data[0].attributes.histogram.map((item) => {
              return {
                count: item.count,
                date: parseISO(item.date),
              };
            });
            this.loading = false;
          },
          (error) => {
            ConsoleLog(error);
            this.onApiError(error);
            this.loading = false;
          }
        );
      }
    },
  },
};
</script>

<style scoped></style>
