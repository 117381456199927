<template>
  <v-row no-gutters>
    <v-container v-if="partnerId" no-gutters class="pa-0" fluid>
      <v-row v-if="partner !== null" no-gutters justify="center" align="center">
        <v-col cols="4" class="od-label"> Partner ID </v-col>
        <v-col cols="8">
          {{ partner.id }}
        </v-col>
      </v-row>
      <v-row v-if="partner !== null" no-gutters justify="center" align="center">
        <v-col cols="4" class="od-label">
          <v-img
            :src="partner.attributes.primaryIconURL"
            max-height="30"
            max-width="100"
            contain
          />
        </v-col>
        <v-col cols="8" class="subtitle-2">
          {{ partner.attributes.name }}
        </v-col>
      </v-row>
      <v-row v-if="partner === null" no-gutters>
        <v-progress-circular indeterminate color="primary" />
      </v-row>
    </v-container>
    <div v-else no-gutters class="grey--text">No partner</div>
  </v-row>
</template>

<script>
import { OneDropApi } from '../onedrop-api';

export default {
  name: 'UserPartnerDetail',
  props: ['partnerId'],
  data: function () {
    return {
      partner: null,
    };
  },
  created: function () {
    if (this.partnerId) {
      OneDropApi.getPartner(
        this.partnerId,
        (result) => {
          this.partner = result.data[0];
        },
        (error) => this.onApiError(error)
      );
    }
  },
};
</script>

<style scoped></style>
