<template>
  <v-container fluid>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template #top>
        <v-toolbar flat color="white">
          <v-text-field
            ref="searchText"
            v-model="search"
            label="Search"
            hide-details
            outlined
          />
          <v-spacer />
          <v-btn
            color="primary"
            class="mr-2"
            :disabled="loading"
            @click="refresh"
          >
            Reload
          </v-btn>
          <v-btn color="primary" :disabled="loading" @click="onNewItem">
            New SKU Entitlement
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.action="{ item }">
        <v-icon
          v-if="hasWritePerm()"
          class="mr-2"
          @click.stop="onEditItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon v-if="hasWritePerm()" @click.stop="onDeleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <div>
      <v-dialog v-model="editItemDialog" max-width="60%">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text v-if="editItem">
            <div v-if="editItem.id" class="pb-2">
              <span class="od-label">ID:</span
              ><span class="pl-2">{{ editItem.id }}</span>
            </div>
            <v-container fluid>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="editItem.attributes.sku"
                    outlined
                    label="SKU"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="editItem.attributes.stripCount"
                    type="number"
                    outlined
                    label="Strip Count"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="editItem.attributes.stripMonths"
                    type="number"
                    outlined
                    label="Strip Months"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="editItem.attributes.coachMonths"
                    type="number"
                    outlined
                    label="Coach Months"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="editItem.attributes.otherMonths"
                    type="number"
                    outlined
                    label="Other Months"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="editItem.attributes.lancetMonths"
                    type="number"
                    outlined
                    label="Lancet Count"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="editItem.attributes.predictionMonths"
                    type="number"
                    outlined
                    label="Prediction Months"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="schedOrder"
                    outlined
                    label="Scheduled Order"
                    hint="Comma separated list of numbers"
                    :rules="[rules.numbers]"
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-autocomplete
              v-model="editItem.attributes.entitlementKeys"
              :items="entitlements"
              label="Entitlement Keys"
              item-text="attributes.key"
              item-value="attributes.key"
              hide-selected
              chips
              deletable-chips
              multiple
            >
              <template #item="data">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ data.item.attributes.key }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.item.attributes.name }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ data.item.attributes.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="onCancel"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteItemDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Delete SKU Entitlement</span>
          </v-card-title>

          <v-card-text v-if="editItem">
            <div class="pb-2">
              <span class="od-label">ID:</span
              ><span class="pl-2">{{ editItem.id }}</span>
            </div>
            <v-text-field
              v-model="editItem.attributes.sku"
              outlined
              label="SKU"
              readonly
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="onCancel"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="doDelete"> Delete </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import { OneDropApi } from '@/onedrop-api';
import { ConsoleLog } from '@/onedrop-utils';

export default {
  name: 'SKUEntitlementList',
  components: {},
  data: function () {
    return {
      loading: false,
      headers: [
        {
          text: 'SKU',
          value: 'attributes.sku',
        },
        {
          text: 'Entitlement Keys',
          value: 'attributes.entitlementKeys',
          width: '30%',
        },
        {
          text: 'Scheduled Order',
          value: 'attributes.scheduledOrder',
          sortable: false,
        },
        {
          text: 'Strip Count',
          value: 'attributes.stripCount',
        },
        {
          text: 'Strip Months',
          value: 'attributes.stripMonths',
        },
        {
          text: 'Coach Months',
          value: 'attributes.coachMonths',
        },
        {
          text: 'Other Months',
          value: 'attributes.otherMonths',
        },
        {
          text: 'Lancet Months',
          value: 'attributes.lancetMonths',
        },
        {
          text: 'Prediction Months',
          value: 'attributes.predictionMonths',
        },
        { text: 'Actions', value: 'action', sortable: false, width: '100px' },
      ],
      items: [],
      entitlements: [],
      search: null,
      editItemDialog: false,
      deleteItemDialog: false,
      editedIndex: -1,
      editItem: null,
      rules: {
        numbers: (value) => {
          try {
            if (!value) return true;
            const list = value.split(',');
            for (let n in list) if (isNaN(list[n])) return 'Not a number';
            return true;
          } catch (e) {
            return 'Invalid';
          }
        },
      },
    };
  },
  computed: {
    formTitle() {
      return this.editItem && this.editItem.id
        ? 'Edit SKU Entitlement'
        : `New SKU Entitlement`;
    },
    schedOrder: {
      get() {
        return this.editItem && this.editItem.attributes.scheduledOrder
          ? this.editItem.attributes.scheduledOrder.join(',')
          : null;
      },
      set(value) {
        if (this.editItem) {
          if (value) {
            const list = value
              .split(',')
              .map((x) => (isNaN(x) ? null : Number(x)))
              .filter((x) => x);
            this.editItem.attributes.scheduledOrder = list;
          } else this.editItem.attributes.scheduledOrder = null;
        }
      },
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'sku-entitlement-write']);
    },
    refresh() {
      this.loading = true;
      this.items = [];
      OneDropApi.get(
        'v3/admin/sku-entitlement',
        (resp) => {
          this.items = resp.data;
          this.refreshEntitlements();
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    refreshEntitlements() {
      this.loading = true;
      this.entitlements = [];
      OneDropApi.get(
        'v3/admin/entitlement',
        (resp) => {
          this.entitlements = resp.data;
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    onNewItem() {
      this.editItem = {
        attributes: {
          coachMonths: 0,
          entitlementKeys: [],
          scheduledOrder: [],
          lancetMonths: 0,
          otherMonths: 0,
          predictionMonths: 0,
          sku: null,
          stripCount: 0,
          stripMonths: 0,
        },
        type: 'sku-entitlement',
      };
      this.editItemDialog = true;
    },
    onEditItem(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.editItemDialog = true;
    },
    onDeleteItem(item) {
      this.editItem = item;
      this.deleteItemDialog = true;
    },
    onCancel() {
      this.editItem = null;
      this.editItemDialog = false;
      this.deleteItemDialog = false;
    },
    doSave() {
      if (
        this.editItem.attributes.entitlementKeys &&
        this.editItem.attributes.entitlementKeys.length == 0
      )
        delete this.editItem.attributes.entitlementKeys;
      if (
        this.editItem.attributes.scheduledOrder &&
        this.editItem.attributes.scheduledOrder.length == 0
      )
        delete this.editItem.attributes.scheduledOrder;
      let body = { data: [this.editItem] };
      this.loading = true;
      ConsoleLog(`Saving SKU: ${JSON.stringify(body)}`);
      OneDropApi.patch(
        '/v3/admin/sku-entitlement',
        body,
        () => {
          this.loading = false;
          this.toast(`SKU Entitlement updated successfully`);
          this.onCancel();
          this.refresh();
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    doDelete() {
      const item = this.editItem;
      ConsoleLog(`Deleting SKU: ${JSON.stringify(item)}`);
      OneDropApi.del(
        `/v3/admin/sku-entitlement/${item.id}`,
        null,
        () => {
          this.loading = false;
          this.toast(`SKU Entitlement deleted successfully`);
          this.onCancel();
          this.refresh();
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style></style>
