<!--Display and edit the track-coach relationship-->
<template>
  <v-card flat>
    <v-data-table
      :items="editedCoaches"
      :headers="headers"
      :loading="loading"
      :search="search"
    >
      <template #top>
        <v-toolbar flat color="white">
          <v-text-field
            ref="searchText"
            v-model="search"
            label="Search"
            outlined
            hide-details
          />
          <v-spacer />
          <v-btn
            color="primary"
            dark
            :loading="loading"
            class="mb-2 mr-2"
            @click="editCoaches"
          >
            Add Coach
          </v-btn>
          <v-btn
            v-if="hasWritePerm()"
            color="primary"
            :loading="loading"
            :disabled="!hasChanges"
            class="mb-2 mr-2"
            @click="onSaveChanges"
          >
            Save
          </v-btn>
          <v-btn
            v-if="hasWritePerm()"
            color="primary"
            :loading="loading"
            :disabled="!hasChanges"
            class="mb-2 mr-2"
            @click="onCancelChanges"
          >
            Cancel
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.action="{ item }">
        <v-icon
          v-if="hasWritePerm()"
          class="mr-2"
          @click.stop="deleteCoach(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template #item.image="{ item }">
        <v-avatar v-if="getImage(item)" left>
          <v-img :src="getImage(item)" />
        </v-avatar>
      </template>
      <template #item.fullName="{ item }">
        {{ item.attributes.firstName }} {{ item.attributes.lastName }}
      </template>
      <template #item.partnerName="{ item }">
        <div v-if="item.attributes.trackCoachPartnerId">
          {{ getPartnerName(item.attributes.trackCoachPartnerId) }} ({{
            item.attributes.trackCoachPartnerId
          }})
        </div>
      </template>
      <template #item.attributes.active="{ item }">
        <v-switch v-model="item.attributes.active" />
      </template>
      <template #no-data>
        <div class="text-center">No coaches</div>
      </template>
    </v-data-table>
    <v-dialog v-model="editing" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Add Coach</span>
        </v-card-title>

        <v-card-text>
          <v-autocomplete
            v-model="selectedCoachId"
            :items="allCoaches"
            label="New Coach"
            :item-text="nameEmail"
            item-value="id"
            chips
            hide-selected
          >
            <template #selection="data">
              <v-chip v-bind="data.attrs" :input-value="data.selected">
                <v-avatar v-if="getImage(data.item)" left>
                  <v-img :src="getImage(data.item)" />
                </v-avatar>
                {{ nameEmail(data.item) }}
              </v-chip>
            </template>
            <template #item="data">
              <v-list-item-avatar>
                <v-img v-if="getImage(data.item)" :src="getImage(data.item)" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.attributes.firstName }}
                  {{ data.item.attributes.lastName }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.item.attributes.email }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-model="selectedPartnerId"
            :items="partnerList"
            label="Partner (Optional)"
            :item-text="partnerName"
            item-value="id"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="selectedCoachId === null"
            @click="addCoach(selectedCoachId)"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { OneDropApi } from '../../onedrop-api';
import { ConsoleLog } from '../../onedrop-utils';

export default {
  name: 'TrackCoach',
  props: ['trackId'],
  data: function () {
    return {
      coaches: [],
      editedCoaches: [],
      allCoaches: [],
      loading: false,
      editing: false,
      headers: [
        {
          text: 'Image',
          value: 'image',
        },
        {
          text: 'Name',
          value: 'fullName',
        },
        {
          text: 'Email',
          value: 'attributes.email',
        },
        {
          text: 'Partner Name (Optional)',
          value: 'partnerName',
        },
        {
          text: 'Active',
          value: 'attributes.active',
        },
        { text: 'Delete', value: 'action', sortable: false },
      ],
      selectedCoachId: null,
      selectedPartnerId: null,
      search: null,
    };
  },
  computed: {
    partnerList() {
      return this.$store.getters.partnerList;
    },
    hasChanges() {
      return (
        JSON.stringify(this.editedCoaches) !== JSON.stringify(this.coaches)
      );
    },
  },
  watch: {
    trackId: function () {
      this.refreshCoaches();
    },
  },
  created() {
    this.refreshCoaches();
    this.getAllCoaches();
  },
  methods: {
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'track-write']);
    },
    partnerName: (item) => `${item.name} (${item.id})`,
    getPartnerById: function (id) {
      return this.$store.getters.partnerById(id);
    },
    getPartnerName: function (id) {
      if (id) {
        const p = this.getPartnerById(id);
        return p ? p.attributes.name : null;
      }
      return null;
    },
    getImage: function (item) {
      if (item && item.attributes) {
        if (item.attributes.imageUrl) return item.attributes.imageUrl;
        else if (item.attributes.imageURL) return item.attributes.imageURL;
      }
      return null;
    },
    getAllCoaches() {
      OneDropApi.getAllCoaches(
        (response) => {
          this.allCoaches = response.data;
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    refreshCoaches: function () {
      this.loading = true;
      OneDropApi.getCoachesForTrack(
        this.trackId,
        (response) => {
          this.coaches = JSON.parse(JSON.stringify(response.data));
          this.editedCoaches = JSON.parse(JSON.stringify(response.data));
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    editCoaches: function () {
      ConsoleLog(`Edit coaches`);
      this.editing = true;
    },
    save: function () {
      ConsoleLog(`Save coaches`);
      this.refreshCoaches();
      this.editing = false;
    },
    close: function () {
      this.selectedPartnerId = null;
      this.selectedCoachId = null;
      this.editing = false;
    },
    deleteCoach: function (coach) {
      ConsoleLog(`Delete coach ${JSON.stringify(coach)}`);
      let index = this.editedCoaches.findIndex((k) => {
        return coach.id === k.id;
      });
      if (index > -1) {
        this.editedCoaches.splice(index, 1);
      }
    },
    nameEmail: (item) =>
      `${item.attributes.firstName} ${item.attributes.lastName} (${item.attributes.email})`,
    addCoach(coachId) {
      const coachIdObject = this.allCoaches.filter((v) => v.id === coachId)[0];
      const updatedCoach = Object.assign({}, coachIdObject, {
        attributes: {
          ...coachIdObject.attributes,
          active: true,
          trackCoachPartnerId: this.selectedPartnerId,
        },
      });
      this.editedCoaches.push(updatedCoach);
      this.close();
    },
    onCancelChanges() {
      this.editedCoaches = this.coaches;
    },
    onSaveChanges() {
      /**
       * We computed all the updated and deleted coaches
       * We then save them and once they are all saved, we emit an event
       */
      ConsoleLog(`Save coaches: ${JSON.stringify(this.editedCoaches)}`);
      let deleted = [];
      this.loading = true;
      const updates = this.editedCoaches.map((coach) => {
        return {
          type: 'track-coach',
          attributes: {
            trackId: this.trackId,
            coachId: coach.id,
            partnerId: coach.attributes.trackCoachPartnerId,
            active: coach.attributes.active,
          },
        };
      });
      for (let i in this.coaches) {
        let c = this.coaches[i];
        const index = this.editedCoaches.findIndex((k) => {
          return c.id === k.id;
        });
        if (index < 0) {
          const o = {
            type: 'track-coach',
            attributes: {
              trackId: this.trackId,
              coachId: c.id,
              partnerId: c.attributes.trackCoachPartnerId,
            },
          };
          deleted.push(o);
        }
      }
      ConsoleLog(`Updated: ${JSON.stringify(updates)}`);
      ConsoleLog(`Deleted: ${JSON.stringify(deleted)}`);
      let prom1 = new Promise((resolve, reject) => {
        if (updates.length == 0) resolve(0);
        else {
          let body = { data: updates };
          OneDropApi.patchTrackCoach(
            body,
            (response) => {
              resolve(response.data);
            },
            (error) => {
              reject(error);
            }
          );
        }
      });
      let prom2 = new Promise((resolve, reject) => {
        if (deleted.length == 0) resolve(0);
        else {
          let body = { data: deleted };
          OneDropApi.deleteTrackCoach(
            body,
            (response) => {
              resolve(response.data);
            },
            (error) => {
              reject(error);
            }
          );
        }
      });
      // If both promises succeed, then we emit a saved event
      // otherwise we return an error
      Promise.all([prom1, prom2])
        .then((values) => {
          ConsoleLog(`All changes saved ${values}`);
          this.loading = false;
          this.refreshCoaches();
          this.$emit('saved', this.editedCoaches);
        })
        .catch((error) => {
          this.onApiError(error);
          ConsoleLog(`Error saving track-coach: ${error}`);
          this.loading = false;
          this.close();
        });
    },
  },
};
</script>

<style scoped></style>
