var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.computedHeaders,"loading":_vm.loading,"search":_vm.search,"sort-by":['attributes.createdAt'],"sort-desc":[true],"show-expand":"","single-expand":""},scopedSlots:_vm._u([(!_vm.eventType && !_vm.objectId)?{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-4",attrs:{"flat":"","color":"white"}},[_c('v-text-field',{staticClass:"mb-4",attrs:{"label":"Search","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-select',{attrs:{"items":_vm.eventTypes,"label":"Event Types","hide-selected":"","chips":"","deletable-chips":"","multiple":""},model:{value:(_vm.selectedEvents),callback:function ($$v) {_vm.selectedEvents=$$v},expression:"selectedEvents"}}),_c('v-spacer'),_c('v-menu',{ref:"dateMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","min-width":"290px","max-width":"290px","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mr-2",attrs:{"label":"Start Date","prepend-inner-icon":"mdi-calendar","hint":"YYYY-MM-DD","persistent-hint":"","outlined":"","clearable":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},on))]}}],null,false,3453042402),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.startDateMenu = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('v-menu',{ref:"dateMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","min-width":"290px","max-width":"290px","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"End Date","prepend-inner-icon":"mdi-calendar","hint":"YYYY-MM-DD","persistent-hint":"","outlined":"","clearable":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},on))]}}],null,false,3433414274),model:{value:(_vm.endDateMenu),callback:function ($$v) {_vm.endDateMenu=$$v},expression:"endDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.endDateMenu = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c('v-spacer')],1)]},proxy:true}:null,{key:"item.attributes.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.attributes.createdAt)))])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-tabs',[(item.attributes.beforeJson || item.attributes.afterJson)?_c('v-tab',{key:"1"},[_vm._v(" "+_vm._s(_vm.contentTitle(item))+" ")]):_vm._e(),(item.attributes.payload)?_c('v-tab',{key:"2"},[_vm._v(" Payload ")]):_vm._e(),_c('v-tab-item',{key:"1"},[(item.attributes.beforeJson && item.attributes.afterJson)?_c('div',[_c('monaco-diff',{staticClass:"diffEditor",attrs:{"language":"json","options":_vm.monacoReadOnlyOpts,"orig":item.attributes.beforeJson,"modified":item.attributes.afterJson}})],1):(item.attributes.beforeJson)?_c('div',[_c('monaco',{staticClass:"editor fill-height",attrs:{"language":"json","options":_vm.monacoReadOnlyOpts},model:{value:(item.attributes.beforeJson),callback:function ($$v) {_vm.$set(item.attributes, "beforeJson", $$v)},expression:"item.attributes.beforeJson"}})],1):(item.attributes.afterJson)?_c('div',[_c('monaco',{staticClass:"editor fill-height",attrs:{"language":"json","options":_vm.monacoReadOnlyOpts},model:{value:(item.attributes.afterJson),callback:function ($$v) {_vm.$set(item.attributes, "afterJson", $$v)},expression:"item.attributes.afterJson"}})],1):_vm._e()]),_c('v-tab-item',{key:"2"},[(item.attributes.payload)?_c('div',[_c('monaco',{staticClass:"editor fill-height",attrs:{"language":"json","options":_vm.monacoReadOnlyOpts},model:{value:(item.attributes.payload),callback:function ($$v) {_vm.$set(item.attributes, "payload", $$v)},expression:"item.attributes.payload"}})],1):_vm._e()])],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }