<template>
  <v-container fluid>
    <v-layout class="display-1 py-4"> Feature Groups </v-layout>
    <div class="text-center">
      <v-progress-circular v-if="saving" indeterminate color="primary" />
    </div>
    <v-data-table
      :items="allGroups"
      :headers="headers"
      :search="search"
      :loading="loading"
    >
      <template #top>
        <v-dialog v-model="dialog" max-width="50%">
          <template #activator="{ on }">
            <v-toolbar flat color="white">
              <v-text-field
                v-model="search"
                label="Search"
                hide-details
                outlined
                class="mr-4"
              />
              <v-btn
                color="primary"
                dark
                :loading="loading"
                :disabled="loading"
                class="mb-2 mr-2"
                @click="refreshGroups"
              >
                Reload
              </v-btn>
              <v-btn
                v-if="hasWritePerm()"
                color="primary"
                :disabled="loading"
                dark
                class="mb-2"
                v-on="on"
                @click="onNewGroup"
              >
                New Group
              </v-btn>
            </v-toolbar>
          </template>
          <v-card v-if="editedItem">
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row label="ID">
                  <v-col>ID: {{ editedItem.id }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="editedItem.attributes.partnerId"
                      :items="partnerList"
                      label="Partner"
                      :item-text="partnerName"
                      item-value="id"
                      hide-selected
                      chips
                      deletable-chips
                    />
                  </v-col>
                  <v-col cols="6">
                    <UserSelect
                      v-model="editedItem.attributes.userId"
                      :initial-items="getInitialItems(editedItem)"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                :disabled="loading"
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" max-width="600px">
          <v-card v-if="editedItem">
            <v-card-title>
              <span class="headline">Delete Group</span>
            </v-card-title>

            <v-card-text>
              <v-row label="ID">
                <v-col>ID: {{ editedItem.id }}</v-col>
              </v-row>
              <v-text-field
                v-model="editedItem.attributes.partnerId"
                outlined
                label="Partner Id"
                readonly
              />
              <v-text-field
                v-model="editedItem.attributes.userId"
                outlined
                label="User Id"
                readonly
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                :disabled="loading"
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                @click="deleteGroup"
              >
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template #header.action="{ header }">
        <span v-if="hasWritePerm()">{{ header.text }}</span>
      </template>
      <template #item.action="{ item }">
        <v-icon
          v-if="hasWritePerm()"
          class="mr-2"
          title="Edit"
          @click.stop="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="hasWritePerm()"
          title="Delete"
          @click.stop="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template #no-data>
        <v-btn color="primary" @click="refreshGroups"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { ConsoleLog, OneDropUtils } from '../onedrop-utils';
import UserSelect from '@/components/UserSelect';

export default {
  name: 'FeatureGroup',
  components: { UserSelect },
  props: ['groupId'],
  data: function () {
    return {
      dialog: false,
      deleteDialog: false,
      loading: false,
      saving: false,
      search: '',
      dateFormat: 'yyyy-MM-dd',
      timeFormat: 'HH:mm',
      headers: [
        {
          text: 'ID',
          value: 'id',
          filterable: false,
        },
        {
          text: 'Feature',
          value: 'attributes.groupId',
        },
        {
          text: 'Partner ID',
          value: 'attributes.partnerId',
        },
        {
          text: 'Partner Name',
          value: 'attributes.partnerName',
        },
        {
          text: 'User ID',
          value: 'attributes.userId',
        },
        {
          text: 'User Email',
          value: 'attributes.userEmail',
        },
        { text: 'Actions', value: 'action', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      allGroups: [],
      editedIndex: -1,
      editedItem: null,
      expanded: [],
      timeZones: [
        'America/New_York',
        'America/Chicago',
        'America/Denver',
        'America/Los_Angeles',
      ],
      timeZonesUserFacing: ['EST', 'CST', 'MST', 'PST'],
      rules: {
        required: (value) => !!value || 'Required.',
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex == -1 ? 'New Group' : `Edit Group`;
    },
    partnerList() {
      return this.$store.getters.partnerList;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    deleteDialog(val) {
      val || this.close();
    },
  },
  created() {
    this.refreshGroups();
  },
  methods: {
    partnerName: (item) => `${item.name} (${item.id})`,
    getInitialItems(item) {
      if (item.attributes.userId) {
        let attrs = {};
        if (item.attributes.userEmail) {
          attrs.email = item.attributes.userEmail;
        }
        return [
          {
            id: item.attributes.userId,
            type: 'user',
            attributes: attrs,
          },
        ];
      } else return [];
    },
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'feature-write']);
    },
    hasAssignRolePerm: function () {
      return OneDropApi.hasPerm('assign-role');
    },
    getPartnerById: function (id) {
      return this.$store.getters.partnerById(id);
    },
    getPartnerName: function (id) {
      if (id) {
        const p = this.getPartnerById(id);
        return p ? p.attributes.name : id;
      }
      return null;
    },
    getRole(item) {
      return item.attributes.userRoleName
        ? item.attributes.userRoleName
        : item.attributes.userRole;
    },
    getUserName(user) {
      return user ? (user.firstName + ' ' + user.lastName).trim() : null;
    },
    getUserEmail(user) {
      return user ? user.email : null;
    },
    formatDate: function (str) {
      return str ? OneDropUtils.displayDate(str) : str;
    },
    refreshGroups: function () {
      ConsoleLog('Fetching feature groups');
      this.loading = true;
      OneDropApi.get(
        `/admin/v3/feature-group/${this.groupId}`,
        (response) => {
          this.allGroups = response.data;
          ConsoleLog(`Got ${this.allGroups.length} groups`);
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    created: function () {
      this.refreshGroups();
    },
    onNewGroup: function () {
      this.editedItem = {
        type: 'feature-group',
        attributes: {
          groupId: this.groupId,
          partnerId: null,
          userId: null,
        },
      };
      this.editedIndex = -1;
      this.dialog = true;
    },
    editItem: function (item) {
      ConsoleLog('editItem', item);
      this.editedIndex = this.allGroups.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.dialog = true;
    },
    deleteItem: function (item) {
      ConsoleLog('deleteItem', item);
      this.editedIndex = this.allGroups.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.deleteDialog = true;
    },
    close() {
      this.editedItem = null;
      this.dialog = false;
      this.deleteDialog = false;
      this.editedIndex = -1;
      this.loading = false;
    },
    save() {
      ConsoleLog(`Saving item ${JSON.stringify(this.editedItem)}`);
      let body = { data: [this.editedItem] };
      ConsoleLog(`Saving group ${this.editedIndex}: ${JSON.stringify(body)}`);
      this.loading = true;
      if (this.editedIndex > -1) {
        OneDropApi.patch(
          `/admin/v3/feature-group`,
          body,
          (response) => {
            const item = response.data[0];
            this.allGroups.splice(this.editedIndex, 1, item);
            this.saving = false;
            this.toast(`Group saved successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.saving = false;
            this.onApiError(error);
            this.loading = false;
          }
        );
      } else {
        OneDropApi.post(
          '/admin/v3/feature-group',
          body,
          (response) => {
            const item = response.data[0];
            this.allGroups.push(item);
            this.toast(`Group added successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.saving = false;
            this.onApiError(error);
            this.loading = false;
          }
        );
      }
    },
    deleteGroup() {
      this.saving = true;
      let item = this.allGroups[this.editedIndex];
      let index = this.editedIndex;
      ConsoleLog('Delete Group', item.id);
      this.loading = true;
      OneDropApi.del(
        `/admin/v3/feature-group/${item.id}`,
        null,
        () => {
          this.allGroups.splice(index, 1);
          this.saving = false;
          this.toast(`Item ${item.id} deleted successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.saving = false;
          this.onApiError(error);
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style></style>
