<template>
  <v-simple-table>
    <template #default>
      <thead>
        <tr>
          <th>Id</th>
          <th>Title</th>
          <th>Slug</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loading">
          <td colspan="3">
            <v-progress-linear
              indeterminate
              color="primary"
              :active="loading"
            />
          </td>
        </tr>
        <tr v-for="group in groups" v-else :key="group.id">
          <td>
            <router-link
              :to="{
                path: '/setup/questioneditor',
                query: { query: String(group.id) },
              }"
            >
              {{ group.id }}
            </router-link>
          </td>
          <td>{{ group.attributes.title }}</td>
          <td>{{ group.attributes.slug }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { ConsoleLog } from '@/onedrop-utils';
import { OneDropApi } from '@/onedrop-api';

export default {
  name: 'QuestionGroup',
  props: ['questionId'],
  data: function () {
    return {
      groups: null,
      loading: false,
    };
  },
  watch: {
    questionId: function () {
      this.refreshList();
    },
  },
  mounted() {
    this.refreshList();
  },
  methods: {
    refreshList: function () {
      ConsoleLog('Fetching groups');
      this.loading = true;
      OneDropApi.get(
        `/v3/admin/question-group/question/${this.questionId} `,
        (response) => {
          this.groups = response.data;
          ConsoleLog(`Got ${this.groups.length} questions`);
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style scoped></style>
