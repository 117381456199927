<!--Display list of tracks for a coach -->
<template>
  <v-data-table
    :items="tracks"
    :headers="headers"
    :loading="loading"
    :search="search"
    dense
  >
    <template #top>
      <v-row dense align="center">
        <v-col cols="4">
          <v-text-field
            ref="searchText"
            v-model="search"
            label="Search"
            outlined
            hide-details
          />
        </v-col>
        <v-col cols="1" />
        <v-col cols="2">
          <v-select
            v-model="selectedLanguages"
            :items="Object.entries(languages)"
            label="Language"
            item-text="[1]"
            item-value="[0]"
            hide-selected
            chips
            deletable-chips
            multiple
          />
        </v-col>
        <v-col>
          <v-switch v-model="activeOnly" label="Active Only" class="pa-3" />
        </v-col>
      </v-row>
    </template>
    <template #item.id="{ item }">
      <router-link
        :to="{ path: '/content/trackeditor', query: { query: item.id } }"
      >
        {{ item.id }}
      </router-link>
    </template>
    <template #item.attributes.hidden="{ item }">
      <v-switch v-model="item.attributes.hidden" readonly />
    </template>
    <template #item.attributes.language="{ item }">
      <span>{{ formatLanguage(item.attributes.language) }}</span>
    </template>
    <template #item.attributes.createdAt="{ item }">
      <span>{{ formatDate(item.attributes.createdAt) }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { ConsoleLog, OneDropUtils } from '../onedrop-utils';

export default {
  name: 'CoachTrackList',
  props: ['coachId'],
  data: function () {
    return {
      tracks: [],
      loading: false,
      search: null,
      activeOnly: true,
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Title',
          value: 'attributes.title',
        },
        {
          text: 'Partner ID',
          value: 'attributes.partner',
        },
        {
          text: 'Partner Name',
          value: 'attributes.partnerName',
        },
        {
          text: 'Hidden',
          value: 'attributes.hidden',
          filter: (value, search, item) =>
            this.activeOnly == false || item.attributes.hidden != true,
        },
        {
          text: 'Language',
          value: 'attributes.language',
          filter: (value, search, item) =>
            this.selectedLanguages.length == 0 ||
            this.selectedLanguages.indexOf(item.attributes.language) > -1,
        },
        {
          text: 'Created At',
          value: 'attributes.createdAt',
        },
      ],
      languages: {
        en: 'English',
        ar: 'Arabic',
        it: 'Italian',
        es: 'Spanish',
        ru: 'Russian',
        de: 'German',
        fr: 'French',
        pt: 'Portuguese',
        zh: 'Chinese',
      },
      selectedLanguages: [],
    };
  },
  watch: {
    coachId: function () {
      this.refreshTracks();
    },
  },
  created() {
    this.refreshTracks();
  },
  methods: {
    getPartnerById: function (id) {
      return this.$store.getters.partnerById(id);
    },
    getPartnerName: function (id) {
      if (id) {
        const p = this.getPartnerById(id);
        return p ? p.attributes.name : null;
      }
      return null;
    },
    formatDate: function (str) {
      return str ? OneDropUtils.displayDate(str) : str;
    },
    formatLanguage: function (str) {
      if (str in this.languages) return this.languages[str];
      else return str;
    },
    refreshTracks: function () {
      this.loading = true;
      OneDropApi.get(
        `/v3/admin/resource/coach/${this.coachId}/tracks`,
        (response) => {
          if (!response.data) this.tracks = [];
          else
            this.tracks = response.data.map((d) => {
              if (d && d.attributes.partner) {
                const name = this.getPartnerName(d.attributes.partner);
                if (name) d.attributes.partnerName = name;
              }
              return d;
            });
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style scoped></style>
