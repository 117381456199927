<template>
  <v-container fluid>
    <v-layout class="display-1 py-4">
      Manage Lessons
      <v-icon
        class="ml-2"
        title="Copy Search Link"
        @click="onCopySearchClicked()"
      >
        mdi-content-copy
      </v-icon>
    </v-layout>
    <div class="text-center">
      <v-progress-circular v-if="saving" indeterminate color="primary" />
    </div>
    <input id="searchUrl" type="hidden" :value="searchUrl()" />
    <v-data-table
      :items="allLessons"
      :headers="headers"
      :search="search"
      :loading="loading"
      :expanded.sync="expanded"
      single-expand
      show-expand
    >
      <template #top>
        <v-dialog v-model="dialog" max-width="75%">
          <template #activator="{ on }">
            <v-toolbar flat color="white">
              <v-text-field
                v-model="search"
                label="Search"
                hide-details
                outlined
              />
              <v-spacer />
              <v-select
                v-model="selectedLanguages"
                :items="Object.entries(languages)"
                label="Language"
                item-text="[1]"
                item-value="[0]"
                hide-selected
                chips
                deletable-chips
                multiple
              />
              <v-spacer />
              <v-switch v-model="freeOnly" label="Free Only" class="pa-3" />
              <v-spacer />
              <v-btn
                v-if="hasWritePerm()"
                color="primary"
                dark
                class="mb-2 mr-2"
                @click="onImport"
              >
                Import
              </v-btn>
              <v-btn
                color="primary"
                dark
                :loading="loading"
                :disabled="loading"
                class="mb-2 mr-2"
                @click="refreshLessons"
              >
                Reload
              </v-btn>
              <v-btn
                v-if="hasWritePerm()"
                color="primary"
                :disabled="loading"
                dark
                class="mb-2"
                v-on="on"
                @click="onNewItem"
              >
                New Lesson
              </v-btn>
            </v-toolbar>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text v-if="editedItem">
              <div class="text-center">
                <v-progress-circular
                  v-if="saving"
                  indeterminate
                  color="primary"
                />
              </div>
              <v-row label="Object ID">
                <v-col>Object ID: {{ editedItem.id }}</v-col>
              </v-row>
              <v-container>
                <v-row justify="space-between">
                  <v-col cols="5">
                    <v-text-field
                      v-model="editedItem.attributes.lessonName"
                      outlined
                      label="Lesson Name"
                    />
                  </v-col>
                  <v-col cols="7">
                    <v-textarea
                      v-model="editedItem.attributes.lessonDescription"
                      outlined
                      label="Lesson Description"
                      auto-grow
                      rows="1"
                      row-height="24px"
                    />
                  </v-col>
                </v-row>
                <v-row justify="space-between">
                  <v-col>
                    <v-text-field
                      v-model="editedItem.attributes.url"
                      outlined
                      label="URL"
                      type="url"
                    />
                  </v-col>
                </v-row>
                <v-row justify="space-between">
                  <v-col cols="4">
                    <v-select
                      v-model="editedItem.attributes.lessonType"
                      :items="lessonTypes"
                      label="Lesson Type"
                      hide-selected
                      chips
                      deletable-chips
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="editedItem.attributes.number"
                      outlined
                      label="Number"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      v-model="editedItem.attributes.language"
                      :items="Object.entries(languages)"
                      label="Language"
                      item-text="[1]"
                      item-value="[0]"
                      hide-selected
                      chips
                      deletable-chips
                    />
                  </v-col>
                </v-row>
                <v-row justify="space-between">
                  <v-col cols="2">
                    <v-switch
                      v-model="editedItem.attributes.isFree"
                      label="Free"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-switch
                      v-model="editedItem.attributes.predeliver"
                      label="Pre-deliver"
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="editedItem.attributes.iconSlug"
                      outlined
                      label="Icon Slug"
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="editedItem.attributes.tag"
                      outlined
                      label="Tag"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="editedItem.attributes.heroImageUrl"
                      type="url"
                      outlined
                      label="Hero Image URL"
                      append-outer-icon="mdi-paperclip"
                      @click:append-outer="editImageDialog = true"
                    />
                    <amazon-s3-uploader
                      v-model="editImageDialog"
                      :edit-item="editedItem.attributes"
                      image-field="heroImageUrl"
                      @setUrlAttribute="setUrlAttribute"
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="editedItem.attributes.metaData"
                      outlined
                      label="Meta Data"
                      :rules="[rules.json]"
                      validate-on-blur
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                :disabled="saving"
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="!canSave() || saving"
                @click="saveLesson"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Delete Lesson</span>
            </v-card-title>

            <v-card-text v-if="editedItem">
              <v-row label="Object ID">
                <v-col>Object ID: {{ editedItem.id }}</v-col>
              </v-row>
              <v-text-field
                v-model="editedItem.attributes.lessonName"
                outlined
                label="Lesson Name"
                readonly
              />
              <v-text-field
                v-model="editedItem.attributes.lessonDescription"
                outlined
                label="Lesson Description"
                readonly
              />
              <v-text-field
                v-model="editedItem.attributes.url"
                outlined
                label="URL"
                type="url"
                readonly
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                :disabled="saving"
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="saving"
                @click="deleteLesson"
              >
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="importDialog" max-width="60%">
          <v-card v-if="importItem">
            <v-card-title>
              <span class="headline">Import</span>
            </v-card-title>
            <v-card-text>
              <div class="align-content-center">
                <v-progress-circular
                  v-if="importInProgress"
                  indeterminate
                  color="primary"
                />
              </div>
              <v-text-field v-model="importItem.url" label="URL" type="url" />
              <div
                v-if="importItem.message"
                class="text-center blue-grey--text text-h6 font-weight-medium"
              >
                {{ importItem.message }}
              </div>
              <v-tabs v-model="activeTab">
                <v-tab
                  v-if="importItem.data && importItem.data.length > 0"
                  key="data"
                >
                  Accepted
                </v-tab>
                <v-tab
                  v-if="importItem.errors && importItem.errors.length > 0"
                  key="errors"
                >
                  Errors
                </v-tab>
                <v-tab
                  v-if="importItem.info && importItem.info.length > 0"
                  key="info"
                >
                  No Action
                </v-tab>
                <v-tab-item
                  v-if="importItem.data && importItem.data.length > 0"
                  key="data"
                  class="text-xl-start font-weight-bold"
                >
                  <lesson-import-detail :items="importItem.data" />
                </v-tab-item>
                <v-tab-item
                  v-if="importItem.errors && importItem.errors.length > 0"
                  key="errors"
                >
                  <import-error :items="importItem.errors" />
                </v-tab-item>
                <v-tab-item
                  v-if="importItem.info && importItem.info.length > 0"
                  key="info"
                >
                  <import-error :items="importItem.info" />
                </v-tab-item>
              </v-tabs>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                :loading="importInProgress"
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="!importItem.url"
                :loading="importInProgress"
                @click="doImport(true)"
              >
                Validate
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="!importItem.url"
                :loading="importInProgress"
                @click="doImport(false)"
              >
                Import
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-container fluid>
            <v-tabs>
              <v-tab key="1"> Details </v-tab>
              <v-tab key="2"> Tracks </v-tab>
              <v-tab key="3"> Audit Events </v-tab>
              <v-tab-item key="1">
                <lesson-detail :lesson="item" />
              </v-tab-item>
              <v-tab-item key="2">
                <TrackList item-type="lesson" :item-content="item.id" />
              </v-tab-item>
              <v-tab-item key="3">
                <audit-log-viewer event-type="lesson" :object-id="item.id" />
              </v-tab-item>
            </v-tabs>
          </v-container>
        </td>
      </template>
      <template #header.action="{ header }">
        <span v-if="hasWritePerm()">{{ header.text }}</span>
      </template>
      <template #item.attributes.language="{ item }">
        <span>{{ formatLanguage(item.attributes.language) }}</span>
      </template>
      <template #item.attributes.createdAt="{ item }">
        <span>{{ formatDate(item.attributes.createdAt) }}</span>
      </template>
      <template #item.attributes.isFree="{ item }">
        <v-switch v-model="item.attributes.isFree" readonly />
      </template>
      <template #item.attributes.predeliver="{ item }">
        <v-switch v-model="item.attributes.predeliver" readonly />
      </template>
      <template #item.attributes.url="{ item }">
        <a
          :title="item.attributes.url"
          @click.stop="followLink(item.attributes.url)"
          >{{ resolveUrl(item) }}</a
        >
      </template>
      <template #item.action="{ item }">
        <v-icon v-if="hasWritePerm()" class="mr-2" @click.stop="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon v-if="hasWritePerm()" @click.stop="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template #no-data>
        <v-btn color="primary" @click="refreshLessons"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import store from '@/store';
import { OneDropApi } from '../onedrop-api';
import { ConsoleLog, OneDropUtils } from '../onedrop-utils';
import TrackList from '../components/track/TrackList';
import ImportError from '../components/ImportError';
import LessonImportDetail from '../components/LessonImportDetail';
import LessonDetail from '@/components/LessonDetail';
import AmazonS3Uploader from '@/components/AmazonS3Uploader';
import AuditLogViewer from '@/components/AuditLogViewer';

export default {
  name: 'Lessons',
  components: {
    TrackList,
    ImportError,
    LessonImportDetail,
    LessonDetail,
    AuditLogViewer,
    AmazonS3Uploader,
  },
  data: function () {
    return {
      dialog: false,
      deleteDialog: false,
      editImageDialog: false,
      loading: false,
      saving: false,
      search: '',
      dateFormat: 'yyyy-MM-dd',
      timeFormat: 'HH:mm',
      freeOnly: false,
      expanded: [],
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Name',
          value: 'attributes.lessonName',
        },
        {
          text: 'Description',
          value: 'attributes.lessonDescription',
          width: '20%',
        },
        {
          text: 'Type',
          value: 'attributes.lessonType',
        },
        {
          text: 'URL',
          value: 'attributes.url',
        },
        {
          text: 'Number',
          value: 'attributes.number',
        },
        {
          text: 'Free',
          value: 'attributes.isFree',
          filter: (value, search, item) =>
            this.freeOnly == false || item.attributes.isFree,
        },
        {
          text: 'Pre-deliver',
          value: 'attributes.predeliver',
        },
        {
          text: 'Language',
          value: 'attributes.language',
          filter: (value, search, item) =>
            this.selectedLanguages.length == 0 ||
            this.selectedLanguages.indexOf(item.attributes.language) > -1,
        },
        {
          text: 'Created At',
          value: 'attributes.createdAt',
          width: '120px',
        },
        { text: 'Actions', value: 'action', sortable: false, width: '100px' },
        { text: '', value: 'data-table-expand' },
      ],
      allLessons: [],
      lessonWrappers: null,
      jotformWrappers: null,
      courseWrappers: null,
      editedIndex: -1,
      editedItem: null,
      languages: {
        en: 'English',
        ar: 'Arabic',
        it: 'Italian',
        es: 'Spanish',
        ru: 'Russian',
        de: 'German',
        fr: 'French',
        pt: 'Portuguese',
        zh: 'Chinese',
      },
      lessonTypes: ['lesson', 'pdf', 'deep-link'],
      selectedLanguages: [],
      importItem: {
        url: null,
        data: null,
        errors: null,
        info: null,
        validated: false,
        message: null,
      },
      importDialog: false,
      importInProgress: false,
      activeTab: 'data',
      rules: {
        required: (value) => !!value || 'Required.',
        json: (value) => {
          try {
            if (value) JSON.parse(value);
            return true;
          } catch (e) {
            return 'Invalid JSON';
          }
        },
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex == -1 ? 'New Lesson' : `Edit Lesson`;
    },
    easyLmsUrls() {
      if (this.courseWrappers)
        return this.courseWrappers.map((t) => t.attributes.content);
      else return [];
    },
    currentUrl: function () {
      let q = {};
      if (this.search) q.query = encodeURIComponent(this.search);
      if (this.selectedLanguages && this.selectedLanguages.length > 0)
        q.languages = encodeURIComponent(
          JSON.stringify(this.selectedLanguages)
        );
      return this.$router.resolve({ path: '/content/lessoneditor', query: q })
        .route.fullPath;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    deleteDialog(val) {
      val || this.close();
    },
    'editedItem.attributes.url': function (val) {
      if (val.endsWith('.pdf')) this.editedItem.attributes.lessonType = 'pdf';
      else if (val.startsWith('onedrop://'))
        this.editedItem.attributes.lessonType = 'deep-link';
      else this.editedItem.attributes.lessonType = 'lesson';
    },
  },
  mounted() {
    if (this.$route.query.query) {
      this.search = decodeURIComponent(this.$route.query.query).trim();
    }
    if (this.$route.query.languages) {
      this.selectedLanguages = JSON.parse(
        decodeURIComponent(this.$route.query.languages).trim()
      );
    }
    this.refreshLessons();
  },
  methods: {
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'lesson-write']);
    },
    formatDate: function (str) {
      return str ? OneDropUtils.displayDate(str) : str;
    },
    formatLanguage: function (str) {
      if (str in this.languages) return this.languages[str];
      else return str;
    },
    setUrlAttribute(attributeKey, newVal) {
      this.editedItem.attributes[attributeKey] = newVal;
    },
    canSave() {
      let item = this.editedItem ? this.editedItem.attributes : this.editedItem;
      return (
        item &&
        item.lessonName &&
        item.lessonDescription &&
        item.lessonType &&
        item.url &&
        item.number &&
        item.language
      );
    },
    isEasyLmsUrl(url) {
      return url ? url.includes('easy-lms.com') : false;
    },
    refreshLessons: function () {
      ConsoleLog('Fetching lessons');
      this.loading = true;
      OneDropApi.getLessons(
        (response) => {
          this.refreshLessonWrappers();
          this.allLessons = response.data;
          ConsoleLog(`Got ${this.allLessons.length} lessons`);
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    refreshLessonWrappers: function () {
      OneDropApi.getContentForPrefixAllLangs(
        'lesson-',
        (response) => {
          this.lessonWrappers = response.data;
          this.refreshJotformWrappers();
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    refreshJotformWrappers: function () {
      OneDropApi.getContentForPrefixAllLangs(
        'jotform-',
        (response) => {
          this.jotformWrappers = response.data;
          this.refreshCourseWrappers();
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    refreshCourseWrappers: function () {
      OneDropApi.getContentForPrefixAllLangs(
        'course-',
        (response) => {
          this.courseWrappers = response.data;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    resolveUrl: function (item) {
      if (!item || !item.attributes) return item;
      const url = item.attributes.url;
      const language = item.attributes.language;
      const regex = /.*api.onedrop.today.*\/(lesson-.*)/gm;
      const jotformRegex = /.*api.onedrop.today.*\/(jotform-.*)/gm;
      // ConsoleLog(`resolveUrl: url=${url}, language=${language}`)
      if (this.lessonWrappers && this.lessonWrappers.length > 0) {
        if (regex.test(url.trim())) return this.resolveLessonUrl(url, language);
        else if (jotformRegex.test(url.trim()))
          return this.resolveJotformUrl(url, language);
      }
      return url;
    },
    resolveLessonUrl: function (url, language) {
      // ConsoleLog(`resolveLessonUrl: url=${url}, language=${language}`)
      // Find a matching lesson content
      const regex = /.*api.onedrop.today.*\/(lesson-.*)/gm;
      const array = [...url.matchAll(regex)];
      const lessonSlug = array[0][1];
      // Find URL associated with the lessonSlug
      const contentUrl = this.findContent(
        this.lessonWrappers,
        lessonSlug,
        language
      );
      if (contentUrl) {
        const regex = /.*api.onedrop.today.*\/(course-.*)/gm;
        const content = contentUrl.attributes.content;
        if (
          this.courseWrappers &&
          this.courseWrappers.length > 0 &&
          regex.test(content)
        )
          return this.resolveCourseUrl(content, language);
        else return content;
      } else return contentUrl;
    },
    resolveCourseUrl: function (url, language) {
      // ConsoleLog(`resolveCourseUrl: url=${url}, language=${language}`)
      // Find a matching course content
      const regex = /.*api.onedrop.today.*\/(course-.*)/gm;
      const array = [...url.matchAll(regex)];
      const courseSlug = array[0][1];
      // Find URL associated with the courseSlug
      const contentUrl = this.findContent(
        this.courseWrappers,
        courseSlug,
        language
      );
      return contentUrl ? contentUrl.attributes.content : contentUrl;
    },
    resolveJotformUrl: function (url, language) {
      ConsoleLog(`resolveJotformUrl: url=${url}, language=${language}`);
      // Find a matching course content
      const regex = /.*api.onedrop.today.*\/(jotform-.*)/gm;
      const array = [...url.matchAll(regex)];
      const courseSlug = array[0][1];
      // Find URL associated with the courseSlug
      const contentUrl = this.findContent(
        this.jotformWrappers,
        courseSlug,
        language
      );
      return contentUrl ? contentUrl.attributes.content : contentUrl;
    },
    findContent: function (list, code, language) {
      // First find the language specific content
      // if not found, find the generic content
      let content = list.find(
        (r) =>
          r.attributes &&
          r.attributes.code == code &&
          r.attributes.language == language
      );
      if (!content)
        content = list.find(
          (r) =>
            r.attributes &&
            r.attributes.code == code &&
            r.attributes.language == 'default'
        );
      return content;
    },
    onNewItem() {
      let attrs = {
        id: null,
        lessonName: null,
        lessonDescription: null,
        lessonType: 'lesson',
        url: null,
        number: null,
        language: 'en',
        isFree: false,
        predeliver: false,
        iconSlug: null,
        tag: null,
        deepLink: null,
        heroImageUrl: null,
        metaData: null,
      };
      this.editedIndex = -1;
      this.editedItem = { type: 'lesson', attributes: attrs };
      this.dialog = true;
    },
    editItem: function (item) {
      ConsoleLog('editItem', item);
      this.editedIndex = this.allLessons.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      const realUrl = item ? this.resolveUrl(item) : item;
      this.editedItem.attributes.url = realUrl;
      this.editedItem.attributes.metaData = this.editedItem.attributes.metaData
        ? JSON.stringify(this.editedItem.attributes.metaData)
        : this.editedItem.attributes.metaData;
      this.dialog = true;
    },
    deleteItem: function (item) {
      ConsoleLog('deleteItem', item);
      this.editedIndex = this.allLessons.indexOf(item);
      this.editedItem = item;
      this.deleteDialog = true;
    },
    onImport: function () {
      this.importItem = {
        url: null,
        data: null,
        errors: null,
        info: null,
        validated: false,
        message: null,
      };
      this.importDialog = true;
    },
    close() {
      this.editedItem = null;
      this.dialog = false;
      this.deleteDialog = false;
      this.editedIndex = -1;
      this.importDialog = false;
    },
    saveLesson() {
      this.saving = true;
      let item = this.editedIndex > -1 ? this.allLessons[this.editedIndex] : {};
      let index = this.editedIndex;
      ConsoleLog(`Saving item ${this.editedItem}`);
      let attrs = this.editedItem.attributes;
      if (!attrs.number) attrs.number = null;
      else attrs.number = Number(attrs.number);
      if (attrs.metaData && attrs.metaData.length > 0)
        attrs.metaData = JSON.parse(attrs.metaData);
      else attrs.metaData = null;
      if (!attrs.heroImageUrl) attrs.heroImageUrl = null;
      if (!attrs.deepLink) attrs.deepLink = null;
      if (!attrs.iconSlug) attrs.iconSlug = null;
      if (!attrs.tag) attrs.tag = null;
      if (index == -1) {
        // Make sure the lesson is marked as active
        attrs.active = true;
      }
      let body = {
        data: [this.editedItem],
      };
      ConsoleLog(`Saving item ${index}`, JSON.stringify(body));
      if (this.editedIndex > -1) {
        OneDropApi.patch(
          'v3/admin/lesson',
          body,
          () => {
            this.saving = false;
            this.toast(`Item ${item.id} saved successfully`);
            this.refreshLessons();
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.saving = false;
            this.onApiError(error);
          }
        );
      } else {
        OneDropApi.post(
          'v3/admin/lesson',
          body,
          (response) => {
            const nItem = response.data[0];
            this.saving = false;
            this.refreshLessons();
            this.toast(`Item ${nItem.id} added successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.saving = false;
            this.onApiError(error);
          }
        );
      }
    },
    saveItem(editedIndex, item, itemToCopy, response) {
      ConsoleLog(`Save response ${editedIndex}`, response, item, itemToCopy);
      item.attributes.lessonName = itemToCopy.lessonName;
      item.attributes.lessonDescription = itemToCopy.lessonDescription;
      item.attributes.lessonType = itemToCopy.lessonType;
      item.attributes.url = itemToCopy.url;
      item.attributes.number = itemToCopy.number;
      item.attributes.language = itemToCopy.language;
      item.attributes.isFree = itemToCopy.isFree;
      item.attributes.predeliver = itemToCopy.predeliver;
    },
    deleteLesson() {
      this.saving = true;
      let item = this.allLessons[this.editedIndex];
      let index = this.editedIndex;
      ConsoleLog('Delete Lesson', item.id);
      OneDropApi.del(
        `v3/admin/lesson/${item.id}`,
        null,
        () => {
          this.allLessons.splice(index, 1);
          this.saving = false;
          this.toast(`Lesson ${item.id} deleted successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.saving = false;
          this.onApiError(error);
        }
      );
    },
    doImport(validateOnly) {
      this.importInProgress = true;
      this.importItem.data = null;
      this.importItem.errors = null;
      this.importItem.info = null;
      this.importItem.validated = false;
      this.importItem.message = null;
      this.activeTab = 'data';
      const url = encodeURIComponent(this.importItem.url);
      OneDropApi.patch(
        `v3/admin/resource/lesson/import?validate=${validateOnly}&url=${url}`,
        null,
        (response) => {
          this.importItem.data = response.data;
          let hasErrors = false;
          if (response.errors && response.errors.length > 0) {
            this.importItem.errors = response.errors.filter(
              (f) => f.code !== 'import-info'
            );
            this.importItem.info = response.errors.filter(
              (f) => f.code === 'import-info'
            );
            hasErrors = true;
          }
          const dl = [
            this.importItem.data ? this.importItem.data.length : 0,
            this.importItem.errors ? this.importItem.errors.length : 0,
            this.importItem.info ? this.importItem.info.length : 0,
          ];
          if (validateOnly) {
            this.importItem.message = `Validation Complete:  ${dl[0]} accepted, ${dl[1]} errors, ${dl[2]} no action`;
          } else if (dl[1] > 0) {
            this.importItem.message = `Import Failed: ${dl[0]} accepted, ${dl[1]} errors, ${dl[2]} no action`;
          } else {
            this.importItem.message = `Import Complete: ${dl[0]} imported, ${dl[1]} errors, ${dl[2]} no action`;
          }
          if (!validateOnly && dl[0] > 0 && dl[1] == 0) {
            this.refreshLessons();
          } else if (validateOnly && !hasErrors) {
            this.importItem.validated = true;
          }
          this.importInProgress = false;
        },
        (error) => {
          ConsoleLog(`errors=${JSON.stringify(error)}`);
          if (error.response && error.response.data) {
            const data = error.response.data;
            if (data.errors) {
              ConsoleLog(`${JSON.stringify(data.errors)}`);
              if (data.errors[0].code === 'import-error')
                this.importItem.errors = data.errors;
              else this.onApiError(JSON.stringify(data.errors));
            } else this.onApiError(error);
          } else this.onApiError(error);
          this.importInProgress = false;
        }
      );
    },
    followLink(link) {
      let actLink = `${link}`;
      if (link.startsWith('https://api.onedrop.today')) {
        const token = store.state.jwtToken;
        if (link.indexOf('?') < 0) actLink = actLink + `?X-Auth-Token=${token}`;
        else actLink = actLink + `&X-Auth-Token=${token}`;
      }
      ConsoleLog(`Redirecting ${link} to ${actLink}`);
      window.open(actLink, '_blank');
    },
    searchUrl() {
      let path = this.currentUrl;
      const protocol = window.location.protocol;
      const host = window.location.host;
      return `${protocol}//${host}/#${path}`;
    },
    onCopySearchClicked: function () {
      const copiedText = this.copyToClipboard('searchUrl');
      this.toast('Copied: ' + copiedText);
    },
  },
};
</script>

<style></style>
