<!--Display and edit the tracks for the given itemType and itemContent -->
<template>
  <v-container fluid>
    <v-card :loading="loading" flat>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="tracks"
        :search="search"
        item-key="id + attributes.scheduleVersionId"
        dense
        group-by="attributes.title"
        items-per-page="-1"
        hide-actions
      >
        <template #group.header="{ group, headers, toggle, isOpen }">
          <td :colspan="headers.length">
            <v-btn :ref="group" small icon :data-open="isOpen" @click="toggle">
              <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
              <v-icon v-else>mdi-chevron-down</v-icon>
            </v-btn>
            {{ group }}
          </td>
        </template>
        <template #top>
          <v-row dense align="center">
            <v-col cols="4">
              <v-text-field
                ref="searchText"
                v-model="search"
                label="Search"
                outlined
                hide-details
              />
            </v-col>
            <v-col cols="1" />
            <v-col cols="2">
              <v-select
                v-model="selectedLanguages"
                :items="Object.entries(languages)"
                label="Language"
                item-text="[1]"
                item-value="[0]"
                hide-selected
                chips
                deletable-chips
                multiple
              />
            </v-col>
            <v-col>
              <v-switch v-model="activeOnly" label="Active Only" class="pa-3" />
            </v-col>
            <v-col cols="3">
              <v-card-text>
                <v-btn color="primary" class="mr-2" @click="closeAll()"
                  >Close All</v-btn
                >
                <v-btn color="primary" @click="openAll()">Open All</v-btn>
              </v-card-text>
            </v-col>
          </v-row>
        </template>
        <template #item.id="{ item }">
          <router-link
            :to="{ path: '/content/trackeditor', query: { query: item.id } }"
          >
            {{ item.id }}
          </router-link>
        </template>
        <template #item.attributes.hidden="{ item }">
          <v-switch v-model="item.attributes.hidden" readonly />
        </template>
        <template #item.attributes.language="{ item }">
          <span>{{ formatLanguage(item.attributes.language) }}</span>
        </template>
        <template #item.attributes.createdAt="{ item }">
          <span>{{ formatDate(item.attributes.createdAt) }}</span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { OneDropApi } from '../../onedrop-api';
import { ConsoleLog, OneDropUtils } from '../../onedrop-utils';
export default {
  name: 'TrackList',
  props: ['itemType', 'itemContent'],
  data: function () {
    return {
      tracks: [],
      loading: false,
      activeOnly: true,
      search: null,
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Title',
          value: 'attributes.title',
        },
        {
          text: 'Version',
          value: 'attributes.scheduleVersion',
        },
        {
          text: 'Partner ID',
          value: 'attributes.partner',
        },
        {
          text: 'Partner Name',
          value: 'attributes.partnerName',
        },
        {
          text: 'Hidden',
          value: 'attributes.hidden',
          filter: (value, search, item) =>
            this.activeOnly == false || item.attributes.hidden != true,
        },
        {
          text: 'Language',
          value: 'attributes.language',
          filter: (value, search, item) =>
            this.selectedLanguages.length == 0 ||
            this.selectedLanguages.indexOf(item.attributes.language) > -1,
        },
        {
          text: 'Created At',
          value: 'attributes.createdAt',
        },
      ],
      languages: {
        en: 'English',
        ar: 'Arabic',
        it: 'Italian',
        es: 'Spanish',
        ru: 'Russian',
        de: 'German',
        fr: 'French',
        pt: 'Portuguese',
        zh: 'Chinese',
      },
      selectedLanguages: [],
    };
  },
  watch: {
    itemType: function () {
      this.refreshTracks();
    },
    itemContent: function () {
      this.refreshTracks();
    },
  },
  updated: function () {
    this.$nextTick(() => {
      this.closeAll();
    });
  },
  created() {
    this.refreshTracks();
  },
  methods: {
    getPartnerById: function (id) {
      return this.$store.getters.partnerById(id);
    },
    getPartnerName: function (id) {
      if (id) {
        const p = this.getPartnerById(id);
        return p ? p.attributes.name : null;
      }
      return null;
    },
    formatDate: function (str) {
      return str ? OneDropUtils.displayDate(str) : str;
    },
    formatLanguage: function (str) {
      if (str in this.languages) return this.languages[str];
      else return str;
    },
    refreshTracks: function () {
      this.loading = true;
      this.tracks = [];
      OneDropApi.get(
        `v3/admin/resource/tracks-by-item-type?type=${this.itemType}&content=${this.itemContent}`,
        (response) => {
          this.tracks = response.data.map((d) => {
            if (d && d.attributes.partner) {
              const name = this.getPartnerName(d.attributes.partner);
              if (name) d.attributes.partnerName = name;
            }
            return d;
          });
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    closeAll() {
      Object.keys(this.$refs).forEach((k) => {
        if (this.$refs[k]?.$attrs['data-open']) {
          this.$refs[k].$el.click();
        }
      });
    },
    openAll() {
      Object.keys(this.$refs).forEach((k) => {
        if (!this.$refs[k]?.$attrs['data-open']) {
          this.$refs[k].$el.click();
        }
      });
    },
  },
};
</script>
<style scoped></style>
