<template>
  <v-container fluid>
    <v-layout class="display-1 py-4"> Manage News Feeds </v-layout>
    <div class="text-center">
      <v-progress-circular v-if="saving" indeterminate color="primary" />
    </div>
    <v-data-table
      :items="allNews"
      :headers="headers"
      :search="search"
      :loading="loading"
      :expanded.sync="expanded"
      show-expand
      @click:row="rowClicked"
    >
      <template #top>
        <v-dialog v-model="dialog" max-width="50%">
          <template #activator="{ on }">
            <v-toolbar flat color="white">
              <v-text-field
                v-model="search"
                label="Search"
                hide-details
                outlined
              />
              <v-spacer />
              <v-switch v-model="activeOnly" label="Active Only" class="pa-3" />
              <v-spacer />
              <v-btn
                color="primary"
                dark
                :loading="loading"
                :disabled="loading"
                class="mb-2 mr-2"
                @click="refreshNewsMessages"
              >
                Reload
              </v-btn>
              <v-btn
                v-if="hasWritePerm()"
                color="primary"
                :disabled="loading"
                dark
                class="mb-2"
                v-on="on"
              >
                New Item
              </v-btn>
            </v-toolbar>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-row label="Object ID">
                <v-col>Object ID: {{ editedItem.id }}</v-col>
                <v-spacer />
                <v-switch v-model="editedItem.active" label="Active" />
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="editedItem.title"
                    outlined
                    label="Title"
                  />
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="editedItem.targetedUserType"
                    :items="['PAID', 'FREE', 'BOTH']"
                    label="Target User Type"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
              <v-textarea
                v-model="editedItem.message"
                outlined
                label="Message"
                auto-grow
                rows="2"
                row-height="24px"
              />
              <v-container>
                <v-row>
                  <v-menu
                    ref="dateMenu"
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="290px"
                    max-width="290px"
                    offset-y
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="editedItem.displayDate"
                        label="Display Date"
                        prepend-inner-icon="mdi-calendar"
                        hint="YYYY-MM-DD"
                        persistent-hint
                        outlined
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="editedItem.displayDate"
                      no-title
                      @input="dateMenu = false"
                    />
                  </v-menu>
                  <v-spacer />
                  <v-menu
                    ref="timeMenu"
                    v-model="timeMenu"
                    :close-on-content-click="false"
                    :return-value.sync="editedItem.displayTime"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="editedItem.displayTime"
                        label="Display Time"
                        prepend-inner-icon="mdi-clock"
                        outlined
                        hint="Local Time"
                        persistent-hint
                        v-on="on"
                      />
                    </template>
                    <v-time-picker
                      v-if="timeMenu"
                      v-model="editedItem.displayTime"
                      full-width
                      no-title
                      @click:minute="
                        $refs.timeMenu.save(editedItem.displayTime)
                      "
                    />
                  </v-menu>
                </v-row>
              </v-container>
              <v-text-field
                v-model="editedItem.url"
                outlined
                label="URL"
                type="url"
              />
              <v-text-field
                v-model="editedItem.imageUrl"
                outlined
                label="Image URL"
                type="url"
                append-outer-icon="mdi-paperclip"
                @click:append-outer="editImageDialog = true"
              />
              <amazon-s3-uploader
                v-model="editImageDialog"
                :edit-item="editedItem"
                image-field="imageUrl"
                @setUrlAttribute="setUrlAttribute"
              />
              <v-autocomplete
                v-model="editedItem.whiteListPartners"
                :items="partnerList"
                label="Partner White List"
                outlined
                :item-text="partnerName"
                item-value="id"
                hide-selected
                chips
                deletable-chips
                multiple
              />
              <v-autocomplete
                v-model="editedItem.blackListPartners"
                :items="partnerList"
                label="Partner Black List"
                outlined
                :item-text="partnerName"
                item-value="id"
                hide-selected
                chips
                deletable-chips
                multiple
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Delete Item</span>
            </v-card-title>

            <v-card-text>
              <v-row label="Object ID">
                <v-col>Object ID: {{ editedItem.id }}</v-col>
              </v-row>
              <v-text-field
                v-model="editedItem.title"
                outlined
                readonly
                label="Brand Name"
              />
              <v-text-field
                v-model="editedItem.message"
                outlined
                readonly
                label="Chemical Name"
              />
              <v-container>
                <v-row>
                  <v-text-field
                    v-model="editedItem.displayDate"
                    hint="YYYY-MM-DD"
                    persistent-hint
                    outlined
                    readonly
                    label="Display Date"
                  />
                  <v-spacer />
                  <v-text-field
                    v-model="editedItem.displayTime"
                    hint="UTC 24 hr"
                    persistent-hint
                    outlined
                    readonly
                    label="Display Time"
                  />
                </v-row>
              </v-container>
              <v-text-field
                v-model="editedItem.url"
                outlined
                readonly
                label="URL"
                type="url"
              />
              <v-text-field
                v-model="editedItem.imageUrl"
                outlined
                readonly
                label="Image URL"
                type="url"
              />
              <v-container>
                <v-row justify="space-around">
                  <v-switch
                    v-model="editedItem.active"
                    readonly
                    label="Active"
                  />
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="deleteNewsMessage">
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template #header.action="{ header }">
        <span v-if="hasWritePerm()">{{ header.text }}</span>
      </template>
      <template #item.displayDate="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <div v-on="on">
              {{ formatDate(item.displayDate) }}
            </div>
          </template>
          <span>{{ item.displayDate }}</span>
        </v-tooltip>
      </template>
      <template #item.url="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <a :href="item.url" target="_blank" v-on="on">
              <span
                class="d-inline-block text-truncate"
                style="max-width: 300px"
              >
                {{ item.url }}</span
              ></a
            >
          </template>
          <span>{{ item.url }}</span>
        </v-tooltip>
      </template>
      <template #item.active="{ item }">
        <v-switch v-model="item.active" readonly />
      </template>
      <template #item.action="{ item }">
        <v-icon v-if="hasWritePerm()" class="mr-2" @click.stop="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon v-if="hasWritePerm()" @click.stop="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-row>
            <v-col cols="6">
              <v-card fill elevation="0" tile>
                <v-card-text>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Message</v-list-item-title>
                      <p class="text--secondary">
                        {{ item.message }}
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="item.whiteListPartners" dense>
                    <v-list-item-content>
                      <v-list-item-title class="mb-1">
                        Partner Whitelist
                      </v-list-item-title>
                      <p v-for="(p, i) in item.whiteListPartners" :key="i">
                        {{ getPartnerNameById(p) }}
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="item.blackListPartners" dense>
                    <v-list-item-content>
                      <v-list-item-title class="mb-1">
                        Partner Blacklist
                      </v-list-item-title>
                      <p v-for="(p, i) in item.blackListPartners" :key="i">
                        {{ getPartnerNameById(p) }}
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card fill elevation="0">
                <v-card-text>
                  <v-list-item v-if="item.url">
                    <v-list-item-content>
                      <v-list-item-title class="mb-1"> URL </v-list-item-title>
                      <p class="text--secondary">
                        <a :title="item.url" :href="item.url" target="_blank">{{
                          item.url
                        }}</a>
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="item.imageUrl">
                    <v-list-item-content>
                      <v-list-item-title class="mb-1">
                        Image URL
                      </v-list-item-title>
                      <p class="text--secondary">
                        {{ item.imageUrl }}
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="item.imageUrl">
                    <v-img
                      :src="item.imageUrl"
                      :title="item.imageUrl"
                      max-height="100"
                      max-width="200"
                      contain
                    />
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </td>
      </template>
      <template #no-data>
        <v-btn color="primary" @click="refreshNewsMessages"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { ConsoleLog, OneDropUtils } from '../onedrop-utils';
import { format, parse } from 'date-fns';
import AmazonS3Uploader from '@/components/AmazonS3Uploader';

export default {
  name: 'NewsMessageEditor',
  components: { AmazonS3Uploader },
  data: function () {
    return {
      dialog: false,
      deleteDialog: false,
      editImageDialog: false,
      activeOnly: false,
      loading: false,
      saving: false,
      search: '',
      dateMenu: false,
      timeMenu: false,
      dateFormat: 'yyyy-MM-dd',
      timeFormat: 'HH:mm',
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Title',
          value: 'title',
          width: '25%',
        },
        {
          text: 'Display Date',
          value: 'displayDate',
        },
        {
          text: 'URL',
          value: 'url',
        },
        {
          text: 'Active',
          value: 'active',
          filter: (value, search, item) =>
            this.activeOnly == false || item.active,
        },
        {
          text: 'Target User',
          value: 'targetedUserType',
        },
        { text: 'Actions', value: 'action', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      allNews: [],
      editedIndex: -1,
      editedItem: {
        id: '',
        title: '',
        message: '',
        displayDate: '',
        displayTime: '',
        url: '',
        imageUrl: '',
        active: false,
        whiteListPartners: [],
        blackListPartners: [],
        targetedUserType: '',
      },
      defaultItem: {
        id: '',
        title: '',
        message: '',
        displayDate: '',
        displayTime: '',
        url: '',
        imageUrl: '',
        active: false,
        whiteListPartners: [],
        blackListPartners: [],
        targetedUserType: '',
      },
      expanded: [],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex == -1 ? 'New Item' : `Edit Item`;
    },
    partnerList() {
      return this.$store.getters.partnerList;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    deleteDialog(val) {
      val || this.close();
    },
  },
  created() {
    this.refreshNewsMessages();
  },
  methods: {
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'news-message-write']);
    },
    formatDate: function (str) {
      return str ? OneDropUtils.displayDate(str) : str;
    },
    setUrlAttribute(attributeKey, newVal) {
      this.editedItem[attributeKey] = newVal;
    },
    refreshNewsMessages: function () {
      ConsoleLog('Fetching news messages');
      this.loading = true;
      OneDropApi.getAllResources(
        'news-message',
        (response) => {
          this.allNews = response.data.map((r) => {
            let attrs = r.attributes;
            attrs.id = r.id;
            return attrs;
          });
          ConsoleLog(`Got ${this.allNews.length} news messages`);
          ConsoleLog(this.allNews);
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    itemToEdited: function (item) {
      return {
        id: item.id,
        title: item.title,
        message: item.message,
        displayDate: item.displayDate
          ? format(new Date(item.displayDate), this.dateFormat)
          : '',
        displayTime: item.displayDate
          ? format(new Date(item.displayDate), this.timeFormat)
          : '',
        url: item.url,
        imageUrl: item.imageUrl,
        active: item.active,
        whiteListPartners: item.whiteListPartners,
        blackListPartners: item.blackListPartners,
        targetedUserType: item.targetedUserType,
      };
    },
    editedToItem: function () {
      let item = {};
      item.title = this.editedItem.title;
      item.message = this.editedItem.message;
      item.url = this.editedItem.url;
      const d = parse(this.editedItem.displayDate, this.dateFormat, new Date());
      const t = parse(this.editedItem.displayTime, this.timeFormat, d);
      ConsoleLog('Time is ', t, ', ISO Time is', t.toISOString());
      item.displayDate = t.toISOString();
      item.imageUrl = this.editedItem.imageUrl;
      item.active = this.editedItem.active;
      item.whiteListPartners = this.checkForNull(
        this.editedItem.whiteListPartners
      );
      item.blackListPartners = this.checkForNull(
        this.editedItem.blackListPartners
      );
      item.targetedUserType = this.editedItem.targetedUserType;
      return item;
    },
    checkForNull: function (alist) {
      return alist && alist.length ? alist : null;
    },
    partnerName: (item) => `${item.name} (${item.id})`,
    getPartnerNameById(id) {
      let p = this.$store.getters.partnerById(id);
      if (p) return `${p.attributes.name} (${id})`;
      else return p;
    },
    getParsePartnerList: function (plist) {
      if (plist) {
        const list = plist.map((id) => {
          return this.$store.getters.partnerById(id);
        });
        return list;
      } else return plist;
    },
    renderPartnerList: function (list) {
      const plist = this.getParsePartnerList(list);
      return plist ? plist.map((p) => p.attributes.name).toString() : plist;
    },
    arrayToParsePartnerJson: function (list) {
      return list ? JSON.stringify(list) : list;
    },
    editItem: function (item) {
      ConsoleLog('editItem', item);
      this.editedIndex = this.allNews.indexOf(item);
      this.editedItem = this.itemToEdited(item);
      ConsoleLog('editedItem', this.editedItem);
      this.dialog = true;
    },
    deleteItem: function (item) {
      ConsoleLog('deleteItem', item);
      this.editedIndex = this.allNews.indexOf(item);
      this.editedItem = this.itemToEdited(item);
      this.deleteDialog = true;
    },
    close() {
      this.editedItem = this.defaultItem;
      this.dialog = false;
      this.deleteDialog = false;
      this.editedIndex = -1;
    },
    save() {
      // this.saving = true
      let item = this.editedIndex > -1 ? this.allNews[this.editedIndex] : {};
      let index = this.editedIndex;
      ConsoleLog('Saving item', this.editedItem);
      let id = index > -1 ? this.editedItem.id : null;
      let itemToCopy = Object.assign({}, this.editedToItem());
      let attrs = Object.assign({}, itemToCopy);
      delete attrs.id;
      let body = {
        data: [
          {
            id: id,
            type: 'news-message',
            attributes: attrs,
          },
        ],
      };
      ConsoleLog(`Saving item ${index}`, JSON.stringify(body));
      if (this.editedIndex > -1) {
        OneDropApi.patchResource(
          'news-message',
          id,
          body,
          (response) => {
            this.saveItem(index, item, itemToCopy, response);
            this.saving = false;
            this.toast(`Item ${id} saved successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.saving = false;
            this.onApiError(error);
          }
        );
      } else {
        OneDropApi.postResource(
          'news-message',
          body,
          (response) => {
            itemToCopy.id = response.data[0].id;
            this.saveItem(index, item, itemToCopy, response);
            this.saving = false;
            this.toast(`Item ${itemToCopy.id} added successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.saving = false;
            this.onApiError(error);
          }
        );
      }
    },
    saveItem(editedIndex, item, itemToCopy, response) {
      ConsoleLog(`Save response ${editedIndex}, ${JSON.stringify(response)}`);
      if (response?.data[0]) {
        let r = response.data[0];
        let attrs = r.attributes;
        item.title = attrs.title;
        item.message = attrs.message;
        item.url = attrs.url;
        item.displayDate = attrs.displayDate;
        item.imageUrl = attrs.imageUrl;
        item.active = itemToCopy.active;
        item.whiteListPartners = attrs.whiteListPartners
          ? JSON.parse(attrs.whiteListPartners)
          : attrs.whiteListPartners;
        item.blackListPartners = attrs.blackListPartners
          ? JSON.parse(attrs.blackListPartners)
          : attrs.blackListPartners;
        item.targetedUserType = attrs.targetedUserType;
        if (editedIndex == -1) {
          item.id = r.id;
          this.allNews.push(item);
        }
      }
    },
    deleteNewsMessage() {
      this.saving = true;
      let item = this.allNews[this.editedIndex];
      let index = this.editedIndex;
      ConsoleLog('Delete Item', item.id);
      let body = {
        data: [
          {
            id: item.id,
            type: 'news-message',
            attributes: {},
          },
        ],
      };
      OneDropApi.deleteResource(
        'news-message',
        item.id,
        body,
        () => {
          this.allNews.splice(index, 1);
          this.saving = false;
          this.toast(`Item ${item.id} deleted successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.saving = false;
          this.onApiError(error);
        }
      );
    },
    rowClicked(item, options) {
      options.expand(!options.isExpanded);
    },
  },
};
</script>

<style></style>
