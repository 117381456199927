<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <v-data-table
    :loading="loading"
    :headers="headers"
    :items.sync="items"
    :search="search"
    dense
  >
    <!-- eslint-enable -->
    <template #top>
      <v-toolbar flat>
        <v-card-title>Value Fields</v-card-title>
        <v-spacer />
        <template v-if="editMode">
          <v-btn
            color="primary"
            icon
            :loading="loading"
            class="mb-2"
            @click="onNewItem()"
          >
            <v-icon title="Edit"> mdi-plus </v-icon>
          </v-btn>
        </template>
      </v-toolbar>
      <v-dialog v-model="editItemDialog" width="50%">
        <v-card>
          <v-card-title>
            <span class="headline">{{ title }}</span>
          </v-card-title>

          <v-card-text v-if="editItem">
            <v-text-field v-model="editItem.field" label="Field" outlined />
            <v-text-field v-model="editItem.unit" label="Unit" outlined />
            <v-text-field
              v-model="editItem.displayStyle"
              label="displayStyle"
              outlined
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="onEditCancel">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="onEditSave"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template #item.action="{ item }">
      <v-icon class="mr-2" @click.stop="onEditItem(item)"> mdi-pencil </v-icon>
      <v-icon @click.stop="onDeleteItem(item)"> mdi-delete </v-icon>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'UserQueryValues',
  props: ['items', 'editMode'],
  data: function () {
    return {
      loading: false,
      viewHeaders: [
        {
          text: 'field',
          value: 'field',
        },
        {
          text: 'Unit',
          value: 'unit',
        },
        {
          text: 'Display Style',
          value: 'displayStyle',
        },
      ],
      editHeaders: [
        {
          text: 'field',
          value: 'field',
        },
        {
          text: 'Unit',
          value: 'unit',
        },
        {
          text: 'Display Style',
          value: 'displayStyle',
        },
        { text: 'Actions', value: 'action', sortable: false },
      ],
      search: null,
      editItemDialog: false,
      editItem: null,
      editItemIndex: -1,
    };
  },
  computed: {
    headers() {
      return this.editMode ? this.editHeaders : this.viewHeaders;
    },
    title() {
      return this.editItemIndex == -1 ? 'New Field' : 'Edit Field';
    },
  },
  methods: {
    onEditItem(item) {
      this.editItem = item;
      this.editItemDialog = true;
      this.editItemIndex = this.items.indexOf(item);
    },
    onDeleteItem(item) {
      let index = this.items.indexOf(item);
      // eslint-disable-next-line
      if (index > -1) this.items.splice(index, 1);
    },
    onNewItem() {
      this.editItemIndex = -1;
      this.editItem = {
        field: null,
        unit: null,
        displayStyle: null,
      };
      this.editItemDialog = true;
    },
    onEditCancel() {
      this.editItemDialog = false;
    },
    onEditSave() {
      // eslint-disable-next-line
      if (this.editItemIndex == -1) this.items.push(this.editItem);
      this.editItemDialog = false;
    },
  },
};
</script>

<style scoped></style>
