<template>
  <v-container fluid>
    <v-layout class="display-1 py-4"> Manage Meds </v-layout>
    <div class="text-center">
      <v-progress-circular v-if="saving" indeterminate color="primary" />
    </div>
    <v-data-table
      :items="allMeds"
      :headers="headers"
      :search="search"
      :loading="loading"
    >
      <template #top>
        <v-dialog v-model="dialog" max-width="600px">
          <template #activator="{ on }">
            <v-toolbar flat color="white">
              <v-text-field
                v-model="search"
                label="Search"
                hide-details
                outlined
              />
              <v-spacer />
              <v-btn
                color="primary"
                dark
                :loading="loading"
                :disabled="loading"
                class="mb-2 mr-2"
                @click="refreshMeds"
              >
                Reload
              </v-btn>
              <v-btn
                v-if="hasWritePerm()"
                color="primary"
                :disabled="loading"
                dark
                class="mb-2"
                v-on="on"
              >
                New Item
              </v-btn>
            </v-toolbar>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-row label="Object ID">
                <v-col>Object ID: {{ editedItem.id }}</v-col>
              </v-row>
              <v-text-field
                v-model="editedItem.brandName"
                outlined
                label="Brand Name"
              />
              <v-text-field
                v-model="editedItem.chemicalName"
                outlined
                label="Chemical Name"
              />
              <v-combobox
                v-model="editedItem.unit"
                :items="unitItems"
                chips
                deletable-chips
                multiple
                outlined
                label="Unit"
              />
              <v-container>
                <v-row justify="space-around">
                  <v-switch
                    v-model="editedItem.isDiabetesMed"
                    label="Is Diabetes Med"
                  />
                  <v-switch v-model="editedItem.isBasalMed" label="Basal" />
                  <v-switch v-model="editedItem.isBolusMed" label="Bolus" />
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Delete Item</span>
            </v-card-title>

            <v-card-text>
              <v-row label="Object ID">
                <v-col>Object ID: {{ editedItem.id }}</v-col>
              </v-row>
              <v-text-field
                v-model="editedItem.brandName"
                outlined
                readonly
                label="Brand Name"
              />
              <v-text-field
                v-model="editedItem.chemicalName"
                outlined
                readonly
                label="Chemical Name"
              />
              <v-combobox
                v-model="editedItem.unit"
                :items="unitItems"
                chips
                readonly
                multiple
                outlined
                label="Unit"
              />
              <v-container>
                <v-row justify="space-around">
                  <v-switch
                    v-model="editedItem.isDiabetesMed"
                    readonly
                    label="Is Diabetes Med"
                  />
                  <v-switch
                    v-model="editedItem.isBasalMed"
                    readonly
                    label="Basal"
                  />
                  <v-switch
                    v-model="editedItem.isBolusMed"
                    readonly
                    label="Bolus"
                  />
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="deleteMed">
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template #header.action="{ header }">
        <span v-if="hasWritePerm()">{{ header.text }}</span>
      </template>
      <template #item.chemicalName="{ item }">
        <div>{{ item.chemicalName }}</div>
      </template>
      <template #item.isDiabetesMed="{ item }">
        <v-switch v-model="item.isDiabetesMed" readonly />
      </template>
      <template #item.isBasal="{ item }">
        <v-switch v-model="item.isBasal" true-value="1" readonly />
      </template>
      <template #item.isBolus="{ item }">
        <v-switch v-model="item.isBolus" true-value="1" readonly />
      </template>
      <template #item.action="{ item }">
        <v-icon v-if="hasWritePerm()" class="mr-2" @click.stop="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon v-if="hasWritePerm()" @click.stop="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template #no-data>
        <v-btn color="primary" @click="refreshMeds"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { ConsoleLog } from '../onedrop-utils';

export default {
  name: 'Meds',
  components: {},
  data: function () {
    return {
      dialog: false,
      deleteDialog: false,
      loading: false,
      saving: false,
      search: '',
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Brand Name',
          value: 'brandName',
        },
        {
          text: 'Chemical Name',
          value: 'chemicalName',
          width: 450,
        },
        {
          text: 'Unit',
          value: 'unit',
          filterable: false,
          sortable: false,
        },
        {
          text: 'Diabetes Med',
          value: 'isDiabetesMed',
          filterable: true,
          sortable: false,
        },
        {
          text: 'Basal',
          value: 'isBasal',
          filterable: true,
          sortable: false,
        },
        {
          text: 'Bolus',
          value: 'isBolus',
          filterable: true,
          sortable: false,
        },
        { text: 'Actions', value: 'action', sortable: false },
      ],
      allMeds: [],
      editedIndex: -1,
      editedItem: {
        id: '',
        brandName: '',
        chemicalName: '',
        isDiabetesMed: false,
        isBasalMed: false,
        isBolusMed: false,
        unit: [],
      },
      defaultItem: {
        id: '',
        brandName: '',
        chemicalName: '',
        isDiabetesMed: false,
        isBasalMed: false,
        isBolusMed: false,
        unit: ['pill'],
      },
      unitItems: ['pill', 'unit', 'mcg', 'ml', 'mg'],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex == -1 ? 'New Item' : `Edit Item`;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    deleteDialog(val) {
      val || this.close();
    },
  },
  created() {
    this.refreshMeds();
  },
  methods: {
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'med-write']);
    },
    refreshMeds: function () {
      ConsoleLog('Fetching meds');
      this.loading = true;
      OneDropApi.getAllResources(
        'med',
        (response) => {
          this.allMeds = response.data.map((r) => {
            let attrs = r.attributes;
            attrs.id = r.id;
            return attrs;
          });
          ConsoleLog(`Got ${this.allMeds.length} meds`);
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    created: function () {
      this.refreshMeds();
    },
    mounted: function () {
      this.refreshMeds();
    },
    itemToEdited: function (item) {
      return {
        id: item.id,
        brandName: item.brandName,
        chemicalName: item.chemicalName,
        isDiabetesMed: item.isDiabetesMed,
        isBasalMed: item.isBasal == 1,
        isBolusMed: item.isBolus == 1,
        unit: item.unit ? JSON.parse(item.unit) : [],
      };
    },
    editedToItem: function () {
      let item = {};
      item.brandName = this.editedItem.brandName;
      item.chemicalName = this.editedItem.chemicalName;
      item.isDiabetesMed = this.editedItem.isDiabetesMed;
      item.isBasal = this.editedItem.isBasalMed ? 1 : 0;
      item.isBolus = this.editedItem.isBolusMed ? 1 : 0;
      if (this.editedItem.unit.length)
        item.unit = JSON.stringify(this.editedItem.unit);
      else item.unit = null;
      return item;
    },
    editItem: function (item) {
      ConsoleLog('editItem', item);
      this.editedIndex = this.allMeds.indexOf(item);
      this.editedItem = this.itemToEdited(item);
      this.dialog = true;
    },
    deleteItem: function (item) {
      ConsoleLog('deleteItem', item);
      this.editedIndex = this.allMeds.indexOf(item);
      this.editedItem = this.itemToEdited(item);
      this.deleteDialog = true;
    },
    close() {
      this.editedItem = this.defaultItem;
      this.dialog = false;
      this.deleteDialog = false;
      this.editedIndex = -1;
    },
    save() {
      this.saving = true;
      let item = this.editedIndex > -1 ? this.allMeds[this.editedIndex] : {};
      let index = this.editedIndex;
      ConsoleLog('Saving item', this.editedItem);
      let id = index > -1 ? this.editedItem.id : null;
      let itemToCopy = Object.assign({}, this.editedToItem());
      let attrs = Object.assign({}, itemToCopy);
      delete attrs.id;
      let body = {
        data: [
          {
            id: id,
            type: 'med',
            attributes: attrs,
          },
        ],
      };
      ConsoleLog(`Saving item ${index}`, JSON.stringify(body));
      if (this.editedIndex > -1) {
        OneDropApi.patchResource(
          'med',
          id,
          body,
          (response) => {
            this.saveItem(index, item, itemToCopy, response);
            this.saving = false;
          },
          (error) => {
            ConsoleLog(error);
            this.saving = false;
            this.onApiError(error);
          }
        );
      } else {
        OneDropApi.postResource(
          'med',
          body,
          (response) => {
            itemToCopy.id = response.data[0].id;
            this.saveItem(index, item, itemToCopy, response);
            this.saving = false;
            this.toast(`Item ${itemToCopy.id} added successfully`);
          },
          (error) => {
            ConsoleLog(error);
            this.saving = false;
            this.onApiError(error);
          }
        );
      }
      this.close();
    },
    saveItem(editedIndex, item, itemToCopy, response) {
      ConsoleLog(`Save response ${editedIndex}`, response, item, itemToCopy);
      item.brandName = itemToCopy.brandName;
      item.chemicalName = itemToCopy.chemicalName;
      item.isDiabetesMed = itemToCopy.isDiabetesMed;
      item.isBasal = itemToCopy.isBasal;
      item.isBolus = itemToCopy.isBolus;
      if (itemToCopy.unit && itemToCopy.unit.length)
        item.unit = itemToCopy.unit;
      else delete item.unit;
      if (editedIndex == -1) {
        item.id = itemToCopy.id;
        this.allMeds.push(item);
      }
    },
    deleteMed() {
      this.saving = true;
      let item = this.allMeds[this.editedIndex];
      let index = this.editedIndex;
      ConsoleLog('Delete Item', item.id);
      this.close();
      let body = {
        data: [
          {
            id: item.id,
            type: 'med',
            attributes: {},
          },
        ],
      };
      OneDropApi.deleteResource(
        'med',
        item.id,
        body,
        () => {
          this.allMeds.splice(index, 1);
          this.saving = false;
          this.toast(`Item ${item.id} deleted successfully`);
        },
        (error) => {
          ConsoleLog(error);
          this.saving = false;
          this.onApiError(error);
        }
      );
    },
  },
};
</script>

<style></style>
