<template>
  <v-card flat>
    <v-data-table
      :items="data"
      :headers="headers"
      :search="search"
      :loading="loading"
      :expanded.sync="expanded"
      :group-by="groupBy"
      show-expand
      single-expand
    >
      <template #top>
        <v-toolbar flat color="white">
          <v-text-field v-model="search" label="Search" hide-details />
          <v-spacer />
          <v-select
            v-model="selectedType"
            :items="types"
            label="Types"
            hide-selected
            chips
            deletable-chips
          />
          <v-spacer />
          <v-btn
            color="primary"
            dark
            :loading="loading"
            :disabled="loading"
            class="mb-2 mr-2"
            @click="refreshList"
          >
            Reload
          </v-btn>
          <v-btn
            color="primary"
            dark
            :loading="loading"
            :disabled="loading"
            class="mb-2 mr-2"
            @click="onNewItem"
          >
            New
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.attributes.createdAt="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <div v-on="on">
              {{ formatDate(item.attributes.createdAt) }}
            </div>
          </template>
          <span>{{ item.displayDate }}</span>
        </v-tooltip>
      </template>
      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-container fluid>
            <v-tabs>
              <v-tab key="1"> Groups </v-tab>
              <v-tab key="2"> Partners </v-tab>
              <v-tab-item key="1">
                <ConfigGroups :config="item" />
              </v-tab-item>
              <v-tab-item key="2">
                <ConfigPartner :config-id="item.id" />
              </v-tab-item>
            </v-tabs>
          </v-container>
        </td>
      </template>
      <template #group.header="{ group, headers, isOpen, toggle }">
        <td :colspan="headers.length">
          <v-btn icon @click="toggle">
            <v-icon v-if="isOpen"> mdi-minus </v-icon>
            <v-icon v-else> mdi-plus </v-icon>
          </v-btn>
          <span>Type: {{ group || 'undefined' }}</span>
        </td>
      </template>
      <template #item.action="{ item }">
        <v-icon
          v-if="hasWritePerm()"
          class="mr-2"
          @click.stop="onEditItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon v-if="hasWritePerm()" @click.stop="onDeleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template #no-data>
        <v-btn color="primary" @click="refreshList"> Reset </v-btn>
      </template>
    </v-data-table>
    <div>
      <v-dialog v-model="editItemDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text v-if="editItem">
            <div v-if="editItem.id" class="pb-2">
              <span class="od-label">Configuration Id:</span
              ><span class="pl-2">{{ editItem.id }}</span>
            </div>
            <v-text-field
              v-model="editItem.attributes.name"
              outlined
              label="Name"
            />
            <v-combobox
              v-model="editItem.attributes.configType"
              :items="types"
              label="Types"
              hide-selected
              chips
              deletable-chips
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteItemDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Delete Configuration</span>
          </v-card-title>

          <v-card-text v-if="editItem">
            <div class="pb-2">
              <span class="od-label">Configuration Id:</span
              ><span class="pl-2">{{ editItem.id }}</span>
            </div>
            <v-text-field
              v-model="editItem.attributes.name"
              outlined
              label="Name"
              readonly
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="doDelete"> Delete </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import { OneDropApi } from '../../onedrop-api';
import { ConsoleLog, OneDropUtils } from '../../onedrop-utils';
import ConfigGroups from './ConfigGroups';
import ConfigPartner from './ConfigPartner';

export default {
  name: 'Config',
  components: { ConfigGroups, ConfigPartner },
  data: function () {
    return {
      loading: false,
      search: '',
      expanded: [],
      editItemDialog: false,
      deleteItemDialog: false,
      headers: [
        {
          text: 'Id',
          value: 'id',
        },
        {
          text: 'Type',
          value: 'attributes.configType',
          filter: (value) =>
            !this.selectedType ||
            this.selectedType.length == 0 ||
            this.selectedType === value,
        },
        {
          text: 'Name',
          value: 'attributes.name',
        },
        {
          text: 'Created At',
          value: 'attributes.createdAt',
        },
        { text: 'Actions', value: 'action', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      data: [],
      types: [],
      selectedType: null,
      editedIndex: -1,
      editItem: null,
    };
  },
  computed: {
    groupBy() {
      if (this.selectedType && this.selectedType.length > 0) return null;
      else return 'attributes.configType';
    },
    formTitle() {
      return this.editedIndex == -1
        ? 'New Configuration'
        : `Edit Configuration`;
    },
  },
  created() {
    this.refreshList();
  },
  methods: {
    formatDate: function (str) {
      return str ? OneDropUtils.displayDate(str) : str;
    },
    refreshList: function () {
      ConsoleLog('Fetching Configuration');
      this.loading = true;
      this.selectedType = null;
      OneDropApi.get(
        'v3.1/admin/config/configuration',
        (response) => {
          this.data = response.data;
          let types = response.data.map((item) => item.attributes.configType);
          this.types = Array.from(new Set(types));
          ConsoleLog(`Got ${this.data.length} Configuration`);
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    created: function () {
      this.refreshList();
    },
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'config-write']);
    },
    close: function () {
      this.loading = false;
      this.editedIndex = -1;
      this.editItem = null;
      this.editItemDialog = false;
      this.deleteItemDialog = false;
    },
    modifyAttrs(attrs) {
      attrs.groupOrder = Number(attrs.groupOrder);
    },
    doSave: function () {
      let body = { data: [this.editItem] };
      ConsoleLog(`Save ${this.editedIndex}, ${JSON.stringify(this.editItem)}`);
      this.loading = true;
      if (this.editedIndex > -1) {
        OneDropApi.patch(
          `v3.1/admin/config/configuration`,
          body,
          () => {
            this.refreshList();
            this.toast(`Configuration updated successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.onApiError(error);
            this.loading = false;
          }
        );
      } else {
        OneDropApi.post(
          `v3.1/admin/config/configuration`,
          body,
          () => {
            this.refreshList();
            this.toast(`Configuration added successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.onApiError(error);
            this.loading = false;
          }
        );
      }
    },
    doDelete: function () {
      ConsoleLog(
        `Delete ${this.editedIndex}, ${JSON.stringify(this.editItem)}`
      );
      this.loading = false;
      OneDropApi.del(
        `v3.1/admin/config/configuration/${this.editItem.id}`,
        null,
        () => {
          this.refreshList();
          this.toast(`Configuration deleted successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.onApiError(error);
          this.loading = false;
        }
      );
    },
    onNewItem: function () {
      this.editItem = {
        type: 'configuration',
        attributes: {
          configType: null,
          name: '',
        },
      };
      this.editedIndex = -1;
      this.editItemDialog = true;
    },
    onEditItem: function (item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.editedIndex = this.data.findIndex((v) => v.id === item.id);
      this.editItemDialog = true;
    },
    onDeleteItem: function (item) {
      this.editItem = item;
      this.editedIndex = this.data.findIndex((v) => v.id === item.id);
      this.deleteItemDialog = true;
    },
  },
};
</script>

<style></style>
