<template>
  <v-container fluid>
    <v-layout class="display-1 py-4"> Manage Eligibility </v-layout>
    <div class="text-center">
      <v-progress-circular v-if="saving" indeterminate color="primary" />
    </div>
    <v-data-table
      :items="records"
      :headers="headers"
      :search="search"
      :loading="loading"
      :expanded.sync="expanded"
      show-expand
      single-expand
      :custom-filter="customFilter"
    >
      <template #top>
        <v-toolbar flat color="white">
          <v-container fluid>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="selectedPartner"
                  :items="partners"
                  label="Partner"
                  item-text="name"
                  item-value="id"
                  hide-selected
                  chips
                  deletable-chips
                />
              </v-col>
              <v-col>
                <v-autocomplete
                  v-model="selectedStates"
                  :items="eligibilityStates"
                  label="Eligibility State"
                  multiple
                  chips
                  clearable
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="search"
                  label="Search"
                  hide-details
                  outlined
                />
              </v-col>
            </v-row>
          </v-container>
          <v-spacer />
          <v-btn
            color="primary"
            dark
            :loading="loading"
            class="mb-2 mr-2"
            @click="refreshPartners"
          >
            Reload
          </v-btn>
          <v-btn
            v-if="hasWritePerm()"
            color="primary"
            :loading="loading"
            :disabled="!selectedPartner"
            class="mb-2"
            @click="onNewItem"
          >
            New Member
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.action="{ item }">
        <v-icon
          v-if="hasWritePerm()"
          class="mr-2"
          title="Edit"
          @click.stop="onEditItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="hasWritePerm()"
          title="Delete"
          @click.stop="onDeleteItem(item)"
        >
          mdi-delete
        </v-icon>
        <v-icon
          v-if="canReset(item)"
          class="mr-2"
          title="Reset"
          @click.stop="onResetItem(item)"
        >
          mdi-account-convert
        </v-icon>
      </template>
      <template #item.data-table-expand="{ expand, item, isExpanded }">
        <v-icon
          v-if="hasUserReadPerm && item.attributes.userId && isExpanded"
          @click="expand(false)"
        >
          mdi-chevron-up
        </v-icon>
        <v-icon
          v-else-if="hasUserReadPerm"
          title="Show User Details"
          @click="expand(true)"
        >
          mdi-chevron-down
        </v-icon>
      </template>
      <template #expanded-item="{ item }">
        <td
          v-if="hasUserReadPerm && item.attributes.userId"
          :colspan="headers.length"
        >
          <UserDetails
            :user-id="item.attributes.userId"
            :include-eligibility-tab="true"
            :audit-id="item.id"
          />
        </td>
        <td v-else :colspan="headers.length">
          <v-container fluid>
            <v-layout class="display-1 py-4">Eligibility Events</v-layout>
            <eligibility-events :eligibility-id="item.id" />
          </v-container>
        </td>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text v-if="editedItem">
          <v-progress-circular v-if="saving" indeterminate color="primary" />
          <v-container>
            <v-row label="Partner">
              <v-col>
                <span class="od-label">Partner: </span
                >{{ getPartnerName(selectedPartner) }}
              </v-col>
            </v-row>
            <v-text-field
              v-model="editedItem.attributes.memberId"
              label="Member Id (Optional, will be autogenerated if blank)"
            />
            <v-text-field
              v-model="editedItem.attributes.firstName"
              :rules="[rules.required]"
              label="First Name"
            />
            <v-text-field
              v-model="editedItem.attributes.lastName"
              :rules="[rules.required]"
              label="Last Name"
            />
            <v-text-field
              v-model="editedItem.attributes.email"
              :rules="[rules.email]"
              label="Email"
              type="email"
            />
            <v-row>
              <v-col cols="6">
                <v-menu
                  ref="birthDateMenu"
                  v-model="birthDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="290px"
                  max-width="290px"
                  offset-y
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.attributes.birthDate"
                      label="Birth Date"
                      prepend-inner-icon="mdi-calendar"
                      hint="YYYY-MM-DD"
                      persistent-hint
                      v-bind="attrs"
                      :rules="[rules.date]"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="editedItem.attributes.birthDate"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1920-01-01"
                    no-title
                    @change="saveBirthday"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  v-model="editedItem.attributes.eligibilityState"
                  :items="filteredStates"
                  chips
                  hide-selected
                  :rules="[rules.required]"
                  label="Eligibility State"
                />
              </v-col>
              <v-col cols="6">
                <v-menu
                  ref="termDateMenu"
                  v-model="termDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="290px"
                  max-width="290px"
                  offset-y
                >
                  <template #activator="{ on }">
                    <v-text-field
                      v-model="editedItem.attributes.termDate"
                      label="Term Date"
                      prepend-inner-icon="mdi-calendar"
                      hint="YYYY-MM-DD"
                      persistent-hint
                      :rules="[rules.date]"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="editedItem.attributes.termDate"
                    no-title
                    @input="termDateMenu = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" :loading="saving" text @click="close">
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            :loading="saving"
            text
            :disabled="!canSave()"
            @click="saveMember"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="resetDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Reset Member</span>
        </v-card-title>

        <v-card-text v-if="editedItem">
          <v-progress-circular v-if="saving" indeterminate color="primary" />
          <v-alert
            v-if="editedItem.attributes.userId"
            type="warning"
            color="red"
            icon="mdi-account-remove"
          >
            This will delete the existing user and reset their eligibility
            record
          </v-alert>
          <v-row label="Partner">
            <v-col>
              <span class="od-label">Partner: </span
              >{{ getPartnerName(selectedPartner) }}
            </v-col>
          </v-row>
          <v-text-field
            v-model="editedItem.attributes.memberId"
            readonly
            label="Member Id"
          />
          <v-text-field
            v-model="editedItem.attributes.firstName"
            readonly
            label="First Name"
          />
          <v-text-field
            v-model="editedItem.attributes.lastName"
            readonly
            label="Last Name"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" :loading="saving" text @click="close">
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            :loading="saving"
            text
            @click="resetMember"
          >
            Reset
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Delete Member</span>
        </v-card-title>

        <v-card-text v-if="editedItem">
          <v-progress-circular v-if="saving" indeterminate color="primary" />
          <v-alert v-if="editedItem.attributes.userId" type="warning">
            Deleting this record will not delete associated user.
          </v-alert>
          <v-row label="Partner">
            <v-col>
              <span class="od-label">Partner: </span
              >{{ getPartnerName(selectedPartner) }}
            </v-col>
          </v-row>
          <v-text-field
            v-model="editedItem.attributes.memberId"
            readonly
            label="Member Id"
          />
          <v-text-field
            v-model="editedItem.attributes.firstName"
            readonly
            label="First Name"
          />
          <v-text-field
            v-model="editedItem.attributes.lastName"
            readonly
            label="Last Name"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" :loading="saving" text @click="close">
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            :loading="saving"
            text
            @click="deleteMember"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { ConsoleLog } from '../onedrop-utils';
import UserDetails from '../components/UserDetails';
import EligibilityEvents from '@/components/EligibilityEvents.vue';

export default {
  name: 'EligibilityEditor',
  components: { UserDetails, EligibilityEvents },
  data: function () {
    return {
      dialog: false,
      deleteDialog: false,
      resetDialog: false,
      loading: false,
      saving: false,
      search: '',
      partners: [],
      selectedPartner: null,
      birthDateMenu: false,
      termDateMenu: false,
      expanded: [],
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'User Id',
          value: 'attributes.userId',
        },
        {
          text: 'Member Id',
          value: 'attributes.memberId',
        },
        {
          text: 'First Name',
          value: 'attributes.firstName',
        },
        {
          text: 'Last Name',
          value: 'attributes.lastName',
        },
        {
          text: 'Email',
          value: 'attributes.email',
        },
        {
          text: 'Birth Date',
          value: 'attributes.birthDate',
        },
        {
          text: 'Eligibility State',
          value: 'attributes.eligibilityState',
          filter: (value, search, item) => {
            if (this.selectedStates.length > 0) {
              if (
                this.selectedStates.indexOf(item.attributes.eligibilityState) ==
                -1
              )
                return false;
            }
            return true;
          },
        },
        {
          text: 'Term Date',
          value: 'attributes.termDate',
        },
        { text: 'Actions', value: 'action', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      records: [],
      editedIndex: -1,
      editedItem: null,
      selectedStates: [],
      eligibilityStates: [
        'withdrawn',
        'withdrawn-eligible',
        'ordered',
        'withdrawn-expired',
        'terminated-eligible',
        'equipped',
        'eligible',
        'eligible-optout',
        'ineligible',
        'terminated-enrolled',
        'terminated',
        'enrolled',
        'terminated-ineligible',
      ],
      rules: {
        required: (value) => !!value || 'Required.',
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !value || pattern.test(value) || 'Invalid e-mail.';
        },
        date: (value) => {
          const pattern =
            /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])$/;
          return !value || pattern.test(value) || 'Invalid date.';
        },
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex == -1 ? 'New Member' : `Edit Member`;
    },
    filteredStates() {
      if (this.editedItem && this.editedIndex > -1) {
        const item = this.records[this.editedIndex];
        if (item && item.attributes.eligibilityState != 'eligible')
          return this.eligibilityStates.filter((v) => v !== 'eligible');
      }
      return this.eligibilityStates;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    deleteDialog(val) {
      val || this.close();
    },
    resetDialog(val) {
      val || this.close();
    },
    selectedPartner(val) {
      this.records = [];
      if (val) this.refreshRecords(val);
    },
    birthDateMenu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
  },
  created() {
    this.refreshPartners();
  },
  methods: {
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['eligibility-write']);
    },
    canReset(item) {
      return (
        this.hasWritePerm() && item.attributes.eligibilityState != 'eligible'
      );
    },
    hasUserReadPerm: function () {
      return OneDropApi.hasOneOfPerms(['admin-read', 'admin-user-read']);
    },
    refreshPartners: function () {
      ConsoleLog('Fetching Partners');
      this.loading = true;
      this.selectedPartner = null;
      OneDropApi.get(
        'admin/v3/eligibility/partners',
        (response) => {
          this.partners = response.data.map((r) => {
            return { id: r.id, name: this.getPartnerName(r.id) };
          });
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    refreshRecords(partnerId) {
      ConsoleLog('Fetching Records');
      this.loading = true;
      OneDropApi.get(
        `admin/v3/eligibility/partner/${partnerId}`,
        (response) => {
          this.records = response.data;
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    match(search, target) {
      return !!target && target.toLowerCase().search(search) != -1;
    },
    customFilter(value, search, item) {
      if (search) {
        const sr = search.toLowerCase();
        const firstName = item.attributes.firstName
          ? item.attributes.firstName
          : '';
        const lastName = item.attributes.lastName
          ? item.attributes.lastName
          : '';
        const fullName = (firstName + ' ' + lastName).trim();
        if (this.match(sr, fullName)) return true;
        else if (this.match(sr, item.attributes.email)) return true;
        else if (this.match(sr, item.id)) return true;
        else if (this.match(sr, item.attributes.userId)) return true;
        else if (this.match(sr, item.attributes.memberId)) return true;
        else return false;
      } else return true;
    },
    getPartnerById: function (id) {
      return this.$store.getters.partnerById(id);
    },
    getPartnerName: function (id) {
      if (id) {
        const p = this.getPartnerById(id);
        return p ? `${p.attributes.name} (${id})` : id;
      }
      return null;
    },
    created: function () {
      this.refreshPartners();
    },
    itemToEdited: function (item) {
      return {
        id: item.id,
        brandName: item.brandName,
        chemicalName: item.chemicalName,
        isDiabetesMed: item.isDiabetesMed,
        isBasalMed: item.isBasal == 1,
        isBolusMed: item.isBolus == 1,
        unit: item.unit ? JSON.parse(item.unit) : [],
      };
    },
    onNewItem() {
      this.editedIndex = -1;
      this.editedItem = {
        type: 'eligibility',
        attributes: {
          partnerId: this.selectedPartner,
          memberId: '',
          firstName: '',
          lastName: '',
          email: '',
          birthDate: '',
          eligibilityState: 'eligible',
          termDate: '',
        },
      };
      this.dialog = true;
    },
    onEditItem: function (item) {
      this.editedIndex = this.records.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.dialog = true;
    },
    onResetItem: function (item) {
      this.editedIndex = this.records.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.resetDialog = true;
    },
    onDeleteItem: function (item) {
      this.editedIndex = this.records.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.deleteDialog = true;
    },
    close() {
      this.editedItem = this.defaultItem;
      this.dialog = false;
      this.deleteDialog = false;
      this.resetDialog = false;
      this.editedIndex = -1;
      this.saving = false;
    },
    canSave() {
      if (!this.editedItem) return false;
      const attrs = this.editedItem.attributes;
      return attrs.firstName && attrs.lastName && attrs.eligibilityState;
    },
    modifyAttrs(attrs) {
      // generate random 12 digit memberId
      if (!attrs.memberId)
        attrs.memberId = String(
          Math.floor(100000000000 + Math.random() * 900000000000)
        );
      if (!attrs.birthDate) delete attrs.birthDate;
      if (!attrs.termDate) delete attrs.termDate;
    },
    saveMember() {
      this.saving = true;
      let index = this.editedIndex;
      this.modifyAttrs(this.editedItem.attributes);
      let body = {
        data: [this.editedItem],
      };
      ConsoleLog(`Saving item ${index} ${JSON.stringify(body)}`);
      if (this.editedIndex > -1) {
        OneDropApi.patch(
          'admin/v3/eligibility',
          body,
          (response) => {
            const item = response.data[0];
            this.records.splice(this.editedIndex, 1, item);
            this.toast(`Member updated successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.onApiError(error);
            this.close();
          }
        );
      } else {
        OneDropApi.post(
          'admin/v3/eligibility',
          body,
          (response) => {
            const item = response.data[0];
            this.records.push(item);
            this.toast(`Member ${item.id} inserted successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.onApiError(error);
            this.close();
          }
        );
      }
    },
    deleteMember() {
      this.saving = true;
      let index = this.editedIndex;
      const id = index > -1 ? this.editedItem.id : null;
      if (!id) {
        this.close();
        return;
      }
      ConsoleLog(`Delete item ${id}`);
      OneDropApi.del(
        `admin/v3/eligibility/${id}`,
        null,
        () => {
          this.records.splice(index, 1);
          this.saving = false;
          this.toast(`Member ${id} deleted successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.onApiError(error);
          this.close();
        }
      );
    },
    resetMember() {
      this.saving = true;
      let index = this.editedIndex;
      const id = index > -1 ? this.editedItem.id : null;
      if (!id) {
        this.close();
        return;
      }
      ConsoleLog(`Delete item ${id}`);
      OneDropApi.patch(
        `admin/v3/eligibility/reset/${id}`,
        null,
        (response) => {
          const item = response.data[0];
          this.records.splice(this.editedIndex, 1, item);
          this.toast(`Member ${id} reset successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.onApiError(error);
          this.close();
        }
      );
    },
    saveBirthday(date) {
      this.$refs.birthDateMenu.save(date);
    },
  },
};
</script>

<style></style>
