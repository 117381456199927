<template>
  <v-card flat>
    <v-data-table
      :items="data"
      :headers="headers"
      :search="search"
      :loading="loading"
      :expanded.sync="expanded"
      show-expand
      single-expand
    >
      <template #top>
        <v-toolbar flat color="white">
          <v-text-field v-model="search" label="Search" hide-details />
          <v-spacer />
          <v-btn
            color="primary"
            dark
            :loading="loading"
            :disabled="loading"
            class="mb-2 mr-2"
            @click="refreshList"
          >
            Reload
          </v-btn>
          <v-btn
            color="primary"
            dark
            :loading="loading"
            :disabled="loading"
            class="mb-2 mr-2"
            @click="onNewItem"
          >
            New Group
          </v-btn>
        </v-toolbar>
      </template>
      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <ConfigGroupMaps :group-id="item.id" :config="config" />
        </td>
      </template>
      <template #item.action="{ item }">
        <v-icon
          v-if="hasWritePerm()"
          class="mr-2"
          @click.stop="onEditItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon v-if="hasWritePerm()" @click.stop="onDeleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template #no-data>
        <v-btn color="primary" @click="refreshList"> Reset </v-btn>
      </template>
    </v-data-table>
    <div>
      <v-dialog v-model="editItemDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text v-if="editItem">
            <div v-if="editItem.id" class="pb-2">
              <span class="od-label">Group:</span
              ><span class="pl-2">{{ editItem.id }}</span>
            </div>
            <v-text-field
              v-model="editItem.attributes.description"
              outlined
              label="Description"
            />
            <v-textarea
              v-model="editItem.attributes.groupRule"
              outlined
              label="Group Rule"
            />
            <v-text-field
              v-model="editItem.attributes.groupOrder"
              type="number"
              outlined
              label="Order"
            />
            <v-switch v-model="editItem.attributes.isDefault" label="Default" />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteItemDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Delete Group</span>
          </v-card-title>

          <v-card-text v-if="editItem">
            <div class="pb-2">
              <span class="od-label">Group:</span
              ><span class="pl-2">{{ editItem.id }}</span>
            </div>
            <v-text-field
              v-model="editItem.attributes.description"
              outlined
              label="Description"
              readonly
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="doDelete"> Delete </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import { OneDropApi } from '../../onedrop-api';
import { ConsoleLog, OneDropUtils } from '../../onedrop-utils';
import ConfigGroupMaps from './ConfigGroupMaps';

export default {
  name: 'ConfigGroups',
  components: { ConfigGroupMaps },
  props: ['config'],
  data: function () {
    return {
      loading: false,
      search: '',
      expanded: [],
      editItemDialog: false,
      deleteItemDialog: false,
      headers: [
        {
          text: 'Id',
          value: 'id',
        },
        {
          text: 'Description',
          value: 'attributes.description',
        },
        {
          text: 'Rule',
          value: 'attributes.groupRule',
        },
        {
          text: 'Order',
          value: 'attributes.groupOrder',
        },
        {
          text: 'Default',
          value: 'attributes.isDefault',
        },
        { text: 'Actions', value: 'action', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      data: [],
      editedIndex: -1,
      editItem: null,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex == -1 ? 'New Group' : `Edit Group`;
    },
  },
  watch: {
    config() {
      this.refreshList();
    },
  },
  created() {
    this.refreshList();
  },
  methods: {
    formatDate: function (str) {
      return str ? OneDropUtils.displayDate(str) : str;
    },
    refreshList: function () {
      this.loading = true;
      let id = this.config ? this.config.id : 'NONE';
      OneDropApi.get(
        `v3.1/admin/config/groups/${id}`,
        (response) => {
          this.data = response.data;
          ConsoleLog(`Got ${this.data.length} Configuration Groups`);
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'config-write']);
    },
    created: function () {
      this.refreshList();
    },
    close: function () {
      this.loading = false;
      this.editedIndex = -1;
      this.editItem = null;
      this.editItemDialog = false;
      this.deleteItemDialog = false;
    },
    modifyAttrs(attrs) {
      attrs.groupOrder = Number(attrs.groupOrder);
    },
    doSave: function () {
      let body = { data: [this.editItem] };
      ConsoleLog(`Save ${this.editedIndex}, ${JSON.stringify(this.editItem)}`);
      this.loading = true;
      if (this.editedIndex > -1) {
        OneDropApi.patch(
          `v3.1/admin/config/group`,
          body,
          () => {
            this.refreshList();
            this.toast(`Group updated successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.onApiError(error);
            this.loading = false;
          }
        );
      } else {
        OneDropApi.post(
          `v3.1/admin/config/group`,
          body,
          () => {
            this.refreshList();
            this.toast(`Group added successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.onApiError(error);
            this.loading = false;
          }
        );
      }
    },
    doDelete: function () {
      ConsoleLog(
        `Delete ${this.editedIndex}, ${JSON.stringify(this.editItem)}`
      );
      this.loading = false;
      OneDropApi.del(
        `v3.1/admin/config/group/${this.editItem.id}`,
        null,
        () => {
          this.refreshList();
          this.toast(`Group deleted successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.onApiError(error);
          this.loading = false;
        }
      );
    },
    onNewItem: function () {
      this.editItem = {
        type: 'configuration-option-group',
        attributes: {
          configurationId: this.config.id,
          description: null,
          groupOrder: this.data.length + 1,
          groupRule: null,
          isDefault: false,
        },
      };
      this.editedIndex = -1;
      this.editItemDialog = true;
    },
    onEditItem: function (item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.editedIndex = this.data.findIndex((v) => v.id === item.id);
      this.editItemDialog = true;
    },
    onDeleteItem: function (item) {
      this.editItem = item;
      this.editedIndex = this.data.findIndex((v) => v.id === item.id);
      this.deleteItemDialog = true;
    },
  },
};
</script>

<style></style>
