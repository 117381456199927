var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-text-field',{ref:"searchText",attrs:{"label":"Search","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1)]},proxy:true},{key:"item.attributes.eventDate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.displayDate(item.attributes.eventDate))+" ")])]}},{key:"item.attributes.trackId",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
          path: '/content/trackeditor',
          query: { query: item.attributes.trackId },
        },"target":"_blank"}},[_vm._v(" "+_vm._s(item.attributes.trackId)+" ")])]}},{key:"item.attributes.sourceTrackId",fn:function(ref){
        var item = ref.item;
return [(item.attributes.sourceTrackId)?_c('div',[_vm._v(" "+_vm._s(item.attributes.sourceTrackTitle)+" ("+_vm._s(item.attributes.sourceTrackId)+") ")]):_vm._e()]}},{key:"item.attributes.sourceCoachId",fn:function(ref){
        var item = ref.item;
return [(item.attributes.sourceCoachId)?_c('div',[_vm._v(" "+_vm._s(item.attributes.sourceCoachName)+" ("+_vm._s(item.attributes.sourceCoachId)+") ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }