<template>
  <v-data-table
    :items="items"
    item-key="objectId"
    :headers="headers"
    show-expand
    single-expand
  >
    <template #expanded-item="{ item }">
      <td :colspan="headers.length">
        <v-simple-table dense>
          <template #default>
            <thead>
              <tr>
                <th>User Id</th>
                <th>Completed Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in item.completedUsers" :key="user.userId">
                <td>{{ user.userId }}</td>
                <td>{{ user.completedDate }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </td>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'MigrateTrackDiff',
  props: ['items'],
  data: function () {
    return {
      headers: [
        {
          text: 'Object ID',
          value: 'objectId',
        },
        {
          text: 'Day',
          value: 'day',
        },
        {
          text: 'Day Order',
          value: 'dayOrder',
        },
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: 'Content',
          value: 'content',
        },
        {
          text: 'Destination',
          value: 'dest',
        },
        {
          text: 'Lesson Name',
          value: 'lessonName',
        },
        {
          text: 'Completed Count',
          value: 'completedCount',
        },
        { text: 'Users', value: 'data-table-expand' },
      ],
    };
  },
};
</script>

<style scoped></style>
