<template>
  <div>
    <v-progress-linear indeterminate color="primary" :active="loading" />
    <v-card v-if="!empty" flat>
      <v-layout>
        <slot />
        <v-spacer />
        <v-btn v-if="hasWritePerm()" icon title="Edit" @click="onAssignRole">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-layout>
      <v-simple-table dense>
        <template #default>
          <tbody>
            <tr>
              <th>ID</th>
              <th>Slug</th>
              <th>Type</th>
              <th>Description</th>
              <th>Expiration Date</th>
            </tr>
            <tr v-for="item in records" :key="item.id">
              <td>{{ item.id }}</td>
              <td>{{ item.attributes.slug }}</td>
              <td>{{ item.attributes.type }}</td>
              <td>{{ item.attributes.description }}</td>
              <td>
                {{
                  item.attributes.expiresAt
                    ? item.attributes.expiresAt.split('T')[0]
                    : 'Indefinitely'
                }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <slot v-else name="empty">
      <template v-if="hasWritePerm()">
        <v-layout>
          <slot />
          <v-spacer />
          <v-btn icon title="Edit" @click="onAssignRole">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-layout>
        <div>No roles</div>
      </template>
    </slot>
    <v-dialog v-model="showAssignRoleDialog" width="75%" hide-overlay>
      <UserRoleSelector
        :user-id="userId"
        :selections="records"
        @assign-done="onAssignRoleSaved"
        @assign-canceled="onAssignRoleCanceled"
      />
    </v-dialog>
  </div>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { ConsoleLog } from '../onedrop-utils';
import UserRoleSelector from '@/components/UserRoleSelector';

export default {
  name: 'UserRole',
  components: { UserRoleSelector },
  props: {
    userId: String,
    perms: {
      type: Array,
      default: function () {
        return ['admin-write'];
      },
    },
  },
  data: function () {
    return {
      records: null,
      loading: false,
      showAssignRoleDialog: false,
    };
  },
  computed: {
    currentUser: function () {
      return OneDropApi.getUserId() === this.userId;
    },
    empty: function () {
      return !this.records || this.records.length == 0;
    },
  },
  watch: {
    userId() {
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.loading = true;
      OneDropApi.get(
        `v3/admin/roles/user/${this.userId}`,
        (result) => {
          this.records = result.data;
          ConsoleLog(`Got ${this.records.length} records`);
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          this.loading = false;
        }
      );
    },
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(this.perms);
    },
    onAssignRole: function () {
      this.showAssignRoleDialog = true;
    },
    onAssignRoleSaved() {
      this.showAssignRoleDialog = false;
      this.refresh();
    },
    onAssignRoleCanceled() {
      this.showAssignRoleDialog = false;
    },
  },
};
</script>

<style scoped></style>
