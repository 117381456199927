<template>
  <v-card flat>
    <v-card-title>Select Options</v-card-title>
    <v-card-text>
      <v-data-table
        v-model="selected"
        :items="filteredOptions"
        :headers="computedHeaders"
        :search="search"
        :loading="loading || saving"
        show-select
      >
        <template #top>
          <v-toolbar flat color="white">
            <v-text-field v-model="search" label="Search" hide-details />
            <v-spacer />
            <v-select
              v-if="isSubOptions"
              v-model="selectedType"
              :items="types"
              label="Types"
              hide-selected
              chips
              deletable-chips
            />
            <v-spacer />
          </v-toolbar>
        </template>
        <template #item.attributes.metaData="{ item }">
          <span>{{ JSON.stringify(item.attributes.metaData) }}</span>
        </template>
        <template #no-data>
          <v-btn color="primary" @click="refreshList"> Reset </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="blue darken-1" text @click="onClose"> Cancel </v-btn>
      <v-btn
        color="blue darken-1"
        :disabled="selected.length < 1"
        text
        @click="onSave"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { OneDropApi } from '../../onedrop-api';
import { ConsoleLog } from '../../onedrop-utils';

export default {
  name: 'OptionSelector',
  props: [
    'type',
    'currentSelections',
    'save',
    'close',
    'saving',
    'isSubOptions',
  ],
  data: function () {
    return {
      loading: false,
      search: '',
      headers: [
        {
          text: 'Id',
          value: 'id',
        },
        {
          text: 'Type',
          value: 'attributes.optionType',
          filter: (value) =>
            !this.isSubOptions ||
            !this.selectedType ||
            this.selectedType.length == 0 ||
            this.selectedType == value,
        },
        {
          text: 'Name',
          value: 'attributes.name',
        },
        {
          text: 'Slug',
          value: 'attributes.slug',
        },
        {
          text: 'Option Type',
          value: 'attributes.optionType',
        },
        {
          text: 'Meta Data',
          value: 'attributes.metaData',
        },
        { text: '', value: 'data-table-expand' },
      ],
      options: [],
      selected: [],
      types: [],
      selectedType: null,
    };
  },
  computed: {
    filteredOptions() {
      return this.options.filter((item) => {
        if (
          this.currentSelections.includes(item.id) ||
          (this.isSubOptions && item.attributes.optionType === 'card')
        )
          return false;
        else return true;
      });
    },
    computedHeaders() {
      return this.headers.filter((header) =>
        this.isSubOptions ? header : header.text !== 'Option Type'
      );
    },
  },
  watch: {
    type: function () {
      this.refreshList();
    },
    currentSelections: function () {
      this.selected = [];
    },
  },
  created() {
    this.refreshList();
  },
  methods: {
    refreshList: function () {
      ConsoleLog('Fetching Options');
      this.loading = true;
      this.selected = [];
      OneDropApi.get(
        this.isSubOptions
          ? `v3.1/admin/config/options`
          : `v3.1/admin/config/options?type=${this.type}`,
        (response) => {
          this.options = response.data;
          let types = response.data.map((item) => item.attributes.optionType);
          this.types = Array.from(new Set(types));
          ConsoleLog(`Got ${this.options.length} options`);
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    created: function () {
      this.refreshList();
    },
    onSave() {
      let selected = this.selected.map((item) => item.id);
      this.save(selected);
    },
    onClose() {
      this.close();
    },
  },
};
</script>

<style></style>
