<template>
  <v-container fluid>
    <v-layout class="display-1 py-4"> Manage Configuration </v-layout>
    <v-tabs>
      <v-tab key="1" to="/setup/configeditor"> Configuration </v-tab>
      <v-tab key="2" to="/setup/configeditor/options"> Options </v-tab>
    </v-tabs>
    <router-view />
  </v-container>
</template>

<script>
export default {
  name: 'Search',
};
</script>
