<template>
  <v-card flat>
    <v-card-title>
      <div class="od-heading mb-2 mt-2">
        <h2>Roles</h2>
      </div>
    </v-card-title>
    <v-card-text>
      <div class="pb-2">
        <span class="od-label">User ID:</span>
        <span class="pl-2">{{ userId }}</span>
      </div>
      <v-data-table
        v-model="selected"
        :items="records"
        :headers="headers"
        :loading="loading"
        show-select
        :search="search"
        sort-by="attributes.slug"
      >
        <template #top>
          <v-toolbar flat color="white">
            <v-text-field v-model="search" label="Search" hide-details />
            <v-spacer />
          </v-toolbar>
        </template>
        <template #item.attributes.expiresAt="props">
          <v-edit-dialog
            :return-value.sync="props.item.attributes.expiresAt"
            large
            @save="
              newSelected = [
                ...(newSelected ? newSelected : []),
                ...(currentSelected ? currentSelected : []),
                props.item,
              ]
            "
          >
            <div>
              <span class="mr-2">{{
                props.item.attributes.expiresAt
                  ? props.item.attributes.expiresAt
                  : 'Indefinitely'
              }}</span>
              <v-icon> mdi-pencil </v-icon>
            </div>
            <template #input>
              <div class="mt-4 text-h6">Set Expiration Date</div>
              <v-menu
                ref="expirationDateMenu"
                v-model="expirationDateMenu[props.item.id]"
                transition="scale-transition"
                min-width="290px"
                max-width="290px"
                offset-y
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="props.item.attributes.expiresAt"
                    label="Expiration Date"
                    prepend-inner-icon="mdi-calendar"
                    hint="YYYY-MM-DD"
                    persistent-hint
                    readonly
                    outlined
                    clearable
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  :key="props.item.id"
                  v-model="props.item.attributes.expiresAt"
                  no-title
                  :min="new Date().toISOString().substr(0, 10)"
                  @input="expirationDateMenu[props.item.id] = false"
                />
              </v-menu>
            </template>
          </v-edit-dialog>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="blue darken-1" text @click="onClose"> Cancel </v-btn>
      <v-btn color="blue darken-1" :disabled="!hasChanges" text @click="onSave">
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { OneDropApi } from '../onedrop-api';

export default {
  name: 'UserRole',
  props: ['userId', 'selections'],
  data: function () {
    return {
      loading: false,
      records: [],
      newSelected: null,
      search: null,
      expirationDateMenu: [],
      headers: [
        {
          text: 'ID',
          value: 'id',
          align: 'end',
        },
        {
          text: 'Slug',
          value: 'attributes.slug',
        },
        {
          text: 'Type',
          value: 'attributes.type',
        },
        {
          text: 'Description',
          value: 'attributes.description',
        },
        {
          text: 'Expiration Date',
          value: 'attributes.expiresAt',
        },
      ],
    };
  },
  computed: {
    currentSelected: function () {
      return this.selections ? this.selections : [];
    },
    currentUser: function () {
      return OneDropApi.getUserId() === this.userId;
    },
    empty: function () {
      return !this.records || this.records.length == 0;
    },
    selected: {
      get() {
        return this.newSelected
          ? this.newSelected
          : JSON.parse(JSON.stringify(this.currentSelected));
      },
      set(val) {
        this.newSelected = val;
      },
    },
    hasChanges() {
      if (this.newSelected) {
        if (this.newSelected.length != this.currentSelected.length) {
          return true;
        }
        for (let i in this.newSelected) {
          let item = this.newSelected[i];
          if (
            this.currentSelected.findIndex((value) => value.id === item.id) ==
            -1
          ) {
            return true;
          }
        }
        for (let j in this.currentSelected) {
          let item = this.currentSelected[j];
          if (
            this.newSelected.findIndex((value) => value.id === item.id) == -1
          ) {
            return true;
          } else if (
            this.newSelected.filter((value) => value.id === item.id)[0]
              .attributes.expiresAt !== item.attributes.expiresAt
          ) {
            return true;
          }
        }
      }
      return false;
    },
  },
  watch: {
    selections() {
      this.newSelected = null;
    },
  },
  mounted() {
    this.refreshRoles();
  },
  methods: {
    refreshRoles() {
      this.loading = true;
      OneDropApi.get(
        `v3/admin/roles`,
        (result) => {
          this.records = result.data.map((v) => {
            return {
              ...v,
              attributes: {
                ...v.attributes,
                expiresAt:
                  this.currentSelected.findIndex(
                    (selected) => selected.id === v.id
                  ) !== -1
                    ? this.currentSelected
                        .filter((selected) => selected.id === v.id)[0]
                        .attributes?.expiresAt?.split('T')[0]
                    : undefined,
              },
            };
          });
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          this.loading = false;
        }
      );
    },
    onClose() {
      this.newSelected = null;
      this.$emit('assign-canceled', null);
    },
    onSave() {
      let body = {
        data: this.newSelected.map((v) => {
          return {
            ...v,
            attributes: {
              ...v.attributes,
              expiresAt: v.attributes.expiresAt
                ? v.attributes.expiresAt.includes('T')
                  ? v.attributes.expiresAt
                  : v.attributes.expiresAt + 'T05:00:00Z'
                : undefined,
            },
          };
        }),
      };
      OneDropApi.patch(
        `v3/admin/roles/user/${this.userId}`,
        body,
        () => {
          this.loading = false;
          this.toast(`Saved roles for the user ${this.userId}`);
          this.$emit('assign-done', this.userId);
          this.newSelected = null;
        },
        (error) => {
          this.onApiError(error);
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style scoped></style>
