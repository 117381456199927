<template>
  <v-container fluid>
    <v-layout class="display-1 py-4"> Manage Inbox Message </v-layout>
    <div class="text-center">
      <v-progress-circular v-if="saving" indeterminate color="primary" />
    </div>
    <v-data-table
      :items="allMessages"
      :headers="headers"
      :search="search"
      :loading="loading"
      :expanded.sync="expanded"
      show-expand
      @click:row="rowClicked"
    >
      <template #top>
        <v-dialog v-model="dialog" max-width="600px">
          <template #activator="{ on }">
            <v-toolbar flat color="white">
              <v-text-field
                v-model="search"
                label="Search"
                hide-details
                outlined
              />
              <v-spacer />
              <v-btn
                color="primary"
                dark
                :loading="loading"
                :disabled="loading"
                class="mb-2 mr-2"
                @click="refreshInboxMessages"
              >
                Reload
              </v-btn>
              <v-btn
                v-if="hasWritePerm()"
                color="primary"
                :disabled="loading"
                dark
                class="mb-2"
                v-on="on"
                @click.stop="onNewItem"
              >
                New Item
              </v-btn>
            </v-toolbar>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text v-if="editedItem">
              <v-text-field
                v-model="editedItem.attributes.description"
                outlined
                label="Description"
              />
              <v-text-field
                v-model="editedItem.attributes.userTitle"
                outlined
                label="User Title"
              />
              <v-text-field
                v-model="editedItem.attributes.userSubtitle"
                outlined
                label="User Subtitle"
              />
              <v-text-field
                v-model="editedItem.attributes.url"
                outlined
                label="URL"
                type="url"
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                :disabled="saving"
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text :loading="saving" @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Delete Item</span>
            </v-card-title>

            <v-card-text v-if="editedItem">
              <v-row label="Object ID">
                <v-col>Object ID: {{ editedItem.id }}</v-col>
              </v-row>
              <v-text-field
                v-model="editedItem.attributes.description"
                outlined
                label="Description"
                readonly
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                :disabled="saving"
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="saving"
                @click="deleteInboxMessage"
              >
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template #header.action="{ header }">
        <span v-if="hasWritePerm()">{{ header.text }}</span>
      </template>
      <template #item.attributes.createdAt="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <div v-on="on">
              {{ formatDate(item.attributes.createdAt) }}
            </div>
          </template>
          <span>{{ item.displayDate }}</span>
        </v-tooltip>
      </template>
      <template #item.url="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <span
              class="d-inline-block text-truncate"
              style="max-width: 200px"
              v-on="on"
            >
              {{ item.url }}
            </span>
          </template>
          <span>{{ item.url }}</span>
        </v-tooltip>
      </template>
      <template #item.imageUrl="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <span
              class="d-inline-block text-truncate"
              style="max-width: 200px"
              v-on="on"
              >{{ item.imageUrl }}</span
            >
          </template>
          <span>{{ item.imageUrl }}</span>
        </v-tooltip>
      </template>
      <template #item.active="{ item }">
        <v-switch v-model="item.active" readonly />
      </template>
      <template #item.action="{ item }">
        <v-icon v-if="hasWritePerm()" class="mr-2" @click.stop="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon v-if="hasWritePerm()" @click.stop="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-container fluid>
            <v-tabs>
              <v-tab key="1"> Content </v-tab>
              <v-tab key="2"> Audit Events </v-tab>
              <v-tab-item key="1">
                <ContentResourceView
                  resource-type="inbox-message"
                  :resource-id="item.id"
                  :default-values="defaultContent"
                  @changed="refreshInboxMessages()"
                />
              </v-tab-item>
              <v-tab-item key="2">
                <audit-log-viewer
                  event-type="inbox-message"
                  :object-id="item.id"
                />
              </v-tab-item>
            </v-tabs>
          </v-container>
        </td>
      </template>
      <template #no-data>
        <v-btn color="primary" @click="refreshInboxMessages"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import ContentResourceView from '@/components/content/ContentResourceView';
import AuditLogViewer from '@/components/AuditLogViewer';
import { ConsoleLog, OneDropUtils } from '../onedrop-utils';

export default {
  name: 'NewsMessageEditor',
  components: { ContentResourceView, AuditLogViewer },
  data: function () {
    return {
      dialog: false,
      deleteDialog: false,
      activeOnly: false,
      loading: false,
      saving: false,
      search: '',
      dateFormat: 'yyyy-MM-dd',
      timeFormat: 'HH:mm',
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Description',
          value: 'attributes.description',
        },
        {
          text: 'Title',
          value: 'attributes.userTitle',
        },
        {
          text: 'Subtitle',
          value: 'attributes.userSubtitle',
        },
        {
          text: 'URL',
          value: 'attributes.url',
        },
        {
          text: 'Created At',
          value: 'attributes.createdAt',
        },
        { text: 'Actions', value: 'action', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      allMessages: [],
      allContent: [],
      editedIndex: -1,
      editedItem: null,
      defaultContent: {
        userTitle: { defaultValue: null },
        userSubtitle: { defaultValue: null },
        url: { defaultValue: null },
      },
      expanded: [],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex == -1 ? 'New Item' : `Edit Item`;
    },
    partnerList() {
      return this.$store.getters.partnerList;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    deleteDialog(val) {
      val || this.close();
    },
  },
  created() {
    this.refreshInboxMessages();
  },
  methods: {
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'inbox-message-write']);
    },
    formatDate: function (str) {
      return str ? OneDropUtils.displayDate(str) : str;
    },
    refreshInboxMessages: function () {
      ConsoleLog('Fetching news messages');
      this.loading = true;
      OneDropApi.getAllResources(
        'inbox-message',
        (response) => {
          this.allMessages = response.data;
          ConsoleLog(`Got ${this.allMessages.length} inbox messages`);
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    mounted: function () {
      this.refreshInboxMessages();
    },
    itemToEdited: function (item) {
      return JSON.parse(JSON.stringify(item));
    },
    onNewItem: function () {
      this.editedItem = {
        type: 'inbox-message',
        attributes: {
          description: null,
          userTitle: null,
          userSubtitle: null,
          url: null,
        },
      };
    },
    editItem: function (item) {
      ConsoleLog('editItem', item);
      this.editedIndex = this.allMessages.indexOf(item);
      this.editedItem = this.itemToEdited(item);
      ConsoleLog(`editedItem ${JSON.stringify(this.editedItem)}`);
      this.dialog = true;
    },
    deleteItem: function (item) {
      ConsoleLog('deleteItem', item);
      this.editedIndex = this.allMessages.indexOf(item);
      this.editedItem = item;
      this.deleteDialog = true;
    },
    close() {
      this.editedItem = null;
      this.dialog = false;
      this.deleteDialog = false;
      this.editedIndex = -1;
      this.saving = false;
    },
    getPrefix(id) {
      return `inbox-message.${id}.`;
    },
    save() {
      this.saving = true;
      let index = this.editedIndex;
      ConsoleLog(`Saving item ${JSON.stringify(this.editedItem)}`);
      let id = index > -1 ? this.editedItem.id : null;
      let body = {
        data: [
          {
            id: id,
            type: 'inbox-message',
            attributes: {
              description: this.editedItem.attributes.description,
            },
          },
        ],
      };
      ConsoleLog(`Saving item ${index}: ${JSON.stringify(body)}`);
      if (this.editedIndex > -1) {
        OneDropApi.patch(
          '/admin/v3/inbox-message',
          body,
          () => {
            this.saveContent(id, this.editedItem);
          },
          (error) => {
            ConsoleLog(error);
            this.saving = false;
            this.onApiError(error);
          }
        );
      } else {
        OneDropApi.post(
          '/admin/v3/inbox-message',
          body,
          (response) => {
            let newId = response.data[0].id;
            this.saveContent(newId, this.editedItem);
          },
          (error) => {
            ConsoleLog(error);
            this.saving = false;
            this.onApiError(error);
          }
        );
      }
    },
    saveContent(id, item) {
      let data = [];
      const titles = ['userTitle', 'userSubtitle', 'url'];
      titles.forEach((k) => {
        let ar = {};
        ar.code = k;
        ar.content = item.attributes[k];
        ar.language = 'default';
        ar.resourceId = id;
        ar.resourceType = 'inbox-message';
        if (ar.content)
          data.push({ type: 'content-resource-group', attributes: ar });
      });
      let body = {
        data: data,
      };
      ConsoleLog(`Saving content ${JSON.stringify(body)}`);
      OneDropApi.patch(
        `/v3/admin/content-by-resource-id`,
        body,
        () => {
          this.refreshInboxMessages();
          this.saving = false;
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.saving = false;
          this.onApiError(error);
          this.close();
        }
      );
    },
    deleteInboxMessage() {
      this.saving = true;
      let item = this.allMessages[this.editedIndex];
      let index = this.editedIndex;
      ConsoleLog('Delete Item', item.id);
      OneDropApi.del(
        `/admin/v3/inbox-message/${item.id}`,
        null,
        () => {
          this.allMessages.splice(index, 1);
          this.saving = false;
          this.toast(`Item ${item.id} deleted successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.saving = false;
          this.onApiError(error);
        }
      );
    },
    rowClicked(item, options) {
      options.expand(!options.isExpanded);
    },
  },
};
</script>

<style></style>
