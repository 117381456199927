<!--Display track events for a specific user-->
<template>
  <v-container fluid>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :search="search"
      dense
    >
      <template #top>
        <v-toolbar flat color="white">
          <v-text-field
            ref="searchText"
            v-model="search"
            label="Search"
            hide-details
          />
          <v-spacer />
        </v-toolbar>
      </template>
      <template #item.attributes.eventDate="{ item }">
        <div class="text-body-2">
          {{ displayDate(item.attributes.eventDate) }}
        </div>
      </template>
      <template #item.attributes.trackId="{ item }">
        <router-link
          :to="{
            path: '/content/trackeditor',
            query: { query: item.attributes.trackId },
          }"
          target="_blank"
        >
          {{ item.attributes.trackId }}
        </router-link>
      </template>
      <template #item.attributes.sourceTrackId="{ item }">
        <div v-if="item.attributes.sourceTrackId">
          {{ item.attributes.sourceTrackTitle }} ({{
            item.attributes.sourceTrackId
          }})
        </div>
      </template>
      <template #item.attributes.sourceCoachId="{ item }">
        <div v-if="item.attributes.sourceCoachId">
          {{ item.attributes.sourceCoachName }} ({{
            item.attributes.sourceCoachId
          }})
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { OneDropApi } from '@/onedrop-api';
import { ConsoleLog } from '@/onedrop-utils';
import { format } from 'date-fns';

export default {
  name: 'UserTrackEventList',
  components: {},
  props: ['userId'],
  data: function () {
    return {
      loading: false,
      search: null,
      headers: [
        {
          text: 'Event Type',
          value: 'attributes.eventType',
        },
        {
          text: 'Event Date',
          value: 'attributes.eventDate',
          width: '200px',
        },
        {
          text: 'Track Id',
          value: 'attributes.trackId',
        },
        {
          text: 'Track Title',
          value: 'attributes.trackTitle',
        },
        {
          text: 'Coach Id',
          value: 'attributes.coachId',
        },
        {
          text: 'Coach Name',
          value: 'attributes.coachName',
        },
        {
          text: 'Day',
          value: 'attributes.day',
        },
        {
          text: 'Source Track Id',
          value: 'attributes.sourceTrackId',
        },
        {
          text: 'Source Coach Id',
          value: 'attributes.sourceCoachId',
        },
        {
          text: 'Source Day',
          value: 'attributes.sourceDay',
        },
      ],
      items: [],
    };
  },
  watch: {
    userId: function () {
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.loading = true;
      this.items = [];
      OneDropApi.get(
        `v3/admin/track-event/user/${this.userId}`,
        (resp) => {
          this.items = resp.data;
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    displayDate: function (isoDate) {
      const date = new Date(isoDate);
      return format(date, 'yyyy-MM-dd h:mm:ss a');
    },
  },
};
</script>

<style></style>
