<template>
  <v-container fluid>
    <v-layout class="display-1 py-4">
      User Search
      <v-icon
        class="ml-2"
        title="Copy Search Link"
        @click="onCopySearchClicked()"
      >
        mdi-content-copy
      </v-icon>
    </v-layout>
    <input id="searchUrl" type="hidden" :value="searchUrl()" />
    <UserList
      ref="userList"
      v-model="query"
      :partner.sync="partner"
      :track-id.sync="trackId"
    >
      <template #actions="slotProps">
        <input
          :id="shareLinkId(slotProps.item.id)"
          type="hidden"
          :value="shareLinkForUserId(slotProps.item.id)"
        />
        <v-menu offset-y>
          <template #activator="{ on }">
            <v-btn fab outlined x-small color="primary" @click.stop v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-2">
            <v-list-item @click="onCopyLinkClicked(slotProps.item.id)">
              <v-icon class="pr-2"> mdi-content-copy </v-icon>
              Copy Link
            </v-list-item>
            <v-list-item @click="onViewCrashReportsClicked(slotProps.item.id)">
              <v-icon class="pr-2"> mdi-bug </v-icon>
              Crash Reports
            </v-list-item>
            <v-list-item
              v-if="hasDeletePerm"
              @click="onViewMergeClicked(slotProps.item)"
            >
              <v-icon class="pr-2"> mdi-account-multiple-plus </v-icon>
              Merge
            </v-list-item>
            <v-list-item
              v-if="hasDeletePerm"
              @click="onViewDeleteClicked(slotProps.item)"
            >
              <v-icon class="pr-2"> mdi-delete </v-icon>
              Delete
            </v-list-item>
            <v-list-item
              v-if="hasAssignRolePerm"
              @click="onAssignRoleClicked(slotProps.item.id)"
            >
              <v-icon class="pr-2"> mdi-account-star </v-icon>
              Assign Roles
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </UserList>

    <!-- Dialogs -->
    <v-dialog
      v-if="itemToDelete"
      v-model="showDeleteDialog"
      width="50%"
      hide-overlay
      transition="dialog-bottom-transition"
      @click:outside="onViewDeleteClosed"
    >
      <DeleteUser
        :user-id="itemToDelete.id"
        :user="itemToDelete"
        :url="currentUrl"
        @cancel="onViewDeleteClosed()"
      />
    </v-dialog>
    <v-dialog
      v-if="assignRoleTo"
      v-model="showAssignRoleDialog"
      width="50%"
      hide-overlay
      transition="dialog-bottom-transition"
      @click:outside="onAssignRoleClosed"
    >
      <UserRoleSelector
        :user-id="assignRoleTo"
        @assign-done="onAssignRoleClosed"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { ConsoleLog, OneDropUtils } from '../onedrop-utils';
import DeleteUser from '../components/DeleteUser';
import UserList from './UserList';
import UserRoleSelector from './UserRoleSelector';

export default {
  name: 'SearchUsers',
  components: { DeleteUser, UserList, UserRoleSelector },
  data: function () {
    return {
      searching: false,
      expand: false,
      showDeleteDialog: false,
      showAssignRoleDialog: false,
      itemToDelete: null,
      assignRoleTo: null,
      query: null,
      partner: null,
      trackId: null,
    };
  },

  computed: {
    hasDeletePerm: function () {
      return OneDropApi.hasPerm('admin-delete');
    },
    hasAssignRolePerm: function () {
      return OneDropApi.hasPerm('assign-role');
    },
    currentUrl: function () {
      let q = {};
      if (this.query) q.query = encodeURIComponent(this.query);
      if (this.partner) q.partner = encodeURIComponent(this.partner);
      if (this.trackId) q.trackId = encodeURIComponent(this.trackId);
      return this.$router.resolve({ path: '/search', query: q }).route.fullPath;
    },
  },

  created: function () {
    if (this.$route.query.query) {
      this.query = decodeURIComponent(this.$route.query.query).trim();
    }
    if (this.$route.query.partner) {
      this.partner = decodeURIComponent(this.$route.query.partner);
    }
  },

  mounted: function () {
    if (this.$route.query.query) {
      this.query = decodeURIComponent(this.$route.query.query).trim();
    }
    if (this.$route.query.partner) {
      this.partner = decodeURIComponent(this.$route.query.partner);
    }
    if (this.$route.query.trackId) {
      this.trackId = decodeURIComponent(this.$route.query.trackId);
    }
  },

  methods: {
    onViewCrashReportsClicked: function (userId) {
      window.open(OneDropUtils.crashReportURIForUser(userId), '_blank');
    },

    onViewDeleteClicked: function (item) {
      // ConsoleLog(`Parameters: ${this.query}`)
      // let url=this.query? `/search?query=${encodeURIComponent(this.query)}` : '/search/query'
      // // Add this url to the query string
      // this.$router.push({name: 'delete', params: {userId: item.id}, query: {url: url}})
      this.itemToDelete = item;
      this.showDeleteDialog = true;
    },

    onAssignRoleClicked: function (item) {
      this.assignRoleTo = item;
      this.showAssignRoleDialog = true;
    },

    onViewDeleteClosed: function () {
      this.itemToDelete = null;
      this.showDeleteDialog = false;
    },

    onViewMergeClicked: function (item) {
      // Add this url to the query string
      this.$router.push({
        name: 'merge',
        params: { userId: item.id },
        query: { url: this.currentUrl },
      });
    },

    searchUrl() {
      let path = this.currentUrl;
      const protocol = window.location.protocol;
      const host = window.location.host;
      return `${protocol}//${host}/#${path}`;
    },

    onCopySearchClicked: function () {
      const copiedText = this.copyToClipboard('searchUrl');
      this.toast('Copied: ' + copiedText);
    },

    onAssignRoleClosed: function (userId) {
      this.assignRoleTo = null;
      this.showAssignRoleDialog = false;
      if (userId) this.$refs.userList.userUpdated(userId);
    },

    onDeleteDone: function (item) {
      this.$refs.userList.userDeleted(item);
    },

    onCopyLinkClicked: function (userId) {
      ConsoleLog(`Copied ${userId}`);
      const copiedText = this.copyToClipboard('hiddenUserLink' + userId);
      this.toast('Copied: ' + copiedText);
    },

    shareLinkId: function (userId) {
      return 'hiddenUserLink' + userId;
    },

    shareLinkForUserId: function (userId) {
      const protocol = window.location.protocol;
      const host = window.location.host;
      return `${protocol}//${host}/#/user/${userId}`;
    },

    displayDate: function (isoDate) {
      return OneDropUtils.displayDate(isoDate);
    },
  },
};
</script>

<style scoped></style>
