<template>
  <v-row no-gutters>
    <v-col v-if="subscription && subscription.entitlements">
      <v-row no-gutters>
        <v-col class="od-heading">
          <h2>Entitlements</h2>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-simple-table dense>
          <template #default>
            <tbody>
              <tr
                v-for="entitlement in subscription.entitlements"
                :key="entitlement.entitlement.key"
              >
                <td class="subtitle-2">
                  {{ entitlement.entitlement.name }}
                </td>
                <td class="caption">
                  {{ expirationDateForEntitlement(entitlement) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-row>
    </v-col>
    <v-col v-else class="grey--text"> No Subscription </v-col>
  </v-row>
</template>

<script>
import { OneDropUtils } from '../onedrop-utils';

export default {
  name: 'EntitlementDetails',
  props: ['subscription'],
  data: function () {
    return {
      track: null,
      trackError: null,
    };
  },

  methods: {
    displayDate: function (isoDate) {
      return OneDropUtils.displayDate(isoDate);
    },

    expirationDateForEntitlement: function (entitlement) {
      if (entitlement.expiration === null) {
        return 'never expires';
      } else {
        return `expires ${this.displayDate(entitlement.expiration)}`;
      }
    },
  },
};
</script>

<style scoped></style>
