import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import vueDebounce from 'vue-debounce';
import VueMeta from 'vue-meta';
import '@/assets/style.css';
import { ConsoleLog } from './onedrop-utils';
import { OneDropApi } from './onedrop-api';
import IdleVue from 'idle-vue';

const eventsHub = new Vue();

Vue.config.productionTip = false;

Vue.use(vueDebounce);

Vue.use(VueMeta);

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 3600000, // 1 hour
  startAtIdle: false,
});

Vue.mixin({
  created() {},
  methods: {
    toast: function (message) {
      this.$store.commit('setInfoMessage', message);
      this.$store.commit('setIsInfoMessageVisible', true);
    },

    onApiError(error) {
      ConsoleLog(error);
      if (error.response) {
        ConsoleLog(error.response.data);
        ConsoleLog(error.response.status);
        this.$store.commit(
          'setErrorMessage',
          error.response.status +
            ': ' +
            JSON.stringify(error.response.data.errors)
        );
      } else {
        this.$store.commit(
          'setErrorMessage',
          'Something went wrong :(. ' + error
        );
      }
      this.$store.commit('setIsErrorMessageVisible', true);
    },

    // returns copied text
    copyToClipboard(elementId) {
      const element = document.querySelector('#' + elementId);
      element.setAttribute('type', 'text');
      element.select();
      document.execCommand('copy');
      element.setAttribute('type', 'hidden');
      return element.value;
    },

    signOut(nextUrl, error) {
      /*
      Sign this user out
      If the nextUrl or error is passed, pass it along
       */
      if (store.state.user !== null) {
        let router = this.$router;
        OneDropApi.logout();
        ConsoleLog(
          `Signing out with nextUrl=${nextUrl}, error=${error}, currentPath=${router.currentRoute.fullPath}`
        );
        router.push({
          name: 'login',
          params: { nextUrl: nextUrl, priorError: error },
        });
      }
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
