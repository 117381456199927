<template>
  <v-card flat>
    <v-data-table
      v-model="selectedPartners"
      :items="partners"
      :headers="headers"
      :loading="loading"
      :search="search"
      multi-sort
      show-select
    >
      <template #top>
        <v-toolbar flat color="white">
          <v-text-field v-model="search" label="Search" hide-details outlined />
          <v-spacer />
          <v-btn
            v-if="hasWritePerm()"
            color="primary"
            :disabled="versionButtonDisabled"
            class="mb-2 mr-2"
            @click="onVersionUpdate"
          >
            Edit
            {{
              selectedPartners && selectedPartners.length > 0
                ? selectedPartners.length
                : ''
            }}
            Partners
          </v-btn>
          <v-btn
            v-if="hasWritePerm()"
            color="primary"
            dark
            :loading="loading"
            :disabled="loading"
            class="mb-2 mr-2"
            @click="onAddPartners"
          >
            Add Partners
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.id="{ item }">
        <router-link
          :to="{ path: '/setup/partnereditor', query: { query: item.id } }"
          target="_blank"
        >
          {{ item.id }}
        </router-link>
      </template>
      <template #item.action="{ item }">
        <v-icon v-if="hasWritePerm()" @click.stop="onEditPartner(item)">
          mdi-pencil
        </v-icon>
        <v-icon v-if="hasWritePerm()" @click.stop="onDeletePartner(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <div>
      <v-dialog v-model="editItemDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Add Partners</span>
          </v-card-title>

          <v-card-text>
            <v-autocomplete
              v-model="addedPartners"
              :items="filteredPartners"
              label="Partner"
              item-text="name"
              item-value="id"
              hide-selected
              multiple
              chips
              deletable-chips
              :rules="rules.partnersRequired"
            />
            <v-select
              v-model="selectedVersion"
              :items="versions"
              label="Version"
              item-text="attributes.version"
              item-value="id"
              hide-selected
              chips
              deletable-chips
              :rules="rules.versionRequired"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text :loading="loading" @click="close">
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="addPartnersSaveButtonDisabled"
              @click="doSave"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="modifyVersionDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Modify Version</span>
          </v-card-title>

          <v-card-text>
            <div>Modify version for {{ selectedPartners.length }} partners</div>
            <v-select
              v-model="selectedVersion"
              :items="versions"
              label="Version"
              item-text="attributes.version"
              item-value="id"
              hide-selected
              chips
              deletable-chips
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text :loading="loading" @click="close">
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              @click="doUpdateVersion"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteItemDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Delete Partner</span>
          </v-card-title>

          <v-card-text>
            <div v-if="deletedPartner" class="pb-2">
              <span class="od-label">Partner:</span
              ><span class="pl-2">{{ deletedPartner.name }}</span>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text :loading="loading" @click="close">
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              @click="doDelete"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import { OneDropApi } from '../../onedrop-api';
import { ConsoleLog } from '../../onedrop-utils';

export default {
  name: 'TrackPartner',
  components: {},
  props: ['trackId'],
  data: function () {
    return {
      partners: [],
      versions: [],
      search: '',
      loading: false,
      editItemDialog: false,
      deleteItemDialog: false,
      modifyVersionDialog: false,
      headers: [
        {
          text: 'Id',
          value: 'id',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: '# Users',
          value: 'attributes.userCount',
        },
        {
          text: 'Version',
          value: 'attributes.scheduleVersion',
        },
        { text: 'Actions', value: 'action', sortable: false },
      ],
      addedPartners: [],
      selectedVersion: null,
      deletedPartner: null,
      selectedPartners: [],
      rules: {
        versionRequired: [(v) => v !== null || 'Version is required'],
        partnersRequired: [(v) => v.length > 0 || 'Partner is required'],
      },
    };
  },
  computed: {
    allPartners() {
      return this.$store.getters.partnerList;
    },
    filteredPartners() {
      return this.allPartners.filter(
        (v) => this.partners.findIndex((p) => p.id === v.id) < 0
      );
    },
    versionButtonDisabled() {
      return !(this.selectedPartners && this.selectedPartners.length > 0);
    },
    addPartnersSaveButtonDisabled() {
      return !(this.addedPartners.length > 0 && this.selectedVersion !== null);
    },
  },
  watch: {
    trackId: function () {
      this.refreshPartners();
    },
  },
  created() {
    this.refreshPartners();
  },
  methods: {
    hasWritePerm() {
      return (
        OneDropApi.hasOneOfPerms(['admin-write', 'question-write']) &&
        !this.readOnly
      );
    },
    getPartnerById: function (id) {
      return this.$store.getters.partnerById(id);
    },
    getPartnerName: function (id) {
      if (id) {
        const p = this.getPartnerById(id);
        return p ? p.attributes.name : null;
      }
      return null;
    },
    refreshPartners: function () {
      this.partners = [];
      if (this.trackId) {
        this.loading = true;
        ConsoleLog(`Loading partners for group ${this.trackId}`);
        this.fetchPartners();
      }
    },
    refreshVersions: function () {
      this.versions = [];
      this.loading = true;
      OneDropApi.get(
        `/v3/admin/track-schedule-versions?track-id=${this.trackId}`,
        (response) => {
          this.versions = response.data;
          this.loaded();
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loaded();
        }
      );
    },
    fetchPartners: function () {
      OneDropApi.get(
        `/v3/admin/resource/track/${this.trackId}/partners`,
        (response) => {
          this.updatePartners(response.data);
          ConsoleLog(`Partners: ${JSON.stringify(this.partners)}`);
          this.loaded();
          this.refreshVersions();
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loaded();
        }
      );
    },
    updatePartners(data) {
      this.partners = data;
      this.partners.map((v) => (v.name = this.getPartnerName(v.id)));
    },
    loaded: function () {
      this.loading = false;
    },
    close: function () {
      this.loading = false;
      this.editItemDialog = false;
      this.deleteItemDialog = false;
      this.modifyVersionDialog = false;
      this.addedPartners = [];
    },
    doSave: function () {
      const list = this.addedPartners.map((p) => {
        return {
          id: p,
          type: 'track-partner',
          attributes: { scheduleVersionId: this.selectedVersion },
        };
      });
      let body = { data: list };
      this.loading = true;
      OneDropApi.patch(
        `/v3/admin/resource/track/${this.trackId}/partners`,
        body,
        (response) => {
          this.updatePartners(response.data);
          this.toast(`Partners added successfully`);
          this.$emit('partnerChanged', this.trackId);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.onApiError(error);
          this.loading = false;
        }
      );
    },
    doUpdateVersion: function () {
      const list = this.selectedPartners.map((p) => {
        return {
          id: p.id,
          type: 'track-partner',
          attributes: { scheduleVersionId: this.selectedVersion },
        };
      });
      let body = { data: list };
      this.loading = true;
      OneDropApi.patch(
        `/v3/admin/resource/track/${this.trackId}/partners`,
        body,
        (response) => {
          this.updatePartners(response.data);
          this.toast(`Partners added successfully`);
          this.$emit('partnerChanged', this.trackId);
          this.selectedPartners = [];
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.onApiError(error);
          this.loading = false;
        }
      );
    },
    doDelete: function () {
      const p = this.deletedPartner;
      let body = { data: [p] };
      this.loading = true;
      OneDropApi.del(
        `/v3/admin/resource/track/${this.trackId}/partners`,
        body,
        (response) => {
          this.updatePartners(response.data);
          this.toast(`Partners deleted successfully`);
          this.$emit('partnerChanged', this.trackId);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.onApiError(error);
          this.loading = false;
        }
      );
    },
    onVersionUpdate: function () {
      this.selectedVersion = null;
      this.modifyVersionDialog = true;
    },
    onAddPartners: function () {
      this.addedPartners = [];
      this.selectedVersion = null;
      this.editItemDialog = true;
    },
    onEditPartner: function (item) {
      this.selectedPartners = [item];
      this.selectedVersion = item.attributes.scheduleVersionId;
      this.modifyVersionDialog = true;
    },
    onDeletePartner: function (item) {
      this.deletedPartner = item;
      this.deleteItemDialog = true;
    },
  },
};
</script>

<style scoped></style>
