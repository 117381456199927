<template>
  <v-card flat>
    <v-data-table
      :items="partners"
      :headers="headers"
      :loading="loading"
      :search="search"
      multi-sort
    >
      <template #top>
        <v-toolbar flat color="white">
          <v-text-field v-model="search" label="Search" hide-details outlined />
          <v-spacer />
          <v-btn
            v-if="hasWritePerm()"
            color="primary"
            dark
            :loading="loading"
            :disabled="loading"
            class="mb-2 mr-2"
            @click="onAddPartners"
          >
            Add Partners
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.action="{ item }">
        <v-icon v-if="hasWritePerm()" @click.stop="onDeletePartner(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <div>
      <v-dialog v-model="editItemDialog" max-width="50%">
        <v-card>
          <v-card-title>
            <span class="headline">Add Partners</span>
          </v-card-title>

          <v-card-text>
            <v-autocomplete
              v-model="addedPartners"
              :items="filteredPartners"
              label="Partner"
              :item-text="partnerName"
              item-value="id"
              hide-selected
              multiple
              chips
              deletable-chips
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              :disabled="loading"
              @click="close"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              @click="doSave"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteItemDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Delete Partner</span>
          </v-card-title>

          <v-card-text>
            <div v-if="deletedPartner" class="pb-2">
              <span class="od-label">Partner:</span
              ><span class="pl-2">{{ deletedPartner.name }}</span>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              :disabled="loading"
              @click="close"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              @click="doDelete"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import { OneDropApi } from '../../onedrop-api';
import { ConsoleLog } from '../../onedrop-utils';

export default {
  name: 'GroupPartner',
  components: {},
  props: ['groupId'],
  data: function () {
    return {
      partners: [],
      search: '',
      loading: false,
      editItemDialog: false,
      deleteItemDialog: false,
      headers: [
        {
          text: 'Id',
          value: 'id',
        },
        {
          text: 'Name',
          value: 'name',
        },
        { text: 'Actions', value: 'action', sortable: false },
      ],
      addedPartners: [],
      deletedPartner: null,
    };
  },
  computed: {
    allPartners() {
      return this.$store.getters.partnerList;
    },
    filteredPartners() {
      return this.allPartners.filter(
        (v) => this.partners.findIndex((p) => p.id === v.id) < 0
      );
    },
  },
  watch: {
    groupId: function () {
      this.refreshPartners();
    },
  },
  created() {
    this.refreshPartners();
  },
  methods: {
    hasWritePerm() {
      return (
        OneDropApi.hasOneOfPerms(['admin-write', 'question-write']) &&
        !this.readOnly
      );
    },
    getPartnerById: function (id) {
      return this.$store.getters.partnerById(id);
    },
    getPartnerName: function (id) {
      if (id) {
        const p = this.getPartnerById(id);
        return p ? p.attributes.name : null;
      }
      return null;
    },
    partnerName: (item) => `${item.name} (${item.id})`,
    refreshPartners: function () {
      this.partners = [];
      if (this.groupId) {
        this.loading = true;
        ConsoleLog(`Loading partners for group ${this.groupId}`);
        this.fetchPartners();
      }
    },
    fetchPartners: function () {
      ConsoleLog(`Fetching answers`);
      this.loading = true;
      OneDropApi.getQuestionPartners(
        this.groupId,
        (response) => {
          this.updatePartners(response.data);
          ConsoleLog(`Partners: ${JSON.stringify(this.partners)}`);
          this.loaded();
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loaded();
        }
      );
    },
    updatePartners(data) {
      this.partners = data;
      this.partners.map((v) => (v.name = this.getPartnerName(v.id)));
    },
    loaded: function () {
      this.loading = false;
    },
    close: function () {
      this.loading = false;
      this.editItemDialog = false;
      this.deleteItemDialog = false;
    },
    doSave: function () {
      const list = this.addedPartners.map((p) => {
        return { id: p, type: 'question-group-partner' };
      });
      let body = { data: list };
      this.loading = true;
      OneDropApi.insertQuestionPartners(
        this.groupId,
        body,
        (response) => {
          this.updatePartners(response.data);
          this.toast(`Partners added successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.onApiError(error);
          this.loading = false;
        }
      );
    },
    doDelete: function () {
      const p = this.deletedPartner;
      let body = { data: [p] };
      this.loading = true;
      OneDropApi.deleteQuestionPartners(
        this.groupId,
        body,
        (response) => {
          this.updatePartners(response.data);
          this.toast(`Partners deleted successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.onApiError(error);
          this.loading = false;
        }
      );
    },
    onAddPartners: function () {
      this.addedPartners = [];
      this.editItemDialog = true;
    },
    onDeletePartner: function (item) {
      this.deletedPartner = item;
      this.deleteItemDialog = true;
    },
  },
};
</script>

<style scoped></style>
