<template>
  <v-data-table
    :items="items"
    :headers="computedHeaders"
    :loading="loading"
    :search="search"
    :sort-by="['attributes.createdAt']"
    :sort-desc="[true]"
    show-expand
    single-expand
  >
    <template v-if="!eventType && !objectId" #top>
      <v-toolbar flat color="white" class="mb-4">
        <v-text-field
          v-model="search"
          label="Search"
          hide-details
          outlined
          class="mb-4"
        />
        <v-spacer />
        <v-select
          v-model="selectedEvents"
          :items="eventTypes"
          label="Event Types"
          hide-selected
          chips
          deletable-chips
          multiple
        />
        <v-spacer />
        <v-menu
          ref="dateMenu"
          v-model="startDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="290px"
          max-width="290px"
          offset-y
        >
          <template #activator="{ on }">
            <v-text-field
              v-model="startDate"
              label="Start Date"
              prepend-inner-icon="mdi-calendar"
              hint="YYYY-MM-DD"
              persistent-hint
              outlined
              class="mr-2"
              clearable
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="startDate"
            no-title
            @input="startDateMenu = false"
          />
        </v-menu>
        <v-menu
          ref="dateMenu"
          v-model="endDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="290px"
          max-width="290px"
          offset-y
        >
          <template #activator="{ on }">
            <v-text-field
              v-model="endDate"
              label="End Date"
              prepend-inner-icon="mdi-calendar"
              hint="YYYY-MM-DD"
              persistent-hint
              outlined
              clearable
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="endDate"
            no-title
            @input="endDateMenu = false"
          />
        </v-menu>
        <v-spacer />
      </v-toolbar>
    </template>
    <template #item.attributes.createdAt="{ item }">
      <span>{{ formatDate(item.attributes.createdAt) }}</span>
    </template>
    <template #expanded-item="{ item, headers }">
      <td :colspan="headers.length">
        <v-tabs>
          <v-tab
            v-if="item.attributes.beforeJson || item.attributes.afterJson"
            key="1"
          >
            {{ contentTitle(item) }}
          </v-tab>
          <v-tab v-if="item.attributes.payload" key="2"> Payload </v-tab>
          <v-tab-item key="1">
            <div v-if="item.attributes.beforeJson && item.attributes.afterJson">
              <monaco-diff
                class="diffEditor"
                language="json"
                :options="monacoReadOnlyOpts"
                :orig="item.attributes.beforeJson"
                :modified="item.attributes.afterJson"
              />
            </div>
            <div v-else-if="item.attributes.beforeJson">
              <monaco
                v-model="item.attributes.beforeJson"
                class="editor fill-height"
                language="json"
                :options="monacoReadOnlyOpts"
              />
            </div>
            <div v-else-if="item.attributes.afterJson">
              <monaco
                v-model="item.attributes.afterJson"
                class="editor fill-height"
                language="json"
                :options="monacoReadOnlyOpts"
              />
            </div>
          </v-tab-item>
          <v-tab-item key="2">
            <div v-if="item.attributes.payload">
              <monaco
                v-model="item.attributes.payload"
                class="editor fill-height"
                language="json"
                :options="monacoReadOnlyOpts"
              />
            </div>
          </v-tab-item>
        </v-tabs>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { ConsoleLog, OneDropUtils } from '@/onedrop-utils';
import Monaco from '@/components/Monaco';
import MonacoDiff from '@/components/MonacoDiff';

export default {
  name: 'AuditLogViewer',
  components: { Monaco, MonacoDiff },
  props: ['eventType', 'objectId'],
  data: function () {
    return {
      items: [],
      loading: false,
      search: '',
      eventTypes: [],
      selectedEvents: [],
      startDateMenu: false,
      endDateMenu: false,
      startDate: null,
      endDate: null,
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Event Type',
          value: 'attributes.eventType',
          filter: (value) =>
            this.selectedEvents.length > 0
              ? this.selectedEvents.includes(value)
              : true,
        },
        {
          text: 'User ID',
          value: 'attributes.userId',
        },
        {
          text: 'User Email',
          value: 'attributes.email',
        },
        {
          text: 'Modified Id',
          value: 'attributes.modifiedId',
        },
        {
          text: 'Event Date',
          value: 'attributes.createdAt',
          filter: (value) => {
            const getStringDate = value.split('T')[0];
            return (
              (this.startDate !== null ? this.startDate : '1900-12-31') <=
                getStringDate &&
              getStringDate <=
                (this.endDate !== null ? this.endDate : '2100-12-31')
            );
          },
        },
        { text: '', value: 'data-table-expand' },
      ],
      monacoReadOnlyOpts: {
        readOnly: true,
        wordWrap: 'on',
        minimap: { enabled: false },
        renderIndentGuides: false,
      },
    };
  },
  computed: {
    computedHeaders() {
      return !this.eventType && !this.objectId
        ? this.headers
        : this.headers.filter(
            (header) => header.value !== 'attributes.modifiedId'
          );
    },
  },
  watch: {
    objectId: function () {
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh: function () {
      this.loading = true;
      const urlParams =
        !this.eventType && !this.objectId
          ? ''
          : `type-prefix=${this.eventType}&modified-id=${this.objectId}`;
      OneDropApi.get(
        `v3/admin/audit-logs?${urlParams}`,
        (resp) => {
          this.items = resp.data.map((d) => {
            d.attributes.beforeJson = d.attributes.beforeJson
              ? JSON.stringify(d.attributes.beforeJson, undefined, 2)
              : d.attributes.beforeJson;
            d.attributes.afterJson = d.attributes.afterJson
              ? JSON.stringify(d.attributes.afterJson, undefined, 2)
              : d.attributes.afterJson;
            d.attributes.payload = d.attributes.payload
              ? JSON.stringify(d.attributes.payload, undefined, 2)
              : d.attributes.payload;
            return d;
          });
          this.eventTypes = [
            ...new Set(resp.data.map((d) => d.attributes.eventType)),
          ];
          this.loading = false;
        },
        (error) => {
          ConsoleLog(error);
          this.loading = false;
          this.onApiError(error);
        }
      );
    },
    formatDate: function (str) {
      return str ? OneDropUtils.displayDate(str) : str;
    },
    contentTitle: function (item) {
      if (item.attributes.beforeJson && item.attributes.afterJson)
        return 'Diff Viewer';
      else if (item.attributes.beforeJson) return 'Before Event';
      else if (item.attributes.afterJson) return 'After Event';
    },
  },
};
</script>

<style scoped>
pre .string {
  color: green;
}

.number {
  color: darkorange;
}

.boolean {
  color: blue;
}

.null {
  color: magenta;
}

.key {
  color: red;
}

.editor {
  min-height: 400px;
}

.diffEditor {
  min-height: 400px;
  height: 400px;
}

body {
  overflow: hidden;
}
</style>
