<template>
  <v-container fluid>
    <v-layout class="display-1 py-4"> Manage User Query </v-layout>
    <user-query-list />
  </v-container>
</template>

<script>
import UserQueryList from '@/components/UserQueryList';

export default {
  name: 'UserQueryEditor',
  components: { UserQueryList },
};
</script>

<style scoped></style>
