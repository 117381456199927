import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import User from '../views/User';
import Playground from '../views/Playground';
import TableCounts from '../views/TableCounts';
import PartnerEditor from '../views/PartnerEditor';
import NewsMessageEditor from '../views/NewsMessageEditor';
import MedsEditor from '../views/MedsEditor';
import TrackEditor from '../views/TrackEditor';
import LessonEditor from '../views/LessonEditor';
import GenericContentEditor from '../views/GenericContentEditor';
import QuestionView from '@/views/QuestionView';
import QuestionGroupList from '@/components/question/QuestionGroupList';
import QuestionList from '@/components/question/QuestionList';
import InboxMessageEditor from '../views/InboxMessageEditor';
import EligibilityEditor from '../views/EligibilityEditor';
import ConfigEditor from '../views/ConfigEditor';
import Config from '../components/config/Config';
import Options from '../components/config/Options';
import Search from '../views/Search';
import Login from '../views/Login';
import Main from '../views/Main';
import ContentNav from '../components/ContentNav';
import CoachEditor from '../views/CoachEditor';
import store from '../store';
import { OneDropUtils, ConsoleLog } from '../onedrop-utils';
import MergeUserView from '@/views/MergeUserView';
import JobStatusView from '@/views/JobStatusView';
import ResourceContentEditor from '@/views/ResourceContentEditor';
import UserQueryEditor from '@/views/UserQueryEditor';
import AmazonS3Editor from '@/views/AmazonS3Editor';
import SkuEntitlementEditor from '@/views/SkuEntitlementEditor';
import EmployerNav from '@/components/EmployerNav';
import PartnerContractEditor from '@/views/PartnerContractEditor';
import EmployerProgramPricingEditor from '@/views/EmployerProgramPricingEditor';
import FeatureEditor from '@/views/FeatureEditor';
import Audits from '@/views/Audits';
import { OneDropApi } from '../onedrop-api';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Main,
    children: [
      {
        path: '',
        component: Home,
        meta: {
          requiresAuth: true,
          permissions: ['admin-read', 'admin-user-read'],
        },
        children: [
          {
            path: 'search',
            name: 'search',
            component: Search,
            meta: { permissions: ['admin-read', 'admin-user-read'] },
          },
          {
            path: 'employer',
            component: EmployerNav,
            meta: { permissions: ['eligibility-read'] },
            children: [
              {
                path: 'eligibility',
                name: 'eligibility',
                component: EligibilityEditor,
                meta: { permissions: ['eligibility-read'] },
              },
              {
                path: 'partner-contract',
                name: 'partner-contract',
                component: PartnerContractEditor,
                meta: { permissions: ['employer-read'] },
              },
              {
                path: 'employer-program-pricing',
                name: 'employer-program-pricing',
                component: EmployerProgramPricingEditor,
                meta: { permissions: ['employer-read'] },
              },
            ],
          },
          {
            path: 'content',
            component: ContentNav,
            meta: { permissions: ['admin-read', 'news-message-read'] },
            children: [
              {
                path: 'newseditor',
                name: 'newseditor',
                component: NewsMessageEditor,
                meta: { permissions: ['admin-read', 'news-message-read'] },
              },
              {
                path: 'trackeditor',
                name: 'trackeditor',
                component: TrackEditor,
                meta: { permissions: ['admin-read', 'track-read'] },
              },
              {
                path: 'lessoneditor',
                name: 'lessoneditor',
                component: LessonEditor,
                meta: { permissions: ['admin-read', 'lesson-read'] },
              },
              {
                path: 'inboxmessageeditor',
                name: 'inboxmessageeditor',
                component: InboxMessageEditor,
                meta: { permissions: ['admin-read', 'inbox-message-read'] },
              },
              {
                path: 'localization',
                name: 'localization',
                component: GenericContentEditor,
                meta: { permissions: ['admin-read', 'content-read'] },
              },
              {
                path: 'rescontenteditor',
                name: 'rescontenteditor',
                component: ResourceContentEditor,
                meta: { permissions: ['admin-read', 'content-read'] },
              },
            ],
          },
          {
            path: 'setup',
            component: ContentNav,
            meta: { permissions: ['admin-read', 'partner-read'] },
            children: [
              {
                path: 'partnereditor',
                name: 'partnereditor',
                component: PartnerEditor,
                meta: { permissions: ['admin-read', 'partner-read'] },
              },
              {
                path: 'medseditor',
                name: 'medseditor',
                component: MedsEditor,
                meta: { permissions: ['admin-read', 'med-read'] },
              },
              {
                path: 'questioneditor',
                component: QuestionView,
                meta: { permissions: ['question-read'] },
                children: [
                  {
                    path: '',
                    component: QuestionGroupList,
                    meta: { permissions: ['question-read'] },
                  },
                  {
                    path: 'questions',
                    component: QuestionList,
                    meta: { permissions: ['question-read'] },
                  },
                ],
              },
              {
                path: 'configeditor',
                component: ConfigEditor,
                meta: { permissions: ['config-read'] },
                children: [
                  {
                    path: '',
                    component: Config,
                    meta: { permissions: ['config-read'] },
                  },
                  {
                    path: 'options',
                    component: Options,
                    meta: { permissions: ['config-read'] },
                  },
                ],
              },
              {
                path: 'coacheditor',
                name: 'coacheditor',
                component: CoachEditor,
                meta: { permissions: ['admin-read', 'coach-admin-read'] },
              },
              {
                path: 'userqueryeditor',
                name: 'userqueryeditor',
                component: UserQueryEditor,
                meta: { permissions: ['admin-read', 'user-query-read'] },
              },
              {
                path: 'awss3editor',
                name: 'awss3editor',
                component: AmazonS3Editor,
                meta: { permissions: ['admin-read', 's3-read'] },
              },
              {
                path: 'skuentitlementeditor',
                name: 'skuentitlementeditor',
                component: SkuEntitlementEditor,
                meta: { permissions: ['admin-read', 'sku-entitlement-read'] },
              },
              {
                path: 'featureeditor',
                name: 'featureeditor',
                component: FeatureEditor,
                meta: { permissions: ['admin-read', 'feature-read'] },
              },
              {
                path: 'audits',
                name: 'audits',
                component: Audits,
                meta: { permissions: ['admin-read'] },
              },
            ],
          },
          {
            path: 'playground',
            name: 'playground',
            component: Playground,
            meta: { permissions: ['playground'] },
          },
          {
            path: 'tablecounts',
            name: 'tablecounts',
            component: TableCounts,
            meta: { permissions: ['table-count-read'] },
          },
        ],
      },
      {
        name: 'login',
        path: 'login',
        component: Login,
        props: true,
        meta: { requiresAuth: false },
      },
      {
        path: 'user/:userId',
        name: 'user',
        component: User,
        meta: { requiresAuth: true },
      },
      {
        path: 'merge/:userId',
        name: 'merge',
        props: true,
        component: MergeUserView,
        meta: { requiresAuth: true },
      },
      {
        path: 'job-status/:jobId',
        name: 'job-status',
        props: true,
        component: JobStatusView,
        meta: { requiresAuth: true },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.state.user == null || !OneDropUtils.isJwtValid()) {
      ConsoleLog(
        `router: Detected invalid credentials. Redirecting to login ${to.fullPath}`
      );
      next({
        name: 'login',
        params: { nextUrl: to.fullPath },
      });
    } else if (
      from.name !== 'login' &&
      to.meta.permissions &&
      !OneDropApi.hasOneOfPerms(to.meta.permissions)
    ) {
      next({
        name: 'user',
        params: { userId: store.state.user.profile.objectId },
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
