<template>
  <v-row no-gutters>
    <v-col v-if="coachId" cols="12">
      <div v-if="coach !== null" class="pa-0">
        <v-row no-gutters>
          <v-col cols="4" class="od-label"> Coach ID </v-col>
          <v-col cols="8">
            {{ coachId }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" class="od-label"> Name </v-col>
          <v-col cols="8">
            {{ coach.attributes.firstName }}
            {{ coach.attributes.lastName }}
          </v-col>
        </v-row>
      </div>
      <v-progress-circular v-else indeterminate color="primary" />
    </v-col>
    <v-col v-else cols="12" class="grey--text"> No Coach </v-col>
  </v-row>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { OneDropUtils } from '../onedrop-utils';

export default {
  name: 'UserCoachDetails',
  props: ['coachId'],
  data: function () {
    return {
      coach: null,
    };
  },

  computed: {
    joinedAt: function () {
      return OneDropUtils.displayDate(this.coach.attributes.createdAt);
    },
  },

  created: function () {
    if (this.coachId) {
      OneDropApi.getCoach(
        this.coachId,
        (result) => {
          this.coach = result.data[0];
        },
        (error) => this.onApiError(error)
      );
    }
  },
};
</script>

<style scoped></style>
