<template>
  <v-container fluid>
    <v-layout class="display-1 py-4"> Manage Partner Program </v-layout>
    <PartnerContractList />
  </v-container>
</template>

<script>
import PartnerContractList from '@/components/PartnerContractList';

export default {
  name: 'SKUEntitlementEditor',
  components: { PartnerContractList },
};
</script>

<style scoped></style>
