<script>
import * as monaco from 'monaco-editor';

export default {
  name: 'Monaco',
  model: {
    event: 'change',
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: 'vs-dark',
    },
    language: String,
    options: Object,
  },
  data: () => ({
    editor: null,
  }),
  watch: {
    options: {
      deep: true,
      handler(options) {
        if (this.editor) {
          const editor = this.getEditor();
          editor.updateOptions(options);
        }
      },
    },

    value(newValue) {
      if (this.editor) {
        const editor = this.getEditor();
        if (newValue !== editor.getValue()) {
          editor.setValue(newValue);
        }
      }
    },

    language(newVal) {
      if (this.editor) {
        const editor = this.getEditor();
        this.monaco.editor.setModelLanguage(editor.getModel(), newVal);
      }
    },

    theme(newVal) {
      if (this.editor) {
        this.monaco.editor.setTheme(newVal);
      }
    },
  },
  mounted() {
    this.initMonaco();
  },
  beforeDestroy() {
    this.editor && this.editor.dispose();
  },
  methods: {
    initMonaco() {
      const options = Object.assign(
        {
          value: this.value,
          theme: this.theme,
          language: this.language,
          automaticLayout: true,
        },
        this.options
      );
      this.editor = monaco.editor.create(this.$el, options);
      this.editor.onDidChangeModelContent((event) => {
        const value = this.editor.getValue();
        if (this.value !== value) {
          this.$emit('change', value, event);
        }
      });
    },
    getEditor() {
      return this.editor;
    },
  },
  render(h) {
    return h('div', {
      style: {
        height: '100%',
        width: '100%',
      },
    });
  },
};
</script>

<style scoped></style>
