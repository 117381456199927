<template>
  <v-data-table
    :loading="loading"
    :headers="headers"
    :items="items"
    :search="search"
  />
</template>

<script>
export default {
  name: 'CodeList',
  props: ['items'],
  data: function () {
    return {
      loading: false,
      search: '',
      headers: [
        {
          text: 'ID',
          value: 'resourceId',
        },
        {
          text: 'Name',
          value: 'resourceName',
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped></style>
