<template>
  <v-container fluid>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :search="search"
      multi-sort
    >
      <template #top>
        <v-container fluid>
          <v-row>
            <v-col cols="4">
              <v-text-field
                ref="searchText"
                v-model="search"
                label="Search"
                hide-details
                outlined
              />
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="selectedPartners"
                :items="partnerList"
                label="Partner"
                :item-text="partnerName"
                item-value="id"
                hide-selected
                chips
                deletable-chips
                multiple
              />
            </v-col>
            <v-col cols="4">
              <v-toolbar flat color="white">
                <v-btn
                  color="primary"
                  class="mr-2"
                  :disabled="loading"
                  @click="refresh"
                >
                  Reload
                </v-btn>
                <v-btn color="primary" :disabled="loading" @click="onNewItem">
                  New Employer program pricing
                </v-btn>
              </v-toolbar>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template #item.action="{ item }">
        <v-icon
          v-if="hasWritePerm()"
          class="mr-2"
          @click.stop="onEditItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon v-if="hasWritePerm()" @click.stop="onDeleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <div>
      <v-dialog v-model="editItemDialog" max-width="60%">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text v-if="editItem">
            <div v-if="editItem.id" class="pb-2">
              <span class="od-label">ID:</span
              ><span class="pl-2">{{ editItem.id }}</span>
            </div>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-autocomplete
                    v-model="editItem.attributes.partnerId"
                    :items="partnerList"
                    label="Partner"
                    :item-text="partnerName"
                    item-value="id"
                    hide-selected
                    chips
                    deletable-chips
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-combobox
                    v-model="editItem.attributes.sku"
                    :items="skus"
                    label="SKU"
                    hide-selected
                    chips
                    deletable-chips
                  />
                </v-col>
                <v-col>
                  <v-combobox
                    v-model="editItem.attributes.dependentSku"
                    :items="skus"
                    label="Dependent SKU (Optional)"
                    hide-selected
                    chips
                    deletable-chips
                  />
                </v-col>
                <v-col>
                  <v-combobox
                    v-model="editItem.attributes.feeType"
                    :items="feeTypes"
                    label="Fee Type"
                    hide-selected
                    chips
                    deletable-chips
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="editItem.attributes.price"
                    type="number"
                    step="any"
                    outlined
                    label="Price"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-menu
                    ref="dateMenu"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="290px"
                    max-width="290px"
                    offset-y
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="editItem.attributes.startDate"
                        label="Start Date"
                        prepend-inner-icon="mdi-calendar"
                        hint="YYYY-MM-DD"
                        persistent-hint
                        outlined
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="editItem.attributes.startDate"
                      no-title
                      @input="startDateMenu = false"
                    />
                  </v-menu>
                </v-col>
                <v-col>
                  <v-menu
                    ref="dateMenu"
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="290px"
                    max-width="290px"
                    offset-y
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="editItem.attributes.endDate"
                        label="End Date"
                        prepend-inner-icon="mdi-calendar"
                        hint="YYYY-MM-DD"
                        persistent-hint
                        outlined
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="editItem.attributes.endDate"
                      no-title
                      @input="endDateMenu = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="onCancel"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteItemDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Delete Employer program pricing</span>
          </v-card-title>

          <v-card-text v-if="editItem">
            <div class="pb-2">
              <span class="od-label">ID:</span
              ><span class="pl-2">{{ editItem.id }}</span>
            </div>
            <v-text-field
              v-model="editItem.attributes.partnerId"
              outlined
              label="Partner ID"
              readonly
            />
            <v-alert type="warning">
              <b>Warning:</b> This information is a system of record for pricing
              so deleting this may not be ideal. Changing the start and end
              dates is recommended before deleting.
            </v-alert>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="onCancel"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="doDelete"> Delete </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import { OneDropApi } from '@/onedrop-api';
import { ConsoleLog } from '@/onedrop-utils';

export default {
  name: 'EmployerProgramPricingList',
  components: {},
  data: function () {
    return {
      loading: false,
      startDateMenu: false,
      endDateMenu: false,
      selectedPartners: [],
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Partner ID',
          value: 'attributes.partnerId',
          filter: (value, search, item) =>
            this.selectedPartners.length == 0 ||
            this.selectedPartners.indexOf(item.attributes.partnerId) > -1,
        },
        {
          text: 'Partner Name',
          value: 'attributes.partnerName',
        },
        {
          text: 'SKU',
          value: 'attributes.sku',
        },
        {
          text: 'Dependent SKU (Optional)',
          value: 'attributes.dependentSku',
        },
        {
          text: 'Fee Type',
          value: 'attributes.feeType',
        },
        {
          text: 'Start Date',
          value: 'attributes.startDate',
        },
        {
          text: 'End Date',
          value: 'attributes.endDate',
        },
        {
          text: 'Price',
          value: 'attributes.price',
        },
        { text: 'Actions', value: 'action', sortable: false, width: '100px' },
      ],
      items: [],
      skus: [],
      search: null,
      editItemDialog: false,
      deleteItemDialog: false,
      editedIndex: -1,
      editItem: null,
    };
  },
  computed: {
    partnerList() {
      return this.$store.getters.partnerList;
    },
    formTitle() {
      return this.editItem && this.editItem.id
        ? 'Edit Employer program pricing'
        : `New Employer program pricing`;
    },
    feeTypes() {
      let feeTypes = [];
      for (let k in this.items) {
        let ft = this.items[k].attributes.feeType;
        if (feeTypes.indexOf(ft) == -1) feeTypes.push(ft);
      }
      return feeTypes;
    },
  },
  mounted() {
    this.refresh();
    this.refreshSkus();
  },
  methods: {
    partnerName: (item) => `${item.name} (${item.id})`,
    getPartnerById: function (id) {
      return this.$store.getters.partnerById(id);
    },
    getPartnerName: function (id) {
      if (id) {
        const p = this.getPartnerById(id);
        return p ? p.attributes.name : null;
      }
      return null;
    },
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['employer-write']);
    },
    refresh() {
      this.loading = true;
      this.items = [];
      OneDropApi.get(
        '/admin/v3/employer/employer-program-pricing',
        (resp) => {
          this.items = resp.data.map((d) => {
            if (d && d.attributes.partnerId) {
              const name = this.getPartnerName(d.attributes.partnerId);
              if (name) d.attributes.partnerName = name;
            }
            return d;
          });
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    refreshSkus() {
      this.loading = true;
      this.items = [];
      OneDropApi.get(
        '/admin/v3/employer/employer-program-pricing/skus',
        (resp) => {
          this.skus = resp.data.map((d) => d.id);
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    onNewItem() {
      this.editItem = {
        attributes: {
          endDate: null,
          partnerId: null,
          sku: null,
          dependentSku: null,
          feeType: null,
          price: null,
          startDate: null,
        },
        type: 'employer-program-pricing',
      };
      this.editItemDialog = true;
    },
    onEditItem(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.editItemDialog = true;
    },
    onDeleteItem(item) {
      this.editItem = item;
      this.deleteItemDialog = true;
    },
    onCancel() {
      this.editItem = null;
      this.editItemDialog = false;
      this.deleteItemDialog = false;
    },
    doSave() {
      let price = this.editItem.attributes.price;
      this.editItem.attributes.price = price ? Number(price) : price;
      let body = { data: [this.editItem] };
      this.loading = true;
      ConsoleLog(`Saving Employer program pricing: ${JSON.stringify(body)}`);
      OneDropApi.patch(
        '/admin/v3/employer/employer-program-pricing',
        body,
        () => {
          this.loading = false;
          this.toast(`Employer program pricing updated successfully`);
          this.onCancel();
          this.refresh();
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    doDelete() {
      const item = this.editItem;
      ConsoleLog(`Deleting Employer program pricing: ${JSON.stringify(item)}`);
      OneDropApi.del(
        `/admin/v3/employer/employer-program-pricing/${item.id}`,
        null,
        () => {
          this.loading = false;
          this.toast(`Employer program pricing deleted successfully`);
          this.onCancel();
          this.refresh();
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style></style>
