<template>
  <v-data-table
    :loading="loading"
    :headers="headers"
    :items="items"
    :search="search"
  >
    <template #item.createdAt="{ item }">
      <div>{{ formatDate(item.createdAt) }}</div>
    </template>
    <template #item.updatedAt="{ item }">
      <div>{{ formatDate(item.updatedAt) }}</div>
    </template>
  </v-data-table>
</template>

<script>
import { OneDropUtils } from '../../onedrop-utils';

export default {
  name: 'CodeList',
  props: ['items'],
  data: function () {
    return {
      loading: false,
      search: '',
      headers: [
        {
          text: 'Language',
          value: 'language',
        },
        {
          text: 'Content',
          value: 'content',
        },
        {
          text: 'Created At',
          value: 'createdAt',
        },
        {
          text: 'Updated At',
          value: 'updatedAt',
        },
      ],
    };
  },
  methods: {
    formatDate: function (str) {
      return str ? OneDropUtils.displayDate(str) : str;
    },
  },
};
</script>

<style scoped></style>
