<!--This file shows configuration options for a specific partner-->
<template>
  <v-card flat>
    <v-data-table
      :items="data"
      :loading="loading"
      :headers="headers"
      :expanded.sync="expanded"
      sort-by="attributes.groupOrder"
      show-expand
      single-expand
    >
      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-container fluid>
            <PartnerConfigOptions :options="item.attributes.options" />
          </v-container>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { OneDropApi } from '../../onedrop-api';
import { ConsoleLog } from '../../onedrop-utils';
import PartnerConfigOptions from './PartnerConfigOptions';

export default {
  name: 'PartnerConfig',
  components: { PartnerConfigOptions },
  props: ['partnerId'],
  data: function () {
    return {
      loading: false,
      expanded: [],
      data: [],
      headers: [
        {
          text: 'Config Id',
          value: 'attributes.configurationId',
        },
        {
          text: 'Name',
          value: 'attributes.name',
        },
        {
          text: 'Type',
          value: 'attributes.configurationType',
        },
        {
          text: 'Description',
          value: 'attributes.description',
        },
        {
          text: 'Order',
          value: 'attributes.groupOrder',
        },
        {
          text: 'Rule',
          value: 'attributes.groupRule',
        },
        { text: '', value: 'data-table-expand' },
      ],
    };
  },
  created() {
    this.refreshList();
  },
  methods: {
    refreshList: function () {
      ConsoleLog('Fetching configurations');
      this.loading = true;
      OneDropApi.get(
        `v3.1/admin/config-by-partner/${this.partnerId}`,
        (response) => {
          this.data = response.data;
          if (this.data) ConsoleLog(`this.data: ${this.data.length}`);
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    created: function () {
      this.refreshList();
    },
  },
};
</script>

<style></style>
