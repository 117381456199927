<template>
  <v-container fluid>
    <v-layout class="display-1 py-4">
      One Drop API Playground
      <v-btn text @click="onShareLinkClicked">
        <v-icon class="pr-2"> mdi-content-copy </v-icon>
      </v-btn>
      <input id="hiddenShareLink" type="hidden" :value="shareLink" />
    </v-layout>
    <v-row no-gutters class="pt-6">
      <v-col cols="1">
        <v-select v-model="request.verb" :items="restVerbs" />
      </v-col>
      <v-col cols="5" class="ml-4">
        <v-text-field v-model="request.endpoint" :label="apiUrl" outlined />
      </v-col>
      <v-col>
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              History
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(item, index) in history()">
              <v-list-item :key="index">
                <a @click="navigateTo(item)">
                  {{ item.name }}
                </a>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
        <v-btn text @click="clearHistory"> Clear History </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col v-if="hasRequestBody" cols="8" class="mb-4">
        <v-card outlined>
          <v-card-title>
            <span>Request Body</span>
            <v-spacer />
            <v-icon
              v-if="bodyCondensed"
              right
              @click="bodyCondensed = !bodyCondensed"
            >
              mdi-chevron-down
            </v-icon>
            <v-icon v-else right @click="bodyCondensed = !bodyCondensed">
              mdi-chevron-up
            </v-icon>
          </v-card-title>
          <monaco
            v-if="!bodyCondensed"
            v-model="request.body"
            class="editor fill-height"
            language="json"
            :options="monacoEditableOpts"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-btn
        large
        depressed
        color="primary"
        :loading="isSubmitting"
        :disabled="isSubmitting"
        @click="onSubmitClicked"
      >
        Submit
      </v-btn>
      <v-btn text color="primary" class="ml-4" @click="showTokens = true">
        My Tokens
      </v-btn>
    </v-row>
    <v-row v-if="responseBody" no-gutters class="py-6">
      <v-card width="1000">
        <v-card-title>Response ({{ responseCode }})</v-card-title>
        <v-divider />
        <v-card-text>
          <h3 class="grey--text pb-2">Headers</h3>
          <v-row
            v-for="header in Object.keys(responseHeaders)"
            :key="header"
            no-gutters
          >
            <v-col cols="12">
              {{ header }}: {{ responseHeaders[header] }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-text>
          <h3 class="grey--text pb-2">Body</h3>
          <!-- eslint-disable-next-line -->
          <pre class="overflow-x-auto" v-html="responseBody"></pre>
        </v-card-text>
      </v-card>
    </v-row>

    <!-- Dialogs -->
    <v-dialog v-model="showTokens" max-width="860">
      <v-card>
        <v-card-title>My API Tokens</v-card-title>
        <v-card-text>
          <h3>V3 Auth Token</h3>
          {{ $store.state.jwtToken }}
        </v-card-text>
        <v-card-text>
          <h3>V3 Refresh Token</h3>
          {{ $store.state.jwtRefreshToken }}
        </v-card-text>
        <v-card-text>
          <h3>Parse Session Token</h3>
          {{ parseSessionToken }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { OneDropUtils } from '../onedrop-utils';
import Monaco from '@/components/Monaco';

export default {
  name: 'Playground',

  components: { Monaco },

  data() {
    return {
      isSubmitting: false,
      showTokens: false,
      restVerbs: ['GET', 'PUT', 'POST', 'PATCH', 'DELETE'],
      request: {
        endpoint: 'v3/user/me',
        verb: 'GET',
        body: '{}',
      },
      responseCode: null,
      responseHeaders: null,
      responseBody: null,
      bodyCondensed: false,
      monacoEditableOpts: {
        readOnly: false,
        wordWrap: 'on',
        minimap: { enabled: false },
        theme: 'vs',
      },
    };
  },

  computed: {
    parseSessionToken: function () {
      return OneDropUtils.parseSessionTokenFromJwt(this.$store.state.jwtToken);
    },

    encodedRequest: function () {
      return encodeURI(btoa(JSON.stringify(this.request)));
    },

    hasRequestBody: function () {
      switch (this.request.verb) {
        case 'PUT':
          return true;
        case 'POST':
          return true;
        case 'PATCH':
          return true;
        default:
          return false;
      }
    },

    shareLink: function () {
      const protocol = window.location.protocol;
      const host = window.location.host;
      return `${protocol}//${host}/#/playground?request=${this.encodedRequest}`;
    },

    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
  },

  watch: {
    hasRequestBody: function (val) {
      if (val) this.bodyCondensed = false;
    },
  },

  created: function () {
    const encodedRequest = this.$route.query.request;
    if (encodedRequest) {
      this.request = JSON.parse(atob(decodeURI(encodedRequest)));
    }
  },

  methods: {
    history() {
      return this.$store.state.playgroundHistory;
    },

    clearHistory() {
      this.$store.commit('clearPlayground');
    },

    navigateTo(item) {
      if (item.request) {
        this.request = JSON.parse(atob(decodeURI(item.request)));
      }
    },

    onShareLinkClicked: function () {
      const copiedText = this.copyToClipboard('hiddenShareLink');
      this.toast('Copied: ' + copiedText);
    },

    onSubmitClicked: function () {
      this.isSubmitting = true;
      this.responseCode = null;
      this.responseHeaders = null;
      this.responseBody = null;

      try {
        const requestBody = this.request.body
          ? JSON.parse(this.request.body)
          : null;
        switch (this.request.verb) {
          case 'GET':
            OneDropApi.get(
              this.request.endpoint,
              (response, r) => this.onRequestSuccess(r),
              (error) => this.onRequestError(error)
            );
            break;
          case 'PUT':
            OneDropApi.put(
              this.request.endpoint,
              requestBody,
              (response, r) => this.onRequestSuccess(r),
              (error) => this.onRequestError(error)
            );
            break;
          case 'POST':
            OneDropApi.post(
              this.request.endpoint,
              requestBody,
              (response, r) => this.onRequestSuccess(r),
              (error) => this.onRequestError(error)
            );
            break;
          case 'PATCH':
            OneDropApi.patch(
              this.request.endpoint,
              requestBody,
              (response, r) => this.onRequestSuccess(r),
              (error) => this.onRequestError(error)
            );
            break;
          case 'DELETE':
            OneDropApi.del(
              this.request.endpoint,
              (response, r) => this.onRequestSuccess(r),
              (error) => this.onRequestError(error)
            );
            break;
        }
      } catch (ex) {
        this.isSubmitting = false;
        this.toast('Invalid JSON Body');
      }
    },

    onRequestSuccess: function (response) {
      this.responseCode = response.status;
      this.responseHeaders = response.headers;
      this.responseBody = OneDropUtils.syntaxHighlightJSON(response.data);
      this.isSubmitting = false;
      const k = this.request.verb + ' ' + this.request.endpoint;
      const pl = { name: k, request: this.encodedRequest };
      this.$store.commit('savePlaygroundCommand', pl);
    },

    onRequestError: function (error) {
      this.responseHeaders = error.response.headers;
      this.responseCode = error.response.status;
      this.responseBody = OneDropUtils.syntaxHighlightJSON(error.response.data);
      this.isSubmitting = false;
    },
  },
};
</script>
<style>
pre .string {
  color: green;
}

.number {
  color: darkorange;
}

.boolean {
  color: blue;
}

.null {
  color: magenta;
}

.key {
  color: red;
}

.editor {
  min-height: 400px;
}
</style>
