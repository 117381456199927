<template>
  <v-card flat>
    <v-data-table
      :items="data"
      :headers="headers"
      :search="search"
      :loading="loading"
      :expanded.sync="expanded"
      :group-by="groupBy"
      show-expand
      single-expand
    >
      <template #top>
        <v-toolbar flat color="white">
          <v-text-field v-model="search" label="Search" hide-details />
          <v-spacer />
          <v-select
            v-model="selectedType"
            :items="types"
            label="Types"
            hide-selected
            chips
            deletable-chips
          />
          <v-spacer />
          <v-btn
            color="primary"
            dark
            :loading="loading"
            :disabled="loading"
            class="mb-2 mr-2"
            @click="refreshList"
          >
            Reload
          </v-btn>
          <v-btn
            color="primary"
            dark
            :loading="loading"
            :disabled="loading"
            class="mb-2 mr-2"
            @click="onNewItem"
          >
            New
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.attributes.metaData="{ item }">
        <span>{{ JSON.stringify(item.attributes.metaData) }}</span>
      </template>
      <template #item.attributes.createdAt="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <div v-on="on">
              {{ formatDate(item.attributes.createdAt) }}
            </div>
          </template>
          <span>{{ item.displayDate }}</span>
        </v-tooltip>
      </template>
      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-container fluid>
            <v-tabs>
              <v-tab key="1"> Details </v-tab>
              <v-tab key="2"> Content </v-tab>
              <v-tab v-if="item.attributes.optionType === 'card'" key="3">
                Sub Options
              </v-tab>
              <v-tab-item key="1">
                <v-card fill elevation="0" tile>
                  <v-card-text>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Meta Data</v-list-item-title>
                        <p class="text--secondary">
                          {{ JSON.stringify(item.attributes.metaData) }}
                        </p>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Display Slug</v-list-item-title>
                        <p class="text--secondary">
                          {{ item.attributes.displaySlug }}
                        </p>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="mb-1">
                          Content Prefix
                        </v-list-item-title>
                        <p class="text--secondary">
                          {{ item.attributes.contentSlugPrefix }}
                        </p>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="mb-1">
                          Data Slug
                        </v-list-item-title>
                        <p class="text--secondary">
                          {{ item.attributes.dataSlug }}
                        </p>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item key="2">
                <v-container fluid>
                  <ContentResourceView
                    ref="contentList"
                    resource-type="configuration-option"
                    :resource-id="item.id"
                    :default-values="defaultContent"
                  >
                    <template #actions>
                      <v-btn
                        color="primary"
                        icon
                        :loading="loading"
                        class="mb-2 mr-2"
                        @click="onContentEdit(item)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                  </ContentResourceView>
                </v-container>
              </v-tab-item>
              <v-tab-item key="3">
                <v-container fluid>
                  <ConfigGroupMaps
                    :group-id="item.id"
                    :config="item"
                    is-sub-options="true"
                  />
                </v-container>
              </v-tab-item>
            </v-tabs>
          </v-container>
        </td>
      </template>
      <template #group.header="{ group, headers, isOpen, toggle }">
        <td :colspan="headers.length">
          <v-btn icon @click="toggle">
            <v-icon v-if="isOpen"> mdi-minus </v-icon>
            <v-icon v-else> mdi-plus </v-icon>
          </v-btn>
          <span>Type: {{ group || 'undefined' }}</span>
        </td>
      </template>
      <template #item.action="{ item }">
        <v-icon
          v-if="hasWritePerm()"
          class="mr-2"
          @click.stop="onEditItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon v-if="hasWritePerm()" @click.stop="onDeleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template #no-data>
        <v-btn color="primary" @click="refreshList"> Reset </v-btn>
      </template>
    </v-data-table>
    <div>
      <v-dialog v-model="editItemDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text v-if="editItem">
            <div v-if="editItem.id" class="pb-2">
              <span class="od-label">Configuration Id:</span
              ><span class="pl-2">{{ editItem.id }}</span>
            </div>
            <v-text-field
              v-model="editItem.attributes.name"
              outlined
              label="Name"
            />
            <v-combobox
              v-model="editItem.attributes.optionType"
              :items="types"
              label="Types"
              hide-selected
              chips
              deletable-chips
            />
            <v-text-field
              v-model="editItem.attributes.slug"
              outlined
              label="Slug"
            />
            <v-text-field
              v-model="editItem.attributes.displaySlug"
              outlined
              label="Display Slug"
            />
            <v-text-field
              v-model="editItem.attributes.dataSlug"
              outlined
              label="Data Slug"
            />
            <v-text-field
              v-model="editItem.attributes.contentSlugPrefix"
              outlined
              label="Content Prefix"
            />
            <v-textarea
              v-model="editItem.attributes.metaData"
              outlined
              label="Meta Data"
              :rules="[rules.json]"
              validate-on-blur
              auto-grow
              rows="3"
              row-height="24px"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteItemDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Delete Option</span>
          </v-card-title>

          <v-card-text v-if="editItem">
            <div class="pb-2">
              <span class="od-label">Configuration Id:</span
              ><span class="pl-2">{{ editItem.id }}</span>
            </div>
            <v-text-field
              v-model="editItem.attributes.name"
              outlined
              label="Name"
              readonly
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="doDelete"> Delete </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="editContentDialog" max-width="75%">
        <v-card>
          <v-card-title>
            <span class="headline">Edit Content</span>
          </v-card-title>

          <v-card-text v-if="editItem">
            <ContentEdit
              ref="contentEditor"
              :prefix="getContentPrefix(editItem)"
              :values="defaultEditContent"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="doSaveContent">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import { OneDropApi } from '../../onedrop-api';
import { ConsoleLog, OneDropUtils } from '../../onedrop-utils';
import ContentEdit from '../ContentEdit';
import ContentResourceView from '@/components/content/ContentResourceView';
import ConfigGroupMaps from './ConfigGroupMaps';

export default {
  name: 'Options',
  components: { ContentResourceView, ContentEdit, ConfigGroupMaps },
  data: function () {
    return {
      loading: false,
      search: '',
      expanded: [],
      editItemDialog: false,
      deleteItemDialog: false,
      editContentDialog: false,
      headers: [
        {
          text: 'Id',
          value: 'id',
        },
        {
          text: 'Type',
          value: 'attributes.optionType',
          filter: (value) =>
            !this.selectedType ||
            this.selectedType.length == 0 ||
            this.selectedType == value,
        },
        {
          text: 'Name',
          value: 'attributes.name',
        },
        {
          text: 'Slug',
          value: 'attributes.slug',
        },
        {
          text: 'Created At',
          value: 'attributes.createdAt',
        },
        { text: 'Actions', value: 'action', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      data: [],
      types: [],
      selectedType: null,
      editedIndex: -1,
      editItem: null,
      rules: {
        json: (value) => {
          try {
            if (value) JSON.parse(value);
            return true;
          } catch (e) {
            return 'Invalid JSON';
          }
        },
      },
      defaultContent: {},
      defaultEditContent: {},
    };
  },
  computed: {
    groupBy() {
      if (this.selectedType && this.selectedType.length > 0) return null;
      else return 'attributes.optionType';
    },
    formTitle() {
      return this.editedIndex == -1
        ? 'New Configuration'
        : `Edit Configuration`;
    },
  },
  created() {
    this.refreshList();
  },
  methods: {
    getContentPrefix(item) {
      return item ? `${item.attributes.contentSlugPrefix}-` : item;
    },
    formatDate: function (str) {
      return str ? OneDropUtils.displayDate(str) : str;
    },
    refreshList: function () {
      ConsoleLog('Fetching Configuration');
      this.loading = true;
      OneDropApi.get(
        'v3.1/admin/config/options',
        (response) => {
          this.data = response.data;
          let types = response.data.map((item) => item.attributes.optionType);
          this.types = Array.from(new Set(types));
          ConsoleLog(`Got ${this.data.length} Configuration`);
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    created: function () {
      this.refreshList();
    },
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'config-write']);
    },
    close: function () {
      this.loading = false;
      this.editedIndex = -1;
      this.editItem = null;
      this.editItemDialog = false;
      this.deleteItemDialog = false;
      this.editContentDialog = false;
    },
    modifyAttrs(item) {
      if (item.attributes.metaData)
        item.attributes.metaData = JSON.parse(item.attributes.metaData);
      return item;
    },
    doSave: function () {
      let item = JSON.parse(JSON.stringify(this.editItem));
      try {
        item = this.modifyAttrs(item);
      } catch (err) {
        this.onApiError(err);
        return;
      }
      let body = { data: [item] };
      ConsoleLog(`Save ${this.editedIndex}, ${JSON.stringify(this.editItem)}`);
      this.loading = true;
      if (this.editedIndex > -1) {
        OneDropApi.patch(
          `v3.1/admin/config/option`,
          body,
          () => {
            this.refreshList();
            this.toast(`Option updated successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.onApiError(error);
            this.loading = false;
          }
        );
      } else {
        OneDropApi.post(
          `v3.1/admin/config/option`,
          body,
          () => {
            this.refreshList();
            this.toast(`Option added successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.onApiError(error);
            this.loading = false;
          }
        );
      }
    },
    doDelete: function () {
      ConsoleLog(
        `Delete ${this.editedIndex}, ${JSON.stringify(this.editItem)}`
      );
      this.loading = false;
      OneDropApi.del(
        `v3.1/admin/config/option/${this.editItem.id}`,
        null,
        () => {
          this.refreshList();
          this.toast(`Option deleted successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.onApiError(error);
          this.loading = false;
        }
      );
    },
    onNewItem: function () {
      this.editItem = {
        type: 'configuration-option',
        attributes: {
          optionType: null,
          name: null,
          slug: null,
          displaySlug: null,
          contentSlugPrefix: null,
          metaData: null,
        },
      };
      this.editedIndex = -1;
      this.editItemDialog = true;
    },
    onEditItem: function (item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      if (this.editItem.attributes.metaData)
        this.editItem.attributes.metaData = JSON.stringify(
          this.editItem.attributes.metaData
        );
      this.editedIndex = this.data.findIndex((v) => v.id === item.id);
      this.editItemDialog = true;
    },
    onDeleteItem: function (item) {
      this.editItem = item;
      this.editedIndex = this.data.findIndex((v) => v.id === item.id);
      this.deleteItemDialog = true;
    },
    onContentEdit: function (item) {
      ConsoleLog(`Edit item: ${JSON.stringify(item)}`);
      this.editItem = JSON.parse(JSON.stringify(item));
      this.editedIndex = this.data.findIndex((v) => v.id === item.id);
      this.editContentDialog = true;
    },
    doSaveContent() {
      const contentChanges = this.$refs.contentEditor.getChanges();
      if (contentChanges) {
        ConsoleLog(`Content Changes: ${JSON.stringify(contentChanges)}`);
        this.$refs.contentEditor.saveChanges(
          contentChanges,
          () => {
            this.$refs.contentList.refreshList();
            this.toast(`Content updated successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.onApiError(error);
            this.close();
          }
        );
      } else this.close();
    },
  },
};
</script>

<style></style>
