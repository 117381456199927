<template>
  <v-stepper v-model="mode">
    <v-stepper-header>
      <v-stepper-step :complete="done" step="F"> Find </v-stepper-step>
      <v-divider />
      <v-stepper-step :complete="done" step="M"> Merge </v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content step="F">
        <v-row>
          <v-col cols="5">
            <v-card v-if="donor">
              <v-card-title
                class="pa-2 ma-0"
                :class="userIsDonor ? 'red lighten-3' : 'green lighten-3'"
              >
                <span>Donor: {{ donor.id }}</span>
                <v-spacer />
                <v-icon
                  v-if="donorCondensed"
                  right
                  @click="donorCondensed = !donorCondensed"
                >
                  mdi-chevron-down
                </v-icon>
                <v-icon v-else right @click="donorCondensed = !donorCondensed">
                  mdi-chevron-up
                </v-icon>
              </v-card-title>
              <v-card-text>
                <UserSummary :user="donor" :condensed="donorCondensed" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <FindUser @user-selected="onUserSelected" @canceled="onFindCanceled" />
      </v-stepper-content>
      <v-stepper-content step="M">
        <v-row no-gutters>
          <v-col cols="5">
            <v-card v-if="donor">
              <v-card-title
                class="pa-2 ma-0"
                :class="userIsDonor ? 'red lighten-3' : 'green lighten-3'"
              >
                <span>Donor: {{ donor.id }}</span>
                <v-spacer />
                <v-icon
                  v-if="donorCondensed"
                  right
                  @click="donorCondensed = !donorCondensed"
                >
                  mdi-chevron-down
                </v-icon>
                <v-icon v-else right @click="donorCondensed = !donorCondensed">
                  mdi-chevron-up
                </v-icon>
              </v-card-title>
              <v-card-text>
                <UserSummary :user="donor" :condensed="donorCondensed" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2" justify="center" align="center">
            <v-btn @click="onSwapClicked()"> Swap </v-btn>
          </v-col>
          <v-col cols="5">
            <v-card v-if="recipient">
              <v-card-title
                class="pa-2 ma-0"
                :class="userIsRecipient ? 'red lighten-3' : 'green lighten-3'"
              >
                <span>Recipient: {{ recipient.id }}</span>
                <v-spacer />
                <v-icon
                  v-if="recipientCondensed"
                  right
                  @click="recipientCondensed = !recipientCondensed"
                >
                  mdi-chevron-down
                </v-icon>
                <v-icon
                  v-else
                  right
                  @click="recipientCondensed = !recipientCondensed"
                >
                  mdi-chevron-up
                </v-icon>
              </v-card-title>
              <v-card-text>
                <UserSummary
                  :user="recipient"
                  :condensed="recipientCondensed"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="space-around">
          <v-switch
            v-model="includeHealthObjects"
            label="Include Health Objects"
          />
          <v-switch v-model="deleteDonor" label="Delete Donor" />
          <v-switch
            v-model="emailFromDonor"
            :disabled="!deleteDonor"
            label="Retain Donor Email"
          />
          <v-switch v-model="moveEntitlements" label="Move Entitlements" />
          <v-switch v-model="ignoreDonorKeys" label="Ignore Donor Keys" />
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            large
            depressed
            color="primary"
            class="mr-2"
            :disabled="merging"
            text
            @click="goBack()"
          >
            Cancel
          </v-btn>
          <v-btn
            large
            depressed
            color="primary"
            class="mr-2"
            :disabled="merging"
            text
            @click="onFindClicked()"
          >
            Find Again
          </v-btn>
          <v-btn
            large
            depressed
            color="primary"
            :loading="merging"
            :disabled="merging"
            @click="onSubmitClicked()"
          >
            Perform Merge
          </v-btn>
        </v-card-actions>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import FindUser from './FindUser';
import UserSummary from './UserSummary';
import { ConsoleLog } from '../onedrop-utils';

export default {
  name: 'MergeUser',
  components: { FindUser, UserSummary },
  props: ['userId', 'url'],
  data: function () {
    return {
      merging: false,
      done: false,
      results: null,
      donor: null,
      recipient: null,
      mode: 'F',
      user: null,
      includeHealthObjects: true,
      deleteDonor: true,
      moveEntitlements: false,
      emailFromDonor: false,
      error: false,
      donorCondensed: true,
      recipientCondensed: true,
      ignoreDonorKeys: false,
    };
  },
  computed: {
    userIsDonor: function () {
      return this.user == this.donor;
    },
    userIsRecipient: function () {
      return this.user == this.recipient;
    },
  },
  watch: {
    deleteDonor: function (val) {
      if (!val) this.emailFromDonor = false;
    },
  },
  mounted: function () {
    this.donor = this.user;
  },
  created: function () {
    OneDropApi.getUser(
      this.userId,
      (result) => {
        this.user = result.data[0];
        this.donor = this.user;
      },
      (error) => this.onApiError(error)
    );
  },
  methods: {
    onSwapClicked: function () {
      ConsoleLog('onSwapClicked');
      const r = this.recipient;
      this.recipient = this.donor;
      this.donor = r;
    },
    onFindClicked: function () {
      this.mode = 'F';
    },
    onUserSelected: function (item) {
      if (item.id == this.userId) {
        this.onApiError('Cannot select the same user');
      } else {
        OneDropApi.getUser(
          item.id,
          (result) => {
            const user2 = result.data[0];
            if (!this.donor) this.donor = user2;
            else if (!this.recipient) this.recipient = user2;
            else if (this.user == this.recipient) this.donor = user2;
            else this.recipient = user2;
            this.mode = 'M';
          },
          (error) => this.onApiError(error)
        );
      }
    },
    onFindCanceled: function () {
      this.mode = 'M';
    },
    onSubmitClicked: function () {
      ConsoleLog(`Merging users ${this.donor.id}, ${this.recipient.id}`);
      this.merging = true;
      this.results = [[], [], [], []];
      this.error = false;
      this.done = false;
      OneDropApi.mergeUser(
        this.donor.id,
        this.recipient.id,
        this.includeHealthObjects,
        this.moveEntitlements,
        this.deleteDonor,
        this.emailFromDonor,
        this.ignoreDonorKeys,
        (resp) => {
          ConsoleLog(resp);
          let jobId = resp.data[0].id;
          ConsoleLog(`Submitted mergeUser ${this.userId} with jobId=${jobId}`);
          this.$router.push({
            path: `/job-status/${jobId}`,
            query: { url: this.url },
          });
          // setTimeout(function() { self.getStatus(jobId) }, 500)
        },
        (error) => {
          let message = `Error merging: ${error}`;
          if (error.response && error.response.data.errors) {
            message = `${JSON.stringify(error.response.data.errors)}`;
          } else if (error.message) message = `Error merging: ${error.message}`;
          ConsoleLog(message);
          this.onApiError(message);
          this.done = false;
          this.merging = false;
          this.error = true;
          this.results = [[], [], [], []];
        }
      );
    },
    onDoneClicked: function () {
      this.$router.go(-1);
    },
    goBack() {
      if (this.url) this.$router.push(this.url);
      else
        window.history.length > 1
          ? this.$router.go(-1)
          : this.$router.push('/');
    },
  },
};
</script>

<style></style>
