<template>
  <v-container fluid>
    <top-bar>
      <template #menu>
        <v-btn text class="grey--text text--darken-1" @click="goBack">
          <v-icon left> mdi-chevron-left </v-icon>
          Home
        </v-btn>
      </template>
    </top-bar>
    <job-status :job-id="jobId" :url="url" />
  </v-container>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import JobStatus from '@/components/JobStatus';
import TopBar from '../components/TopBar';

export default {
  name: 'User',
  components: {
    JobStatus,
    TopBar,
  },
  data: function () {
    return {
      jobId: this.$route.params.jobId,
      url: this.$route.query.url,
    };
  },
  mounted() {
    if (!OneDropApi.hasPerm('admin-delete')) {
      // Not allowed to merge if the user doesn't have permission
      this.$router.push('/');
    }
  },
  methods: {
    goBack() {
      if (this.url) this.$router.push(this.url);
      else this.$router.push('/');
    },
  },
};
</script>
