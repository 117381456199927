<template>
  <v-container flat>
    <v-progress-linear indeterminate color="primary" :active="loading" />
    <v-row dense>
      <v-col cols="4">
        <v-layout>
          <v-list-item-title>Group</v-list-item-title>
          <v-spacer />
          <v-btn
            v-if="hasWritePerm()"
            color="primary"
            icon
            class="mb-2"
            @click="onContentEdit()"
          >
            <v-icon title="Edit"> mdi-pencil </v-icon>
          </v-btn>
        </v-layout>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4">{{ slug }}</v-col>
    </v-row>
    <v-dialog v-model="editDialog" max-width="50%">
      <v-card>
        <v-card-title>
          <span class="headline">Partner Groups</span>
        </v-card-title>

        <v-card-text>
          <v-select v-model="slug" :items="slugOptions" chips />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            :disabled="loading"
            @click="onCancel"
          >
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text :loading="loading" @click="onSave">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { OneDropApi } from '@/onedrop-api';
import { ConsoleLog } from '@/onedrop-utils';

export default {
  name: 'PartnerGroup',
  props: ['partnerId'],
  data: function () {
    return {
      slug: null,
      loading: false,
      editDialog: false,
      slugOptions: ['onedrop-managed', 'onedrop-qa-managed'],
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'partner-write']);
    },
    refresh() {
      this.loading = true;
      this.items = null;
      OneDropApi.get(
        `/admin/v3/partner-group/${this.partnerId}`,
        (resp) => {
          this.slug =
            resp.data.length > 0 ? resp.data[0].attributes.slug : null;
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    onContentEdit() {
      this.editDialog = true;
    },
    onSave() {
      this.loading = true;
      OneDropApi.patch(
        `/admin/v3/partner-group/${this.partnerId}/slug/${this.slug}`,
        null,
        (resp) => {
          this.slug =
            resp.data.length > 0 ? resp.data[0].attributes.slug : null;
          this.loading = false;
          this.editDialog = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    onCancel() {
      this.editDialog = false;
    },
  },
};
</script>

<style scoped></style>
