<script>
import * as monaco from 'monaco-editor';

export default {
  name: 'MonacoDiff',
  model: {
    event: 'change',
  },
  props: {
    orig: {
      type: String,
      required: true,
    },
    modified: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: 'vs-dark',
    },
    language: String,
    options: Object,
  },
  data: () => ({
    editor: null,
  }),
  watch: {
    options: {
      deep: true,
      handler(options) {
        if (this.editor) {
          const editor = this.getEditor();
          editor.updateOptions(options);
        }
      },
    },

    orig() {
      if (this.editor) {
        this.refreshModel();
      }
    },

    modified() {
      if (this.editor) {
        this.refreshModel();
      }
    },

    language(newVal) {
      if (this.editor) {
        const editor = this.getEditor();
        this.monaco.editor.setModelLanguage(editor.getModel(), newVal);
      }
    },

    theme(newVal) {
      if (this.editor) {
        this.monaco.editor.setTheme(newVal);
      }
    },
  },
  mounted() {
    this.initMonaco();
  },
  beforeDestroy() {
    this.editor && this.editor.dispose();
  },
  methods: {
    initMonaco() {
      const options = Object.assign(
        {
          theme: this.theme,
          language: this.language,
          automaticLayout: true,
        },
        this.options
      );
      this.editor = monaco.editor.createDiffEditor(this.$el, options);
      this.refreshModel();
    },
    refreshModel: function () {
      if (this.editor && this.orig && this.modified) {
        let oModel = monaco.editor.createModel(this.orig);
        let mModel = monaco.editor.createModel(this.modified);
        this.editor.setModel({
          original: oModel,
          modified: mModel,
        });
      }
    },
    getEditor() {
      return this.editor;
    },
  },
  render(h) {
    return h('div', {
      style: {
        width: '100%',
      },
    });
  },
};
</script>

<style scoped></style>
