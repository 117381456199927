<template>
  <v-container fluid>
    <v-layout class="display-1 py-4"> Manage Resource Content </v-layout>
    <group-list />
  </v-container>
</template>

<script>
import GroupList from '@/components/content/GroupList';

export default {
  name: 'ResourceContentView',
  components: { GroupList },
  data: function () {
    return {};
  },
};
</script>

<style scoped></style>
