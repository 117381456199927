<template>
  <v-container fluid>
    <top-bar>
      <template #menu>
        <v-btn text to="/" class="grey--text text--darken-1">
          <v-icon left> mdi-chevron-left </v-icon>
          Home
        </v-btn>
      </template>
    </top-bar>
    <v-layout class="display-1 py-4"> User Detail </v-layout>
    <user-details :user-id="userId" />
  </v-container>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import UserDetails from '../components/UserDetails';
import TopBar from '../components/TopBar';

export default {
  name: 'User',
  components: {
    UserDetails,
    TopBar,
  },
  data: function () {
    return {
      userId: this.$route.params.userId,
    };
  },
  computed: {
    isLoggedIn: function () {
      return OneDropApi.isLoggedIn();
    },
  },
};
</script>
