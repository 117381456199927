<template>
  <div fluid>
    <v-progress-circular v-if="loading" indeterminate color="primary" />
    <div v-else-if="values2 == null">
      <div v-for="name in values1" :key="name" fluid>
        <v-textarea
          v-model="editedValues[name]"
          :label="name"
          :hint="textHint"
          auto-grow
          rows="1"
          row-height="24px"
        />
      </div>
    </div>
    <v-row v-else>
      <v-container fluid>
        <v-row>
          <v-col>
            <div v-for="name in values1" :key="name" fluid>
              <v-textarea
                v-model="editedValues[name]"
                :label="name"
                :hint="textHint"
                auto-grow
                rows="2"
                row-height="24px"
              />
            </div>
          </v-col>
          <v-col>
            <div v-for="name in values2" :key="name" fluid>
              <v-textarea
                v-model="editedValues[name]"
                :label="name"
                :hint="textHint"
                auto-grow
                rows="2"
                row-height="24px"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </div>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { ConsoleLog } from '../onedrop-utils';

export default {
  name: 'PartContentEdit',
  props: ['prefix', 'values', 'allContent'],
  data: function () {
    return {
      loading: false,
      emptyMessage: 'No Content',
      origValues: null,
      editedValues: null,
      textHint:
        " Use two single-quotes ('') to represent a single-quote ('). Text enclosed in {} is used for substitution. To escape substitution enclose the content in single-quote like '{foo}'.",
    };
  },
  computed: {
    values1: function () {
      let foo = Object.keys(this.editedValues);
      if (foo.length > 6) {
        return foo.slice(0, foo.length / 2);
      } else {
        return foo;
      }
    },
    values2: function () {
      let foo = Object.keys(this.editedValues);
      if (foo.length > 6) {
        return foo.slice(foo.length / 2, foo.length);
      } else {
        return null;
      }
    },
  },
  watch: {
    prefix: function () {
      if (!this.allContent) this.refreshList();
    },
  },
  created: function () {
    this.refreshList();
  },
  methods: {
    getCode: function (item) {
      let code = item ? item.attributes.code : '';
      code = code.replace(this.prefix, '');
      code = code.replace(/^\./, '');
      return code;
    },
    refreshList: function () {
      // This component can be used in 2 modes
      // One where the parent supplies all content
      // the other where we fetch it
      // If the allContent was not provided, we fetch it here
      if (this.allContent) this.refreshContent(this.allContent);
      else {
        this.loading = true;
        OneDropApi.getContentForPrefix(
          this.prefix,
          (result) => {
            ConsoleLog(`refreshList: Got content for prefix ${this.prefix}`);
            this.refreshContent(result.data);
            this.loading = false;
          },
          (error) => {
            ConsoleLog(
              `refreshList: Failed to get content for prefix ${this.prefix}`
            );
            ConsoleLog(error);
            this.loading = false;
            this.emptyMessage = `Failed to get content with error ${error}`;
          }
        );
      }
    },
    refreshContent: function (content) {
      this.emptyMessage = 'No Content';
      this.origValues = JSON.parse(JSON.stringify(this.values));
      let allKeys = Object.keys(this.origValues);
      for (let d in content) {
        let item = content[d];
        let code = this.getCode(item);
        if (allKeys.includes(code))
          this.origValues[code] = item.attributes.content;
        else if (!allKeys || allKeys.length == 0)
          this.origValues[code] = item.attributes.content;
      }
      // Make a copy of it for editing
      this.editedValues = JSON.parse(JSON.stringify(this.origValues));
    },
    getChanges: function () {
      let o = {};
      for (let k in this.origValues) {
        if (this.origValues[k] !== this.editedValues[k])
          o[k] = this.editedValues[k];
      }
      ConsoleLog(`getChanges: ${JSON.stringify(o)}`);
      return o;
    },
    saveChanges: function (changes, onSuccess, onFailure) {
      ConsoleLog(`Save Content ${this.prefix}, ${JSON.stringify(changes)}`);
      let list = [];
      for (let k in changes) {
        let o = {};
        o.code = `${this.prefix}${k}`;
        o.content = changes[k];
        o.language = 'default';
        let c = {
          type: 'content',
          attributes: o,
        };
        list.push(c);
      }
      let body = { data: list };
      ConsoleLog(`saveChanges: body: ${JSON.stringify(body)}`);
      OneDropApi.patchContent(body, onSuccess, onFailure);
    },
  },
};
</script>

<style scoped></style>
