<template>
  <v-container fluid>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :search="search"
      show-expand
      single-expand
      :expanded.sync="expanded"
    >
      <template #top>
        <v-toolbar flat color="white">
          <v-text-field
            ref="searchText"
            v-model="search"
            label="Search"
            hide-details
            outlined
          />
          <v-spacer />
          <v-btn
            color="primary"
            class="ml-2"
            :disabled="loading"
            @click="refresh"
          >
            Reload
          </v-btn>
        </v-toolbar>
      </template>
      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-container fluid>
            <template v-if="editMode && editItem && hasWritePerm()">
              <v-row no-gutters dense>
                <v-toolbar flat dense>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    class="mr-2"
                    :disabled="loading"
                    @click="onCancel"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="primary"
                    :disabled="!canSave(item)"
                    :loading="loading"
                    @click="onSave(item)"
                  >
                    Save
                  </v-btn>
                </v-toolbar>
              </v-row>
              <v-row dense>
                <v-col cols="6">
                  <v-card flat>
                    <v-card-title>SQL</v-card-title>
                    <monaco
                      v-model="editItem.attributes.sql"
                      class="editor fill-height"
                      language="sql"
                      :options="monacoEditableOpts"
                    />
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card flat>
                    <template v-if="editItem.attributes.valueFields">
                      <user-query-values
                        :items.sync="editItem.attributes.valueFields"
                        :edit-mode="editMode"
                      />
                    </template>
                    <template v-if="editItem.attributes.valueTemplate">
                      <v-card-title>Value Template</v-card-title>
                      <monaco
                        v-model="editItem.attributes.valueTemplate"
                        class="editor fill-height"
                        language="json"
                        :options="monacoEditableOpts"
                      />
                    </template>
                  </v-card>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <v-row no-gutters dense>
                <v-toolbar flat dense>
                  <v-spacer />
                  <v-btn
                    v-if="hasWritePerm()"
                    color="primary"
                    @click="onEditMode(item)"
                  >
                    Edit
                  </v-btn>
                </v-toolbar>
              </v-row>
              <v-row v-if="item" dense>
                <v-col cols="6">
                  <v-card flat>
                    <v-card-title>SQL</v-card-title>
                    <monaco
                      v-model="item.attributes.sql"
                      class="editor fill-height"
                      language="sql"
                      :options="monacoReadOnlyOpts"
                    />
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card flat>
                    <template v-if="item.attributes.valueFields">
                      <user-query-values
                        :items="item.attributes.valueFields"
                        :edit-mode="editMode"
                      />
                    </template>
                    <template v-if="item.attributes.valueTemplate">
                      <v-card-title>Value Template</v-card-title>
                      <monaco
                        v-model="item.attributes.valueTemplate"
                        class="editor fill-height"
                        language="json"
                        :options="monacoReadOnlyOpts"
                      />
                    </template>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-container>
        </td>
      </template>
      <template #item.action="{ item }">
        <v-icon
          v-if="
            item && item.attributes.slug.startsWith('data') && hasWritePerm()
          "
          class="mr-2"
          @click.stop="onTest(item)"
        >
          mdi-test-tube
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="testDialog" width="75%">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Test</v-toolbar-title>
          <v-spacer />
          <v-progress-circular v-if="loading" indeterminate color="primary" />
          <v-spacer />
          <v-btn color="blue darken-1" text @click="onTestCancel">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="onTestRun"> Test </v-btn>
        </v-toolbar>

        <input
          id="testOutput"
          type="hidden"
          :value="JSON.stringify(testOutput)"
        />

        <v-card-text>
          <v-row dense>
            <v-col cols="8">
              <v-text-field v-model="testUrl" label="URL" />
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="testUser" label="User ID" />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="8">
              <v-text-field v-model="testQuery" label="Query" />
            </v-col>
          </v-row>
          <v-row v-if="testOutput">
            <h3 class="grey--text pb-2">Output</h3>
            <v-icon
              class="ml-2"
              title="Copy Search Link"
              @click="onCopyClicked()"
            >
              mdi-content-copy
            </v-icon>
          </v-row>
          <v-row v-if="testOutput">
            <v-container fluid>
              <monaco
                v-model="testOutputJson"
                class="editor fill-height"
                language="json"
                :options="monacoReadOnlyOpts"
              />
            </v-container>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { OneDropApi } from '@/onedrop-api';
import { ConsoleLog, OneDropUtils } from '@/onedrop-utils';
import UserQueryValues from '@/components/UserQueryValues';
import Monaco from '@/components/Monaco';

export default {
  name: 'UserQueryList',
  components: { Monaco, UserQueryValues },
  data: function () {
    return {
      loading: false,
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Name',
          value: 'attributes.name',
        },
        {
          text: 'Slug',
          value: 'attributes.slug',
        },
        {
          text: 'Localized Content',
          value: 'attributes.localizedContent',
        },
        { text: 'Actions', value: 'action', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      items: [],
      search: null,
      editItem: null,
      editMode: false,
      testDialog: false,
      testItem: null,
      testUser: null,
      testUrl: null,
      testQuery: null,
      testOutput: null,
      expanded: [],
      rules: {
        json: (value) => {
          try {
            // eslint-disable-next-line
            let modValue = value.replace(/\"?\$(\w*)\"?/gm, '"$1"');
            if (modValue) JSON.parse(modValue);
            return true;
          } catch (e) {
            return 'Invalid JSON';
          }
        },
      },
      monacoReadOnlyOpts: {
        readOnly: true,
        wordWrap: 'on',
        minimap: { enabled: false },
        renderIndentGuides: false,
      },
      monacoEditableOpts: {
        readOnly: false,
        wordWrap: 'on',
        minimap: { enabled: false },
      },
    };
  },
  computed: {
    testOutputJson() {
      return this.testOutput
        ? JSON.stringify(this.testOutput, null, '\t')
        : this.testOutput;
    },
  },
  watch: {
    expanded() {
      // If the expanded changes cancel any editing going on
      if (this.editMode) {
        this.editItem = null;
        this.editMode = false;
      }
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'user-query-write']);
    },
    refresh() {
      this.loading = true;
      this.items = [];
      OneDropApi.get(
        'v3/admin/user-query',
        (resp) => {
          this.items = resp.data;
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    jsonOutput(value) {
      let result = value ? OneDropUtils.syntaxHighlightJSON(value) : value;
      return result;
    },
    onEditMode(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.editMode = true;
    },
    canSave(item) {
      if (this.editItem && this.editItem.id == item.id) {
        let attrs1 = this.editItem.attributes;
        let attrs2 = item.attributes;
        let v1 = JSON.stringify(attrs1.valueFields);
        let v2 = JSON.stringify(attrs2.valueFields);
        let valuesSame = true;
        if (attrs2.valueFields)
          valuesSame =
            attrs1.valueFields.length == attrs2.valueFields.length && v1 == v2;
        else if (attrs1.valueFields && attrs1.valueFields.length > 0)
          valuesSame = false;
        return !(
          attrs1.sql == attrs2.sql &&
          valuesSame &&
          attrs1.valueTemplate == attrs2.valueTemplate
        );
      }
      return false;
    },
    onCancel() {
      this.editItem = null;
      this.editMode = false;
    },
    onSave(item) {
      let body = { data: [this.editItem] };
      this.loading = true;
      ConsoleLog(`Saving Query: ${JSON.stringify(body)}`);
      OneDropApi.patch(
        'v3/admin/user-query',
        body,
        (resp) => {
          let index = this.items.indexOf(item);
          this.items.splice(index, 1, resp.data[0]);
          this.toast(`Saved query successfully`);
          this.loading = false;
          this.editItem = null;
          this.editMode = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    getTestUrl() {
      if (this.testItem) {
        let slug = this.testItem.attributes.slug;
        let url = null;
        if (slug.startsWith('data-goal') || slug.startsWith('data-tile')) {
          let data = slug.replace('data-', '');
          url = `/v3/admin/config-data/${data}/user/`;
        } else if (slug.startsWith('data')) {
          let data = slug.replace('data-', '');
          url = `/v3/admin/data/${data}/user/`;
        }
        return url;
      }
    },
    onTest(item) {
      this.testItem = item;
      this.testDialog = true;
      this.testUser = OneDropApi.getUserId();
      this.testUrl = this.getTestUrl();
      this.testOutput = null;
      this.testQuery = null;
    },
    onTestCancel() {
      this.testDialog = false;
    },
    onTestRun() {
      let url = this.testUrl + this.testUser;
      if (url && this.testQuery) url = url + `?${this.testQuery}`;
      this.loading = true;
      OneDropApi.get(
        url,
        (resp) => {
          this.testOutput = resp;
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    onCopyClicked: function () {
      this.copyToClipboard('testOutput');
      this.toast('Copied Test Output');
    },
  },
};
</script>

<style>
pre .string {
  color: green;
}

.number {
  color: darkorange;
}

.boolean {
  color: blue;
}

.null {
  color: magenta;
}

.key {
  color: red;
}

.editor {
  min-height: 400px;
}
</style>
