<template>
  <v-container flat>
    <v-progress-linear indeterminate color="primary" :active="loading" />
    <v-row>
      <v-col cols="12">
        <v-layout flat>
          <span v-if="error" class="error--text"> {{ error }} </span>
          <v-spacer />
          <v-btn
            v-if="!editMode && hasWritePerm()"
            color="primary"
            class="mb-2"
            @click="onContentEdit()"
          >
            Edit
          </v-btn>
          <v-btn
            v-if="editMode"
            color="primary"
            class="mb-2"
            @click="onCancel()"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="editMode"
            color="primary"
            class="mb-2 ml-2"
            :disabled="Boolean(error) || !hasChanges()"
            @click="onSave()"
          >
            Save
          </v-btn>
        </v-layout>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table dense>
          <template #default>
            <tbody>
              <tr>
                <th>Action</th>
                <th>Description</th>
                <th>Order</th>
                <th>Enroll</th>
                <th>Re-enroll</th>
              </tr>
              <tr v-for="(item, index) in items" :key="index">
                <td>
                  {{ item.attributes.action }}
                </td>
                <td>{{ item.attributes.desc }}</td>
                <td>
                  <template v-if="item.attributes.executionOrder">
                    {{ item.attributes.executionOrder }}
                  </template>
                </td>
                <td>
                  <v-switch
                    v-model="item.attributes.performOnEnroll"
                    dense
                    label=""
                    title="Perform on enroll"
                    :disabled="!editMode"
                    @change="itemChanged(item)"
                  />
                </td>
                <td>
                  <v-switch
                    v-model="item.attributes.performOnReenroll"
                    dense
                    label=""
                    title="Perform on re-enroll"
                    :disabled="!editMode"
                    @change="itemChanged(item)"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/*
This file handles the manipulation of enrollment_actions for a Partner
 */
import { OneDropApi } from '@/onedrop-api';
import { ConsoleLog } from '@/onedrop-utils';

export default {
  name: 'PartnerCodes',
  props: ['partnerId'],
  data: function () {
    return {
      items: [],
      allItems: [
        {
          id: null,
          type: 'enrollment-action',
          attributes: {
            action: 'employer-change-partner',
            desc: 'Change partner',
            executionOrder: 0,
            performOnEnroll: false,
            performOnReenroll: false,
          },
        },
        {
          id: null,
          type: 'enrollment-action',
          attributes: {
            action: 'employer-enroll-coaching-default',
            desc: 'Assign track from default rules (not normal)',
            executionOrder: 0,
            performOnEnroll: false,
            performOnReenroll: false,
          },
        },
        {
          id: null,
          type: 'enrollment-action',
          attributes: {
            action: 'assign-diabetes-type-from-answers',
            desc: 'Assign diabetes type from answers',
            executionOrder: 0,
            performOnEnroll: false,
            performOnReenroll: false,
          },
        },
        {
          id: null,
          type: 'enrollment-action',
          attributes: {
            action: 'assign-cv-prevent-from-cv-risk-score',
            desc: 'Assign cv-prevent diagnosis and risk score from answers',
            executionOrder: 0,
            performOnEnroll: false,
            performOnReenroll: false,
          },
        },
        {
          id: null,
          type: 'enrollment-action',
          attributes: {
            action: 'evaluate-pre-diabetes-from-answers',
            desc: 'Evaluate pre-diabetes from answers',
            executionOrder: 0,
            performOnEnroll: false,
            performOnReenroll: false,
          },
        },
        {
          id: null,
          type: 'enrollment-action',
          attributes: {
            action: 'employer-assign-track-from-answers',
            desc: 'Assign track from answers. (Deprecated by employer-assign-track-from-diagnosis)',
            executionOrder: 0,
            performOnEnroll: false,
            performOnReenroll: false,
          },
        },
        {
          id: null,
          type: 'enrollment-action',
          attributes: {
            action: 'employer-assign-track-from-diagnosis',
            desc: 'Assign track from diagnosis. (Current)',
            executionOrder: 0,
            performOnEnroll: false,
            performOnReenroll: false,
          },
        },
        {
          id: null,
          type: 'enrollment-action',
          attributes: {
            action: 'employer-set-eligibility',
            desc: 'Set enrollment info in eligibility records',
            executionOrder: 0,
            performOnEnroll: false,
            performOnReenroll: false,
          },
        },
        {
          id: null,
          type: 'enrollment-action',
          attributes: {
            action: 'employer-enroll-klaviyo',
            desc: 'Enroll user in Klaviyo',
            executionOrder: 0,
            performOnEnroll: false,
            performOnReenroll: false,
          },
        },
        {
          id: null,
          type: 'enrollment-action',
          attributes: {
            action: 'employer-start-track',
            desc: 'Start track',
            executionOrder: 0,
            performOnEnroll: false,
            performOnReenroll: false,
          },
        },
        {
          id: null,
          type: 'enrollment-action',
          attributes: {
            action: 'employer-klaviyo-branchio',
            desc: 'Enroll user in Klavioyo branchio email',
            executionOrder: 0,
            performOnEnroll: false,
            performOnReenroll: false,
          },
        },
        {
          id: null,
          type: 'enrollment-action',
          attributes: {
            action: 'employer-remove-order',
            desc: 'Remove employer order record (not normal)',
            executionOrder: 0,
            performOnEnroll: false,
            performOnReenroll: false,
          },
        },
      ],
      savedActions: [],
      loading: false,
      editMode: false,
      error: null,
      hasCVPreventQuestions: -1,
    };
  },
  computed: {
    actions: function () {
      let v = [];
      for (let i in this.items) {
        let item = this.items[i];
        if (
          item.attributes.performOnEnroll ||
          item.attributes.performOnReenroll
        ) {
          item.attributes.executionOrder = v.length + 1;
          v.push(item);
        } else item.attributes.executionOrder = 0;
      }
      return v;
    },
    selected: function () {
      let v = [];
      for (let i in this.items) {
        let item = this.items[i];
        if (
          item.attributes.performOnEnroll ||
          item.attributes.performOnReenroll
        )
          v.push(item.attributes.action);
      }
      return v;
    },
  },
  watch: {
    partnerId: function () {
      this.refreshQuestions();
      this.refresh();
    },
  },
  mounted() {
    this.refreshQuestions();
    this.refresh();
  },
  methods: {
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'partner-write']);
    },
    refresh() {
      this.loading = true;
      this.items = JSON.parse(JSON.stringify(this.allItems));
      this.savedActions = [];
      OneDropApi.get(
        `/v3/partner/${this.partnerId}/enrollment-actions`,
        (resp) => {
          this.savedActions = resp.data;
          this.resetSelected();
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    itemChanged(item) {
      this.error = null;
      if (
        item &&
        (item.attributes.performOnEnroll || item.attributes.performOnReenroll)
      ) {
        const nv = this.selected;
        if (
          nv.includes('employer-assign-track-from-answers') &&
          nv.includes('employer-assign-track-from-diagnosis')
        )
          this.error =
            "Can't have both actions employer-assign-track-from-answers and employer-assign-track-from-diagnosis";
        else if (
          nv.includes('employer-assign-track-from-answers') &&
          (nv.includes('assign-cv-prevent-from-cv-risk-score') ||
            nv.includes('evaluate-pre-diabetes-from-answers'))
        )
          this.error =
            'Choose employer-assign-track-from-diagnosis with assign-cv-prevent-from-cv-risk-score or evaluate-pre-diabetes-from-answers actions';
        if (
          nv.includes('assign-cv-prevent-from-cv-risk-score') &&
          (!this.cvPreventQuestionIndex || this.cvPreventQuestionIndex === -1)
        )
          this.error =
            'Failed to find cv-prevent marker question with slug cv-disqual-events.';
      }
    },
    hasChanges() {
      let acts = this.actions ? this.actions : [];
      let sacts = this.savedActions ? this.savedActions : [];
      if (acts.length !== sacts.length) return true;
      else {
        for (let i in this.savedActions) {
          let o = this.savedActions[i];
          let n = this.actions[i];
          if (
            o.id !== n.id ||
            o.attributes.action !== n.attributes.action ||
            o.attributes.performOnEnroll !== n.attributes.performOnEnroll ||
            o.attributes.performOnReenroll !== n.attributes.performOnReenroll
          )
            return true;
        }
      }
      return false;
    },
    refreshQuestions() {
      this.cvPreventQuestionIndex = -1;
      this.loading = true;
      OneDropApi.getAllQuestionsAndGroups(
        this.partnerId,
        (response) => {
          this.cvPreventQuestionIndex = response.data.findIndex(
            (r) => r.attributes.questionSlug === 'cv-disqual-events'
          );
          this.resetSelected();
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    resetSelected() {
      this.items = JSON.parse(JSON.stringify(this.allItems));
      for (let i in this.savedActions) {
        let o = this.savedActions[i];
        let obj = this.items.find(
          (x) => x.attributes.action === o.attributes.action
        );
        ConsoleLog(`Finding: ${JSON.stringify(o)} - ${JSON.stringify(obj)}`);
        if (obj) {
          obj.id = o.id;
          obj.attributes.executionOrder = o.attributes.executionOrder;
          obj.attributes.performOnEnroll = o.attributes.performOnEnroll;
          obj.attributes.performOnReenroll = o.attributes.performOnReenroll;
        }
      }
    },
    onContentEdit() {
      this.editMode = true;
    },
    onSave() {
      let data = this.actions;
      let body = { data: data };
      this.loading = true;
      OneDropApi.patch(
        `/v3/partner/${this.partnerId}/enrollment-actions`,
        body,
        (resp) => {
          this.savedActions = resp.data;
          this.resetSelected();
          this.loading = false;
          this.editMode = false;
          this.toast(`Actions saved successfully`);
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    onCancel() {
      this.resetSelected();
      this.editMode = false;
      this.error = null;
    },
  },
};
</script>

<style scoped></style>
