<template>
  <v-card flat>
    <v-data-table
      :items="questions"
      :headers="headers"
      :loading="loading"
      :expanded.sync="expanded"
      disable-pagination
      hide-default-footer
      show-expand
      single-expand
    >
      <template #top>
        <v-toolbar flat color="white">
          <v-spacer />
          <v-btn
            color="primary"
            dark
            :loading="loading"
            :disabled="loading || readOnly"
            class="mb-2 mr-2"
            @click="onNewItem"
          >
            New Question
          </v-btn>
          <v-btn
            v-if="hasWritePerm()"
            color="primary"
            :loading="loading"
            :disabled="!hasChanges"
            class="mb-2 mr-2"
            @click="onSaveChanges"
          >
            Save
          </v-btn>
          <v-btn
            v-if="hasWritePerm()"
            color="primary"
            :loading="loading"
            :disabled="!hasChanges"
            class="mb-2 mr-2"
            @click="onCancelChanges"
          >
            Cancel
          </v-btn>
        </v-toolbar>
      </template>
      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-container fluid>
            <v-tabs>
              <v-tab key="1"> Answer Choices </v-tab>
              <v-tab v-if="item.attributes.isExternal == false" key="2">
                Content
              </v-tab>
              <v-tab-item key="1">
                <AnswerChoice
                  :question-object-id="item.id"
                  :question-id="item.attributes.questionId"
                  :questions="questions"
                  :read-only="item.attributes.isExternal == true"
                />
              </v-tab-item>
              <v-tab-item v-if="item.attributes.isExternal == false" key="2">
                <ContentResourceView
                  ref="contentList"
                  resource-type="question"
                  :resource-id="item.id"
                  :default-values="defaultContent"
                >
                  <template #actions>
                    <v-btn
                      color="primary"
                      icon
                      :loading="loading"
                      class="mb-2 mr-2"
                      @click="onContentEdit(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </ContentResourceView>
              </v-tab-item>
            </v-tabs>
          </v-container>
        </td>
      </template>
      <template #item.attributes.sequence="{ item }">
        <v-layout>
          {{ item.attributes.sequence }}
          <v-icon
            v-if="hasWritePerm()"
            class="ml-2"
            :disabled="upDisabled(item)"
            @click.stop="onSequenceUp(item)"
          >
            mdi-arrow-up-drop-circle-outline
          </v-icon>
          <v-icon
            v-if="hasWritePerm()"
            :disabled="downDisabled(item)"
            @click.stop="onSequenceDown(item)"
          >
            mdi-arrow-down-drop-circle-outline
          </v-icon>
        </v-layout>
      </template>
      <template #item.action="{ item }">
        <v-icon
          v-if="hasWritePerm() && item.attributes.isExternal == false"
          class="mr-2"
          @click.stop="onEditItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="hasWritePerm() && item.attributes.isExternal == false"
          class="mr-2"
          @click.stop="onDeleteItem(item)"
        >
          mdi-delete
        </v-icon>
        <v-icon
          v-if="hasWritePerm() && item.attributes.isExternal == false"
          @click.stop="onCopyItem(item)"
        >
          mdi-content-duplicate
        </v-icon>
      </template>
      <template #no-data>
        <v-btn color="primary" @click="refreshList"> Reset </v-btn>
      </template>
    </v-data-table>
    <div>
      <v-dialog v-model="editItemDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text v-if="editItem">
            <div class="pb-2">
              <span class="od-label">Question:</span
              ><span class="pl-2">{{ editItem.attributes.questionId }}</span>
            </div>
            <v-text-field
              v-model="editItem.attributes.question"
              outlined
              label="Question"
            />
            <v-text-field
              v-model="editItem.attributes.slug"
              outlined
              label="Slug"
            />
            <v-text-field
              v-model="editItem.attributes.sequence"
              type="number"
              outlined
              label="Sequence"
            />
            <v-row>
              <v-col>
                <v-switch
                  v-model="editItem.attributes.isMultiChoice"
                  label="Multi Choice"
                />
              </v-col>
              <v-col>
                <v-switch
                  v-model="editItem.attributes.isSplitAnswer"
                  label="Split Answer"
                />
              </v-col>
              <v-col>
                <v-switch
                  v-model="editItem.attributes.isExternal"
                  label="External"
                  readonly
                  disabled
                />
              </v-col>
            </v-row>
            <v-text-field
              v-model="editItem.attributes.linkTable"
              outlined
              label="Link Table"
            />
            <v-text-field
              v-model="editItem.attributes.linkField"
              outlined
              label="Link Field"
            />
            <v-text-field
              v-model="editItem.attributes.metaData"
              outlined
              label="Meta Data"
              :rules="[rules.json]"
              validate-on-blur
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              :disabled="loading"
              @click="close"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              @click="doSave"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteItemDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Delete Answer Choice</span>
          </v-card-title>

          <v-card-text v-if="editItem">
            <div class="pb-2">
              <span class="od-label">Question:</span
              ><span class="pl-2">{{ editItem.attributes.questionId }}</span>
            </div>
            <v-text-field
              v-model="editItem.attributes.question"
              outlined
              label="Question"
              readonly
            />
            <v-text-field
              v-model="editItem.attributes.slug"
              outlined
              label="Slug"
              readonly
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              :disabled="loading"
              @click="close"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              @click="doDelete"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="editContentDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Edit Content</span>
          </v-card-title>

          <v-card-text v-if="editItem">
            <ContentEdit
              ref="contentEditor"
              :prefix="getQuestionPrefix(editItem)"
              :values="defaultEditContent"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              :disabled="loading"
              @click="close"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              @click="doSaveContent"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import { OneDropApi } from '../../onedrop-api';
import { ConsoleLog } from '../../onedrop-utils';
import AnswerChoice from './AnswerChoice';
import ContentEdit from '../ContentEdit';
import ContentResourceView from '@/components/content/ContentResourceView';

export default {
  name: 'Question',
  components: { ContentResourceView, AnswerChoice, ContentEdit },
  props: ['groupId', 'readOnly'],
  data: function () {
    return {
      loading: false,
      expanded: [],
      editItemDialog: false,
      deleteItemDialog: false,
      editContentDialog: false,
      headers: [
        {
          text: 'Id',
          value: 'attributes.questionId',
        },
        {
          text: 'Object Id',
          value: 'id',
        },
        {
          text: 'Question',
          value: 'attributes.question',
        },
        {
          text: 'Slug',
          value: 'attributes.slug',
        },
        {
          text: 'Sequence',
          value: 'attributes.sequence',
        },
        {
          text: 'Multi-choice',
          value: 'attributes.isMultiChoice',
        },
        {
          text: 'Split Answer',
          value: 'attributes.isSplitAnswer',
        },
        {
          text: 'External',
          value: 'attributes.isExternal',
        },
        {
          text: 'Meta Data',
          value: 'attributes.metaData',
        },
        {
          text: 'Link Table',
          value: 'attributes.linkTable',
        },
        {
          text: 'Link Field',
          value: 'attributes.linkField',
        },
        { text: 'Actions', value: 'action', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      rules: {
        json: (value) => {
          try {
            if (value) JSON.parse(value);
            return true;
          } catch (e) {
            return 'Invalid JSON';
          }
        },
      },
      questions: [],
      origQuestions: null,
      editedIndex: -1,
      editItem: null,
      defaultContent: {
        headline: { defaultValue: null },
        title: { defaultValue: null },
        description: { defaultValue: null },
        whyWeAsk: { defaultValue: null },
      },
      defaultEditContent: {
        headline: null,
        title: null,
        description: null,
        whyWeAsk: { defaultValue: null },
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex == -1 ? 'New Question' : `Edit Question`;
    },
    hasChanges() {
      if (this.origQuestions) {
        for (let i in this.questions) {
          ConsoleLog(
            `${i}: ${this.questions[i].id}, ${this.origQuestions[i].id}`
          );
          if (this.questions[i].id !== this.origQuestions[i].id) return true;
        }
      }
      return false;
    },
  },
  watch: {
    groupId: function (n, o) {
      if (o !== n) this.refreshList();
    },
  },
  created() {
    this.refreshList();
  },
  methods: {
    getQuestionPrefix(item) {
      return item ? `question.${item.attributes.questionId}.` : item;
    },
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'question-write']);
    },
    refreshList: function () {
      ConsoleLog('Fetching questions');
      this.loading = true;
      OneDropApi.getQuestions(
        this.groupId,
        (response) => {
          this.questions = response.data;
          this.origQuestions = JSON.parse(JSON.stringify(this.questions));
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    created: function () {
      this.refreshList();
    },
    close: function () {
      this.loading = false;
      this.editedIndex = -1;
      this.editItem = null;
      this.editItemDialog = false;
      this.deleteItemDialog = false;
      this.editContentDialog = false;
    },
    modifyAttrs(attrs) {
      attrs.groupId = Number(attrs.groupId);
      attrs.sequence = Number(attrs.sequence);
      if (!attrs.slug) delete attrs.slug;
      if (!attrs.linkTable) delete attrs.linkTable;
      if (!attrs.linkTable) delete attrs.linkField;
      if (!attrs.metaData) delete attrs.metaData;
    },
    doSave: function () {
      let attrs = this.editItem.attributes;
      this.modifyAttrs(attrs);
      let body = { data: [this.editItem] };
      ConsoleLog(`Save ${this.editedIndex}, ${JSON.stringify(this.editItem)}`);
      this.loading = true;
      if (this.editedIndex > -1) {
        OneDropApi.updateQuestion(
          body,
          () => {
            this.refreshList();
            this.toast(`Question updated successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.onApiError(error);
            this.loading = false;
          }
        );
      } else {
        OneDropApi.insertQuestion(
          body,
          () => {
            this.refreshList();
            this.toast(`Question added successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.onApiError(error);
            this.loading = false;
          }
        );
      }
    },
    doDelete: function () {
      ConsoleLog(
        `Delete ${this.editedIndex}, ${JSON.stringify(this.editItem)}`
      );
      this.loading = true;
      OneDropApi.del(
        `v3/admin/question-group/${this.groupId}/question/${this.editItem.attributes.questionId}`,
        null,
        () => {
          this.refreshList();
          this.toast(`Question deleted successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.onApiError(error);
          this.loading = false;
        }
      );
    },
    doSaveContent() {
      const contentChanges = this.$refs.contentEditor.getChanges();
      if (contentChanges) {
        ConsoleLog(`Content Changes: ${JSON.stringify(contentChanges)}`);
        this.$refs.contentEditor.saveChanges(
          contentChanges,
          () => {
            this.$refs.contentList.refreshList();
            this.toast(`Content updated successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.onApiError(error);
            this.loading = false;
          }
        );
      } else this.close();
    },
    onNewItem: function () {
      this.editItem = {
        type: 'question',
        attributes: {
          groupId: this.groupId,
          sequence: 0,
          question: '',
          linkTable: null,
          linkField: null,
          slug: null,
          isMultiChoice: false,
          isSplitAnswer: false,
          isExternal: false,
          metaData: null,
        },
      };
      this.editedIndex = -1;
      this.editItemDialog = true;
    },
    onEditItem: function (item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.editedIndex = this.questions.findIndex((v) => v.id === item.id);
      this.editItemDialog = true;
    },
    onDeleteItem: function (item) {
      this.editItem = item;
      this.editedIndex = this.questions.findIndex((v) => v.id === item.id);
      this.deleteItemDialog = true;
    },
    onContentEdit: function (item) {
      ConsoleLog(`Edit item: ${JSON.stringify(item)}`);
      this.editItem = JSON.parse(JSON.stringify(item));
      this.editedIndex = this.questions.findIndex((v) => v.id === item.id);
      this.editContentDialog = true;
    },
    onCopyItem(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.editItem.attributes.sequence = this.questions.length + 1;
      delete this.editItem.attributes.questionId;
      delete this.editItem.id;
      this.editedIndex = -1;
      this.editItemDialog = true;
    },
    upDisabled(item) {
      let index = this.questions.findIndex((v) => v.id === item.id);
      return index <= 0;
    },
    downDisabled(item) {
      let index = this.questions.findIndex((v) => v.id === item.id);
      return index >= this.questions.length - 1;
    },
    onSequenceUp(item) {
      let index = this.questions.findIndex((v) => v.id === item.id);
      if (index > 0) {
        let prev = this.questions[index - 1];
        item.attributes.sequence = item.attributes.sequence - 1;
        prev.attributes.sequence = prev.attributes.sequence + 1;
        this.questions.splice(index - 1, 1, item);
        this.questions.splice(index, 1, prev);
      }
    },
    onSequenceDown(item) {
      let index = this.questions.findIndex((v) => v.id === item.id);
      if (index < this.questions.length) {
        let next = this.questions[index + 1];
        item.attributes.sequence = item.attributes.sequence + 1;
        next.attributes.sequence = next.attributes.sequence - 1;
        this.questions.splice(index, 1, next);
        this.questions.splice(index + 1, 1, item);
      }
    },
    onSaveChanges() {
      const data = [];
      for (let i in this.questions) {
        if (this.questions[i].id !== this.origQuestions[i].id) {
          const d = this.questions[i];
          data.push(d);
        }
      }
      ConsoleLog(`Save: ${JSON.stringify(data)}`);
      if (data.length > 0) {
        this.loading = true;
        const body = { data: data };
        OneDropApi.updateQuestion(
          body,
          () => {
            this.refreshList();
            this.toast(`Questions updated successfully`);
          },
          (error) => {
            ConsoleLog(error);
            this.onApiError(error);
            this.loading = false;
          }
        );
      }
    },
    onCancelChanges() {
      this.questions = JSON.parse(JSON.stringify(this.origQuestions));
    },
  },
};
</script>

<style></style>
