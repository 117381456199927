<template>
  <v-container fluid class="ma-4">
    <v-progress-linear v-if="user === null" indeterminate color="primary" />
    <template v-else>
      <v-tabs>
        <v-tab key="de"> Details </v-tab>
        <v-tab key="la"> Linked Accounts </v-tab>
        <v-tab v-if="hasUserReadPerm" key="te"> Track Events </v-tab>
        <v-tab v-if="hasQuestionResponsePerm" key="qr">
          Question Responses
        </v-tab>
        <v-tab v-if="hasWritePerm" key="ro"> Roles </v-tab>
        <v-tab v-if="hasRiskScorePerm" key="rs"> Risk Scores </v-tab>
        <v-tab v-if="hasUserReadPerm && includeEligibilityTab" key="ee">
          Eligibility Events
        </v-tab>
        <v-tab v-if="hasUserReadPerm" key="ae"> Audit Events </v-tab>
        <v-tab-item key="de">
          <v-row>
            <v-col>
              <v-row no-gutters>
                <v-col cols="12" class="od-heading">
                  <h2>Profile</h2>
                </v-col>
              </v-row>
              <ProfileDetails
                :user-id="user.id"
                :profile="user.attributes.profile"
              />
            </v-col>
            <v-col>
              <v-row no-gutters>
                <v-col cols="12" class="od-heading">
                  <h2>Partner</h2>
                </v-col>
              </v-row>
              <UserPartnerDetail
                :partner-id="user.attributes.profile.partner"
              />
              <v-row no-gutters>
                <v-col cols="12" class="od-heading">
                  <h2>Coach</h2>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="4" class="od-label"> Coaching Status </v-col>
                <v-col cols="8">
                  {{ user.attributes.coachingStatus }}
                </v-col>
              </v-row>
              <UserCoachDetails :coach-id="user.attributes.coachId" />
            </v-col>
            <v-col>
              <v-row no-gutters>
                <v-col cols="12" class="od-heading">
                  <h2>Subscription</h2>
                </v-col>
              </v-row>
              <SubscriptionDetails
                :subscription="user.attributes.subscriptionStatus"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row no-gutters>
                <v-col cols="12" class="od-heading">
                  <h2>Devices</h2>
                </v-col>
              </v-row>
              <UserDevices :devices="sortedDevices" />
            </v-col>
            <v-col>
              <v-row no-gutters>
                <v-col v-if="hasUserReadPerm" cols="12" class="od-heading">
                  <h2>Activity</h2>
                </v-col>
              </v-row>
              <ActivityDetails v-if="hasUserReadPerm" :user-id="user.id" />
            </v-col>
            <v-col>
              <EntitlementDetails
                :subscription="user.attributes.subscriptionStatus"
              />
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item key="la">
          <linked-accounts :user-id="user.id" />
        </v-tab-item>
        <v-tab-item v-if="hasUserReadPerm" key="te">
          <user-track-event-list :user-id="user.id" />
        </v-tab-item>
        <v-tab-item v-if="hasQuestionResponsePerm" key="qr">
          <v-row>
            <v-col cols="8">
              <question-viewer :user-id="user.id" />
            </v-col>
            <v-col />
          </v-row>
        </v-tab-item>
        <v-tab-item v-if="hasWritePerm" key="ro">
          <v-row>
            <v-col>
              <UserRole :user-id="user.id" />
            </v-col>
            <v-col />
          </v-row>
        </v-tab-item>
        <v-tab-item v-if="hasRiskScorePerm" key="rs">
          <RiskScoreDetails :user-id="user.id" />
        </v-tab-item>
        <v-tab-item v-if="hasWritePerm && includeEligibilityTab" key="ee">
          <eligibility-events :user-id="user.id" />
        </v-tab-item>
        <v-tab-item key="ae">
          <audit-log-viewer event-type="eligibility" :object-id="auditId" />
        </v-tab-item>
      </v-tabs>
    </template>
  </v-container>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import UserPartnerDetail from './UserPartnerDetail';
import SubscriptionDetails from './SubscriptionDetails';
import { OneDropUtils } from '../onedrop-utils';
import ActivityDetails from './ActivityDetails';
import ProfileDetails from './ProfileDetails';
import UserDevices from './UserDevices';
import UserRole from './UserRole';
import QuestionViewer from '@/components/question/UserQuestionViewer';
import UserCoachDetails from '@/components/UserCoachDetails';
import LinkedAccounts from '@/components/LinkedAccounts';
import EntitlementDetails from '@/components/EntitlementDetails';
import UserTrackEventList from '@/components/UserTrackEventList';
import RiskScoreDetails from '@/components/RiskScoreDetails';
import EligibilityEvents from '@/components/EligibilityEvents.vue';
import AuditLogViewer from '@/components/AuditLogViewer';

export default {
  name: 'UserDetails',
  components: {
    UserDevices,
    ProfileDetails,
    ActivityDetails,
    SubscriptionDetails,
    UserPartnerDetail,
    UserCoachDetails,
    UserRole,
    QuestionViewer,
    LinkedAccounts,
    EntitlementDetails,
    UserTrackEventList,
    RiskScoreDetails,
    EligibilityEvents,
    AuditLogViewer,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
    includeEligibilityTab: Boolean,
    auditId: String,
  },
  data: function () {
    return {
      user: null,
    };
  },
  computed: {
    joinedAt: function () {
      return OneDropUtils.displayDate(this.user.attributes.profile.createdAt);
    },
    hasUserReadPerm: function () {
      return OneDropApi.hasOneOfPerms(['admin-read', 'admin-user-read']);
    },
    hasQuestionResponsePerm: function () {
      return OneDropApi.hasOneOfPerms(['admin-read', 'question-response-read']);
    },
    hasWritePerm: function () {
      return OneDropApi.hasOneOfPerms(['admin-write']);
    },
    hasRiskScorePerm: function () {
      return OneDropApi.hasPerm('risk-score-read');
    },
    sortedDevices: function () {
      if (this.user.attributes.installations)
        return this.user.attributes.installations.concat();
      else return null;
    },
  },
  watch: {
    userId: function () {
      this.refreshUser();
    },
  },
  created: function () {
    this.refreshUser();
  },
  methods: {
    refreshUser: function () {
      this.user = null;
      OneDropApi.getUser(
        this.userId,
        (result) => {
          this.user = result.data[0];
        },
        (error) => this.onApiError(error)
      );
    },
  },
};
</script>

<style scoped></style>
