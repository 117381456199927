<template>
  <v-container fluid>
    <v-layout class="display-1 py-4"> Manage SKU Entitlement </v-layout>
    <SKUEntitlementList />
  </v-container>
</template>

<script>
import SKUEntitlementList from '@/components/SKUEntitlementList';

export default {
  name: 'SKUEntitlementEditor',
  components: { SKUEntitlementList },
};
</script>

<style scoped></style>
