<!-- Fetch questions and display them for a specific user -->
<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <v-card class="mx-auto" flat>
    <slot />
    <v-progress-linear indeterminate color="primary" :active="loading" />
    <v-expansion-panels
      v-if="data && data.length > 0"
      v-model="panels"
      multiple
    >
      <v-expansion-panel
        v-for="item in data"
        v-if="item.attributes.questions.length > 0"
        :key="item.id"
      >
        <v-expansion-panel-header>
          <h2 v-if="item.id === 'NA'">
            Group {{ item.id }}: {{ item.attributes.title }}
          </h2>
          <router-link
            v-else
            :to="{
              path: '/setup/questioneditor',
              query: { query: String(item.id) },
            }"
            target="_blank"
          >
            <h2>Group {{ item.id }}: {{ item.attributes.title }}</h2>
          </router-link>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div
            v-for="question in item.attributes.questions"
            :key="question.questionId"
          >
            <user-question :question="question" />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-else>No responses</div>
  </v-card>
</template>

<script>
import { OneDropApi } from '@/onedrop-api';
import { ConsoleLog } from '@/onedrop-utils';
import UserQuestion from '@/components/question/UserQuestion';

export default {
  name: 'QuestionViewer',
  components: { UserQuestion },
  props: {
    userId: String,
  },
  data: function () {
    return {
      loading: false,
      data: null,
      groups: null,
      responses: null,
      panels: [],
      answersOptions: [],
      questions: [],
      priorQuestionGroup: {
        id: 'NA',
        attributes: {
          title: 'Prior Question Group Answers',
          slug: 'NA',
          questions: [],
        },
      },
    };
  },
  mounted() {
    this.allQuestions();
    this.refresh();
  },
  methods: {
    refresh() {
      this.loading = true;
      OneDropApi.get(
        `/v3/questions/response/admin/user/${this.userId}`,
        (response) => {
          this.responses = response.data;
          this.loading = false;
          this.refreshQuestions();
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    refreshQuestions() {
      this.loading = true;
      OneDropApi.get(
        `/v3/questions/admin/user/${this.userId}`,
        (response) => {
          this.groups = response.data;
          this.processQuestions();
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    buildPriorQuestions(id, datum) {
      OneDropApi.getAnswers(
        id,
        (response) => {
          this.priorQuestionGroup.attributes.questions.push({
            ...datum,
            answerChoiceId: id,
            question: datum.attributes.question,
            questionId: datum.attributes.questionId,
            answerChoices: response.data.map((v) => ({
              ...v.attributes,
              answerId: v.attributes.answerChoiceId,
              ...(String(datum.attributes.answerId) ===
                String(v.attributes.answerChoiceId) && {
                response: datum,
              }),
            })),
          });
        },
        (error) => {
          this.onApiError(error);
        }
      );
    },
    allQuestions() {
      OneDropApi.get(
        '/v3/admin/questions',
        (response) => {
          this.questions = response.data;
        },
        (error) => {
          this.onApiError(error);
        }
      );
    },
    processQuestions() {
      this.data = [];
      const priorQuestionIds = [];
      for (let i in this.groups) {
        let g = this.groups[i];
        let gq = g.attributes.questions;
        for (let j in gq) {
          let q = gq[j];
          let ac = q.answerChoices;
          for (let k in ac) {
            let a = ac[k];
            const ua = this.responses.find(
              (x) =>
                String(q.questionId) == String(x.attributes.questionId) &&
                String(a.answerId) == String(x.attributes.answerId)
            );
            if (ua) {
              a['response'] = ua;
              if (!this.data.includes(g)) this.data.push(g);
            }
          }
        }
      }
      this.responses.forEach((v) => {
        if (
          !this.data
            ?.map((v) => v.attributes.questions.map((q) => q.questionId))[0]
            ?.includes(String(v.attributes.questionId)) &&
          !priorQuestionIds.includes(v.attributes.questionId)
        ) {
          const validId = this.questions?.filter(
            (question) =>
              String(v.attributes.questionId) ==
                String(question.attributes.questionId) &&
              String(v.attributes.answerId) == String(v.attributes.answerId)
          )[0].id;
          priorQuestionIds.push(v.attributes.questionId);
          this.buildPriorQuestions(validId, v);
        }
      });
      this.data = [...this.data, this.priorQuestionGroup];
      if (this.data)
        this.panels = [...Array(this.data.length).keys()].map((k, i) => i);
      else this.panels = [];
    },
    getClass(answer) {
      if (answer.response) return 'font-weight-bold';
      else return '';
    },
  },
};
</script>

<style scoped></style>
