<template>
  <v-combobox
    v-model="tagValue"
    :label="tagKey"
    :items="tagTypes"
    :hint="hint"
    persistent-hint
    outlined
    chips
    deletable-chips
    multiple
    hide-selected
  />
</template>

<script>
import { ConsoleLog } from '@/onedrop-utils';

export default {
  name: 'TagEditor',
  props: ['value', 'tagKey', 'hint'],
  data: function () {
    return {
      tagTypes: [
        'type-1',
        'type-2',
        'pre-diabetes',
        'high-blood-pressure',
        'high-cholesterol',
        'gestational',
        'cv-prevent',
      ],
    };
  },
  computed: {
    jsonTag: {
      get: function () {
        return this.value ? JSON.parse(this.value) : {};
      },
    },
    tagValue: {
      get: function () {
        return this.jsonTag ? this.jsonTag[this.tagKey] : this.jsonTag;
      },
      set: function (newValue) {
        ConsoleLog(`tagValue: ${newValue}`);
        let tags = this.jsonTag;
        if (newValue && newValue.length > 0) tags[this.tagKey] = newValue;
        else delete tags[this.tagKey];
        if (Object.keys(tags).length == 0) tags = null;
        else tags = JSON.stringify(tags);
        this.$emit('input', tags);
      },
    },
  },
};
</script>

<style scoped></style>
