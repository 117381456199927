var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-select',{attrs:{"items":_vm.resourceTypes,"label":"Resource Type","hide-selected":""},model:{value:(_vm.selectedResourceType),callback:function ($$v) {_vm.selectedResourceType=$$v},expression:"selectedResourceType"}}),_c('v-spacer')],1),_c('v-toolbar-title',[_vm._v("Groups")]),_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"show-expand":"","single-expand":""},scopedSlots:_vm._u([{key:"item.attributes.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(item.attributes.createdAt)))])]}},{key:"item.resourceId",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.getTopResource(item).resourceId))])]}},{key:"item.resourceName",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.getTopResource(item).resourceName))])]}},{key:"item.resourceNumber",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.attributes.resources ? item.attributes.resources.length : 0)+" ")])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-tabs',[_c('v-tab',{key:"1"},[_vm._v(" Codes ")]),_c('v-tab',{key:"2"},[_vm._v(" Resources ")]),_c('v-tab-item',{key:"1"},[_c('code-list',{attrs:{"items":item.attributes.codes}})],1),_c('v-tab-item',{key:"2"},[_c('resource-list',{attrs:{"items":item.attributes.resources}})],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }