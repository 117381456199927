<template>
  <v-card flat>
    <v-data-table
      :items="questions"
      :headers="headers"
      :loading="loading"
      :search="search"
      :expanded.sync="expanded"
      disable-pagination
      hide-default-footer
      show-expand
      single-expand
    >
      <template #top>
        <v-layout>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="search"
                label="Search"
                hide-details
                outlined
              />
            </v-col>
            <v-col />
          </v-row>
        </v-layout>
      </template>
      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-container fluid>
            <v-tabs>
              <v-tab key="1"> Answer Choices </v-tab>
              <v-tab v-if="item.attributes.isExternal == false" key="2">
                Content
              </v-tab>
              <v-tab key="3"> Groups </v-tab>
              <v-tab-item key="1">
                <AnswerChoice
                  :question-object-id="item.id"
                  :question-id="item.attributes.questionId"
                  :questions="questions"
                  :read-only="item.attributes.isExternal == true"
                />
              </v-tab-item>
              <v-tab-item v-if="item.attributes.isExternal == false" key="2">
                <ContentResourceView
                  ref="contentList"
                  resource-type="question"
                  :resource-id="item.id"
                  :default-values="defaultContent"
                >
                  <template #actions>
                    <v-btn
                      color="primary"
                      icon
                      :loading="loading"
                      class="mb-2 mr-2"
                      @click="onContentEdit(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </ContentResourceView>
              </v-tab-item>
              <v-tab-item key="3">
                <question-group :question-id="item.attributes.questionId" />
              </v-tab-item>
            </v-tabs>
          </v-container>
        </td>
      </template>
      <template #item.action="{ item }">
        <v-icon
          v-if="hasWritePerm() && item.attributes.isExternal == false"
          class="mr-2"
          @click.stop="onEditItem(item)"
        >
          mdi-pencil
        </v-icon>
      </template>
      <template #no-data>
        <v-btn color="primary" @click="refreshList"> Reset </v-btn>
      </template>
    </v-data-table>
    <div>
      <v-dialog v-model="editItemDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text v-if="editItem">
            <div class="pb-2">
              <span class="od-label">Question:</span
              ><span class="pl-2">{{ editItem.attributes.questionId }}</span>
            </div>
            <v-text-field
              v-model="editItem.attributes.question"
              outlined
              label="Question"
            />
            <v-text-field
              v-model="editItem.attributes.slug"
              outlined
              label="Slug"
            />
            <v-text-field
              v-model="editItem.attributes.sequence"
              type="number"
              outlined
              label="Sequence"
            />
            <v-switch
              v-model="editItem.attributes.isMultiChoice"
              label="Multi Choice"
            />
            <v-switch
              v-model="editItem.attributes.isSplitAnswer"
              label="Split Answer"
            />
            <v-text-field
              v-model="editItem.attributes.linkTable"
              outlined
              label="Link Table"
            />
            <v-text-field
              v-model="editItem.attributes.linkField"
              outlined
              label="Link Field"
            />
            <v-text-field
              v-model="editItem.attributes.metaData"
              outlined
              label="Meta Data"
              :rules="[rules.json]"
              validate-on-blur
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="editContentDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Edit Content</span>
          </v-card-title>

          <v-card-text v-if="editItem">
            <ContentEdit
              ref="contentEditor"
              :prefix="getQuestionPrefix(editItem)"
              :values="defaultEditContent"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="doSaveContent">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import { OneDropApi } from '../../onedrop-api';
import { ConsoleLog } from '../../onedrop-utils';
import AnswerChoice from './AnswerChoice';
import ContentEdit from '../ContentEdit';
import ContentResourceView from '@/components/content/ContentResourceView';
import QuestionGroup from '@/components/question/QuestionGroup';

export default {
  name: 'QuestionList',
  components: { ContentResourceView, AnswerChoice, ContentEdit, QuestionGroup },
  data: function () {
    return {
      loading: false,
      search: '',
      expanded: [],
      editItemDialog: false,
      editContentDialog: false,
      headers: [
        {
          text: 'Id',
          value: 'attributes.questionId',
        },
        {
          text: 'Object Id',
          value: 'id',
        },
        {
          text: 'Question',
          value: 'attributes.question',
        },
        {
          text: 'Slug',
          value: 'attributes.slug',
        },
        {
          text: 'Sequence',
          value: 'attributes.sequence',
        },
        {
          text: 'Multi-choice',
          value: 'attributes.isMultiChoice',
        },
        {
          text: 'Split Answer',
          value: 'attributes.isSplitAnswer',
        },
        {
          text: 'Meta Data',
          value: 'attributes.metaData',
        },
        {
          text: 'Link Table',
          value: 'attributes.linkTable',
        },
        {
          text: 'Link Field',
          value: 'attributes.linkField',
        },
        {
          text: 'External',
          value: 'attributes.isExternal',
        },
        { text: 'Actions', value: 'action', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      rules: {
        json: (value) => {
          try {
            if (value) JSON.parse(value);
            return true;
          } catch (e) {
            return 'Invalid JSON';
          }
        },
      },
      questions: [],
      editedIndex: -1,
      editItem: null,
      defaultContent: {
        headline: { defaultValue: null },
        title: { defaultValue: null },
        description: { defaultValue: null },
        whyWeAsk: { defaultValue: null },
      },
      defaultEditContent: {
        headline: null,
        title: null,
        description: null,
        whyWeAsk: null,
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex == -1 ? 'New Question' : `Edit Question`;
    },
  },
  watch: {
    groupId: function (n, o) {
      if (o !== n) this.refreshList();
    },
  },
  mounted() {
    if (this.$route.query.query) {
      this.search = decodeURIComponent(this.$route.query.query).trim();
    }
    this.refreshList();
  },
  methods: {
    getQuestionPrefix(item) {
      return item ? `question.${item.attributes.questionId}.` : item;
    },
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'question-write']);
    },
    refreshList: function () {
      ConsoleLog('Fetching questions');
      this.loading = true;
      OneDropApi.get(
        '/v3/admin/questions',
        (response) => {
          this.questions = response.data;
          ConsoleLog(`Got ${this.questions.length} questions`);
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    created: function () {
      this.refreshList();
    },
    close: function () {
      this.loading = false;
      this.editedIndex = -1;
      this.editItem = null;
      this.editItemDialog = false;
      this.editContentDialog = false;
    },
    modifyAttrs(attrs) {
      attrs.groupId = Number(attrs.groupId);
      attrs.sequence = Number(attrs.sequence);
      if (!attrs.slug) delete attrs.slug;
      if (!attrs.linkTable) delete attrs.linkTable;
      if (!attrs.linkTable) delete attrs.linkField;
      if (!attrs.metaData) delete attrs.metaData;
    },
    doSave: function () {
      let attrs = this.editItem.attributes;
      this.modifyAttrs(attrs);
      let body = { data: [this.editItem] };
      ConsoleLog(`Save ${this.editedIndex}, ${JSON.stringify(this.editItem)}`);
      this.loading = true;
      if (this.editedIndex > -1) {
        OneDropApi.updateQuestion(
          body,
          () => {
            this.refreshList();
            this.toast(`Question updated successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.onApiError(error);
            this.close();
          }
        );
      } else {
        OneDropApi.insertQuestion(
          body,
          () => {
            this.refreshList();
            this.toast(`Question added successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.onApiError(error);
            this.close();
          }
        );
      }
    },
    doSaveContent() {
      const contentChanges = this.$refs.contentEditor.getChanges();
      if (contentChanges) {
        ConsoleLog(`Content Changes: ${JSON.stringify(contentChanges)}`);
        this.$refs.contentEditor.saveChanges(
          contentChanges,
          () => {
            this.$refs.contentList.refreshList();
            this.toast(`Content updated successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.onApiError(error);
            this.close();
          }
        );
      } else this.close();
    },
    onNewItem: function () {
      this.editItem = {
        type: 'question',
        attributes: {
          groupId: this.groupId,
          sequence: 0,
          question: '',
          linkTable: null,
          linkField: null,
          slug: null,
          isMultiChoice: false,
          isSplitAnswer: false,
          metaData: null,
        },
      };
      this.editedIndex = -1;
      this.editItemDialog = true;
    },
    onEditItem: function (item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.editedIndex = this.questions.findIndex((v) => v.id === item.id);
      this.editItemDialog = true;
    },
    onContentEdit: function (item) {
      ConsoleLog(`Edit item: ${JSON.stringify(item)}`);
      this.editItem = JSON.parse(JSON.stringify(item));
      this.editedIndex = this.questions.findIndex((v) => v.id === item.id);
      this.editContentDialog = true;
    },
  },
};
</script>

<style></style>
