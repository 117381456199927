<template>
  <v-container fluid>
    <v-layout class="display-1 py-4"> Manage Coaches </v-layout>
    <div class="text-center">
      <v-progress-circular v-if="saving" indeterminate color="primary" />
    </div>
    <v-data-table
      :items="allCoaches"
      :headers="headers"
      :search="search"
      :loading="loading"
      :expanded.sync="expanded"
      show-expand
      single-expand
      @click:row="rowClicked"
    >
      <template #top>
        <v-dialog v-model="dialog" max-width="75%">
          <template #activator="{ on }">
            <v-toolbar flat color="white">
              <v-text-field
                v-model="search"
                label="Search"
                hide-details
                outlined
                class="mr-4"
              />
              <v-switch
                v-model="includeArchived"
                label="Show Archived"
                class="pa-3"
              />
              <v-switch
                v-model="includeHidden"
                label="Show Hidden"
                class="pa-3 mr-4"
              />
              <v-btn
                color="primary"
                dark
                :loading="loading"
                :disabled="loading"
                class="mb-2 mr-2"
                @click="refreshCoaches"
              >
                Reload
              </v-btn>
              <v-btn
                v-if="hasWritePerm()"
                color="primary"
                :disabled="loading"
                dark
                class="mb-2"
                v-on="on"
                @click="onNewCoach"
              >
                New Coach
              </v-btn>
            </v-toolbar>
          </template>
          <v-card v-if="editedItem">
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
              <v-spacer />
              <v-img
                v-if="editedItem.attributes.imageUrl"
                :src="editedItem.attributes.imageUrl"
                max-width="40"
              />
            </v-card-title>

            <v-card-subtitle>
              <template v-if="editedItem.id">
                <span class="od-label">Id: </span
                ><span>{{ editedItem.id }}</span>
              </template>
            </v-card-subtitle>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="5">
                    <UserSelect
                      v-model="editedItem.attributes.userId"
                      :initial-items="getInitialItems(editedItem)"
                      hint="User associated with the coach"
                      :rules="[rules.required]"
                      :readonly="!!editedItem.id"
                      @item-changed="userSelected"
                    />
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      v-model="editedItem.attributes.email"
                      label="Email"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-switch
                      v-model="editedItem.attributes.hidden"
                      label="Hidden"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5">
                    <v-text-field
                      v-model="editedItem.attributes.firstName"
                      label="First Name"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      v-model="editedItem.attributes.lastName"
                      label="Last Name"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-switch
                      v-model="editedItem.attributes.archived"
                      label="Archived"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="editedItem.attributes.partnerId"
                      :items="partnerList"
                      label="Partner"
                      :item-text="partnerName"
                      item-value="id"
                      hide-selected
                      chips
                      deletable-chips
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      v-model="editedItem.attributes.coachGroups"
                      :items="groupList"
                      item-value="id"
                      item-text="slug"
                      label="Group"
                      hide-selected
                      chips
                      deletable-chips
                      multiple
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="userRoles"
                      :items="coachRoleOptions"
                      :loading="userRolesLoading"
                      hide-no-data
                      hide-selected
                      label="Coach Roles"
                      chips
                      multiple
                      deletable-chips
                      :rules="[rules.required]"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="editedItem.attributes.title"
                    label="Title"
                    :rules="[rules.required]"
                  />
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="editedItem.attributes.imageUrl"
                    label="Image URL"
                    type="url"
                    :rules="[rules.required]"
                    append-outer-icon="mdi-paperclip"
                    @click:append-outer="editImageDialog = true"
                  />
                  <amazon-s3-uploader
                    v-model="editImageDialog"
                    :edit-item="editedItem.attributes"
                    image-field="imageUrl"
                    @setUrlAttribute="setUrlAttribute"
                  />
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="editedItem.attributes.infoUrl"
                    label="Info URL"
                    type="url"
                    :rules="[rules.required]"
                  />
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="editedItem.attributes.scheduleUrl"
                    label="Schedule URL"
                    type="url"
                  />
                </v-row>
                <v-row>
                  <v-textarea
                    v-model="editedItem.attributes.biography"
                    label="Biography"
                    auto-grow
                    rows="2"
                    row-height="24px"
                  />
                  =
                </v-row>
                <v-row>
                  <v-col cols="5">
                    <v-combobox
                      v-model="editedItem.attributes.timeZone"
                      :items="timeZones"
                      label="TimeZone"
                    />
                  </v-col>
                  <v-col />
                  <v-col cols="5">
                    <v-combobox
                      v-model="editedItem.attributes.timeZoneUserFacing"
                      :items="timeZonesUserFacing"
                      label="TimeZone User Facing"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="saveCoachButtonDisabled"
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" max-width="600px">
          <v-card v-if="editedItem">
            <v-card-title>
              <span class="headline">Delete Item</span>
            </v-card-title>

            <v-card-text>
              <v-row label="Object ID">
                <v-col>Object ID: {{ editedItem.id }}</v-col>
              </v-row>
              <v-text-field
                v-model="editedItem.attributes.firstName"
                outlined
                label="First Name"
                readonly
              />
              <v-text-field
                v-model="editedItem.attributes.lastName"
                outlined
                label="Last Name"
                readonly
              />
              <v-text-field
                v-model="editedItem.attributes.email"
                outlined
                label="Email"
                readonly
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="deleteCoach">
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-if="assignRoleTo"
          v-model="showAssignRoleDialog"
          width="50%"
          hide-overlay
        >
          <UserRoleSelector
            :user-id="assignRoleTo"
            @assign-done="onAssignRoleClosed"
          />
        </v-dialog>
      </template>
      <template #header.action="{ header }">
        <span v-if="hasWritePerm()">{{ header.text }}</span>
      </template>
      <template #item.attributes.createdAt="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <div v-on="on">
              {{ formatDate(item.attributes.createdAt) }}
            </div>
          </template>
          <span>{{ item.displayDate }}</span>
        </v-tooltip>
      </template>
      <template #item.attributes.imageUrl="{ item }">
        <v-img
          v-if="item.attributes.imageUrl"
          :src="item.attributes.imageUrl"
          max-width="40"
          :title="item.attributes.imageUrl"
        />
      </template>
      <template #item.attributes.hidden="{ item }">
        <v-switch v-model="item.attributes.hidden" readonly />
      </template>
      <template #item.attributes.archived="{ item }">
        <v-switch v-model="item.attributes.archived" readonly />
      </template>
      <template #item.imageUrl="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <span
              class="d-inline-block text-truncate"
              style="max-width: 200px"
              v-on="on"
              >{{ item.imageUrl }}</span
            >
          </template>
          <span>{{ item.imageUrl }}</span>
        </v-tooltip>
      </template>
      <template #item.attributes.coachGroups="{ item }">
        <span>{{ groupIdToGroupSlugs(item.attributes.coachGroups) }}</span>
      </template>
      <template #item.action="{ item }">
        <v-icon
          v-if="hasWritePerm()"
          class="mr-2"
          title="Edit"
          @click.stop="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="hasWritePerm()"
          title="Delete"
          @click.stop="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-container fluid>
            <v-tabs>
              <v-tab key="1"> Details </v-tab>
              <v-tab key="2"> Tracks </v-tab>
              <v-tab key="3"> Audit Events </v-tab>
              <v-tab-item key="1">
                <coach-detail :coach="item" />
              </v-tab-item>
              <v-tab-item key="2">
                <CoachTrackList :coach-id="item.id" />
              </v-tab-item>
              <v-tab-item key="3">
                <audit-log-viewer event-type="coach" :object-id="item.id" />
              </v-tab-item>
            </v-tabs>
          </v-container>
        </td>
      </template>
      <template #no-data>
        <v-btn color="primary" @click="refreshCoaches"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { ConsoleLog, OneDropConstants, OneDropUtils } from '../onedrop-utils';
import UserSelect from '../components/UserSelect';
import UserRoleSelector from '../components/UserRoleSelector';
import CoachTrackList from '@/components/CoachTrackList';
import CoachDetail from '@/components/CoachDetail';
import AuditLogViewer from '@/components/AuditLogViewer';
import AmazonS3Uploader from '@/components/AmazonS3Uploader';

export default {
  name: 'CoachEditor',
  components: {
    UserSelect,
    UserRoleSelector,
    CoachTrackList,
    CoachDetail,
    AuditLogViewer,
    AmazonS3Uploader,
  },
  data: function () {
    return {
      dialog: false,
      deleteDialog: false,
      editImageDialog: false,
      includeArchived: false,
      includeHidden: false,
      loading: false,
      saving: false,
      search: '',
      dateFormat: 'yyyy-MM-dd',
      timeFormat: 'HH:mm',
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Image',
          value: 'attributes.imageUrl',
        },
        {
          text: 'First Name',
          value: 'attributes.firstName',
        },
        {
          text: 'Last Name',
          value: 'attributes.lastName',
        },
        {
          text: 'Email',
          value: 'attributes.email',
        },
        {
          text: 'Title',
          value: 'attributes.title',
        },
        {
          text: 'Hidden',
          value: 'attributes.hidden',
          filter: (value, search, item) =>
            this.includeHidden == true || !item.attributes.hidden,
        },
        {
          text: 'Archived',
          value: 'attributes.archived',
          filter: (value, search, item) =>
            this.includeArchived == true || !item.attributes.archived,
        },
        {
          text: 'User ID',
          value: 'attributes.userId',
        },
        {
          text: 'Groups',
          value: 'attributes.coachGroupSlugs',
        },
        { text: 'Actions', value: 'action', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      allCoaches: [],
      editedIndex: -1,
      editedItem: null,
      expanded: [],
      timeZones: [
        'America/New_York',
        'America/Chicago',
        'America/Denver',
        'America/Los_Angeles',
      ],
      timeZonesUserFacing: ['EST', 'CST', 'MST', 'PST'],
      rules: {
        required: (value) => !!value || 'Required.',
      },
      showAssignRoleDialog: false,
      assignRoleTo: null,
      groupList: [],
      coachRoles: [],
      coachRoleOptions: [],
      userRoles: [],
      currentUserRoles: [],
      userRolesLoading: false,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex == -1 ? 'New Coach' : 'Edit Coach';
    },
    partnerList() {
      return this.$store.getters.partnerList;
    },
    saveCoachButtonDisabled() {
      return !(
        this.editedItem.attributes.user !== null &&
        this.editedItem.attributes.email !== null &&
        this.editedItem.attributes.firstName !== null &&
        this.editedItem.attributes.lastName !== null &&
        this.editedItem.attributes.partnerId !== null &&
        this.editedItem.attributes.title !== null &&
        this.editedItem.attributes.imageUrl !== null &&
        this.editedItem.attributes.infoUrl !== null
      );
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    deleteDialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.refreshGroupList();
    this.refreshCoaches();
    this.getCoachRoles();
  },
  methods: {
    partnerName: (item) => `${item.name} (${item.id})`,
    getInitialItems(item) {
      if (item.attributes.userId) {
        let attrs = {};
        let coachUser = item.attributes.coachUser;
        if (coachUser) {
          attrs.firstname = coachUser.firstName;
          attrs.lastname = coachUser.lastName;
          attrs.email = coachUser.email;
        }
        return [
          {
            id: item.attributes.userId,
            type: 'user',
            attributes: attrs,
          },
        ];
      } else return [];
    },
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'coach-admin-write']);
    },
    hasAssignRolePerm: function () {
      return OneDropApi.hasPerm('assign-role');
    },
    setUrlAttribute(attributeKey, newVal) {
      this.editedItem.attributes[attributeKey] = newVal;
    },
    refreshGroupList() {
      OneDropApi.get(
        'v3/admin/coach-groups',
        (response) => {
          this.groupList = response.data.map((o) => {
            return { id: o.id, slug: o.attributes.slug };
          });
        },
        (error) => {
          // we are not displaying the error below because only coach-admins use this feature
          // but other users can still view this page and will see an error (403) which is confusing
          // the error is still visible if you look at the log
          // this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    groupIdToGroupSlugs(list) {
      return list
        ? list.map((id) => {
            let obj = this.groupList.find((o) => o.id == id);
            return obj ? obj.slug : id;
          })
        : list;
    },
    getCoachRoles() {
      OneDropApi.get(
        'v3/admin/roles',
        (response) => {
          this.coachRoles = response.data.filter((v) =>
            v.attributes.slug.includes('coach')
          );
          this.coachRoleOptions = this.coachRoles.map((v) => v.attributes.slug);
        },
        (error) => {
          this.onApiError(error);
        }
      );
    },
    getUserCoachRoles(userId) {
      this.userRolesLoading = true;
      OneDropApi.get(
        `v3/admin/roles/user/${userId}`,
        (response) => {
          const roles = response.data.flatMap((v) =>
            v.attributes.slug.includes('coach') ? [v.attributes.slug] : []
          );
          this.userRoles = roles;
          this.currentUserRoles = roles;
          this.userRolesLoading = false;
        },
        (error) => {
          this.onApiError(error);
          this.userRolesLoading = false;
        }
      );
    },
    enhanceGroups(item) {
      if (item.attributes?.coachGroups) {
        item.attributes.coachGroupSlugs = this.groupIdToGroupSlugs(
          item.attributes.coachGroups
        );
      }
      return item;
    },
    getPartnerById: function (id) {
      return this.$store.getters.partnerById(id);
    },
    getPartnerName: function (id) {
      if (id) {
        const p = this.getPartnerById(id);
        return p ? p.attributes.name : id;
      }
      return null;
    },
    getRole(item) {
      return item.attributes.userRoleName
        ? item.attributes.userRoleName
        : item.attributes.userRole;
    },
    getUserName(user) {
      return user ? (user.firstName + ' ' + user.lastName).trim() : null;
    },
    getUserEmail(user) {
      return user ? user.email : null;
    },
    formatDate: function (str) {
      return str ? OneDropUtils.displayDate(str) : str;
    },
    refreshCoaches: function () {
      ConsoleLog('Fetching coaches');
      this.loading = true;
      OneDropApi.get(
        'v3/admin/coaches',
        (response) => {
          this.allCoaches = response.data.map((o) => this.enhanceGroups(o));
          ConsoleLog(`Got ${this.allCoaches.length} coaches`);
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    onNewCoach: function () {
      this.editedItem = {
        type: 'coach',
        attributes: {
          archived: false,
          firstName: null,
          hidden: false,
          imageUrl: null,
          infoUrl: null,
          scheduleUrl: null,
          lastName: null,
          partnerId: OneDropConstants.coachDefaultPartnerId,
          timeZoneUserFacing: null,
          title: null,
          userId: null,
          email: null,
        },
      };
      this.editedIndex = -1;
      this.dialog = true;
    },
    editItem: function (item) {
      ConsoleLog('editItem', item);
      this.editedIndex = this.allCoaches.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.getUserCoachRoles(item.attributes.userId);
      this.dialog = true;
    },
    deleteItem: function (item) {
      ConsoleLog('deleteItem', item);
      this.editedIndex = this.allCoaches.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.deleteDialog = true;
    },
    close() {
      this.editedItem = null;
      this.dialog = false;
      this.deleteDialog = false;
      this.editedIndex = -1;
      this.userRoles = [];
      this.currentUserRoles = [];
    },
    getPrefix(id) {
      return `coach.${id}.`;
    },
    modifyAttrs(attrs) {
      delete attrs.coachUser;
      delete attrs.userRoleName;
      delete attrs.createdAt;
      delete attrs.updatedAt;
      delete attrs.coachGroupSlugs;
    },
    saveCoachRoles(id) {
      if (
        JSON.stringify(this.userRoles) !== JSON.stringify(this.currentUserRoles)
      ) {
        const roles = this.coachRoles.filter((v) =>
          this.userRoles.includes(v.attributes.slug)
        );
        const coachRolesBody = { data: roles };
        OneDropApi.patch(
          `v3/admin/coach/${id}/roles`,
          coachRolesBody,
          (response) => {
            ConsoleLog(response);
          },
          (error) => {
            ConsoleLog(error);
            this.onApiError(error);
          }
        );
      }
    },
    save() {
      ConsoleLog(`Saving item ${JSON.stringify(this.editedItem)}`);
      let id = this.editedItem.id;
      let attrs = this.editedItem.attributes;
      this.modifyAttrs(attrs);
      let body = { data: [this.editedItem] };
      ConsoleLog(`Saving coach ${this.editedIndex}: ${JSON.stringify(body)}`);
      if (this.editedIndex > -1) {
        OneDropApi.patch(
          `v3/admin/coach/${id}`,
          body,
          (response) => {
            const item = response.data[0];
            this.enhanceGroups(item);
            this.allCoaches.splice(this.editedIndex, 1, item);
            this.saving = false;
            this.toast(`Coach saved successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.saving = false;
            this.onApiError(error);
          }
        );
        this.saveCoachRoles(id);
      } else {
        OneDropApi.post(
          'v3/admin/coach',
          body,
          (response) => {
            const item = response.data[0];
            this.enhanceGroups(item);
            this.allCoaches.push(item);
            this.saveCoachRoles(item.id);
            this.toast(`Coach added successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.saving = false;
            this.onApiError(error);
          }
        );
      }
    },
    deleteCoach() {
      this.saving = true;
      let item = this.allCoaches[this.editedIndex];
      let index = this.editedIndex;
      ConsoleLog('Delete Coach', item.id);
      OneDropApi.del(
        `v3/admin/coach/${item.id}`,
        null,
        () => {
          this.allCoaches.splice(index, 1);
          this.saving = false;
          this.toast(`Item ${item.id} deleted successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.saving = false;
          this.onApiError(error);
        }
      );
    },
    rowClicked(item, options) {
      options.expand(!options.isExpanded);
    },
    onAssignRole: function (item) {
      this.assignRoleTo = item.attributes.userId;
      this.showAssignRoleDialog = true;
    },
    onAssignRoleClosed() {
      this.showAssignRoleDialog = false;
      this.assignRoleTo = null;
      this.expanded = [];
    },
    userSelected(user) {
      if (this.editedIndex == -1) {
        this.editedItem.attributes.firstName = user.attributes.firstname;
        this.editedItem.attributes.lastName = user.attributes.lastname;
        this.editedItem.attributes.email = user.attributes.email;
      }
    },
  },
};
</script>

<style></style>
