var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{ref:"searchText",attrs:{"outlined":"","label":"search terms","autofocus":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSearchClicked.apply(null, arguments)},"focus":function($event){return $event.target.select()}},model:{value:(_vm.searchTerms),callback:function ($$v) {_vm.searchTerms=$$v},expression:"searchTerms"}}),_c('v-btn',{staticClass:"ml-2 mb-8",attrs:{"depressed":"","large":"","loading":_vm.searching,"disabled":_vm.searchDisabled,"color":"primary"},on:{"click":_vm.onSearchClicked}},[_vm._v(" Search ")])],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{staticClass:"mb-2",attrs:{"items":_vm.partnerList,"label":"Partner","item-text":_vm.partnerName,"item-value":"id","hide-selected":"","chips":"","deletable-chips":""},model:{value:(_vm.selectedPartner),callback:function ($$v) {_vm.selectedPartner=$$v},expression:"selectedPartner"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{staticClass:"mb-2",attrs:{"items":_vm.trackList,"label":"Track","item-text":_vm.trackName,"item-value":"id","hide-selected":"","chips":"","deletable-chips":""},model:{value:(_vm.selectedTrack),callback:function ($$v) {_vm.selectedTrack=$$v},expression:"selectedTrack"}})],1)],1),_c('v-data-table',{attrs:{"items":_vm.results,"headers":_vm.headers,"loading":_vm.loading || _vm.searching,"expanded":_vm.expanded,"show-expand":"","single-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(item.attributes.firstname)+" "+_vm._s(item.attributes.lastname)+" ")])]}},{key:"item.attributes.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(item.attributes.email)+" ")])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.attributes.partner",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(_vm.getPartnerName(item.attributes.partner))+" ")])]}},{key:"item.attributes.createdat",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(_vm.displayDate(item.attributes.createdat))+" ")])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('UserDetails',{attrs:{"user-id":item.id}})],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_vm._t("actions",null,{"item":item})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }