<!-- prettier-ignore -->
<!-- The AmazonS3Uploader component is designed for two use cases 
 1. To exist on the top of the Manage S3 page (#/setup/awss3editor) 
 2. For all cases of a v-text-field that takes a url path to an image in S3
 The data from S3 is returned as an array of objects that contain the keys:
 {
    id: string,
    name: string,
    parent: string,
    file: string,
    keyName: string,
 },
 To update or add a value to S3 a file path(ex. v3/admin/s3/file/one-drop-static/${path}) and a name are used in a POST.
 For use case 1 a object of the format object above can be provided to the component.
 If so we take those values and fill in the v-text-field in this component. If not we return the default s3Object object.
 For use case 2 the default s3Object object will always be used but to change the v-text-field in the parent
 a event must be emitted (setUrlAttribute) to take the editItem object and imageField and find the correct v-text-field 
 to update after the new image has been saved. That editItem[imageField] url path is saved after a user finishes their
 edits to a Partner/Lesson. AmazonS3Uploader exist to provide the same UI and saving process for both use cases but since
 the parent components are solving different problems we need to account for those. The imageField is used as the indicator of
 which cases should be handled. If its undefined its case 1 else its case 2. After s3Object is set correctly AmazonS3Uploader
 can handle both cases correctly with the only difference being the emitting events in the save. With refreshContent to refresh 
 the AWS page for case 1 and setUrlAttribute to update the v-text-field in the parent for case 2. A setUrlAttribute function
 will have to be a part of any parent that uses AmazonS3Uploader.
-->

<template>
  <v-dialog v-model="s3UploadDialog" max-width="600px">
    <v-card>
      <v-card-title>
        <span v-if="isS3EditMode(s3Object)" class="headline">Edit Item</span>
        <span v-else-if="imageField" class="headline">New Image</span>
        <span v-else class="headline">New Item</span>
      </v-card-title>

      <v-card-text v-if="s3Object">
        <div v-if="s3Object.keyName" class="pb-2">
          <span class="od-label">KeyName:</span
          ><span class="pl-2">{{ s3Object.keyName }}</span>
        </div>
        <v-file-input
          v-model="s3Object.file"
          outlined
          label="File"
          @change="fileChanged"
        />
        <v-text-field
          v-model="s3Object.name"
          :disabled="isS3EditMode(s3Object)"
          outlined
          label="Name"
          :rules="[rules.noslash, rules.nameCheck]"
        />
        <v-text-field
          v-model="s3Object.parent"
          :disabled="isS3EditMode(s3Object)"
          outlined
          label="S3 Directory"
          :rules="[rules.noBegOrEndSlash, rules.dirCheck]"
        />
        <v-alert v-if="imageField" type="warning">
          <b>Warning:</b> File will be saved to S3 after clicking save but will
          not be reflected until the edit is saved
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text :loading="loading" @click="doCancel">
          Cancel
        </v-btn>
        <v-btn color="blue darken-1" text :loading="loading" @click="doSave">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { OneDropApi } from '@/onedrop-api';

export default {
  name: 'AmazonS3Uploader',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    editItem: [Object, String],
    imageField: String,
    refreshContent: Function,
    setUrlAttribute: Function,
  },
  data: function () {
    return {
      loading: false,
      s3Object: {
        id: null,
        name: '',
        parent: '',
        file: null,
        keyName: null,
      },
      rules: {
        nameCheck: (value) =>
          // eslint-disable-next-line
          /^[a-zA-Z0-9\-\._]*$/.test(value ? value.trim() : value) ||
          !value ||
          'Invalid characters. Must contain a-z, A-Z, 0-9, -, _, .',
        dirCheck: (value) =>
          // eslint-disable-next-line
          /^[a-zA-Z0-9\-\._\/]*$/.test(value ? value.trim() : value) ||
          !value ||
          'Invalid characters. Must contain a-z, A-Z, 0-9, -, _, /, .',
        noslash: (value) =>
          !value || value.indexOf('/') === -1 || 'Cannot contain /',
        noBegOrEndSlash: (value) =>
          !value ||
          (!value.trim().startsWith('/') && !value.trim().endsWith('/')) ||
          'Cannot contain a beginning or ending slash',
      },
    };
  },
  computed: {
    s3UploadDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    editItem(val) {
      !this.imageField ? (this.s3Object = val) : this.s3Object;
    },
  },
  methods: {
    isS3EditMode(item) {
      return !!item?.id;
    },
    doCancel() {
      this.s3UploadDialog = false;
    },
    doSave() {
      let path = this.s3Object.keyName
        ? this.s3Object.keyName
        : this.s3Object.parent
        ? `${this.s3Object.parent.trim()}/${this.s3Object.name.trim()}`
        : this.s3Object.name.trim();
      this.loading = true;
      const savedPath = `https://s3.amazonaws.com/one-drop-static/${path}`;
      path = encodeURI(`v3/admin/s3/file/one-drop-static/${path}`);
      OneDropApi.post(
        path,
        this.s3Object.file,
        () => {
          this.toast(`Content uploaded successfully`);
          this.$emit('refreshContent');
          this.$emit('setUrlAttribute', this.imageField, savedPath);
          this.loading = false;
          this.s3UploadDialog = false;
        },
        (error) => {
          this.onApiError(error);
          this.loading = false;
        }
      );
    },
    fileChanged(file) {
      if (file && !this.s3Object.name) this.s3Object.name = file.name;
    },
  },
};
</script>

<style scoped></style>
