<template>
  <v-data-iterator :items="items">
    <template #default="{ items }">
      <v-container>
        <v-row dense>
          <v-col cols="1" class="font-weight-bold"> Row </v-col>
          <v-col class="font-weight-bold"> Detail </v-col>
        </v-row>
        <v-row v-for="(item, i) in items" :key="i" dense class="mt-0">
          <v-col cols="1">
            <span class="font-weight-regular text--primary">{{ item.id }}</span>
          </v-col>
          <v-col>
            <span class="font-weight-regular text--primary">{{
              item.detail
            }}</span>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-data-iterator>
</template>

<script>
export default {
  name: 'ImportError',
  props: ['items'],
};
</script>

<style scoped></style>
