<template>
  <v-card flat>
    <v-data-table :items="options" :loading="loading" :headers="headers" />
  </v-card>
</template>

<script>
export default {
  name: 'PartnerConfigOptions',
  components: {},
  props: ['options'],
  data: function () {
    return {
      loading: false,
      headers: [
        {
          text: 'Object Id',
          value: 'objectId',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Type',
          value: 'optionType',
        },
        {
          text: 'Slug',
          value: 'slug',
        },
        {
          text: 'Order',
          value: 'optionOrder',
        },
      ],
    };
  },
  methods: {},
};
</script>

<style></style>
