<template>
  <v-row no-gutters>
    <v-col cols="12">
      <span v-if="!devices || devices.length === 0" class="od-label"
        >No Devices</span
      >
      <v-data-table
        v-else
        :items="devices"
        :headers="headers"
        dense
        :items-per-page="5"
        sort-desc
        sort-by="updatedAt"
        :footer-props="{
          'items-per-page-text': '',
          'items-per-page-options': [5, 10, 15],
        }"
      >
        <template #item.deviceType="{ item }">
          <v-icon v-if="item.deviceType === 'android'"> mdi-android </v-icon>
          <v-icon v-else-if="item.deviceType === 'ios'"> mdi-apple </v-icon>
          {{ item.deviceType }}
        </template>
        <template #item.updatedAt="{ item }">
          <span class="caption">{{ formatDate(item.updatedAt) }}</span>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { OneDropUtils } from '@/onedrop-utils';
export default {
  name: 'UserDevices',
  props: ['devices'],
  data: function () {
    return {
      headers: [
        {
          text: 'Device Type',
          value: 'deviceType',
        },
        {
          text: 'Version',
          value: 'appVersion',
        },
        {
          text: 'Last Seen',
          value: 'updatedAt',
        },
      ],
    };
  },
  methods: {
    formatDate: function (str) {
      return str ? OneDropUtils.displayDate(str) : str;
    },
  },
};
</script>

<style></style>
