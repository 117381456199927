<template>
  <v-container fluid>
    <v-layout class="display-1 py-4">
      Manage Tracks
      <v-icon
        class="ml-2"
        title="Copy Search Link"
        @click="onCopySearchClicked()"
      >
        mdi-content-copy
      </v-icon>
    </v-layout>
    <div class="text-center">
      <v-progress-circular v-if="saving" indeterminate color="primary" />
    </div>
    <input id="searchUrl" type="hidden" :value="searchUrl()" />
    <v-data-table
      v-model="multiSelectTracks"
      :items="allTracks"
      :headers="headers"
      :search="search"
      :loading="loading"
      :expanded.sync="expanded"
      show-expand
      single-expand
      show-select
      @click:row="rowClicked"
    >
      <template #top>
        <v-dialog v-model="showCommonDialog" max-width="600px">
          <template #activator="{ on }">
            <v-container fluid>
              <v-row align="center">
                <v-col cols="3">
                  <v-text-field
                    v-model="search"
                    label="Search"
                    hide-details
                    outlined
                  />
                </v-col>
                <v-col>
                  <v-autocomplete
                    v-model="selectedPartners"
                    :items="partnerList"
                    label="Partner"
                    :item-text="partnerName"
                    item-value="id"
                    hide-selected
                    chips
                    deletable-chips
                    multiple
                  />
                </v-col>
                <v-col>
                  <v-autocomplete
                    v-model="selectedTags"
                    :items="tagTypes"
                    label="Tags"
                    hide-selected
                    chips
                    deletable-chips
                    multiple
                    class="disable-expansion"
                  />
                </v-col>
                <v-col>
                  <v-select
                    v-model="selectedLanguages"
                    :items="Object.entries(languages)"
                    label="Language"
                    item-text="[1]"
                    item-value="[0]"
                    hide-selected
                    chips
                    deletable-chips
                    multiple
                  />
                </v-col>
                <v-col cols="4">
                  <v-toolbar flat color="white">
                    <v-switch
                      v-model="activeOnly"
                      label="Active Only"
                      class="pa-3"
                    />
                    <v-spacer />
                    <v-btn
                      v-if="hasWritePerm()"
                      color="primary"
                      :loading="loading"
                      :disabled="commonButtonDisabled"
                      class="mb-2 mr-2"
                      v-on="on"
                      @click="editCommon"
                    >
                      Multi-Edit
                    </v-btn>
                    <v-btn
                      v-if="hasWritePerm()"
                      color="primary"
                      :loading="loading"
                      :disabled="loading"
                      class="mb-2 mr-2"
                      @click="refreshTracks"
                    >
                      Reload
                    </v-btn>
                  </v-toolbar>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <v-card v-if="commonItem">
            <v-card-title>
              <span class="headline">Edit Common</span>
              <v-spacer />
              <v-img
                v-if="commonItem.heroImageUrl"
                :src="commonItem.heroImageUrl"
                max-height="50"
                max-width="100"
                contain
              />
            </v-card-title>

            <v-card-text>
              <v-text-field
                v-model="commonItem.heroImageUrl"
                label="Hero Image URL"
                type="url"
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="saveCommon">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="copyDialog" max-width="600px">
          <track-copy
            :track="donorTrack"
            :partner-list="partnerList"
            :languages="languages"
            @track-created="trackCreated"
            @cancel="close"
          />
        </v-dialog>
        <v-dialog v-model="editDialog" max-width="600px">
          <v-card v-if="editedItem">
            <v-card-title>
              <span class="headline">Edit Track</span>
              <v-spacer />
              <v-img
                v-if="editedItem.attributes.heroImageUrl"
                :src="editedItem.attributes.heroImageUrl"
                max-height="50"
                max-width="100"
                contain
              />
            </v-card-title>

            <v-card-text>
              <div class="text-center">
                <v-progress-circular
                  v-if="saving"
                  indeterminate
                  color="primary"
                />
              </div>
              <span class="od-label">Object ID: </span>{{ editedItem.id }}
              <v-container class="ma-0 pa-0">
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="editedItem.attributes.slug"
                      label="Slug"
                    />
                  </v-col>
                  <v-col>
                    <v-switch
                      v-model="editedItem.attributes.hidden"
                      label="Hidden"
                    />
                  </v-col>
                </v-row>
              </v-container>
              <v-text-field
                v-model="editedItem.attributes.heroImageUrl"
                label="Hero Image URL"
                type="url"
              />
              <v-select
                v-model="editedItem.attributes.language"
                :items="Object.entries(languages)"
                label="Language"
                item-text="[1]"
                item-value="[0]"
                hide-selected
                chips
                deletable-chips
              />
              <v-card flat outlined rounded>
                <v-card-subtitle>Tags</v-card-subtitle>
                <v-card-text>
                  <tag-editor
                    v-model="editedItem.attributes.tags"
                    tag-key="all-of"
                    hint="all-of matches all tags in list"
                  />
                  <tag-editor
                    v-model="editedItem.attributes.tags"
                    tag-key="one-of"
                    hint="one-of matches one or more tags in list"
                  />
                  <tag-editor
                    v-model="editedItem.attributes.tags"
                    tag-key="any-of"
                    hint="any-of matches zero or more tags in list"
                  />
                </v-card-text>
              </v-card>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                :disabled="saving"
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="saving"
                @click="saveTrack"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" max-width="600px">
          <v-card v-if="itemToDelete">
            <v-card-title>
              <span class="headline">Delete Track</span>
              <v-spacer />
              <v-img
                v-if="itemToDelete.attributes.heroImageUrl"
                :src="itemToDelete.attributes.heroImageUrl"
                max-height="50"
                max-width="100"
                class="mb-2"
                contain
              />
            </v-card-title>

            <v-card-text>
              <div class="text-center">
                <v-progress-circular
                  v-if="saving"
                  indeterminate
                  color="primary"
                />
              </div>
              <v-row label="Object ID">
                <v-col>Object ID: {{ itemToDelete.id }}</v-col>
              </v-row>
              <v-text-field
                v-model="itemToDelete.attributes.title"
                outlined
                readonly
                label="Title"
              />
              <v-text-field
                v-model="itemToDelete.attributes.description"
                outlined
                readonly
                label="Description"
              />
              <v-text-field
                v-model="itemToDelete.attributes.longDescription"
                outlined
                readonly
                label="Long Description"
              />
              <v-text-field
                v-model="itemToDelete.attributes.heroImageUrl"
                outlined
                label="Hero Image URL"
                type="url"
              />
              <v-container>
                <v-row justify="space-around">
                  <v-switch
                    v-model="itemToDelete.attributes.hidden"
                    readonly
                    label="Hidden"
                  />
                </v-row>
              </v-container>
              <v-alert v-if="!itemToDelete.attributes.hidden" type="warning">
                <b>Warning:</b>
                <span v-if="itemToDelete.attributes.userCount > 0">
                  This is an active track with
                  {{ itemToDelete.attributes.userCount }} users on it. It is
                  recommended to deactivate before deleting.
                </span>
                <span v-else>
                  This is an active track. Users could be enrolling in this
                  track on sign-up. It is recommended to deactivate before
                  deleting.
                </span>
              </v-alert>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                :disabled="saving"
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="saving"
                @click="deleteTrack"
              >
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template #header.action="{ header }">
        <span v-if="hasWritePerm()">{{ header.text }}</span>
      </template>
      <template #item.attributes.url="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <span
              class="d-inline-block text-truncate"
              style="max-width: 200px"
              v-on="on"
            >
              {{ item.attributes.url }}
            </span>
          </template>
          <span>{{ item.url }}</span>
        </v-tooltip>
      </template>
      <template #item.attributes.imageUrl="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <span
              class="d-inline-block text-truncate"
              style="max-width: 200px"
              v-on="on"
              >{{ item.attributes.imageUrl }}</span
            >
          </template>
          <span>{{ item.attributes.imageUrl }}</span>
        </v-tooltip>
      </template>
      <template #item.attributes.createdAt="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <div v-on="on">
              {{ formatDate(item.attributes.createdAt) }}
            </div>
          </template>
          <span>{{ item.attributes.createdAt }}</span>
        </v-tooltip>
      </template>
      <template #item.attributes.partners="{ item }">
        <span>{{ formatPartners(item.attributes.partners) }}</span>
      </template>
      <template #item.attributes.language="{ item }">
        <span>{{ formatLanguage(item.attributes.language) }}</span>
      </template>
      <template #item.attributes.hidden="{ item }">
        <v-switch v-model="item.attributes.hidden" readonly />
      </template>
      <template #item.action="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <span v-on="on">
              <v-icon
                v-if="hasWritePerm()"
                class="mr-2"
                @click.stop="copyItem(item)"
              >
                mdi-content-duplicate
              </v-icon>
            </span>
          </template>
          <span>Copy</span>
        </v-tooltip>
        <v-tooltip top>
          <template #activator="{ on }">
            <span v-on="on">
              <v-icon
                v-if="hasWritePerm()"
                class="mr-2"
                @click.stop="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </span>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip top>
          <template #activator="{ on }">
            <span v-on="on">
              <v-icon v-if="hasWritePerm()" @click.stop="deleteItem(item)">
                mdi-delete
              </v-icon>
            </span>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-container fluid>
            <v-tabs>
              <v-tab key="1"> Details </v-tab>
              <v-tab key="2"> Schedule Versions </v-tab>
              <v-tab key="3"> Partners </v-tab>
              <v-tab v-if="hasWritePerm()" key="4"> Audit Events </v-tab>
              <v-tab key="5"> Coaches </v-tab>
              <v-tab-item key="1">
                <TrackDetail
                  :track="item"
                  :prefix="getPrefix(item)"
                  :content-fields="contentFields"
                  :all-tracks="allTracks"
                  @changed="saveChanges"
                />
              </v-tab-item>
              <v-tab-item key="2">
                <TrackScheduleVersion
                  ref="schedule"
                  :track="item"
                  :all-tracks="allTracks"
                />
              </v-tab-item>
              <v-tab-item key="3">
                <track-partner
                  :track-id="item.id"
                  @partnerChanged="partnerChanged"
                />
              </v-tab-item>
              <v-tab-item key="4">
                <audit-log-viewer event-type="track" :object-id="item.id" />
              </v-tab-item>
              <v-tab-item key="5">
                <TrackCoach :track-id="item.id" />
              </v-tab-item>
            </v-tabs>
          </v-container>
        </td>
      </template>
      <template #no-data>
        <v-btn color="primary" @click="refreshTracks"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { ConsoleLog, OneDropUtils, OneDropConstants } from '../onedrop-utils';
import TrackDetail from '../components/track/TrackDetail';
import TrackScheduleVersion from '@/components/track/TrackScheduleVersion';
import TrackPartner from '@/components/track/TrackPartner';
import AuditLogViewer from '@/components/AuditLogViewer';
import TagEditor from '@/components/track/TagEditor';
import TrackCopy from '@/components/track/TrackCopy';
import TrackCoach from '@/components/track/TrackCoach';

export default {
  name: 'TrackEditor',
  components: {
    AuditLogViewer,
    TagEditor,
    TrackDetail,
    TrackScheduleVersion,
    TrackPartner,
    TrackCopy,
    TrackCoach,
  },
  data: function () {
    return {
      copyDialog: false,
      editDialog: false,
      deleteDialog: false,
      showCommonDialog: false,
      activeOnly: true,
      loading: false,
      saving: false,
      search: '',
      dateFormat: 'yyyy-MM-dd',
      timeFormat: 'HH:mm',
      multiSelectTracks: [],
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Title',
          value: 'attributes.title',
        },
        {
          text: 'Partners',
          value: 'attributes.partners',
          filter: (value, search, item) =>
            this.selectedPartners.length == 0 ||
            this.intersectPartners(
              item.attributes.partners,
              this.selectedPartners
            ),
        },
        {
          text: 'Tags',
          value: 'attributes.tags',
          filter: (value) => {
            const selectedTagsArray = JSON.parse(
              JSON.stringify(this.selectedTags)
            );
            return (
              this.selectedTags.length == 0 ||
              selectedTagsArray.every((tag) => value.includes(tag))
            );
          },
        },
        {
          text: 'Created At',
          value: 'attributes.createdAt',
        },
        {
          text: 'Hidden',
          value: 'attributes.hidden',
          filter: (value, search, item) =>
            this.activeOnly == false || item.attributes.hidden != true,
        },
        {
          text: 'Language',
          value: 'attributes.language',
          filter: (value, search, item) =>
            this.selectedLanguages.length == 0 ||
            this.selectedLanguages.indexOf(item.attributes.language) > -1,
        },
        {
          text: '# Users',
          value: 'attributes.userCount',
        },
        { text: 'Actions', value: 'action', sortable: false, width: '120px' },
        { text: '', value: 'data-table-expand' },
      ],
      selectedLanguages: [],
      selectedTrack: null,
      allTracks: [],
      editedIndex: -1,
      itemToDelete: null,
      donorTrack: null,
      editedItem: null,
      commonItem: {
        id: '',
        heroImageUrl: '',
      },
      contentFields: [
        'title',
        'displayTitle',
        'description',
        'longDescription',
        'welcomeMessage',
        'welcomeMessageAlt',
      ],
      expanded: [],
      selectedPartners: [],
      tagKeys: [
        { text: 'none', value: null },
        { text: 'all-of', value: 'all-of' },
        { text: 'one-of', value: 'one-of' },
        { text: 'any-of', value: 'any-of' },
      ],
      languages: OneDropConstants.trackLanguageOptions,
      tagTypes: OneDropConstants.trackTagOptions,
      selectedTags: [],
    };
  },
  computed: {
    partnerList() {
      return this.$store.getters.partnerList;
    },
    commonButtonDisabled() {
      return this.loading || this.multiSelectTracks.length < 2;
    },
    currentUrl: function () {
      let q = {};
      if (this.search) q.query = encodeURIComponent(this.search);
      if (this.selectedPartners && this.selectedPartners.length > 0)
        q.partners = encodeURIComponent(JSON.stringify(this.selectedPartners));
      return this.$router.resolve({ path: '/content/trackeditor', query: q })
        .route.fullPath;
    },
  },
  watch: {
    copyDialog(val) {
      val || this.close();
    },
    editDialog(val) {
      val || this.close();
    },
    deleteDialog(val) {
      val || this.close();
    },
  },
  mounted() {
    if (this.$route.query.query) {
      this.search = decodeURIComponent(this.$route.query.query).trim();
    }
    if (this.$route.query.activeOnly) {
      this.activeOnly = this.$route.query.activeOnly === 'true';
    }
    if (this.$route.query.partners) {
      this.selectedPartners = JSON.parse(
        decodeURIComponent(this.$route.query.partners).trim()
      );
    }
    this.refreshTracks();
  },
  methods: {
    partnerName: (item) => `${item.name} (${item.id})`,
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'track-write']);
    },
    formatDate: function (str) {
      return str ? OneDropUtils.displayDate(str) : str;
    },
    formatLanguage: function (str) {
      if (str in this.languages) return this.languages[str];
      else return str;
    },
    intersectPartners: function (partners, selected) {
      if (partners && selected) {
        const filteredArray = partners.filter((value) =>
          selected.includes(value.objectId)
        );
        return filteredArray.length > 0;
      } else return false;
    },
    formatPartners: function (partners) {
      if (partners) {
        if (partners.length > 1) return `${partners.length} Partners`;
        else return partners[0].name;
      }
      return partners;
    },
    refreshTracks: function () {
      this.loading = true;
      OneDropApi.getAllResources(
        'track',
        (response) => {
          this.allTracks = response.data;
          ConsoleLog(`Got ${this.allTracks.length} tracks`);
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    getPrefix: function (item) {
      return item ? `track.${item.id}.` : item;
    },
    created: function () {
      this.refreshTracks();
    },
    mounted: function () {
      this.refreshTracks();
    },
    onScheduleEditDialogClosed: function () {
      this.selectedTrack = null;
    },
    copyItem: function (item) {
      ConsoleLog(`Copy : ${JSON.stringify(item)}`);
      this.donorTrack = item;
      this.copyDialog = true;
    },
    getPartnerById: function (id) {
      return this.$store.getters.partnerById(id);
    },
    getPartnerName: function (id) {
      if (id) {
        const p = this.getPartnerById(id);
        return p ? p.attributes.name : null;
      }
      return null;
    },
    editItem: function (item) {
      ConsoleLog(`Editing item: ${JSON.stringify(item)}`);
      this.expanded = [];
      this.editedIndex = this.allTracks.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      ConsoleLog(`Editing: ${JSON.stringify(this.editedItem)}`);
      this.editDialog = true;
    },
    deleteItem: function (item) {
      ConsoleLog('deleteItem', item);
      this.editedIndex = this.allTracks.indexOf(item);
      this.itemToDelete = item;
      this.deleteDialog = true;
    },
    editCommon: function () {
      ConsoleLog(`Common tracks: ${this.multiSelectTracks}`);
      this.expanded = [];
      let f = this.multiSelectTracks[0];
      let o = {
        heroImageUrl: f.attributes.heroImageUrl,
      };
      for (let k = 1; k < this.multiSelectTracks.length; k++) {
        let p = this.multiSelectTracks[k].attributes;
        if (p.heroImageUrl !== o.heroImageUrl) o.heroImageUrl = '';
      }
      ConsoleLog(`commonItem: ${JSON.stringify(o)}`);
      this.commonItem = o;
      this.showCommonDialog = true;
    },
    updateTracks: function (tracks) {
      for (let i in tracks) {
        let item = tracks[i];
        for (let k in this.multiSelectTracks) {
          let t = this.multiSelectTracks[k];
          if (t.id == item.id)
            t.attributes = Object.assign({}, item.attributes);
        }
      }
    },
    saveCommon: function () {
      let o = {};
      if (this.commonItem.heroImageUrl)
        o['heroImageUrl'] = this.commonItem.heroImageUrl;
      let d = this.multiSelectTracks.map((t) => {
        return {
          id: t.id,
          type: 'track',
          attributes: o,
        };
      });
      let body = { data: d };
      ConsoleLog(`Save common: ${JSON.stringify(body)}`);
      this.saving = true;
      OneDropApi.patchBatch(
        'track',
        body,
        (response) => {
          ConsoleLog(`Saved response`);
          ConsoleLog(response.data);
          this.saving = false;
          this.toast(`${response.data.length} tracks saved successfully`);
          this.updateTracks(response.data);
        },
        (error) => {
          ConsoleLog(error);
          this.saving = false;
          this.onApiError(error);
          this.close();
        }
      );
    },
    close() {
      this.editedItem = null;
      this.commonItem = null;
      this.copyDialog = false;
      this.editDialog = false;
      this.deleteDialog = false;
      this.showCommonDialog = false;
      this.editedIndex = -1;
    },
    saveCreate: function (item) {
      ConsoleLog(`Copy item: ${item.donor.id}, ${item.partner}`);
      this.saving = true;
      OneDropApi.post(
        `/v3/admin/resource/copy-track/${item.donor.id}?partnerId=${item.partner}&language=${item.language}`,
        null,
        (response) => {
          ConsoleLog(response);
          const newTrack = response.data[0];
          newTrack.attributes.partnerName = this.getPartnerName(
            newTrack.attributes.partner
          );
          ConsoleLog('Track create successful', newTrack);
          this.allTracks.push(newTrack);
          this.saving = false;
          this.toast(`Track copied successfully ${newTrack.id}`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.saving = false;
          this.onApiError(error);
        }
      );
    },
    saveTrack() {
      this.saving = true;
      let index = this.editedIndex;
      ConsoleLog('Saving item', this.editedItem);
      this.expanded = [];
      let id = index > -1 ? this.editedItem.id : null;
      let attrs = this.editedItem.attributes;
      delete attrs.partners;
      delete attrs.userCount;
      let body = {
        data: [
          {
            id: id,
            type: 'track',
            attributes: attrs,
          },
        ],
      };
      ConsoleLog(`Saving item ${index}: ${JSON.stringify(body)}`);
      OneDropApi.patchResource(
        'track',
        id,
        body,
        (response) => {
          ConsoleLog(`Saved response`);
          ConsoleLog(response.data[0]);
          this.updateItem(index, response.data[0]);
          this.saving = false;
          this.toast(`Item ${id} saved successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.saving = false;
          this.onApiError(error);
        }
      );
    },
    trackCreated: function (newTrack) {
      newTrack.attributes.partnerName = this.getPartnerName(
        newTrack.attributes.partner
      );
      ConsoleLog('Track create successful', newTrack);
      this.allTracks.push(newTrack);
      this.toast(`Track copied successfully to ${newTrack.id}`);
      this.close();
    },
    updateItem(index, itemToCopy) {
      ConsoleLog(`Update response ${index}, ${JSON.stringify(itemToCopy)}`);
      let item = this.allTracks[index];
      item.attributes.title = itemToCopy.attributes.title;
      item.attributes.displayTitle = itemToCopy.attributes.displayTitle;
      item.attributes.description = itemToCopy.attributes.description;
      item.attributes.longDescription = itemToCopy.attributes.longDescription;
      item.attributes.heroImageUrl = itemToCopy.attributes.heroImageUrl;
      item.attributes.hidden = itemToCopy.attributes.hidden;
      item.attributes.tags = itemToCopy.attributes.tags;
      item.attributes.slug = itemToCopy.attributes.slug;
      item.attributes.language = itemToCopy.attributes.language;
      item.attributes.welcomeMessage = itemToCopy.attributes.welcomeMessage;
      item.attributes.welcomeMessageAlt =
        itemToCopy.attributes.welcomeMessageAlt;
    },
    deleteTrack() {
      this.saving = true;
      let item = this.allTracks[this.editedIndex];
      let index = this.editedIndex;
      ConsoleLog(`Delete Track ${item.id}`);
      OneDropApi.deleteTrack(
        item.id,
        () => {
          this.allTracks.splice(index, 1);
          this.saving = false;
          this.toast(`Item ${item.id} deleted successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.saving = false;
          this.onApiError(error);
        }
      );
    },
    rowClicked(item, options) {
      options.expand(!options.isExpanded);
    },
    saveChanges(item) {
      if (item.attributes.partnerName) delete item.attributes.partnerName;
      if (item.attributes.partners) delete item.attributes.partners;
      delete item.attributes['userCount'];
      let body = {
        data: [item],
      };
      let id = item.id;
      this.saving = true;
      const index = this.allTracks.findIndex((v) => v.id == item.id);
      ConsoleLog(
        `saveChanges: Saving track: ${index}: ${JSON.stringify(body)}`
      );
      OneDropApi.patchResource(
        'track',
        id,
        body,
        (response) => {
          ConsoleLog(`TrackDetail: ${JSON.stringify(response.data)}`);
          this.updateItem(index, response.data[0]);
          this.saving = false;
          this.toast(`Successfully saved track`);
        },
        (error) => {
          ConsoleLog(`Error saving track ${error}`);
          this.onApiError(error);
          this.saving = false;
        }
      );
    },
    partnerChanged(trackId) {
      const index = this.allTracks.findIndex((v) => v.id == trackId);
      this.$refs.schedule.partnerChanged();
      OneDropApi.get(
        `v3/admin/resource/track/${trackId}`,
        (response) => {
          this.allTracks.splice(index, 1, response.data[0]);
        },
        (error) => {
          ConsoleLog(`Error saving track ${error}`);
          this.onApiError(error);
          this.saving = false;
        }
      );
    },
    searchUrl() {
      let path = this.currentUrl;
      const protocol = window.location.protocol;
      const host = window.location.host;
      return `${protocol}//${host}/#${path}`;
    },
    onCopySearchClicked: function () {
      const copiedText = this.copyToClipboard('searchUrl');
      this.toast('Copied: ' + copiedText);
    },
  },
};
</script>

<style>
.disable-expansion {
  max-width: 220px;
}
</style>
