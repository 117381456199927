<template>
  <v-card flat>
    <template v-if="!empty">
      <v-progress-linear indeterminate color="primary" :active="loading" />
      <v-simple-table dense>
        <template #default>
          <thead>
            <tr>
              <th />
              <th>Partner</th>
              <th>Last Sync</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in linkedAccounts" :key="item.id">
              <td>
                <v-img
                  v-if="item.attributes.linked"
                  :src="item.attributes.logoUrl"
                  max-height="20"
                  max-width="60"
                  contain
                  :class="getClass(item)"
                />
                <v-img
                  v-else
                  :src="item.attributes.logoUrl"
                  max-height="20"
                  max-width="60"
                  contain
                  :class="getClass(item)"
                  style="opacity: 0.5"
                />
              </td>
              <td :class="getClass(item)">
                {{ item.attributes.name }}
              </td>
              <td class="caption">
                {{ formatDate(item) }}
              </td>
              <td v-if="currentUser">
                <v-btn
                  v-if="item.attributes.linked"
                  :disabled="loading"
                  text
                  @click="unlink(item.attributes.slug)"
                >
                  Unlink
                </v-btn>
                <v-btn
                  v-else
                  text
                  :disabled="loading"
                  :href="link(item.attributes.slug)"
                >
                  Link
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
    <div v-else class="od-heading">
      <h3>No Linked Accounts</h3>
    </div>
  </v-card>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { OneDropUtils } from '../onedrop-utils';
import store from '../store';

export default {
  name: 'LinkedAccounts',
  props: ['userId'],
  data: function () {
    return {
      linkedAccounts: null,
      loading: false,
    };
  },
  computed: {
    currentUser: function () {
      return OneDropApi.getUserId() === this.userId;
    },
    empty: function () {
      return !this.linkedAccounts || this.linkedAccounts.length == 0;
    },
  },
  watch: {
    userId() {
      this.refreshAccounts();
    },
  },
  created() {
    this.refreshAccounts();
  },
  methods: {
    formatDate(item) {
      if (item.attributes.linked) {
        const date = item.attributes.lastSync;
        return date ? OneDropUtils.displayDate(date) : date;
      } else return null;
    },
    refreshAccounts() {
      this.loading = true;
      OneDropApi.getLinkedAccounts(
        this.userId,
        (result) => {
          this.linkedAccounts = result.data;
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          this.loading = false;
        }
      );
    },
    getClass(item) {
      if (item.attributes.linked) return 'font-weight-medium';
      else return 'font-weight-light';
    },
    link: function (slug) {
      const baseUrl = process.env.VUE_APP_API_URL;
      const jwtToken = store.state.jwtToken;
      const currentUrl = encodeURIComponent(window.location.href);
      return `${baseUrl}/v3/oauth2/link-auth/${slug}/partner-link?X-Auth-Token=${jwtToken}&url=${currentUrl}`;
    },
    unlink: function (slug) {
      this.loading = true;
      OneDropApi.del(
        `/v3/linkedpartner/${slug}/user`,
        null,
        () => {
          this.refreshAccounts();
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          this.loading = false;
        }
      );
    },
    currentWindow() {
      return window.location.href;
    },
  },
};
</script>

<style scoped></style>
