var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.devices || _vm.devices.length === 0)?_c('span',{staticClass:"od-label"},[_vm._v("No Devices")]):_c('v-data-table',{attrs:{"items":_vm.devices,"headers":_vm.headers,"dense":"","items-per-page":5,"sort-desc":"","sort-by":"updatedAt","footer-props":{
        'items-per-page-text': '',
        'items-per-page-options': [5, 10, 15],
      }},scopedSlots:_vm._u([{key:"item.deviceType",fn:function(ref){
      var item = ref.item;
return [(item.deviceType === 'android')?_c('v-icon',[_vm._v(" mdi-android ")]):(item.deviceType === 'ios')?_c('v-icon',[_vm._v(" mdi-apple ")]):_vm._e(),_vm._v(" "+_vm._s(item.deviceType)+" ")]}},{key:"item.updatedAt",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.formatDate(item.updatedAt)))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }