<template>
  <v-container fluid class="ma-0 pa-0">
    <top-bar>
      <template #menu>
        <v-tabs class="mb-2">
          <v-app-bar flat>
            <template v-for="item in menu">
              <template v-if="item.children">
                <v-menu v-if="hasChildPermission(item)" :key="item.id" offset-y>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      text
                      v-bind="attrs"
                      :class="classObject(item.path)"
                      v-on="on"
                    >
                      {{ item.name }}
                      <v-icon right> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="child in item.children">
                      <v-list-item
                        v-if="hasPermission(child.permissions)"
                        :key="child.id"
                        :to="child.path"
                        :class="listClassObject(child.path)"
                      >
                        {{ child.name }}
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </template>
              <template v-else-if="item.name == 'spacer'">
                <v-spacer :key="item.id" />
              </template>
              <template v-else>
                <v-btn
                  v-if="hasPermission(item.permissions)"
                  :key="item.id"
                  text
                  :class="classObject(item.path)"
                  :to="item.path"
                >
                  {{ item.name }}
                </v-btn>
              </template>
            </template>
          </v-app-bar>
        </v-tabs>
      </template>
    </top-bar>
    <router-view />
  </v-container>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import TopBar from '../components/TopBar';

export default {
  name: 'Home',
  components: { TopBar },
  data: function () {
    return {
      menu: [
        {
          id: 'search',
          name: 'User Search',
          path: '/search',
          permissions: ['admin-read', 'admin-user-read'],
        },
        {
          id: 'employer',
          name: 'Employer',
          path: '/employer',
          children: [
            {
              id: 'eligibility',
              name: 'Eligibility',
              path: '/employer/eligibility',
              permissions: ['eligibility-read'],
            },
            {
              id: 'partner-contract',
              name: 'Partner Program',
              path: '/employer/partner-contract',
              permissions: ['employer-read'],
            },
            {
              id: 'employer-program-pricing',
              name: 'Program Pricing',
              path: '/employer/employer-program-pricing',
              permissions: ['employer-read'],
            },
          ],
        },
        {
          id: 'content',
          name: 'Content',
          path: '/content',
          children: [
            {
              id: 'newseditor',
              name: 'News Feed',
              path: '/content/newseditor',
              permissions: ['admin-read', 'news-message-read'],
            },
            {
              id: 'trackeditor',
              name: 'Tracks',
              path: '/content/trackeditor',
              permissions: ['admin-read', 'track-read'],
            },
            {
              id: 'lessoneditor',
              name: 'Lessons',
              path: '/content/lessoneditor',
              permissions: ['admin-read', 'lesson-read'],
            },
            {
              id: 'inboxmessageeditor',
              name: 'Inbox Messages',
              path: '/content/inboxmessageeditor',
              permissions: ['admin-read', 'inbox-message-read'],
            },
            {
              id: 'localization',
              name: 'Localization',
              path: '/content/localization',
              permissions: ['admin-read', 'content-read'],
            },
            {
              id: 'rescontenteditor',
              name: 'Resource Content',
              path: '/content/rescontenteditor',
              permissions: ['admin-read', 'content-read'],
            },
          ],
        },
        {
          id: 'setup',
          name: 'Setup',
          path: '/setup',
          children: [
            {
              id: 'partnereditor',
              name: 'Partners',
              path: '/setup/partnereditor',
              permissions: ['admin-read', 'partner-read'],
            },
            {
              id: 'coacheditor',
              name: 'Coaches',
              path: '/setup/coacheditor',
              permissions: ['admin-read', 'coach-admin-read'],
            },
            {
              id: 'medseditor',
              name: 'Meds',
              path: '/setup/medseditor',
              permissions: ['admin-read', 'med-read'],
            },
            {
              id: 'questioneditor',
              name: 'Questions',
              path: '/setup/questioneditor',
              permissions: ['question-read'],
            },
            {
              id: 'configeditor',
              name: 'Configurations',
              path: '/setup/configeditor',
              permissions: ['config-read'],
            },
            {
              id: 'userqueryeditor',
              name: 'UserQuery',
              path: '/setup/userqueryeditor',
              permissions: ['admin-read', 'user-query-read'],
            },
            {
              id: 'awss3editor',
              name: 'Amazon S3 Editor',
              path: '/setup/awss3editor',
              permissions: ['admin-read', 's3-read'],
            },
            {
              id: 'skuentitlementeditor',
              name: 'SKU Entitlement Editor',
              path: '/setup/skuentitlementeditor',
              permissions: ['admin-read', 'sku-entitlement-read'],
            },
            {
              id: 'featureeditor',
              name: 'Feature Editor',
              path: '/setup/featureeditor',
              permissions: ['admin-read', 'feature-read'],
            },
            {
              id: 'audits',
              name: 'Audits',
              path: '/setup/audits',
              permissions: ['admin-read'],
            },
          ],
        },
        {
          id: 'tablecounts',
          name: 'Table Counts',
          path: '/tablecounts',
          permissions: ['table-count-read'],
        },
        {
          id: 'playground',
          name: 'Playground',
          path: '/playground',
          permissions: ['playground'],
        },
      ],
    };
  },
  computed: {
    loggedinInUser: function () {
      return OneDropApi.getUserId();
    },
  },
  mounted() {
    // Find the first item with permission
    // If none found, go to the user page for this user
    if (this.$route.path === '/')
      this.$nextTick().then(() => {
        let path = null;
        for (let i in this.menu) {
          const item = this.menu[i];
          if (item.path && item.children) {
            for (let k in item.children) {
              const child = item.children[k];
              if (child.permissions && this.hasPermission(child.permissions)) {
                path = child.path;
                break;
              }
            }
          } else if (
            item.path &&
            item.permissions &&
            this.hasPermission(item.permissions)
          ) {
            path = item.path;
            break;
          }
        }
        if (path) this.$router.push(path);
        else this.$router.push(`/user/${this.loggedinInUser}`);
      });
  },
  methods: {
    hasPermission(list) {
      return OneDropApi.hasOneOfPerms(list);
    },
    hasChildPermission(item) {
      for (let i in item.children) {
        let child = item.children[i];
        if (this.hasPermission(child.permissions)) return true;
      }
      return false;
    },
    isActive(path) {
      return this.$route.path.startsWith(path);
    },
    classObject: function (path) {
      if (this.isActive(path))
        return {
          'blue--text': true,
          'text--darken-2': true,
          blue: true,
          'lighten-4': true,
        };
      else return { 'grey--text': true, 'text--darken-1': true };
    },
    listClassObject: function (path) {
      if (this.isActive(path))
        return { 'blue--text': true, 'text--darken-3': true };
      else return { 'grey--text': true, 'text--darken-1': true };
    },
  },
};
</script>
