<template>
  <v-container fluid>
    <v-row>
      <v-col class="display-1 py-4" cols="3">
        {{ jobName }}
      </v-col>
      <v-col cols="6" class="text-center">
        <div v-if="status === 'complete'">
          <h1 color="secondary" class="green--text pl-2 ml-2">Completed</h1>
        </div>
        <div v-else-if="status === 'error'">
          <h1 color="secondary" class="red--text pl-2 ml-2">Error</h1>
        </div>
        <div v-else>
          <v-progress-circular indeterminate />
          <h1 color="secondary">Working</h1>
        </div>
      </v-col>
      <v-col cols="3" class="text-right">
        <v-spacer />
        <v-btn color="primary" :disabled="working" class="ml-2" @click="goBack">
          Done
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="jobType == 'purge-user'">
      <v-row no-gutters dense>
        <v-col>
          <v-card v-if="params">
            <v-card-title class="pb-0 pt-0 pl-2">
              User: {{ params.user.objectId }}
            </v-card-title>
            <v-container class="pt-0 pl-2">
              <v-row no-gutters>
                <v-col class="od-label"> Email </v-col>
                <v-col>{{ params.user.email }}</v-col>
                <v-col class="od-label"> Partner </v-col>
                <v-col>{{ params.user.partner }}</v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="od-label"> First Name </v-col>
                <v-col>{{ params.user.firstName }}</v-col>
                <v-col class="od-label"> Last Name </v-col>
                <v-col>{{ params.user.lastName }}</v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="6" />
      </v-row>
    </template>
    <template v-else-if="jobType == 'merge-user'">
      <v-row no-gutters dense>
        <v-col cols="5">
          <v-card v-if="params">
            <v-card-title class="pb-0 pt-0 pl-2">
              Donor: {{ params.donor.objectId }}
            </v-card-title>
            <v-container class="pt-0 pl-2">
              <v-row no-gutters>
                <v-col class="od-label"> Email </v-col>
                <v-col>{{ params.donor.email }}</v-col>
                <v-col class="od-label"> Partner </v-col>
                <v-col>{{ params.donor.partner }}</v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="od-label"> First Name </v-col>
                <v-col>{{ params.donor.firstName }}</v-col>
                <v-col class="od-label"> Last Name </v-col>
                <v-col>{{ params.donor.lastName }}</v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="2" />
        <v-col cols="5">
          <v-card v-if="params">
            <v-card-title class="pb-0 pt-0 pl-2">
              Recipient: {{ params.recipient.objectId }}
            </v-card-title>
            <v-container class="pt-0 pl-2">
              <v-row no-gutters>
                <v-col class="od-label"> Email </v-col>
                <v-col>{{ params.recipient.email }}</v-col>
                <v-col class="od-label"> Partner </v-col>
                <v-col>{{ params.recipient.partner }}</v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="od-label"> First Name </v-col>
                <v-col>{{ params.recipient.firstName }}</v-col>
                <v-col class="od-label"> Last Name </v-col>
                <v-col>{{ params.recipient.lastName }}</v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="params" justify="space-around">
        <v-col>
          <v-switch
            v-model="params.includeHealthObjects"
            readonly
            label="Include Health Objects"
            dense
          />
        </v-col>
        <v-col>
          <v-switch
            v-model="params.deleteDonor"
            readonly
            label="Delete Donor"
            dense
          />
        </v-col>
        <v-col>
          <v-switch
            v-model="params.emailFromDonor"
            readonly
            label="Retain Donor Email"
            dense
          />
        </v-col>
        <v-col>
          <v-switch
            v-model="params.moveEntitlements"
            readonly
            label="Move Entitlements"
            dense
          />
        </v-col>
        <v-col>
          <v-switch
            v-model="params.ignoreDonorKeys"
            readonly
            label="Ignore Donor Keys"
            dense
          />
        </v-col>
      </v-row>
    </template>
    <v-card-title class="pa-2 ma-0"> Detail </v-card-title>
    <v-data-iterator
      v-if="results"
      :items="results"
      hide-default-footer
      disable-pagination
    >
      <template #default="{ items }">
        <v-row v-for="(row, i) in items" :key="i" dense :class="getStyle(i)">
          <template v-for="(item, index) in row">
            <v-col :key="index + '_' + item.key" cols="2">
              {{ item.key }}
            </v-col>
            <v-col :key="index" cols="1" class="text-right pr-6">
              {{ item.value }}
            </v-col>
          </template>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import { OneDropApi } from '@/onedrop-api';
import { ConsoleLog } from '@/onedrop-utils';

export default {
  name: 'JobStatus',
  props: ['jobId', 'url'],
  data: function () {
    return {
      working: false,
      done: false,
      error: false,
      data: null,
    };
  },
  computed: {
    jobType() {
      return this.data ? this.data.attributes.jobType : this.data;
    },
    output() {
      return this.data ? this.data.attributes.output : this.data;
    },
    detail() {
      return this.output ? this.output.detail : this.output;
    },
    params() {
      return this.output ? this.output.params : this.output;
    },
    status() {
      return this.output ? this.output.status : this.output;
    },
    jobName() {
      if (this.jobType === 'merge-user') return 'Merge User';
      else if (this.jobType == 'purge-user') return 'Delete User';
      else return '';
    },
    results() {
      let results = [];
      let row = null;
      for (let i in this.detail) {
        if (i % 4 == 0) {
          row = [];
          results.push(row);
        }
        for (let k in this.detail[i]) {
          row.push({ key: k, value: this.detail[i][k] });
        }
      }
      return results;
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    getKey(item) {
      return Object.keys(item)[0];
    },
    getValue(item) {
      return item[Object.keys(item)[0]];
    },
    getStyle(i) {
      if (i % 2 == 0) return 'blue-grey lighten-5';
      else return '';
    },
    refresh() {
      this.working = true;
      OneDropApi.getJobStatus(
        this.jobId,
        (resp) => {
          ConsoleLog(`getStatus: Got success for jobId: ${this.jobId}`);
          this.data = resp.data[0];
          const self = this;
          if (this.status !== 'complete' && this.status !== 'error')
            setTimeout(function () {
              self.refresh();
            }, 500);
          else this.working = false;
        },
        (error) => {
          ConsoleLog(`getStatus: Received error: ${error}`);
          this.onApiError('Error merging: ' + error);
          this.working = false;
        }
      );
    },
    goBack() {
      if (this.url) this.$router.push(this.url);
      else this.$router.push('/');
    },
  },
};
</script>

<style scoped></style>
