<template>
  <v-data-table
    :loading="loading"
    :headers="headers"
    :items="tracks"
    :search="search"
    dense
  >
    <template #top>
      <v-row dense align="center">
        <v-col cols="4">
          <v-text-field
            ref="searchText"
            v-model="search"
            label="Search"
            outlined
            hide-details
          />
        </v-col>
        <v-col cols="1" />
        <v-col cols="3">
          <v-select
            v-model="selectedLanguages"
            :items="Object.entries(languages)"
            label="Language"
            item-text="[1]"
            item-value="[0]"
            hide-selected
            chips
            deletable-chips
            multiple
          />
        </v-col>
        <v-col cols="1" />
        <v-col>
          <v-switch
            v-model="activeOnly"
            label="Active Only"
            class="pa-3 align-content-end"
          />
        </v-col>
      </v-row>
    </template>
    <template #item.id="{ item }">
      <router-link
        :to="{ path: '/content/trackeditor', query: { query: item.id } }"
      >
        {{ item.id }}
      </router-link>
    </template>
    <template #item.attributes.hidden="{ item }">
      <v-switch v-model="item.attributes.hidden" readonly />
    </template>
    <template #item.attributes.language="{ item }">
      <span>{{ formatLanguage(item.attributes.language) }}</span>
    </template>
    <template #item.attributes.createdAt="{ item }">
      <span>{{ formatDate(item.attributes.createdAt) }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { ConsoleLog, OneDropUtils } from '../onedrop-utils';

export default {
  name: 'PartnerTrackDetail',
  props: ['partnerId'],
  data: function () {
    return {
      tracks: [],
      loading: false,
      activeOnly: true,
      search: null,
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Title',
          value: 'attributes.title',
        },
        {
          text: 'Version',
          value: 'attributes.scheduleVersion',
        },
        {
          text: 'Tags',
          value: 'attributes.tags',
        },
        {
          text: 'Hidden',
          value: 'attributes.hidden',
          filter: (value, search, item) =>
            this.activeOnly == false || item.attributes.hidden != true,
        },
        {
          text: 'Language',
          value: 'attributes.language',
          filter: (value, search, item) =>
            this.selectedLanguages.length == 0 ||
            this.selectedLanguages.indexOf(item.attributes.language) > -1,
        },
        {
          text: 'Created At',
          value: 'attributes.createdAt',
        },
      ],
      languages: {
        en: 'English',
        ar: 'Arabic',
        it: 'Italian',
        es: 'Spanish',
        ru: 'Russian',
        de: 'German',
        fr: 'French',
        pt: 'Portuguese',
        zh: 'Chinese',
      },
      selectedLanguages: [],
    };
  },

  watch: {
    partnerId: function (newPartnerId) {
      this.onPartnerChange(newPartnerId);
    },
  },

  mounted: function () {
    this.onPartnerChange(this.partnerId);
  },

  methods: {
    onPartnerChange: function (partnerId) {
      if (partnerId) {
        this.loading = true;
        OneDropApi.getTracksForPartner(
          this.partnerId,
          (response) => {
            ConsoleLog('Got new tracks for partner', response.data);
            this.tracks = response.data;
            this.loading = false;
          },
          (error) => {
            this.onApiError(error);
            this.loading = false;
          }
        );
      }
    },
    formatDate: function (str) {
      return str ? OneDropUtils.displayDate(str) : str;
    },
    formatLanguage: function (str) {
      if (str in this.languages) return this.languages[str];
      else return str;
    },
  },
};
</script>

<style scoped></style>
