var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.tracks,"search":_vm.search,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"dense":"","align":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{ref:"searchText",attrs:{"label":"Search","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":Object.entries(_vm.languages),"label":"Language","item-text":"[1]","item-value":"[0]","hide-selected":"","chips":"","deletable-chips":"","multiple":""},model:{value:(_vm.selectedLanguages),callback:function ($$v) {_vm.selectedLanguages=$$v},expression:"selectedLanguages"}})],1),_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',[_c('v-switch',{staticClass:"pa-3 align-content-end",attrs:{"label":"Active Only"},model:{value:(_vm.activeOnly),callback:function ($$v) {_vm.activeOnly=$$v},expression:"activeOnly"}})],1)],1)]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ path: '/content/trackeditor', query: { query: item.id } }}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.attributes.hidden",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"readonly":""},model:{value:(item.attributes.hidden),callback:function ($$v) {_vm.$set(item.attributes, "hidden", $$v)},expression:"item.attributes.hidden"}})]}},{key:"item.attributes.language",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatLanguage(item.attributes.language)))])]}},{key:"item.attributes.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.attributes.createdAt)))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }