<template>
  <v-container fluid>
    <v-layout class="display-1 py-4"> Manage Features </v-layout>
    <div class="text-center">
      <v-progress-circular v-if="saving" indeterminate color="primary" />
    </div>
    <v-data-table
      :items="allFeatures"
      :headers="headers"
      :search="search"
      :loading="loading"
      :expanded.sync="expanded"
      show-expand
      single-expand
      @click:row="rowClicked"
    >
      <template #top>
        <v-dialog v-model="dialog" max-width="50%">
          <template #activator="{ on }">
            <v-toolbar flat color="white">
              <v-text-field
                v-model="search"
                label="Search"
                hide-details
                outlined
                class="mr-4"
              />
              <v-btn
                color="primary"
                dark
                :loading="loading"
                :disabled="loading"
                class="mb-2 mr-2"
                @click="refreshFeatures"
              >
                Reload
              </v-btn>
              <v-btn
                v-if="hasWritePerm()"
                color="primary"
                :disabled="loading"
                dark
                class="mb-2"
                v-on="on"
                @click="onNewFeature"
              >
                New Feature
              </v-btn>
            </v-toolbar>
          </template>
          <v-card v-if="editedItem">
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row label="ID">
                  <v-col>ID: {{ editedItem.id }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                      v-model="editedItem.attributes.feature"
                      outlined
                      label="Feature"
                      :rules="[rules.required]"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.attributes.groupId"
                      outlined
                      label="Group Id"
                      type="number"
                    />
                  </v-col>
                  <v-col>
                    <v-layout>
                      <v-btn text @click="editedItem.attributes.groupId = ''">
                        Enable for All
                      </v-btn>
                      <v-btn
                        text
                        @click="editedItem.attributes.groupId = '999999'"
                      >
                        Disable
                      </v-btn>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                :disabled="loading"
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" max-width="600px">
          <v-card v-if="editedItem">
            <v-card-title>
              <span class="headline">Delete Feature</span>
            </v-card-title>

            <v-card-text>
              <v-row label="ID">
                <v-col>ID: {{ editedItem.id }}</v-col>
              </v-row>
              <v-text-field
                v-model="editedItem.attributes.feature"
                outlined
                label="Feature"
                readonly
              />
              <v-text-field
                v-model="editedItem.attributes.groupId"
                outlined
                label="Group Id"
                readonly
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                :disabled="loading"
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                @click="deleteFeature"
              >
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template #item.attributes.groupId="{ item }">
        <span>{{ item.attributes.groupId }}</span>
        <span
          v-if="item.attributes.groupId === 999999"
          class="red--text darken-1"
        >
          (Disabled)
        </span>
        <span v-else-if="!item.attributes.groupId" class="green--text">
          (Enabled for All)
        </span>
      </template>
      <template #header.action="{ header }">
        <span v-if="hasWritePerm()">{{ header.text }}</span>
      </template>
      <template #item.action="{ item }">
        <v-icon
          v-if="hasWritePerm()"
          class="mr-2"
          title="Edit"
          @click.stop="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="hasWritePerm()"
          title="Delete"
          @click.stop="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-container fluid>
            <div v-if="!item.attributes.groupId">No Groups</div>
            <div v-else-if="item.attributes.groupId === 999999">No Groups</div>
            <div v-else>
              <feature-group
                :group-id="item.attributes.groupId"
              ></feature-group>
            </div>
          </v-container>
        </td>
      </template>
      <template #no-data>
        <v-btn color="primary" @click="refreshFeatures"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { ConsoleLog, OneDropUtils } from '../onedrop-utils';
import FeatureGroup from '@/components/FeatureGroup';

export default {
  name: 'FeatureEditor',
  components: {
    FeatureGroup,
  },
  data: function () {
    return {
      dialog: false,
      deleteDialog: false,
      loading: false,
      saving: false,
      search: '',
      dateFormat: 'yyyy-MM-dd',
      timeFormat: 'HH:mm',
      headers: [
        {
          text: 'ID',
          value: 'id',
          filterable: false,
        },
        {
          text: 'Feature',
          value: 'attributes.feature',
        },
        {
          text: 'Group ID',
          value: 'attributes.groupId',
        },
        { text: 'Actions', value: 'action', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      allFeatures: [],
      editedIndex: -1,
      editedItem: null,
      expanded: [],
      timeZones: [
        'America/New_York',
        'America/Chicago',
        'America/Denver',
        'America/Los_Angeles',
      ],
      timeZonesUserFacing: ['EST', 'CST', 'MST', 'PST'],
      rules: {
        required: (value) => !!value || 'Required.',
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex == -1 ? 'New Feature' : `Edit Feature`;
    },
    partnerList() {
      return this.$store.getters.partnerList;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    deleteDialog(val) {
      val || this.close();
    },
  },
  created() {
    this.refreshFeatures();
  },
  methods: {
    partnerName: (item) => `${item.name} (${item.id})`,
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'feature-write']);
    },
    hasAssignRolePerm: function () {
      return OneDropApi.hasPerm('assign-role');
    },
    getPartnerById: function (id) {
      return this.$store.getters.partnerById(id);
    },
    getPartnerName: function (id) {
      if (id) {
        const p = this.getPartnerById(id);
        return p ? p.attributes.name : id;
      }
      return null;
    },
    getRole(item) {
      return item.attributes.userRoleName
        ? item.attributes.userRoleName
        : item.attributes.userRole;
    },
    getUserName(user) {
      return user ? (user.firstName + ' ' + user.lastName).trim() : null;
    },
    getUserEmail(user) {
      return user ? user.email : null;
    },
    formatDate: function (str) {
      return str ? OneDropUtils.displayDate(str) : str;
    },
    refreshFeatures: function () {
      ConsoleLog('Fetching features');
      this.loading = true;
      OneDropApi.get(
        '/admin/v3/feature',
        (response) => {
          this.allFeatures = response.data;
          ConsoleLog(`Got ${this.allFeatures.length} features`);
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    created: function () {
      this.refreshFeatures();
    },
    onNewFeature: function () {
      this.editedItem = {
        type: 'feature',
        attributes: {
          feature: null,
          groupId: null,
        },
      };
      this.editedIndex = -1;
      this.dialog = true;
    },
    editItem: function (item) {
      ConsoleLog('editItem', item);
      this.editedIndex = this.allFeatures.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.dialog = true;
    },
    deleteItem: function (item) {
      ConsoleLog('deleteItem', item);
      this.editedIndex = this.allFeatures.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.deleteDialog = true;
    },
    close() {
      this.editedItem = null;
      this.dialog = false;
      this.deleteDialog = false;
      this.editedIndex = -1;
      this.loading = false;
    },
    modifyAttrs(attrs) {
      delete attrs.userEmail;
      delete attrs.partnerName;
      attrs.groupId = attrs.groupId ? Number(attrs.groupId) : null;
    },
    save() {
      ConsoleLog(`Saving item ${JSON.stringify(this.editedItem)}`);
      let attrs = this.editedItem.attributes;
      this.modifyAttrs(attrs);
      let body = { data: [this.editedItem] };
      ConsoleLog(`Saving feature ${this.editedIndex}: ${JSON.stringify(body)}`);
      this.loading = true;
      if (this.editedIndex > -1) {
        OneDropApi.patch(
          `/admin/v3/feature`,
          body,
          (response) => {
            const item = response.data[0];
            this.allFeatures.splice(this.editedIndex, 1, item);
            this.saving = false;
            this.toast(`Feature saved successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.saving = false;
            this.onApiError(error);
            this.loading = false;
          }
        );
      } else {
        OneDropApi.post(
          '/admin/v3/feature',
          body,
          (response) => {
            const item = response.data[0];
            this.allFeatures.push(item);
            this.toast(`Feature ${item.id} added successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.saving = false;
            this.onApiError(error);
            this.loading = false;
          }
        );
      }
    },
    deleteFeature() {
      this.saving = true;
      let item = this.allFeatures[this.editedIndex];
      let index = this.editedIndex;
      ConsoleLog('Delete Feature', item.id);
      this.loading = true;
      OneDropApi.del(
        `/admin/v3/feature/${item.id}`,
        null,
        () => {
          this.allFeatures.splice(index, 1);
          this.saving = false;
          this.toast(`Feature ${item.id} deleted successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.saving = false;
          this.onApiError(error);
          this.loading = false;
        }
      );
    },
    rowClicked(item, options) {
      options.expand(!options.isExpanded);
    },
  },
};
</script>

<style></style>
