var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{staticClass:"display-1 py-4"},[_vm._v(" Manage Amazon S3 ")]),_c('input',{ref:"linkurl",attrs:{"id":"linkurl","type":"hidden"}}),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"label":"Search","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"color":"primary","disabled":_vm.loading,"dark":""},on:{"click":_vm.onRefresh}},[_vm._v(" Reload ")]),(_vm.hasWritePerm())?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","disabled":_vm.loading},on:{"click":_vm.onNewItem}},[_vm._v(" New Item ")]):_vm._e(),(_vm.hasWritePerm())?_c('amazon-s3-uploader',{attrs:{"edit-item":_vm.editItem},on:{"refreshContent":_vm.refreshContent},model:{value:(_vm.editItemDialog),callback:function ($$v) {_vm.editItemDialog=$$v},expression:"editItemDialog"}}):_vm._e()],1),(_vm.loading)?_c('v-progress-linear',{staticClass:"align-center",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(_vm.items && _vm.items.length > 0)?_c('v-treeview',{attrs:{"items":_vm.items,"search":_vm.search,"open":_vm.openNodes},on:{"update:open":function($event){_vm.openNodes=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(item.itemType !== 'file')?_c('v-icon',[_vm._v(" "+_vm._s(open ? 'mdi-folder-open' : 'mdi-folder')+" ")]):_c('v-icon',[_vm._v(" "+_vm._s(_vm.fileIcon(item))+" ")])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"8"}},[(item.url)?_c('a',{staticClass:"text-sm-body-2",attrs:{"href":item.url,"title":item.url,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(item.name))]):_c('span',{staticClass:"text-body-2",attrs:{"title":item.path}},[_vm._v(_vm._s(item.name))])]),_c('v-col',{attrs:{"cols":"2"}},[(item.url)?_c('span',{staticClass:"text-start text-body-2",attrs:{"title":"Last Modified"}},[_vm._v(_vm._s(_vm.formatDate(item.lastModified)))]):_vm._e()])],1)]}},{key:"append",fn:function(ref){
var item = ref.item;
return [(_vm.isEditable(item))?[_c('v-btn',{attrs:{"icon":"","title":"Copy Link"},on:{"click":function($event){return _vm.onCopyLink(item)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-btn',{attrs:{"icon":"","title":"Edit Item"},on:{"click":function($event){return _vm.onEditItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","title":"Delete Item"},on:{"click":function($event){return _vm.onDeleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]:[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-blank")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-blank")])],1),_c('v-btn',{attrs:{"icon":"","title":"Add Item"},on:{"click":function($event){return _vm.onAddItem(item)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)]]}}],null,false,3800150590)}):_vm._e(),_c('div',[_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.deleteItemDialog),callback:function ($$v) {_vm.deleteItemDialog=$$v},expression:"deleteItemDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Delete Item")])]),(_vm.editItem)?_c('v-card-text',[(_vm.editItem.keyName)?_c('div',{staticClass:"pb-2"},[_c('span',{staticClass:"od-label"},[_vm._v("KeyName:")]),_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.editItem.keyName))])]):_vm._e(),_c('v-text-field',{attrs:{"outlined":"","label":"Description","readonly":""},model:{value:(_vm.editItem.name),callback:function ($$v) {_vm.$set(_vm.editItem, "name", $$v)},expression:"editItem.name"}})],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.loading},on:{"click":_vm.doCancel}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.loading},on:{"click":_vm.doDelete}},[_vm._v(" Delete ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }