<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="5">
        <v-toolbar flat>
          <v-text-field
            ref="searchText"
            v-model="searchTerms"
            outlined
            label="search terms"
            autofocus
            @keyup.enter="onSearchClicked"
            @focus="$event.target.select()"
          />
          <v-btn
            depressed
            large
            :loading="searching"
            :disabled="searchDisabled"
            color="primary"
            class="ml-2 mb-8"
            @click="onSearchClicked"
          >
            Search
          </v-btn>
        </v-toolbar>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="selectedPartner"
          :items="partnerList"
          label="Partner"
          :item-text="partnerName"
          item-value="id"
          hide-selected
          chips
          deletable-chips
          class="mb-2"
        />
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          v-model="selectedTrack"
          :items="trackList"
          label="Track"
          :item-text="trackName"
          item-value="id"
          hide-selected
          chips
          deletable-chips
          class="mb-2"
        />
      </v-col>
    </v-row>
    <v-data-table
      :items="results"
      :headers="headers"
      :loading="loading || searching"
      :expanded.sync="expanded"
      show-expand
      single-expand
      @click:row="rowClicked"
    >
      <template #item.name="{ item }">
        <div style="cursor: pointer">
          {{ item.attributes.firstname }} {{ item.attributes.lastname }}
        </div>
      </template>
      <template #item.attributes.email="{ item }">
        <div style="cursor: pointer">
          {{ item.attributes.email }}
        </div>
      </template>
      <template #item.id="{ item }">
        <div style="cursor: pointer">
          {{ item.id }}
        </div>
      </template>
      <template #item.attributes.partner="{ item }">
        <div style="cursor: pointer">
          {{ getPartnerName(item.attributes.partner) }}
        </div>
      </template>
      <template #item.attributes.createdat="{ item }">
        <div style="cursor: pointer">
          {{ displayDate(item.attributes.createdat) }}
        </div>
      </template>
      <template #expanded-item="{ item }">
        <td :colspan="headers.length">
          <UserDetails :user-id="item.id" />
        </td>
      </template>
      <template #item.action="{ item }">
        <slot name="actions" :item="item" />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import UserDetails from './UserDetails';
import { ConsoleLog, OneDropUtils } from '../onedrop-utils';

export default {
  name: 'UserList',
  components: { UserDetails },
  model: {
    prop: 'query',
    event: 'input',
  },
  props: {
    query: String,
    partner: String,
    trackId: String,
  },
  data: function () {
    return {
      search: '',
      loading: false,
      expanded: [],
      searching: false,
      expand: false,
      results: [],
      headers: [
        {
          text: 'Id',
          value: 'id',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Email',
          value: 'attributes.email',
        },
        {
          text: 'Partner',
          value: 'attributes.partner',
        },
        {
          text: 'Track',
          value: 'attributes.trackTitle',
        },
        {
          text: 'Created',
          value: 'attributes.createdat',
        },
        { text: '', value: 'action', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      trackList: [],
    };
  },

  computed: {
    partnerList() {
      return this.$store.getters.partnerList;
    },
    searchTerms: {
      // This computed property is there
      // so that we don't mutate the parent directly
      get() {
        return this.query;
      },
      set(value) {
        this.$emit('input', value.trim());
      },
    },
    selectedPartner: {
      // This computed property is there
      // so that we don't mutate the parent directly
      get() {
        return this.partner;
      },
      set(value) {
        this.$emit('update:partner', value);
      },
    },
    selectedTrack: {
      // This computed property is there
      // so that we don't mutate the parent directly
      get() {
        return this.trackId;
      },
      set(value) {
        this.$emit('update:trackId', value);
      },
    },
    searchDisabled() {
      return !this.query || this.query.trim().length === 0 || this.searching;
    },
  },

  watch: {
    partner: function (newPartnerId) {
      this.onPartnerChange(newPartnerId);
    },
  },

  mounted: function () {
    if (this.partner) this.onPartnerChange(this.partner);
    if (this.query) {
      this.onSearchClicked();
    }
    this.$refs.searchText.focus();
  },

  methods: {
    partnerName: (item) => `${item.name} (${item.id})`,
    trackName: (item) => `${item.attributes.title} (${item.id})`,
    onSearchClicked: function () {
      if (!this.query || this.query.trim().length === 0)
        this.onApiError('Nothing to search');
      else {
        this.results = [];
        this.searching = true;
        OneDropApi.searchUsers(
          this.query.trim(),
          this.selectedPartner,
          this.trackId,
          (response) => this.onSearchResults(response),
          (error) => {
            this.searching = false;
            this.$refs.searchText.$refs.input.select();
            this.onApiError(error);
          }
        );
      }
    },

    onSearchResults: function (response) {
      this.results = response.data;
      this.searching = false;
      ConsoleLog(this.results);
      this.$refs.searchText.$refs.input.select();
    },

    displayDate: function (isoDate) {
      return OneDropUtils.displayDate(isoDate);
    },

    rowClicked(item, options) {
      options.expand(!options.isExpanded);
    },

    userDeleted: function (item) {
      const index = this.results.findIndex((r) => r.id === item.id);
      ConsoleLog('Received userDeleted', index, item.id);
      if (index > -1) this.results.splice(index, 1);
    },

    userUpdated: function () {
      // Simply close the expanded dialog so it will refresh
      this.expanded = [];
    },

    getPartnerById: function (id) {
      return id ? this.$store.getters.partnerById(id) : null;
    },

    getPartnerName: function (id) {
      if (id) {
        const p = this.getPartnerById(id);
        return p ? p.attributes.name : null;
      }
      return null;
    },

    onPartnerChange: function (partnerId) {
      this.trackList = [];
      this.selectedTrack = null;
      if (partnerId) {
        OneDropApi.getTracksForPartner(
          partnerId,
          (response) => {
            ConsoleLog('Got new tracks for partner');
            ConsoleLog(response.data);
            this.trackList = response.data;
          },
          (error) => this.onApiError(error)
        );
      }
    },
  },
};
</script>

<style scoped></style>
