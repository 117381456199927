<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-data-table
        :loading="loading"
        :items="scores"
        :headers="headers"
        dense
        sort-by="formattedDate"
        sort-desc
        :items-per-page="5"
        :footer-props="{
          'items-per-page-text': '',
          'items-per-page-options': [5, 10, 15],
        }"
      >
        <template #item.attributes.createdAt="{ item }">
          <span>{{ formatDate(item.attributes.createdAt) }}</span>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { OneDropUtils } from '../onedrop-utils';
export default {
  name: 'RiskScoreDetails',
  props: ['userId'],
  data: function () {
    return {
      loading: false,
      scores: [],
      headers: [
        {
          text: 'Score',
          value: 'attributes.value',
        },
        {
          text: 'Score Type',
          value: 'attributes.riskType',
        },
        {
          text: 'Date',
          value: 'attributes.createdAt',
        },
      ],
    };
  },
  created: function () {
    this.refresh();
  },
  methods: {
    refresh: function () {
      this.loading = true;
      OneDropApi.getUserRiskScores(
        this.userId,
        (response) => {
          this.scores = response.data;
          this.loading = false;
        },
        (err) => {
          this.onApiError(err);
          this.loading = false;
        }
      );
    },
    formatDate: function (str) {
      return str ? OneDropUtils.displayDate(str) : str;
    },
  },
};
</script>

<style scoped></style>
