<template>
  <v-container fluid>
    <v-layout class="display-1 py-4">
      Table Counts
      <v-spacer />
      <v-btn
        color="primary"
        dark
        depressed
        :loading="loading"
        :disabled="loading"
        class="mb-2 mr-2"
        @click="refreshCounts"
      >
        Refresh
      </v-btn>
    </v-layout>
    <v-simple-table>
      <template #default>
        <thead>
          <tr>
            <th class="text-left">Table</th>
            <th class="text-left">Description</th>
            <th class="text-right">Count</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="table in results" :key="table.name">
            <td>{{ table.name }}</td>
            <td>{{ findDesc(table.name) }}</td>
            <td class="text-right">
              {{ Number(table.count).toLocaleString() }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div
      v-if="lastUpdated"
      align="right"
      class="font-weight-light caption pr-2 pb-1"
    >
      Last updated {{ lastUpdated.toLocaleDateString() }}
      {{ lastUpdated.toLocaleTimeString() }}
    </div>
  </v-container>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { ConsoleLog } from '../onedrop-utils';

export default {
  name: 'TableCounts',
  data: function () {
    return {
      tables: [
        'DataObject',
        'HealthObject',
        '_User',
        'user_science_prediction',
        'stat',
        '_Installation',
      ],
      descriptions: [
        'DataObject',
        'HealthObject',
        '# Users',
        'DataScience Predictions',
        'App foregrounds since mid-March 2018',
        'Total app installations',
      ],
      results: [],
      lastUpdated: null,
      loading: false,
    };
  },
  computed: {},
  created() {
    this.refreshCounts();
  },
  methods: {
    refreshCounts: function () {
      ConsoleLog('Fetching Table Counts');
      this.loading = true;
      OneDropApi.getTableCounts(
        this.tables.toString(),
        (response) => {
          ConsoleLog(response);
          this.results = response.meta.results.map((t) => {
            return {
              name: t.table,
              count: t.rows,
            };
          });
          this.loading = false;
          this.lastUpdated = new Date();
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    findDesc: function (table) {
      const index = this.tables.indexOf(table);
      return index > -1 ? this.descriptions[index] : '';
    },
    created: function () {
      this.refreshCounts();
    },
    mounted: function () {
      this.refreshCounts();
    },
  },
};
</script>

<style scoped></style>
