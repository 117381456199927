<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-row no-gutters>
        <v-col cols="4" class="od-label"> User ID </v-col>
        <v-col cols="8">
          {{ userId }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="4" class="od-label"> First Name </v-col>
        <v-col cols="8">
          {{ profile.firstName }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="4" class="od-label"> Last Name </v-col>
        <v-col cols="8">
          {{ profile.lastName }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="4" class="od-label"> Email </v-col>
        <v-col cols="8">
          {{ profile.email }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="4" class="od-label"> Created </v-col>
        <v-col cols="8">
          {{ joinedAt }}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { OneDropUtils } from '../onedrop-utils';

export default {
  name: 'ProfileDetails',
  components: {},
  props: {
    userId: String,
    profile: Object,
    condensed: Boolean,
  },
  computed: {
    joinedAt: function () {
      return OneDropUtils.displayDate(this.profile.createdAt);
    },
  },
};
</script>

<style scoped></style>
