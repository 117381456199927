<template>
  <v-container fluid>
    <v-toolbar flat>
      <v-select
        v-model="selectedResourceType"
        :items="resourceTypes"
        label="Resource Type"
        hide-selected
      />
      <v-spacer />
    </v-toolbar>
    <v-toolbar-title>Groups</v-toolbar-title>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :search="search"
      show-expand
      single-expand
    >
      <template #item.attributes.createdAt="{ item }">
        <div>{{ formatDate(item.attributes.createdAt) }}</div>
      </template>
      <template #item.resourceId="{ item }">
        <div>{{ getTopResource(item).resourceId }}</div>
      </template>
      <template #item.resourceName="{ item }">
        <div>{{ getTopResource(item).resourceName }}</div>
      </template>
      <template #item.resourceNumber="{ item }">
        <div>
          {{ item.attributes.resources ? item.attributes.resources.length : 0 }}
        </div>
      </template>
      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-container fluid>
            <v-tabs>
              <v-tab key="1"> Codes </v-tab>
              <v-tab key="2"> Resources </v-tab>
              <v-tab-item key="1">
                <code-list :items="item.attributes.codes" />
              </v-tab-item>
              <v-tab-item key="2">
                <resource-list :items="item.attributes.resources" />
              </v-tab-item>
            </v-tabs>
          </v-container>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { OneDropApi } from '../../onedrop-api';
import { ConsoleLog, OneDropUtils } from '../../onedrop-utils';
import CodeList from '@/components/content/CodeList';
import ResourceList from '@/components/content/ResourceList';

export default {
  name: 'GroupList',
  components: { CodeList, ResourceList },
  data: function () {
    return {
      items: [],
      resourceTypes: [],
      selectedResourceType: null,
      loading: false,
      search: '',
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Slug',
          value: 'attributes.slug',
        },
        {
          text: 'Created At',
          value: 'attributes.createdAt',
        },
        {
          text: 'Resource Id (First)',
          value: 'resourceId',
        },
        {
          text: 'ResourceName (First)',
          value: 'resourceName',
        },
        {
          text: '# resources',
          value: 'resourceNumber',
        },
        { text: '', value: 'data-table-expand' },
      ],
    };
  },
  watch: {
    selectedResourceType() {
      this.refreshContent();
    },
  },
  mounted: function () {
    this.refreshResourceTypes();
  },
  methods: {
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'content-write']);
    },
    formatDate: function (str) {
      return str ? OneDropUtils.displayDate(str) : str;
    },
    getTopResource(item) {
      return item.attributes &&
        item.attributes.resources &&
        item.attributes.resources.length > 0
        ? item.attributes.resources[0]
        : { resourceId: null, resourceName: null };
    },
    refreshResourceTypes() {
      this.loading = true;
      OneDropApi.get(
        'v3/admin/content-resource-types',
        (resp) => {
          this.resourceTypes = resp.data.map((x) => x.id);
          this.selectedResourceType =
            this.resourceTypes.length > 0 ? this.resourceTypes[0] : null;
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
    refreshContent() {
      this.loading = true;
      OneDropApi.get(
        `/v3/admin/content-by-resource-type/${this.selectedResourceType}`,
        (resp) => {
          this.items = resp.data;
          this.loading = false;
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style scoped></style>
