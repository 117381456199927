<template>
  <v-container fluid>
    <top-bar>
      <template #menu>
        <v-btn text class="grey--text text--darken-1" @click="goBack">
          <v-icon left> mdi-chevron-left </v-icon>
          Home
        </v-btn>
      </template>
    </top-bar>
    <v-layout class="display-1 py-4">
      <span>Merge User</span>
      <v-spacer />
    </v-layout>
    <merge-user :user-id="userId" :url="url" @merge-done="done = true" />
  </v-container>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import TopBar from '../components/TopBar';
import MergeUser from '@/components/MergeUser';

export default {
  name: 'User',
  components: {
    MergeUser,
    TopBar,
  },
  data: function () {
    return {
      userId: this.$route.params.userId,
      url: this.$route.query.url,
      done: false,
    };
  },
  mounted() {
    if (!OneDropApi.hasPerm('admin-delete')) {
      // Not allowed to merge if the user doesn't have permission
      this.$router.push('/');
    }
  },
  methods: {
    goBack() {
      if (this.url) this.$router.push(this.url);
      else
        window.history.length > 1
          ? this.$router.go(-1)
          : this.$router.push('/');
    },
  },
};
</script>
