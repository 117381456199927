<template>
  <v-container fluid>
    <v-data-table
      dense
      :loading="loading"
      :headers="headers"
      :items="listValues"
      :search="search"
      sort-by="attributes.code"
      :item-class="getRowClass"
    >
      <template #top>
        <v-toolbar flat>
          <v-row>
            <v-col>
              <v-text-field
                v-model="search"
                label="Search"
                hide-details
                class="mb-3"
              />
            </v-col>
            <v-col>
              <v-select
                v-model="selectedLang"
                :items="availableLangs"
                label="Language"
                item-text="value"
                item-value="key"
                hide-selected
              />
            </v-col>
          </v-row>
          <v-spacer />
          <v-btn
            v-if="sharedResources > 0"
            color="primary"
            icon
            :loading="loading"
            title="Shared Resources"
            class="mb-2"
            @click="sharedResourceDialog = !sharedResourceDialog"
          >
            <v-icon>mdi-share-variant</v-icon>
          </v-btn>
          <template v-if="editMode">
            <v-btn
              color="primary"
              text
              :loading="loading"
              class="mb-2"
              title="Cancel"
              @click="onContentAdd()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <template v-if="sharedResources > 0">
              <v-btn
                color="primary"
                icon
                :loading="loading"
                :disabled="saveDisabled"
                title="Clone and Save"
                class="mb-2 mr-2"
                @click="onContentSave(false)"
              >
                <v-icon>mdi-content-duplicate</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                icon
                :loading="loading"
                :disabled="saveDisabled"
                :title="`Save ${sharedResources} shared content`"
                class="mb-2 mr-2"
                @click="onContentSave(true)"
              >
                <v-icon>mdi-content-save-all</v-icon>
              </v-btn>
            </template>
            <v-btn
              v-else
              color="primary"
              icon
              :loading="loading"
              :disabled="saveDisabled"
              title="Save"
              class="mb-2 mr-2"
              @click="onContentSave(true)"
            >
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              text
              :loading="loading"
              class="mb-2"
              title="Cancel"
              @click="onContentClose()"
            >
              <v-icon>mdi-cancel</v-icon>
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              color="primary"
              icon
              :loading="loading"
              class="mb-2"
              @click="onContentEdit()"
            >
              <v-icon title="Edit"> mdi-pencil </v-icon>
            </v-btn>
          </template>
        </v-toolbar>
      </template>
      <template #item.attributes.content="{ item }">
        <v-row :class="getClass(item)" :title="item.attributes.defaultContent">
          {{ item.attributes.content }}
        </v-row>
      </template>
      <template #item.action="{ item }">
        <v-icon
          v-if="hasWritePerm()"
          class="mr-2"
          @click.stop="onEditItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="hasWritePerm()"
          :disabled="canDelete(item)"
          @click.stop="onDeleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="editItemDialog" max-width="50%">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Content</span>
        </v-card-title>

        <v-card-text v-if="editItem">
          <div class="mb-2">Group ID: {{ editItem.attributes.groupId }}</div>
          <div class="mb-4">Code ID: {{ editItem.attributes.codeId }}</div>
          <v-text-field
            v-model="editItem.attributes.code"
            label="Code"
            outlined
            :disabled="editItem.attributes.codeId != null"
          />
          <v-select
            v-model="editItem.attributes.language"
            :items="allLangs"
            label="Language"
            item-text="value"
            item-value="key"
            hide-selected
            :readonly="editItem.attributes.codeId != null"
          />
          <v-textarea
            v-model="editItem.attributes.content"
            label="Content"
            outlined
            autofocus
            :hint="textHint"
            :rules="[rules.content]"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="onEditCancel">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="onEditSave"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sharedResourceDialog" max-width="50%">
      <v-card>
        <v-card-title>Shared Resources</v-card-title>
        <v-card-text>
          <slot name="resources" :resources="groupResources">
            <v-list v-for="(group, key) in groupResources" :key="key">
              <v-list-item-title>Group {{ key }}</v-list-item-title>
              <v-list-item v-for="(item, i) in group" :key="i">
                {{ item }}
              </v-list-item>
            </v-list>
          </slot>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="sharedResourceDialog = !sharedResourceDialog"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="caption">
      Text in italics not localized. Text in purple shows a difference between
      the localized version and the native object. Text in teal is not on the
      main table.
    </div>
  </v-container>
</template>

<script>
import { OneDropApi } from '../../onedrop-api';
import { ConsoleLog } from '../../onedrop-utils';

export default {
  name: 'ContentList',
  props: ['resourceType', 'resourceId', 'defaultValues'],
  data: function () {
    return {
      loading: false,
      emptyMessage: 'No Content',
      values: [],
      groupIds: [],
      editMode: false,
      editItemDialog: false,
      sharedResourceDialog: false,
      editItem: null,
      editValues: [],
      viewHeaders: [
        {
          text: 'Code ID',
          value: 'attributes.codeId',
          width: '100px',
        },
        {
          text: 'Code',
          value: 'attributes.code',
          width: '250px',
        },
        {
          text: 'Lang',
          value: 'attributes.language',
          align: ' d-none',
          filter: (value, search, item) =>
            (this.selectedLang &&
              item.attributes.language == this.selectedLang) ||
            !this.selectedLang,
        },
        {
          text: 'Content',
          value: 'attributes.content',
        },
      ],
      editHeaders: [
        {
          text: 'Code ID',
          value: 'attributes.codeId',
          width: '100px',
        },
        {
          text: 'Code',
          value: 'attributes.code',
          width: '250px',
        },
        {
          text: 'Lang',
          value: 'attributes.language',
          align: ' d-none',
          filter: (value, search, item) =>
            (this.selectedLang &&
              item.attributes.language == this.selectedLang) ||
            !this.selectedLang,
        },
        {
          text: 'Content',
          value: 'attributes.content',
        },
        { text: 'Actions', value: 'action', sortable: false, width: '100px' },
      ],
      langs: ['default'],
      search: null,
      selectedLang: 'default',
      allLangs: [
        { key: 'default', value: 'Default' },
        { key: 'ar', value: 'Arabic' },
        { key: 'it', value: 'Italian' },
        { key: 'es', value: 'Spanish' },
        { key: 'ru', value: 'Russian' },
        { key: 'de', value: 'German' },
        { key: 'fr', value: 'French' },
        { key: 'pt', value: 'Portuguese' },
        { key: 'zh', value: 'Chinese' },
      ],
      groupResources: {},
      sharedResources: 0,
      textHint:
        "Use two single-quotes ('') to represent a single-quote ('). Text enclosed in {} is used for substitution. To escape substitution enclose the content in single-quote like '{foo}'.",
      rules: {
        content: (value) => {
          const pattern1 = /([^'}])'([^{'])/;
          const pattern2 =
            /(^|[^'])[{]([^}]*)[}]([^']|$)|['][{]([^}]*)[}]($|[^'])|(^|[^'])[{]([^}]*)[}][']/;
          if (pattern1.test(value)) return 'Unescaped single quote';
          else if (pattern2.test(value)) return 'Unescaped variable';
          else return true;
        },
      },
    };
  },
  computed: {
    availableLangs() {
      return this.allLangs.filter(
        (v) => this.langs.findIndex((k) => v.key == k) != -1
      );
    },
    headers() {
      return this.editMode ? this.editHeaders : this.viewHeaders;
    },
    listValues() {
      return this.editMode ? this.editValues : this.values;
    },
    saveDisabled() {
      for (let k in this.editValues) {
        let item = this.editValues[k];
        if (item.attributes.origContent !== item.attributes.content)
          return false;
      }
      return true;
    },
  },
  watch: {
    resourceId: function () {
      this.refreshList();
    },
    defaultValues: function () {
      this.refreshList();
    },
  },
  mounted: function () {
    this.refreshList();
  },
  methods: {
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'content-write']);
    },
    getRowClass(item) {
      let attrs = item.attributes;
      if (this.editMode) {
        if (!attrs.content && attrs.content != attrs.origContent)
          return 'red accent-1';
        else if (
          (attrs.content && attrs.content != attrs.origContent) ||
          (attrs.origContent && attrs.origContent != attrs.content)
        )
          return 'yellow accent-3';
      }
    },
    getClass(item) {
      let attrs = item.attributes;
      if (attrs.defaultContent && attrs.content) {
        if (attrs.defaultContent == attrs.content && attrs.groupId) return '';
        else return 'deep-purple--text text--darken-3';
      } else if (attrs.defaultContent) return 'font-italic blue-grey--text';
      else if (attrs.content) return 'teal--text text--darken-3';
    },
    canDelete(item) {
      return !(item && item.attributes.content && item.attributes.groupId);
    },
    filterByLang(value, search, item) {
      return item.attributes.language == this.selectedLang;
    },
    refreshList: function () {
      // ConsoleLog(`defaultValues: ${JSON.stringify(this.defaultValues)}`)
      this.values = [];
      this.groupIds = [];
      this.langs = ['default'];
      for (let k in this.defaultValues) {
        let defaultValue = this.defaultValues[k].defaultValue;
        let o = {};
        let attrs = {};
        attrs.code = k;
        attrs.language = 'default';
        attrs.content = defaultValue;
        attrs.defaultContent = defaultValue;
        attrs.resourceType = this.resourceType;
        o.attributes = attrs;
        o.id = `${attrs.code}.${attrs.language}`;
        o.type = 'content-resource-group';
        this.values.push(o);
      }
      // ConsoleLog(`default values=${JSON.stringify(this.values)}`)
      if (this.resourceId) {
        ConsoleLog(
          `Refreshing content for ${this.resourceType}/${this.resourceId}`
        );
        this.loading = true;
        this.emptyMessage = 'No Content';
        OneDropApi.get(
          `/v3/admin/content-by-resource-id/${this.resourceType}/${this.resourceId}`,
          (result) => {
            ConsoleLog(
              `Got content for ${this.resourceType}/${this.resourceId}`
            );
            for (let d in result.data) {
              let item = result.data[d];
              let attrs = item.attributes;
              let id = `${attrs.code}.${attrs.language}`;
              let v = this.values.findIndex((v) => v.id == id);
              if (v == -1) {
                // Add a new entry
                let o = {};
                o.attributes = attrs;
                o.id = id;
                this.values.push(o);
              } else {
                // Replace the existing entry
                let old = this.values[v];
                attrs.defaultContent = old.attributes.defaultContent;
                let o = {};
                o.attributes = attrs;
                o.id = id;
                this.values.splice(v, 1, o);
              }
              let groupId = attrs.groupId;
              if (this.groupIds.indexOf(groupId) == -1)
                this.groupIds.push(groupId);

              if (
                this.langs.findIndex((e) => e == item.attributes.language) == -1
              )
                this.langs.push(item.attributes.language);
            }
            this.loading = false;
            this.checkMultipleResources();
            ConsoleLog(`values=${JSON.stringify(this.values)}`);
          },
          (error) => {
            ConsoleLog(
              `Failed to get content for ${this.resourceType}/${this.resourceId}`
            );
            ConsoleLog(error);
            this.onApiError(error);
            this.loading = false;
            this.emptyMessage = `Failed to get content with error ${error}`;
          }
        );
      } else {
        this.loading = false;
      }
    },
    checkMultipleResources() {
      this.groupResources = {};
      this.sharedResources = 0;
      for (let k in this.groupIds) {
        let gid = this.groupIds[k];
        OneDropApi.get(
          `/v3/admin/content-resource-by-group-id/${this.resourceType}/${gid}`,
          (resp) => {
            this.groupResources[gid] = resp.data.map((t) => t.id);
            // Store the value of number of resources shared with
            if (this.groupResources[gid].length > 1)
              this.sharedResources = this.groupResources[gid].length;
            ConsoleLog(`gid: ${gid} - ${JSON.stringify(this.groupResources)}`);
          },
          (error) => {
            ConsoleLog(
              `Failed to get content for ${this.resourceType}/${this.resourceId}`
            );
            ConsoleLog(error);
            this.loading = false;
            this.emptyMessage = `Failed to get content with error ${error}`;
          }
        );
      }
    },
    onContentEdit() {
      this.editMode = true;
      this.editItem = null;
      this.editValues = JSON.parse(JSON.stringify(this.values));
      this.editValues = this.editValues.map((item) => {
        item.attributes.origContent = item.attributes.content;
        return item;
      });
    },
    onContentSave(forall) {
      const changes = this.editValues
        .filter(
          (item) => item.attributes.origContent != item.attributes.content
        )
        .map((item) => {
          let attrs = item.attributes;
          if (!attrs.groupId && this.groupIds.length > 0)
            attrs.groupId = this.groupIds[0];
          attrs.resourceId = this.resourceId;
          item.type = 'content-resource-group';
          return item;
        });
      let body = { data: changes };
      this.loading = true;
      ConsoleLog(`changes=${JSON.stringify(body)}`);
      let url = `/v3/admin/content-by-resource-id`;
      if (!forall) url = url + '?clone=true';
      OneDropApi.patch(
        url,
        body,
        () => {
          this.editMode = false;
          this.loading = false;
          this.toast(`Successfully saved content`);
          this.afterSave(changes);
          this.refreshList();
        },
        (error) => {
          ConsoleLog(`Failed to save content for ${this.resourceType}`);
          ConsoleLog(error);
          this.onApiError(error);
          this.editMode = false;
          this.loading = false;
        }
      );
    },
    onContentClose() {
      this.editMode = false;
    },
    onEditItem(item) {
      this.editItem = item;
      this.editItemDialog = true;
    },
    onDeleteItem(item) {
      item.attributes.content = null;
    },
    onEditSave() {
      this.editItem = null;
      this.editItemDialog = false;
    },
    onEditCancel() {
      this.editItem.attributes.content = this.editItem.attributes.origContent;
      this.editItem = null;
      this.editItemDialog = false;
    },
    onContentAdd() {
      this.editItem = {
        type: 'content-resource-group',
        attributes: {
          resourceType: this.resourceType,
          code: null,
          language: 'default',
          content: null,
        },
      };
      if (this.groupIds.length > 0)
        this.editItem.attributes.groupId = this.groupIds[0];
      this.editValues.push(this.editItem);
      this.editItemDialog = true;
    },
    afterSave(changes) {
      let defaultKeys = Object.keys(
        this.defaultValues ? this.defaultValues : {}
      );
      if (defaultKeys.length > 0) {
        ConsoleLog(`afterSave: defaultKeys ${defaultKeys}`);
        let values = {};
        changes
          .filter((item) => {
            let attrs = item.attributes;
            ConsoleLog(`item: ${JSON.stringify(item)}`);
            return (
              attrs.language == 'default' &&
              defaultKeys.indexOf(attrs.code) > -1
            );
          })
          .map((item) => {
            let attrs = item.attributes;
            values[attrs.code] = { defaultValue: attrs.content };
            return item;
          });
        ConsoleLog(`afterSave: emit ${JSON.stringify(values)}`);
        this.$emit('changed', values);
      }
    },
  },
};
</script>

<style scoped>
.row {
  margin: 0px;
}
</style>
