<template>
  <v-container fluid>
    <v-layout class="display-1 py-4">
      Manage Partners
      <v-icon
        class="ml-2"
        title="Copy Search Link"
        @click="onCopySearchClicked()"
      >
        mdi-content-copy
      </v-icon>
    </v-layout>
    <input id="searchUrl" type="hidden" :value="searchUrl()" />
    <div class="text-center">
      <v-progress-circular v-if="saving" indeterminate color="primary" />
    </div>
    <v-data-table
      :items="filteredPartners"
      :headers="headers"
      :search="search"
      :loading="loading"
      :expanded.sync="expanded"
      show-expand
      single-expand
      @click:row="rowClicked"
    >
      >
      <template #top>
        <v-toolbar flat color="white">
          <v-text-field v-model="search" label="Search" hide-details outlined />
          <v-spacer />
          <v-switch
            v-model="employerOnly"
            label="Employer Only"
            class="pa-3 align-content-end"
          />
          <v-spacer />
          <v-switch
            v-model="showArchived"
            label="Show Archived"
            class="pa-3 align-content-end"
          />
          <v-spacer />
          <v-btn
            v-if="hasWritePerm()"
            color="primary"
            dark
            :loading="loading"
            :disabled="loading"
            class="mb-2 mr-2"
            @click="createPartnerDialog = true"
          >
            Create Partner
          </v-btn>
          <create-partner
            v-if="hasWritePerm()"
            v-model="createPartnerDialog"
            @refreshPartners="refreshPartners"
          />
          <v-btn
            color="primary"
            dark
            :loading="loading"
            :disabled="loading"
            class="mb-2 mr-2"
            @click="refreshPartners"
          >
            Reload
          </v-btn>
        </v-toolbar>
        <v-dialog v-model="createDialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Clone Partner</span>
              <v-spacer />
              <v-img
                v-if="newItem.primaryIconURL"
                :src="newItem.primaryIconURL"
                max-height="30"
                max-width="100"
                contain
              />
            </v-card-title>

            <v-progress-linear v-if="saving" indeterminate color="primary" />

            <v-card-subtitle class="mt-2 title">
              Donor: {{ getPartnerName(newItem.donor.id) }}
            </v-card-subtitle>

            <v-card-text>
              <v-text-field v-model="newItem.name" outlined label="Name" />
              <v-text-field
                v-model="newItem.primaryIconURL"
                outlined
                label="Primary Icon"
                type="url"
              />
              <v-text-field
                v-model="newItem.supportEmail"
                outlined
                label="Support Email"
                type="email"
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                :loading="saving"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                :loading="saving"
                text
                @click="saveCreate"
              >
                Create
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="editDialog" max-width="80%">
          <v-card>
            <PartnerDetailEdit
              :partner="editedItem.partner"
              @cancel="close"
              @saved="saveEdit"
            />
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Delete Partner</span>
              <v-spacer />
              <v-img
                v-if="editedItem.primaryIconURL"
                :src="editedItem.primaryIconURL"
                max-height="30"
                max-width="100"
                contain
              />
            </v-card-title>

            <v-progress-linear v-if="saving" indeterminate color="primary" />

            <v-card-text>
              <v-row label="Object ID">
                <v-col>Object ID: {{ editedItem.id }}</v-col>
              </v-row>
              <v-text-field
                v-model="editedItem.name"
                outlined
                readonly
                label="Name"
              />
              <v-text-field
                v-model="editedItem.primaryIconURL"
                outlined
                readonly
                label="Primary Icon"
                type="url"
              />
              <v-text-field
                v-model="editedItem.supportEmail"
                outlined
                readonly
                label="Support Email"
                type="email"
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                :loading="saving"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                :loading="saving"
                text
                @click="deletePartner"
              >
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="migrateDialog" max-width="85%">
          <migrate-track
            :source="migrateItem"
            :visible="migrateDialog"
            @close="close"
          />
        </v-dialog>
      </template>
      <template #header.action="{ header }">
        <span v-if="hasWritePerm()">{{ header.text }}</span>
      </template>
      <template #item.attributes.primaryIconURL="{ item }">
        <v-img
          :src="item.attributes.primaryIconURL"
          max-height="30"
          max-width="100"
          contain
        />
      </template>
      <template #item.attributes.employer="{ item }">
        <v-switch v-model="item.attributes.employer" readonly />
      </template>
      <template #item.attributes.createdAt="{ item }">
        <span>{{ formatDate(item.attributes.createdAt) }}</span>
      </template>
      <template #item.action="{ item }">
        <v-icon
          v-if="hasWritePerm()"
          class="mr-2"
          title="Clone"
          @click.stop="createItem(item)"
        >
          mdi-content-duplicate
        </v-icon>
        <v-icon
          v-if="hasWritePerm()"
          class="mr-2"
          title="Edit"
          @click.stop="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="hasWritePerm()"
          title="Delete"
          @click.stop="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
        <v-icon
          v-if="hasWritePerm()"
          title="Migrate Tracks"
          @click.stop="onMigrateItem(item)"
        >
          mdi-arrow-right-bold-circle
        </v-icon>
      </template>
      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-container fluid>
            <v-tabs>
              <v-tab key="1"> Details </v-tab>
              <v-tab key="2"> Tracks </v-tab>
              <v-tab key="3"> Questions </v-tab>
              <v-tab key="4"> Configuration </v-tab>
              <v-tab key="5"> Enrollment Actions </v-tab>
              <v-tab v-if="hasWritePerm()" key="6"> Audit Events </v-tab>
              <v-tab-item key="1">
                <PartnerDetail :partner="item" @changed="saveChanges" />
              </v-tab-item>
              <v-tab-item key="2">
                <partner-track-detail :partner-id="item.id" />
              </v-tab-item>
              <v-tab-item key="3">
                <GroupQuestion :partner-id="item.id" />
              </v-tab-item>
              <v-tab-item key="4">
                <PartnerConfig :partner-id="item.id" />
              </v-tab-item>
              <v-tab-item key="5">
                <partner-enrollment-actions :partner-id="item.id" />
              </v-tab-item>
              <v-tab-item key="6">
                <audit-log-viewer event-type="partner" :object-id="item.id" />
              </v-tab-item>
            </v-tabs>
          </v-container>
        </td>
      </template>
      <template #no-data>
        <v-btn color="primary" @click="refreshPartners"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { ConsoleLog, OneDropUtils } from '../onedrop-utils';
import PartnerDetail from '../components/PartnerDetail';
import PartnerDetailEdit from '../components/PartnerDetailEdit';
import GroupQuestion from '../components/question/GroupQuestion';
import PartnerConfig from '../components/config/PartnerConfig';
import MigrateTrack from '@/components/track/MigrateTrack';
import PartnerTrackDetail from '@/components/PartnerTrackDetail';
import AuditLogViewer from '@/components/AuditLogViewer';
import PartnerEnrollmentActions from '@/components/PartnerEnrollmentActions';
import CreatePartner from '@/components/CreatePartner';

export default {
  name: 'PartnerEditor',
  components: {
    PartnerTrackDetail,
    PartnerDetail,
    PartnerDetailEdit,
    GroupQuestion,
    PartnerConfig,
    MigrateTrack,
    AuditLogViewer,
    PartnerEnrollmentActions,
    CreatePartner,
  },
  data: function () {
    return {
      createDialog: false,
      editDialog: false,
      deleteDialog: false,
      activeOnly: false,
      showArchived: false,
      loading: false,
      saving: false,
      search: '',
      employerOnly: false,
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Name',
          value: 'attributes.name',
        },
        {
          text: 'Primary Icon',
          value: 'attributes.primaryIconURL',
          sortable: false,
        },
        {
          text: 'Email',
          value: 'attributes.supportEmail',
        },
        {
          text: 'Employer',
          value: 'attributes.employer',
          filter: (value, search, item) =>
            this.employerOnly == false || item.attributes.employer == true,
        },
        {
          text: 'Created At',
          value: 'attributes.createdAt',
        },
        { text: 'Actions', value: 'action', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      editedIndex: -1,
      newItem: {
        id: '',
        name: '',
        primaryIconURL: '',
        supportEmail: '',
        donor: '',
        deep: false,
      },
      defaultNewItem: {
        id: '',
        name: '',
        primaryIconURL: '',
        supportEmail: '',
        donor: '',
        deep: false,
      },
      editedItem: {
        id: '',
        name: '',
        primaryIconURL: '',
        supportEmail: '',
        partner: null,
      },
      defaultItem: {
        id: '',
        name: '',
        primaryIconURL: '',
        supportEmail: '',
      },
      expanded: [],
      migrateItem: null,
      migrateDialog: false,
      createPartnerDialog: false,
    };
  },
  computed: {
    allPartners() {
      return this.$store.state.allPartners;
    },
    partnerList() {
      return this.$store.getters.partnerList;
    },
    currentUrl: function () {
      let q = {};
      if (this.search) q.query = encodeURIComponent(this.search);
      if (this.employerOnly) q.employerOnly = encodeURIComponent(true);
      return this.$router.resolve({ path: '/setup/partnereditor', query: q })
        .route.fullPath;
    },
    filteredPartners() {
      return this.showArchived
        ? this.allPartners
        : this.allPartners.filter((v) => !v.attributes.archived);
    },
  },
  watch: {
    createDialog(val) {
      val || this.close();
    },
    editDialog(val) {
      val || this.close();
    },
    deleteDialog(val) {
      val || this.close();
    },
  },
  mounted() {
    if (this.$route.query.query) {
      this.search = decodeURIComponent(this.$route.query.query).trim();
    }
    if (this.$route.query.employerOnly) {
      this.employerOnly = decodeURIComponent(this.$route.query.employerOnly);
    }
    this.refreshPartners();
  },
  methods: {
    hasWritePerm() {
      return OneDropApi.hasOneOfPerms(['admin-write', 'partner-write']);
    },
    formatDate: function (str) {
      return str ? OneDropUtils.displayDate(str) : str;
    },
    refreshPartners: function () {
      this.saving = true;
      OneDropApi.getAllPartners(
        () => {
          this.saving = false;
          ConsoleLog('Partners:', this.allPartners);
        },
        (error) => {
          this.saving = false;
          this.onApiError(error);
        }
      );
    },
    getPartnerById: function (id) {
      return this.$store.getters.partnerById(id);
    },
    getPartnerName: function (id) {
      if (id) {
        const p = this.getPartnerById(id);
        return p ? p.attributes.name : null;
      }
      return null;
    },
    itemToEdited: function (item) {
      return {
        id: item.id,
        name: item.attributes.name,
        primaryIconURL: item.attributes.primaryIconURL,
        supportEmail: item.attributes.supportEmail,
        partner: item,
      };
    },
    parsePartnerJsonToIds: function (json) {
      if (json) {
        const list = JSON.parse(json).map((p) => {
          return p.objectId;
        });
        return list;
      } else return [];
    },
    getParsePartnerList: function (json) {
      const list = this.parsePartnerJsonToIds(json).map((id) => {
        return this.$store.getters.partnerById(id);
      });
      return list;
    },
    createItem: function (item) {
      this.editedIndex = -1;
      this.newItem = JSON.parse(JSON.stringify(this.defaultNewItem));
      this.newItem.primaryIconURL = item.attributes.primaryIconURL;
      this.newItem.donor = item;
      this.newItem.deep = false;
      ConsoleLog('createItem', this.newItem);
      this.createDialog = true;
    },
    editItem: function (item) {
      this.editedIndex = this.allPartners.indexOf(item);
      this.editedItem = this.itemToEdited(item);
      ConsoleLog('editedItem', this.editedItem);
      this.editDialog = true;
    },
    deleteItem: function (item) {
      ConsoleLog('deleteItem', item);
      this.editedIndex = this.allPartners.indexOf(item);
      this.editedItem = this.itemToEdited(item);
      this.deleteDialog = true;
    },
    onMigrateItem: function (item) {
      ConsoleLog(`migrateItem: ${item.id}`);
      this.migrateItem = item.id;
      this.migrateDialog = true;
    },
    close() {
      this.editedItem = this.defaultItem;
      this.newItem = JSON.parse(JSON.stringify(this.defaultNewItem));
      this.createDialog = false;
      this.editDialog = false;
      this.deleteDialog = false;
      this.migrateDialog = false;
      this.editedIndex = -1;
    },
    saveCreate() {
      ConsoleLog(`Creating Partner: ${JSON.stringify(this.newItem)}`);
      let attrs = {};
      attrs.name = this.newItem.name;
      attrs.primaryIconURL = this.newItem.primaryIconURL;
      attrs.supportEmail = this.newItem.supportEmail;
      let body = {
        data: [
          {
            type: 'partner',
            attributes: attrs,
          },
        ],
      };
      const donorId = this.newItem.donor.id;
      let url = `v3/admin/resource/clone-partner/${donorId}?cloneTracks=true`;
      if (this.newItem.deep) url = url + '&deep=true';
      this.saving = true;
      OneDropApi.post(
        url,
        body,
        (response) => {
          ConsoleLog('Partner create successful', response);
          const partner = response.data[0];
          this.$store.commit('addPartner', partner);
          this.saving = false;
          this.toast(`Partner created ${partner.id}`);
          this.close();
        },
        (error) => {
          ConsoleLog('Error cloning partner', error);
          this.saving = false;
          this.onApiError(error);
        }
      );
    },
    saveEdit(item) {
      this.expanded = [];
      ConsoleLog(`saveEdit: ${JSON.stringify(item)}`);
      this.$store.commit('updatePartner', item);
      this.toast(`Partner ${item.id} saved successfully`);
      this.close();
    },
    saveChanges(item) {
      let body = {
        data: [item],
      };
      let id = item.id;
      this.saving = true;
      ConsoleLog(`saveChanges: Saving partner: ${JSON.stringify(body)}`);
      OneDropApi.patchResource(
        'partner',
        id,
        body,
        (response) => {
          ConsoleLog(`saveChanges: ${JSON.stringify(response.data)}`);
          this.toast(`Partner ${item.id} saved successfully`);
          this.saving = false;
          this.refreshPartners();
        },
        (error) => {
          ConsoleLog(`Error saving partner ${error}`);
          this.onApiError(error);
          this.saving = false;
        }
      );
    },
    deletePartner() {
      this.saving = true;
      let item = this.allPartners[this.editedIndex];
      let index = this.editedIndex;
      ConsoleLog('Delete Item', index, item);
      OneDropApi.deletePartner(
        item.id,
        () => {
          this.$store.commit('deletePartner', item);
          this.saving = false;
          this.toast(`Item ${item.id} deleted successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.saving = false;
          this.onApiError(error);
        }
      );
    },
    rowClicked(item, options) {
      options.expand(!options.isExpanded);
    },
    searchUrl() {
      let path = this.currentUrl;
      const protocol = window.location.protocol;
      const host = window.location.host;
      return `${protocol}//${host}/#${path}`;
    },
    onCopySearchClicked: function () {
      const copiedText = this.copyToClipboard('searchUrl');
      this.toast('Copied: ' + copiedText);
    },
  },
};
</script>

<style></style>
