<template>
  <v-card>
    <v-card-title>
      <span class="headline">Copy Track</span>
    </v-card-title>

    <v-card-text>
      <div class="text-center">
        <v-progress-circular v-if="loading" indeterminate color="primary" />
      </div>
      <div class="mb-2">
        <div class="subtitle-1">Donor:</div>
        <div>
          <span class="od-label">Track:</span>
          <span class="ml-2">{{ track ? track.attributes.title : '' }}</span>
        </div>
      </div>
      <v-select
        v-model="versionId"
        :items="versions"
        label="Version"
        :item-text="versionName"
        item-value="id"
        hide-selected
        chips
        deletable-chips
      />
      <v-autocomplete
        v-model="partnerIds"
        :items="partnerList"
        label="Recipient Partner"
        :item-text="partnerName"
        item-value="id"
        hide-selected
        chips
        deletable-chips
        multiple
      />
      <v-select
        v-model="language"
        :items="Object.entries(languages)"
        label="Language"
        item-text="[1]"
        item-value="[0]"
        hide-selected
        chips
        deletable-chips
      />
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="blue darken-1" text :disabled="loading" @click="close">
        Cancel
      </v-btn>
      <v-btn color="blue darken-1" text :loading="loading" @click="saveCreate">
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ConsoleLog } from '@/onedrop-utils';
import { OneDropApi } from '@/onedrop-api';

export default {
  name: 'TrackCopy',
  props: ['track', 'partnerList', 'languages'],
  data: function () {
    return {
      versions: null,
      loading: false,
      versionId: null,
      partnerIds: null,
      language: 'en',
    };
  },
  computed: {
    trackId() {
      return this.track ? this.track.id : this.track;
    },
  },
  mounted() {
    this.versionId = null;
    this.partnerIds = null;
    this.refreshVersions();
  },
  methods: {
    partnerName: (item) => `${item.name} (${item.id})`,
    versionName: (item) =>
      item ? `${item.attributes.version} (${item.id})` : item,
    refreshVersions: function () {
      this.versions = [];
      if (this.trackId) {
        this.loading = true;
        ConsoleLog(`Loading versions for track ${this.trackId}`);
        OneDropApi.get(
          `/v3/admin/track-schedule-versions?track-id=${this.trackId}`,
          (response) => {
            this.versions = response.data;
            this.versionId = this.versions
              ? this.versions[this.versions.length - 1].id
              : null;
            this.loading = false;
          },
          (error) => {
            this.onApiError(error);
            ConsoleLog(error);
            this.loading = false;
          }
        );
      }
    },
    saveCreate: function () {
      ConsoleLog(
        `Copy item: ${this.track.id}, ${
          this.track.attributes.title
        }, ${JSON.stringify(this.versionId)}`
      );
      this.loading = true;
      OneDropApi.post(
        `/v3/admin/resource/copy-track/${this.trackId}?partnerId=${this.partnerIds}&language=${this.language}&versionId=${this.versionId}`,
        null,
        (response) => {
          ConsoleLog(response);
          const newTrack = response.data[0];
          this.loading = false;
          this.$emit('track-created', newTrack);
        },
        (error) => {
          ConsoleLog(error);
          this.loading = false;
          this.onApiError(error);
        }
      );
    },
    close: function () {
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped></style>
