<template>
  <v-card flat>
    <v-data-table
      :items="answers"
      :headers="headers"
      :loading="loading"
      :expanded.sync="expanded"
      multi-sort
      disable-pagination
      hide-default-footer
      show-expand
      single-expand
    >
      <template #top>
        <v-toolbar v-if="!readOnly" flat color="white" class="pa-0 ma-0">
          <v-spacer />
          <v-btn
            v-if="hasWritePerm()"
            color="primary"
            dark
            :loading="loading"
            :disabled="loading"
            class="mb-2 mr-2"
            @click="onNewItem"
          >
            New Answer
          </v-btn>
          <v-btn
            v-if="hasWritePerm()"
            color="primary"
            :loading="loading"
            :disabled="!hasChanges"
            class="mb-2 mr-2"
            @click="onSaveChanges"
          >
            Save
          </v-btn>
          <v-btn
            v-if="hasWritePerm()"
            color="primary"
            :loading="loading"
            :disabled="!hasChanges"
            class="mb-2 mr-2"
            @click="onCancelChanges"
          >
            Cancel
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.attributes.nextQuestionId="{ item }">
        <span>{{ questionNameFromId(item.attributes.nextQuestionId) }}</span>
      </template>
      <template #item.attributes.sequence="{ item }">
        <v-layout>
          {{ item.attributes.sequence }}
          <v-icon
            v-if="hasWritePerm()"
            class="ml-2"
            :disabled="upDisabled(item)"
            @click.stop="onSequenceUp(item)"
          >
            mdi-arrow-up-drop-circle-outline
          </v-icon>
          <v-icon
            v-if="hasWritePerm()"
            :disabled="downDisabled(item)"
            @click.stop="onSequenceDown(item)"
          >
            mdi-arrow-down-drop-circle-outline
          </v-icon>
        </v-layout>
      </template>
      <template #item.action="{ item }">
        <v-icon
          v-if="hasWritePerm()"
          class="mr-2"
          @click.stop="onEditItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon v-if="hasWritePerm()" @click.stop="onDeleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-container v-if="!readOnly" fluid>
            <v-card-title>Content</v-card-title>
            <div>readOnly={{ readOnly }}</div>
            <ContentResourceView
              ref="contentList"
              resource-type="answer-choice"
              :resource-id="item.id"
              :default-values="defaultContent"
            >
              <template #actions>
                <v-btn
                  color="primary"
                  icon
                  :loading="loading"
                  class="mb-2 mr-2"
                  @click="onContentEdit(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
            </ContentResourceView>
          </v-container>
        </td>
      </template>
    </v-data-table>
    <div>
      <v-dialog v-model="editItemDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text v-if="editItem">
            <div class="pb-2">
              <span class="od-label">Question:</span
              ><span class="pl-2">{{ editItem.attributes.questionId }}</span>
            </div>
            <v-text-field
              v-model="editItem.attributes.answer"
              outlined
              label="Answer"
            />
            <v-text-field
              v-model="editItem.attributes.answerSlug"
              outlined
              label="Slug"
            />
            <v-text-field
              v-model="editItem.attributes.sequence"
              type="number"
              outlined
              label="Sequence"
            />
            <v-text-field
              v-model="editItem.attributes.linkValue"
              outlined
              label="Link Value"
            />
            <v-text-field
              v-model="editItem.attributes.metaData"
              outlined
              label="Meta Data"
              :rules="[rules.json]"
              validate-on-blur
            />
            <v-select
              v-if="filteredQuestions.length > 0"
              v-model="editItem.attributes.nextQuestionId"
              :items="filteredQuestions"
              label="Next Question"
              :item-text="questionName"
              item-value="attributes.questionId"
              hide-selected
              chips
              deletable-chips
            />
            <v-text-field
              v-model="editItem.attributes.track"
              outlined
              label="Track"
            />
            <v-combobox
              v-model="editItem.attributes.valueType"
              :items="valueTypes"
              label="Value Type"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="doSave"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteItemDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Delete Answer Choice</span>
          </v-card-title>

          <v-card-text v-if="editItem">
            <div class="pb-2">
              <span class="od-label">Question:</span
              ><span class="pl-2">{{ editItem.attributes.questionId }}</span>
            </div>
            <v-text-field
              v-model="editItem.attributes.answer"
              outlined
              label="Answer"
              readonly
            />
            <v-text-field
              v-model="editItem.attributes.answerSlug"
              outlined
              label="Slug"
              readonly
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="doDelete"> Delete </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import { OneDropApi } from '../../onedrop-api';
import { ConsoleLog } from '../../onedrop-utils';
import ContentResourceView from '@/components/content/ContentResourceView';

export default {
  name: 'AnswerChoice',
  components: { ContentResourceView },
  props: ['questionId', 'questionObjectId', 'questions', 'readOnly'],
  data: function () {
    return {
      answers: [],
      origAnswers: null,
      expanded: [],
      loading: false,
      editItemDialog: false,
      deleteItemDialog: false,
      headers: [
        {
          text: 'Id',
          value: 'attributes.answerChoiceId',
        },
        {
          text: 'Object Id',
          value: 'id',
        },
        {
          text: 'Answer',
          value: 'attributes.answer',
          width: '300px',
        },
        {
          text: 'Slug',
          value: 'attributes.answerSlug',
        },
        {
          text: 'Sequence',
          value: 'attributes.sequence',
        },
        {
          text: 'Link Value',
          value: 'attributes.linkValue',
        },
        {
          text: 'Meta Data',
          value: 'attributes.metaData',
        },
        {
          text: 'Next Question',
          value: 'attributes.nextQuestionId',
        },
        {
          text: 'Track',
          value: 'attributes.track',
        },
        {
          text: 'Value Type',
          value: 'attributes.valueType',
        },
        { text: 'Actions', value: 'action', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      rules: {
        json: (value) => {
          try {
            if (value) JSON.parse(value);
            return true;
          } catch (e) {
            return 'Invalid JSON';
          }
        },
      },
      valueTypes: ['String', 'Boolean', 'Int', 'Float', 'date'],
      destinationTypes: ['pubnub', 'push-notification', 'inbox', 'none'],
      editedIndex: -1,
      editItem: null,
      defaultContent: {
        title: { defaultValue: null },
        description: { defaultValue: null },
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex == -1
        ? 'New Answer Choice'
        : `Edit Answer Choice`;
    },
    filteredQuestions() {
      return this.questions.filter((q) => q.id !== this.questionObjectId);
    },
    hasChanges() {
      if (this.origAnswers) {
        for (let i in this.answers) {
          ConsoleLog(`${i}: ${this.answers[i].id}, ${this.origAnswers[i].id}`);
          if (this.answers[i].id !== this.origAnswers[i].id) return true;
        }
      }
      return false;
    },
  },
  watch: {
    questionObjectId: function () {
      this.refreshAnswers();
    },
  },
  created() {
    this.refreshAnswers();
  },
  methods: {
    hasWritePerm() {
      return (
        OneDropApi.hasOneOfPerms(['admin-write', 'question-write']) &&
        !this.readOnly
      );
    },
    questionName(q) {
      ConsoleLog(`q=${JSON.stringify(q)}`);
      return q ? `${q.id}: ${q.attributes.question}` : q;
    },
    questionNameFromId(id) {
      if (this.questions) {
        for (let k in this.questions) {
          if (this.questions[k].id === id)
            return this.questionName(this.questions[k]);
        }
      }
      return id;
    },
    dialogTitle: function () {
      return this.editedIndex == -1
        ? 'New Answer Choice'
        : 'Edit Answer Choice';
    },
    refreshAnswers: function () {
      this.schedule = [];
      if (this.questionObjectId) {
        this.loading = true;
        ConsoleLog(`Loading answers for question ${this.questionObjectId}`);
        this.fetchAnswers();
      }
    },
    fetchAnswers: function () {
      ConsoleLog(`Fetching answers`);
      OneDropApi.getAnswers(
        this.questionObjectId,
        (response) => {
          this.answers = response.data;
          this.origAnswers = JSON.parse(JSON.stringify(this.answers));
          ConsoleLog(`Answers: ${JSON.stringify(response.data)}`);
          this.loaded();
        },
        (error) => {
          this.onApiError(error);
          ConsoleLog(error);
          this.loaded();
        }
      );
    },
    loaded: function () {
      this.loading = false;
    },
    close: function () {
      this.loading = false;
      this.editedIndex = -1;
      this.editItem = null;
      this.editItemDialog = false;
      this.deleteItemDialog = false;
    },
    modifyAttrs(attrs) {
      attrs.sequence = Number(attrs.sequence);
      attrs.nextQuestionId = attrs.nextQuestionId
        ? Number(attrs.nextQuestionId)
        : attrs.nextQuestionId;
      if (!attrs.answerSlug) delete attrs.answerSlug;
      if (!attrs.metaData) delete attrs.metaData;
      if (!(attrs.linkValue && attrs.linkValue.trim())) delete attrs.linkValue;
    },
    doSave: function () {
      let attrs = this.editItem.attributes;
      this.modifyAttrs(attrs);
      let body = { data: [this.editItem] };
      ConsoleLog(`Save ${this.editedIndex}, ${JSON.stringify(this.editItem)}`);
      this.loading = true;
      if (this.editedIndex > -1) {
        OneDropApi.updateAnswerChoice(
          body,
          () => {
            this.refreshAnswers();
            this.toast(`Answer choice updated successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.onApiError(error);
            this.close();
          }
        );
      } else {
        OneDropApi.insertAnswerChoice(
          body,
          () => {
            this.refreshAnswers();
            this.toast(`Answer choice added successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.onApiError(error);
            this.close();
          }
        );
      }
    },
    doDelete: function () {
      ConsoleLog(
        `Delete ${this.editedIndex}, ${JSON.stringify(this.editItem)}`
      );
      this.modifyAttrs(this.editItem.attributes);
      this.loading = false;
      OneDropApi.deleteAnswerChoice(
        this.editItem.id,
        () => {
          this.refreshAnswers();
          this.toast(`Answer choice deleted successfully`);
          this.close();
        },
        (error) => {
          ConsoleLog(error);
          this.onApiError(error);
          this.close();
        }
      );
    },
    onNewItem: function () {
      this.editItem = {
        type: 'answer-choice',
        attributes: {
          answer: '',
          answerSlug: '',
          sequence: this.answers ? this.answers.length + 1 : 1,
          linkValue: '',
          metaData: '',
          nextQuestionId: null,
          questionId: this.questionId,
        },
      };
      this.editedIndex = -1;
      this.editItemDialog = true;
    },
    onEditItem: function (item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.editedIndex = this.answers.findIndex((v) => v.id === item.id);
      this.editItemDialog = true;
    },
    onDeleteItem: function (item) {
      this.editItem = item;
      this.editedIndex = this.answers.findIndex((v) => v.id === item.id);
      this.deleteItemDialog = true;
    },
    onContentEdit: function (item) {
      ConsoleLog(`Edit item: ${JSON.stringify(item)}`);
      // this.editItem = JSON.parse(JSON.stringify(item))
      // this.editedIndex = this.questions.findIndex(v => v.id === item.id)
      // this.editContentDialog = true
    },
    onSequenceUp(item) {
      let index = this.answers.findIndex((v) => v.id === item.id);
      ConsoleLog(`Sequence Up: ${index}`);
      if (index > 0) {
        let prev = this.answers[index - 1];
        item.attributes.sequence = item.attributes.sequence - 1;
        prev.attributes.sequence = prev.attributes.sequence + 1;
        this.answers.splice(index - 1, 1, item);
        this.answers.splice(index, 1, prev);
      }
    },
    onSequenceDown(item) {
      let index = this.answers.findIndex((v) => v.id === item.id);
      ConsoleLog(`Sequence Down: ${index}: ${this.answers.length}`);
      if (index < this.answers.length) {
        let next = this.answers[index + 1];
        item.attributes.sequence = item.attributes.sequence + 1;
        next.attributes.sequence = next.attributes.sequence - 1;
        this.answers.splice(index, 1, next);
        this.answers.splice(index + 1, 1, item);
      }
    },
    upDisabled(item) {
      let index = this.answers.findIndex((v) => v.id === item.id);
      return index <= 0;
    },
    downDisabled(item) {
      let index = this.answers.findIndex((v) => v.id === item.id);
      return index >= this.answers.length - 1;
    },
    onSaveChanges() {
      const data = [];
      for (let i in this.answers) {
        if (this.answers[i].id !== this.origAnswers[i].id) {
          const d = this.answers[i];
          data.push(d);
        }
      }
      ConsoleLog(`Save: ${JSON.stringify(data)}`);
      if (data.length > 0) {
        this.loading = true;
        const body = { data: data };
        OneDropApi.patch(
          `/v3/admin/answer-choice`,
          body,
          () => {
            this.refreshAnswers();
            this.toast(`Answers updated successfully`);
            this.close();
          },
          (error) => {
            ConsoleLog(error);
            this.onApiError(error);
            this.loading = false;
          }
        );
      }
    },
    onCancelChanges() {
      this.answers = JSON.parse(JSON.stringify(this.origAnswers));
    },
  },
};
</script>

<style scoped></style>
