import Vue from 'vue';
import Vuex from 'vuex';
import { VuexPersistence } from 'vuex-persist';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

const getDefaultState = () => {
  return {
    user: null,
    jwtToken: null,
    jwtRefreshToken: null,
    infoMessage: null,
    isInfoMessageVisible: false,
    errorMessage: null,
    clipboardContent: null,
    isErrorMessageVisible: false,
    perms: null,
    allPartners: [],
    playgroundHistory: [],
  };
};

const store = new Vuex.Store({
  state: getDefaultState(),
  getters: {
    partnerList: (state) => {
      return state.allPartners.map((p) => {
        return {
          id: p.id,
          name: p.attributes.name,
        };
      });
    },
    partnerById: (state) => (id) => {
      return state.allPartners.find((p) => p.id == id);
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },

    setPartners(state, partners) {
      state.allPartners = partners;
    },

    setPerms(state, perms) {
      state.perms = perms;
    },

    setJwtToken(state, jwtToken) {
      state.jwtToken = jwtToken;
    },

    setJwtRefreshToken(state, jwtRefreshToken) {
      state.jwtRefreshToken = jwtRefreshToken;
    },

    setInfoMessage(state, infoMessage) {
      state.infoMessage = infoMessage;
    },

    setIsInfoMessageVisible(state, isInfoMessageVisible) {
      state.isInfoMessageVisible = isInfoMessageVisible;
    },

    setErrorMessage(state, errorMessage) {
      state.errorMessage = errorMessage;
    },

    setIsErrorMessageVisible(state, isErrorMessageVisible) {
      state.isErrorMessageVisible = isErrorMessageVisible;
    },

    setClipboardContent(state, clipboardContent) {
      state.clipboardContent = clipboardContent;
    },

    reset(state) {
      Object.assign(state, getDefaultState());
    },

    addPartner(state, partner) {
      state.allPartners.push(partner);
    },

    updatePartner(state, partner) {
      const index = state.allPartners.findIndex((p) => p.id == partner.id);
      if (index > -1) {
        state.allPartners[index] = partner;
      }
    },

    deletePartner(state, partner) {
      const index = state.allPartners.findIndex((p) => p.id == partner.id);
      if (index > -1) {
        state.allPartners.splice(index, 1);
      }
    },

    savePlaygroundCommand(state, cmd) {
      if (state.playgroundHistory.length > 0) {
        const top = state.playgroundHistory[0];
        if (JSON.stringify(cmd) == JSON.stringify(top)) return;
      }
      const index = state.playgroundHistory.findIndex(
        (val) => val.request == cmd.request
      );
      if (index > -1) state.playgroundHistory.splice(index, 1);
      state.playgroundHistory.unshift(cmd);
      state.playgroundHistory = state.playgroundHistory.slice(0, 20);
    },

    clearPlayground(state) {
      state.playgroundHistory = [];
    },
  },
  actions: {
    reset() {
      store.commit('reset');
    },
  },
  modules: {},
  plugins: [vuexLocal.plugin],
});

export default store;
