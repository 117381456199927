<template>
  <v-data-iterator :items="items">
    <template #default="{ items }">
      <v-container>
        <v-row dense>
          <v-col cols="3" class="font-weight-bold"> Name </v-col>
          <v-col class="font-weight-bold"> URL </v-col>
        </v-row>
        <v-row v-for="(item, i) in items" :key="i" dense>
          <v-col cols="3" class="font-weight-regular text--primary">
            {{ item.attributes.lessonName }}
          </v-col>
          <v-col class="font-weight-regular text--primary">
            {{ item.attributes.url }}
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-data-iterator>
</template>

<script>
export default {
  name: 'LessonImportDetail',
  props: ['items'],
};
</script>

<style scoped></style>
