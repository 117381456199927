import axios from 'axios';
import store from './store/index';
import { ConsoleLog } from './onedrop-utils';

const client = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    ConsoleLog(`Received error:`);
    ConsoleLog(`${JSON.stringify(error)}`);
    if (error.response && error.response.status == 401) {
      ConsoleLog('onedrop-api: Detected Auth failure');
      window.dispatchEvent(new CustomEvent('api-error', { detail: error }));
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

function getHeaders() {
  const jwtToken = store.state.jwtToken;

  if (jwtToken != null) {
    return {
      'Content-Type': 'application/json',
      'X-Auth-Token': jwtToken,
    };
  } else {
    return {
      'Content-Type': 'application/json',
    };
  }
}

function logError(error) {
  ConsoleLog(error);
}

const adminPerms = [
  'admin-read',
  'admin-write',
  'admin-delete',
  'med-read',
  'med-write',
  'news-message-read',
  'news-message-write',
  'inbox-message-write',
  'eligibility-write',
  'admin-user-read',
  'partner-read',
  'partner-write',
  'track-read',
  'track-write',
  'lesson-read',
  'config-read',
  'config-write',
  'lesson-write',
  'content-read',
  'content-write',
  'question-read',
  'question-write',
  'inbox-message-read',
  'eligibility-read',
  'coach-admin-read',
  'coach-admin-write',
  'assign-role',
  'question-response-read',
  's3-read',
  's3-write',
  'user-query-read',
  'user-query-write',
  'sku-entitlement-read',
  'sku-entitlement-write',
  'employer-read',
  'employer-write',
  'risk-score-read',
  'feature-read',
  'feature-write',
  'table-count-read',
];

const allPerms = ['playground'].concat(adminPerms);

function makeV3RequestBody(type, attributes) {
  return {
    data: {
      type: type,
      attributes: attributes,
    },
  };
}

export const OneDropApi = {
  isLoggedIn: function () {
    return store.state.jwtToken != null;
  },

  isSuperAdmin: function () {
    return this.hasOneOfPerms(['admin-read', 'admin-write', 'admin-delete']);
  },

  hasPerm: function (perm) {
    if (store.state.perms) return store.state.perms.includes(perm);
    else return false;
  },

  hasOneOfPerms: function (list) {
    const reducer = (accumulator, currentValue) => accumulator || currentValue;
    const result = list
      .map((p) => {
        return this.hasPerm(p);
      })
      .reduce(reducer);
    return result;
  },

  getName: function () {
    ConsoleLog('getName', store.state.user);
    if (store.state.user) {
      var profile = store.state.user.profile;
      return profile.firstName + ' ' + profile.lastName;
    } else return null;
  },

  getEmail: function () {
    if (store.state.user) {
      var profile = store.state.user.profile;
      return profile.email;
    } else return null;
  },

  getUserId: function () {
    if (store.state.user) return store.state.user.profile.objectId;
    else null;
  },

  logout: function () {
    OneDropApi.get(
      `v3/user/signout`,
      (resp) => {
        ConsoleLog(resp);
      },
      (error) => {
        this.onApiError(error);
        ConsoleLog(error);
      }
    );
    store.dispatch('reset');
  },

  loginSuccess: function (response, onSuccess, onError) {
    store.commit('setJwtToken', response.data[0].attributes.token);
    store.commit(
      'setJwtRefreshToken',
      response.data[0].attributes.refreshToken
    );
    const userId = response.data[0].attributes.profile.objectId;
    this.getPerms(
      userId,
      () => {
        ConsoleLog('origResponse', response);
        if (this.hasOneOfPerms(adminPerms)) this.getAllPartners();
        onSuccess(response);
      },
      onError
    );
  },

  login: function (email, password, onSuccess, onError) {
    ConsoleLog('API_URL', process.env.VUE_APP_API_URL);
    OneDropApi.post(
      'v3/user/signin/credentials',
      makeV3RequestBody('authentication', {
        credentials: {
          identifier: email,
          password: password,
        },
      }),
      (response) => {
        this.loginSuccess(response, onSuccess, onError);
      },
      onError
    );
  },

  socialLogin: function (url, body, onSuccess, onError) {
    OneDropApi.post(
      url,
      body,
      (response) => {
        this.loginSuccess(response, onSuccess, onError);
      },
      onError
    );
  },

  searchUsers: function (searchTerm, partner, trackId, onSuccess, onError) {
    let url = `v3/admin/search/user?q=${encodeURIComponent(searchTerm)}`;
    if (partner) url = url + `&partner=${partner}`;
    if (trackId) url = url + `&trackId=${trackId}`;
    OneDropApi.get(url, onSuccess, onError);
  },

  getPerms: function (userId, onSuccess, onError) {
    var perms = allPerms.join(',');
    OneDropApi.get(
      `v3/admin/has-permissions?perm=${encodeURIComponent(perms)}`,
      (response) => {
        ConsoleLog('getPerms', response);
        store.commit('setPerms', response.data[0].attributes.permissions);
        onSuccess(response);
      },
      onError
    );
  },

  getUser: function (userId, onSuccess, onError) {
    if (this.hasOneOfPerms(['admin-read', 'admin-user-read'])) {
      ConsoleLog('getUser', userId);
      OneDropApi.get(`v3/admin/user/${userId}`, onSuccess, onError);
    } else OneDropApi.get(`v3/user/me`, onSuccess, onError);
  },

  getCoach(coachId, onSuccess, onError) {
    OneDropApi.getResource('coach', coachId, onSuccess, onError);
  },

  getPartner(partnerId, onSuccess, onError) {
    OneDropApi.getResource('partner', partnerId, onSuccess, onError);
  },

  getTrack(trackId, onSuccess, onError) {
    if (trackId) OneDropApi.getResource('track', trackId, onSuccess, onError);
    else null;
  },

  getLinkedAccounts(userId, onSuccess, onError) {
    if (this.isSuperAdmin() || this.hasOneOfPerms(['admin-user-read'])) {
      OneDropApi.get(`v3/admin/linkedpartner/${userId}`, onSuccess, onError);
    } else OneDropApi.get(`v3/linkedpartner`, onSuccess, onError);
  },

  getTableCounts(tables, onSuccess, onError) {
    OneDropApi.get(
      `v3/webhook/tablecounts?tables=${tables}`,
      onSuccess,
      onError
    );
  },

  getAllResources(resourceName, onSuccess, onError) {
    OneDropApi.get(`v3/admin/resource/${resourceName}`, onSuccess, onError);
  },

  getContentForPrefix(prefix, onSuccess, onError) {
    OneDropApi.get(
      `/v3/admin/resource/content?prefix=${prefix}`,
      onSuccess,
      onError
    );
  },

  getContentForPrefixAndLang(prefix, language, onSuccess, onError) {
    if (language)
      OneDropApi.get(
        `/v3/admin/resource/content?prefix=${prefix}&language=${language}`,
        onSuccess,
        onError
      );
    else
      OneDropApi.get(
        `/v3/admin/resource/content?prefix=${prefix}`,
        onSuccess,
        onError
      );
  },

  getContentForPrefixAllLangs(prefix, onSuccess, onError) {
    OneDropApi.get(
      `/v3/admin/resource/content-all?prefix=${prefix}`,
      onSuccess,
      onError
    );
  },

  postContent(body, onSuccess, onError) {
    OneDropApi.post(`v3/admin/resource/content`, body, onSuccess, onError);
  },

  patchContent(body, onSuccess, onError) {
    OneDropApi.patch(`v3/admin/resource/content`, body, onSuccess, onError);
  },

  deleteContent(body, onSuccess, onError) {
    OneDropApi.del(`v3/admin/resource/content`, body, onSuccess, onError);
  },

  deleteContentForPrefix(prefix, onSuccess, onError) {
    OneDropApi.del(
      `/v3/admin/resource/content-by-prefix?prefix=${prefix}`,
      null,
      onSuccess,
      onError
    );
  },

  getTracksForPartner(partnerId, onSuccess, onError) {
    OneDropApi.get(
      `/v3/admin/partner/${partnerId}/tracks?showHidden=true`,
      onSuccess,
      onError
    );
  },

  getCoachesForTrack(trackId, onSuccess, onError) {
    OneDropApi.get(
      `/v3/admin/resource/track/${trackId}/coaches`,
      onSuccess,
      onError
    );
  },

  getAllCoaches(onSuccess, onError) {
    OneDropApi.get(`/v3/resource/coach`, onSuccess, onError);
  },

  getLessons(onSuccess, onError) {
    OneDropApi.get('/v3/admin/lessons', onSuccess, onError);
  },
  getUserRiskScores(userId, onSuccess, onError) {
    OneDropApi.get(
      `/v3/admin/participant/${userId}/risk-score`,
      onSuccess,
      onError
    );
  },
  patchTrackCoach(body, onSuccess, onError) {
    OneDropApi.patch(
      `/v3/admin/resource/track-coach`,
      body,
      onSuccess,
      onError
    );
  },

  deleteTrackCoach(body, onSuccess, onError) {
    OneDropApi.del(`/v3/admin/resource/track-coach`, body, onSuccess, onError);
  },

  getScheduleForTrack(trackId, onSuccess, onError) {
    OneDropApi.get(
      `/v3/admin/resource/track/${trackId}/schedule`,
      onSuccess,
      onError
    );
  },

  getAllQuestionsAndGroups(partnerId, onSuccess, onError) {
    const url = partnerId
      ? `/v3/admin/questions-and-groups?partner=${partnerId}`
      : `/v3/admin/questions-and-groups`;
    OneDropApi.get(url, onSuccess, onError);
  },

  getQuestionGroups(onSuccess, onError) {
    OneDropApi.get('/v3/admin/question-groups', onSuccess, onError);
  },

  getQuestions(groupId, onSuccess, onError) {
    OneDropApi.get(`/v3/admin/questions/${groupId}`, onSuccess, onError);
  },

  getQuestionPartners(groupId, onSuccess, onError) {
    OneDropApi.get(
      `/v3/admin/question-partners/${groupId}`,
      onSuccess,
      onError
    );
  },

  getAnswers(questionId, onSuccess, onError) {
    OneDropApi.get(
      `/v3/admin/answer-choices/${questionId}`,
      onSuccess,
      onError
    );
  },

  insertQuestion(body, onSuccess, onError) {
    OneDropApi.post(`/v3/admin/question`, body, onSuccess, onError);
  },

  updateQuestion(body, onSuccess, onError) {
    OneDropApi.patch(`/v3/admin/question`, body, onSuccess, onError);
  },

  deleteQuestion(questionId, onSuccess, onError) {
    OneDropApi.del(
      `/v3/admin/question/${questionId}`,
      null,
      onSuccess,
      onError
    );
  },

  insertAnswerChoice(body, onSuccess, onError) {
    OneDropApi.post(`/v3/admin/answer-choice`, body, onSuccess, onError);
  },

  updateAnswerChoice(body, onSuccess, onError) {
    OneDropApi.patch(`/v3/admin/answer-choice`, body, onSuccess, onError);
  },

  deleteAnswerChoice(answerChoiceId, onSuccess, onError) {
    OneDropApi.del(
      `/v3/admin/answer-choice/${answerChoiceId}`,
      null,
      onSuccess,
      onError
    );
  },

  insertQuestionPartners(groupId, body, onSuccess, onError) {
    OneDropApi.patch(
      `/v3/admin/question-partners/${groupId}`,
      body,
      onSuccess,
      onError
    );
  },

  deleteQuestionPartners(groupId, body, onSuccess, onError) {
    OneDropApi.del(
      `/v3/admin/question-partners/${groupId}`,
      body,
      onSuccess,
      onError
    );
  },

  testSendTrackMessage(
    user,
    trackId,
    versionId,
    day,
    dayOrder,
    lang,
    onSuccess,
    onError
  ) {
    let url = `/v3/admin/test-send/user/${user}/track/${trackId}?version-id=${versionId}&day=${day}&lang=${lang}`;
    if (dayOrder) url = url + `&dayOrder=${dayOrder}`;
    OneDropApi.post(url, null, onSuccess, onError);
  },

  getResource: function (resourceName, resourceId, onSuccess, onError) {
    OneDropApi.get(
      `v3/resource/${resourceName}/${resourceId}`,
      onSuccess,
      onError
    );
  },

  postResource: function (resourceName, body, onSuccess, onError) {
    OneDropApi.post(`v3/resource/${resourceName}`, body, onSuccess, onError);
  },

  patchResource: function (resourceName, resourceId, body, onSuccess, onError) {
    OneDropApi.patch(`v3/resource/${resourceName}`, body, onSuccess, onError);
  },

  deleteResource: function (
    resourceName,
    resourceId,
    body,
    onSuccess,
    onError
  ) {
    OneDropApi.del(`v3/resource/${resourceName}`, body, onSuccess, onError);
  },

  passwordReset: function (email, onSuccess, onError) {
    OneDropApi.post(
      `/v3/user/email/reset-password-request/${email}`,
      null,
      onSuccess,
      onError
    );
  },

  getPingHistogram: function (userId, fromDate, toDate, onSuccess, onError) {
    OneDropApi.get(
      `v3/stats/ping/histogram/${userId}?from=${encodeURIComponent(
        fromDate
      )}&to=${encodeURIComponent(toDate)}`,
      onSuccess,
      onError
    );
  },

  getAllPartners: function (onSuccess, onError) {
    this.getAllResources(
      'partner',
      (response) => {
        ConsoleLog('Got partners', response.data);
        store.commit('setPartners', response.data);
        if (onSuccess) onSuccess(response.data);
      },
      (error) => {
        ConsoleLog(error);
        if (onError) onError(error);
      }
    );
  },

  clonePartner: function (partnerId, body, onSuccess, onError) {
    OneDropApi.post(
      `v3/admin/resource/clone-partner/${partnerId}`,
      body,
      onSuccess,
      onError
    );
  },

  deletePartner: function (partnerId, onSuccess, onError) {
    OneDropApi.del(
      `v3/admin/resource/partner/${partnerId}`,
      null,
      onSuccess,
      onError
    );
  },

  copyTrack: function (trackId, partnerId, onSuccess, onError) {
    OneDropApi.post(
      `/v3/admin/resource/copy-track/${trackId}?partnerId=${partnerId}`,
      null,
      onSuccess,
      onError
    );
  },

  deleteTrack: function (trackId, onSuccess, onError) {
    OneDropApi.del(
      `/v3/admin/resource/track/${trackId}`,
      null,
      onSuccess,
      onError
    );
  },

  patchBatch: function (resourceName, body, onSuccess, onError) {
    OneDropApi.patch(`/v3/resource/${resourceName}`, body, onSuccess, onError);
  },

  deleteUser: function (userId, onSuccess, onError) {
    OneDropApi.del(`/v3/admin/user/${userId}`, null, onSuccess, onError);
  },

  deleteUserWs: function (userId, onMessage, onComplete, onApiError) {
    const token = store.state.jwtToken;
    const wsBase = process.env.VUE_APP_WS_API_URL;
    const wsUrl = `${wsBase}/v3/admin/user/${userId}/delete/ws?X-Auth-Token=${token}`;
    ConsoleLog('Deleting from url ' + wsUrl);
    const websocket = new WebSocket(wsUrl);
    let errorEvent = null;
    websocket.onmessage = function (ev) {
      if (ev.type == 'message') {
        ConsoleLog(ev);
        ConsoleLog(ev.data);
        JSON.parse(ev.data, (k, v) => {
          if (k) {
            ConsoleLog('Pushing', k, v);
            onMessage(k, v);
          }
        });
      }
    };
    websocket.onerror = function (ev) {
      ConsoleLog('Error', ev);
      errorEvent = ev;
    };
    websocket.onclose = function () {
      ConsoleLog('Closing the websocket');
      if (errorEvent) onApiError(errorEvent);
      else onComplete(true);
    };
  },

  mergeUser: function (
    donorId,
    recipientId,
    includeHealthObjects,
    moveEntitlements,
    deleteDonor,
    emailFromDonor,
    ignoreDonorKeys,
    onSuccess,
    onError
  ) {
    const url = `/v3/admin/merge/donor/${donorId}/recipient/${recipientId}?includeHealthObjects=${includeHealthObjects}&moveEntitlements=${moveEntitlements}&deleteDonor=${deleteDonor}&emailFromDonor=${emailFromDonor}&ignoreDonorKeys=${ignoreDonorKeys}`;
    OneDropApi.patch(url, null, onSuccess, onError);
  },

  mergeUserWs: function (
    donorId,
    recipientId,
    includeHealthObjects,
    moveEntitlements,
    deleteDonor,
    onMessage,
    onComplete,
    onApiError
  ) {
    const token = store.state.jwtToken;
    const wsBase = process.env.VUE_APP_WS_API_URL;
    const wsUrl = `${wsBase}/v3/admin/merge/donor/${donorId}/recipient/${recipientId}/ws?X-Auth-Token=${token}&includeHealthObjects=${includeHealthObjects}&moveEntitlements=${moveEntitlements}&deleteDonor=${deleteDonor}`;
    ConsoleLog('Deleting from url ' + wsUrl);
    const websocket = new WebSocket(wsUrl);
    let errorEvent = null;
    websocket.onmessage = function (ev) {
      if (ev.type == 'message') {
        ConsoleLog(ev);
        ConsoleLog(ev.data);
        JSON.parse(ev.data, (k, v) => {
          if (k) {
            ConsoleLog('Pushing', k, v);
            onMessage(k, v);
          }
        });
      }
    };
    websocket.onerror = function (ev) {
      ConsoleLog('Error', ev);
      errorEvent = ev;
    };
    websocket.onclose = function () {
      ConsoleLog('Closing the websocket');
      if (errorEvent) onApiError(errorEvent);
      else onComplete(true);
    };
  },

  getJobStatus: function (jobId, onSuccess, onError) {
    OneDropApi.get(`v3/job-status/${jobId}`, onSuccess, onError);
  },

  get: function (uri, onSuccess, onError) {
    client
      .get(uri, {
        headers: getHeaders(),
      })
      .then((r) => {
        onSuccess(r.data, r);
      })
      .catch((e) => {
        logError(e);
        onError(e);
      });
  },

  put: function (uri, body, onSuccess, onError) {
    client
      .put(uri, body, {
        headers: getHeaders(),
      })
      .then((r) => {
        onSuccess(r.data, r);
      })
      .catch((e) => {
        logError(e);
        onError(e);
      });
  },

  patch: function (uri, body, onSuccess, onError) {
    client
      .patch(uri, body, {
        headers: getHeaders(),
      })
      .then((r) => {
        onSuccess(r.data, r);
      })
      .catch((e) => {
        logError(e);
        onError(e);
      });
  },

  post: function (uri, body, onSuccess, onError) {
    client
      .post(uri, body, {
        headers: getHeaders(),
      })
      .then((r) => {
        onSuccess(r.data, r);
      })
      .catch((e) => {
        logError(e);
        onError(e);
      });
  },

  del: function (uri, body, onSuccess, onError) {
    client
      .delete(uri, {
        headers: getHeaders(),
        data: body,
      })
      .then((r) => {
        onSuccess(r.data, r);
      })
      .catch((e) => {
        logError(e);
        onError(e);
      });
  },
};
