<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-data-table
        :loading="loading"
        :items="events"
        :headers="headers"
        dense
        sort-by="formattedDate"
        sort-desc
      >
        <template #item.attributes.eventDate="{ item }">
          <span>{{ formatDate(item.attributes.eventDate) }}</span>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { OneDropUtils } from '../onedrop-utils';
export default {
  name: 'EligibilityEvents',
  props: ['userId', 'eligibilityId'],
  data() {
    return {
      loading: false,
      events: [],
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Event Type',
          value: 'attributes.eventType',
        },
        {
          text: 'Eligibility State',
          value: 'attributes.eligibilityState',
        },
        {
          text: 'Event Date',
          value: 'attributes.eventDate',
        },
      ],
    };
  },
  created() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.loading = true;
      OneDropApi.get(
        this.userId
          ? `admin/v3/eligibilityevents/user/${this.userId}`
          : `admin/v3/eligibilityevents/${this.eligibilityId}`,
        (response) => {
          this.events = response.data;
          this.loading = false;
        },
        (err) => {
          this.onApiError(err);
          this.loading = false;
        }
      );
    },
    formatDate(str) {
      return OneDropUtils.displayDate(str);
    },
  },
};
</script>

<style scoped></style>
