<template>
  <v-container class="ma-4">
    <v-progress-circular v-if="user === null" indeterminate color="primary" />
    <div v-else>
      <v-row no-gutters>
        <v-col>
          <v-row no-gutters>
            <v-col cols="12" class="od-heading">
              <h2>Profile</h2>
            </v-col>
          </v-row>
          <ProfileDetails
            :user-id="user.id"
            :profile="user.attributes.profile"
            :condensed="condensed"
          />
        </v-col>
      </v-row>
      <v-row v-if="!condensed" no-gutters>
        <v-col>
          <v-row no-gutters>
            <v-col cols="12" class="od-heading">
              <h2>Subscription</h2>
            </v-col>
          </v-row>
          <SubscriptionDetails
            v-if="!condensed"
            :subscription="user.attributes.subscriptionStatus"
          />
        </v-col>
      </v-row>
      <v-row v-if="!condensed" no-gutters>
        <v-col>
          <v-row no-gutters>
            <v-col cols="12" class="od-heading">
              <h2>Activity</h2>
            </v-col>
          </v-row>
          <ActivityDetails v-if="!condensed" :user-id="user.id" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import SubscriptionDetails from './SubscriptionDetails';
import { OneDropUtils } from '../onedrop-utils';
import ActivityDetails from './ActivityDetails';
import ProfileDetails from './ProfileDetails';

export default {
  name: 'UserDetails',
  components: {
    ProfileDetails,
    ActivityDetails,
    SubscriptionDetails,
  },
  props: ['user', 'condensed'],
  computed: {
    joinedAt: function () {
      return OneDropUtils.displayDate(this.user.attributes.profile.createdAt);
    },
    hasUserReadPerm: function () {
      return OneDropApi.hasOneOfPerms(['admin-read', 'admin-user-read']);
    },
    sortedDevices: function () {
      if (this.user.attributes.installations)
        return this.user.attributes.installations.concat().sort((lhs, rhs) => {
          return (
            new Date(rhs.updatedAt).getMilliseconds() -
            new Date(lhs.udatedAt).getMilliseconds()
          );
        });
      else return null;
    },
  },
};
</script>

<style scoped></style>
