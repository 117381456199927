<script>
import { Line } from 'vue-chartjs';
import { subDays } from 'date-fns';

export default {
  name: 'ActivityPlot',
  extends: Line,
  props: ['history', 'options'],

  watch: {
    history: function () {
      this.onHistoryChange();
    },
  },

  mounted() {
    this.onHistoryChange();
  },

  methods: {
    onHistoryChange: function () {
      const theSeries = this.history.map((item) => {
        return {
          x: item.date,
          y: item.count,
        };
      });

      this.renderChart(
        {
          labels: theSeries.map(() => {
            return '';
          }),
          datasets: [
            {
              label: 'Sessions / Day',
              backgroundColor: '#f87979',
              data: theSeries,
            },
          ],
        },
        {
          scales: {
            xAxes: [
              {
                type: 'time',
                time: {
                  unit: 'month',
                },
                distribution: 'linear',
                ticks: {
                  min: subDays(new Date(), 180),
                  max: new Date(),
                  maxRotation: 45,
                  minRotation: 45,
                },
              },
            ],
          },
        }
      );
    },
  },
};
</script>

<style scoped></style>
