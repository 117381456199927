<template>
  <v-app-bar app>
    <v-toolbar-title class="headline text-uppercase">
      <router-link to="/">
        <v-avatar tile>
          <img :src="require('../assets/logo.png')" height="32" />
        </v-avatar>
      </router-link>
      &nbsp;
      <span>One Drop Admin Console</span>
    </v-toolbar-title>
    <v-spacer />
    <v-btn
      v-if="isSignedIn"
      text
      color="primary"
      class="mr-2"
      @click="onNameClicked"
    >
      {{ email }}
    </v-btn>
    <v-btn v-if="isSignedIn" depressed @click="onSignOutClicked">
      Sign Out
    </v-btn>
    <template #extension>
      <slot name="menu" />
    </template>
  </v-app-bar>
</template>

<script>
import { OneDropApi } from '../onedrop-api';

export default {
  name: 'TopBar',
  computed: {
    isSignedIn: function () {
      return OneDropApi.isLoggedIn();
    },
    loggedinInUser: function () {
      return OneDropApi.getUserId();
    },
    email: function () {
      return OneDropApi.getEmail();
    },
  },
  methods: {
    onSignOutClicked: function () {
      this.signOut();
    },
    onNameClicked: function () {
      if (this.isSignedIn) this.$router.push(`/user/${this.loggedinInUser}`);
    },
  },
};
</script>

<style scoped></style>
