<template>
  <UserList v-model="query" :partner.sync="partner" :track-id.sync="trackId">
    <template #actions="slotProps">
      <v-col cols="2">
        <v-btn @click.stop="onUserSelected(slotProps.item)"> Select </v-btn>
      </v-col>
    </template>
  </UserList>
</template>

<script>
import UserList from './UserList';
import { ConsoleLog } from '../onedrop-utils';

export default {
  name: 'FindUsers',
  components: { UserList },
  data: function () {
    return {
      query: null,
      partner: null,
      trackId: null,
    };
  },

  computed: {},

  methods: {
    onUserSelected: function (item) {
      ConsoleLog('Selected', item);
      this.$emit('user-selected', item);
    },
  },
};
</script>

<style scoped></style>
