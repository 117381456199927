<template>
  <v-container fluid>
    <top-bar />
    <v-row>
      <v-col cols="4" />
      <v-col cols="4">
        <v-card flat class="mb-2">
          <v-card-actions class="justify-center">
            <div id="google-signin-button" />
          </v-card-actions>
        </v-card>
        <v-card outlined>
          <v-card-title class="align-content-center"> Sign In </v-card-title>
          <v-card-text>
            <v-card-text>
              <v-text-field
                ref="emailText"
                v-model="email"
                outlined
                label="email address"
                @keyup.enter="onSubmitClicked"
              />
              <v-text-field
                v-model="password"
                outlined
                type="password"
                label="password"
                @keyup.enter="onSubmitClicked"
              />
              <v-card-actions>
                <v-btn
                  large
                  depressed
                  :loading="isLoggingIn"
                  :disabled="isLoggingIn"
                  color="primary"
                  @click="onSubmitClicked()"
                >
                  Submit
                </v-btn>
                <v-spacer />
                <v-btn text color="primary" @click="forgotDialog = true">
                  Forgot Password
                </v-btn>
              </v-card-actions>
            </v-card-text>
            <v-dialog v-model="forgotDialog" max-width="450px">
              <v-card>
                <v-card-title>Forgot Password</v-card-title>
                <v-card-subtitle>
                  Enter your email address to reset password
                </v-card-subtitle>
                <v-card-text>
                  <v-text-field
                    ref="emailText"
                    v-model="email"
                    outlined
                    label="email address"
                    :rules="[rules.required, rules.email]"
                    validate-on-blur
                    @keyup.enter="onForgotSubmit"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="forgotDialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    :disabled="!validEmail"
                    @click="onForgotSubmit"
                  >
                    Reset Password
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { OneDropApi } from '@/onedrop-api';
import TopBar from '../components/TopBar';

export default {
  name: 'Login',
  components: { TopBar },
  props: ['nextUrl', 'priorError'],
  metaInfo: {
    meta: [
      {
        name: 'google-signin-client_id',
        content: process.env.VUE_APP_GOOGLE_SIGNIN_CLIENT_ID,
      },
    ],
  },
  data: function () {
    return {
      isLoggingIn: false,
      email: '',
      password: '',
      forgotDialog: false,
      rules: {
        required: (value) => !!value || 'Required.',
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        },
      },
      validEmail: false,
      token: null,
    };
  },
  watch: {
    email: function () {
      this.validEmail = this.rules.email(this.email) === true;
    },
  },
  mounted: function () {
    window.addEventListener('google-loaded', this.renderGoogleLoginButton);
    // eslint-disable-next-line
    if (typeof google !== 'undefined' && google !== null)
      this.renderGoogleLoginButton();
    if (typeof this.priorError !== 'undefined' && this.priorError)
      this.onApiError(this.priorError);
    this.$refs.emailText.focus();
  },
  methods: {
    renderGoogleLoginButton() {
      // eslint-disable-next-line
      google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_SIGNIN_CLIENT_ID,
        callback: this.handleGoogleLoginResponse,
      });
      // eslint-disable-next-line
      google.accounts.id.renderButton(
        document.getElementById('google-signin-button'),
        { theme: 'outline', size: 'large' } // customization attributes
      );
      // eslint-disable-next-line
      google.accounts.id.prompt(); // also display the One Tap dialog
    },
    onSubmitClicked: function () {
      this.isLoggingIn = true;
      OneDropApi.login(
        this.email,
        this.password,
        (result) => this.onLoginSuccess(result),
        (error) => {
          this.isLoggingIn = false;
          this.onApiError(error);
        }
      );
    },

    onLoginSuccess: function (result) {
      this.$store.commit('setUser', result.data[0].attributes);
      this.isLoggingIn = false;

      // TODO: use deferred routing instead (kinda complicated)
      let url = this.nextUrl ? this.nextUrl : '/';
      this.$router.push(url);
    },

    onForgotSubmit: function () {
      if (this.email == null || !this.validEmail) {
        this.onApiError('Not a valid email address');
        return;
      }
      this.isLoggingIn = true;
      OneDropApi.passwordReset(
        this.email,
        () => {
          this.toast(`Email sent to ${this.email}`);
          this.isLoggingIn = false;
        },
        (error) => {
          this.onApiError(error);
          this.isLoggingIn = false;
        }
      );
      this.forgotDialog = false;
    },

    handleGoogleLoginResponse(response) {
      const body = {
        data: {
          type: 'user',
          attributes: {
            token: response.credential,
          },
        },
      };
      OneDropApi.socialLogin(
        '/v3/user/signin/jwt/google',
        body,
        (result) => this.onLoginSuccess(result),
        (error) => {
          this.isLoggingIn = false;
          this.onApiError(error);
        }
      );
    },
  },
};
</script>

<style scoped></style>
