<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-textarea v-model="valueLocal" outlined rows="2" label="Content" />
      </v-col>
      <!--      <v-col cols="3">-->
      <!--        <v-toolbar flat>-->
      <!--          <v-spacer/>-->
      <!--          <v-btn @click="encode" color="primary">Encode</v-btn>-->
      <!--          <v-btn @click="decode" color="primary" class="ml-2">Decode</v-btn>-->
      <!--        </v-toolbar>-->
      <!--      </v-col>-->
    </v-row>
    <v-card raised>
      <v-card-title>Deep Link</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="5">
            <v-text-field
              v-model="link"
              outlined
              label="Link"
              prefix="onedrop://"
              suffix="="
            />
          </v-col>
          <v-col>
            <v-textarea v-model="content" outlined rows="3" label="Text" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { ConsoleLog } from '../onedrop-utils';

export default {
  name: 'DeepLinkEncoder',
  props: ['value'],
  data: function () {
    return {
      link: null,
      content: null,
    };
  },
  computed: {
    valueLocal: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    link: function () {
      this.encode();
    },
    content: function () {
      this.encode();
    },
    value: function () {
      this.decode();
    },
  },
  mounted() {
    this.decode();
  },
  methods: {
    encode() {
      if (this.content) {
        const p = encodeURIComponent(btoa(this.content));
        this.valueLocal = `onedrop://${this.link}=${p}`;
      }
    },
    decode() {
      const str = this.valueLocal;
      const regexp = /onedrop:\/\/(.*)[=]([^=]*)$/gm;

      const matches = str ? [...str.matchAll(regexp)] : str;
      this.link = null;
      this.content = null;
      if (matches && matches.length > 0) {
        ConsoleLog(`matches: ${matches}`);
        this.link = matches[0][1];
        const p = matches[0][2];
        try {
          this.content = atob(decodeURIComponent(p));
        } catch (e) {
          this.onApiError('Failed to decode');
        }
      }
    },
  },
};
</script>

<style scoped></style>
