<template>
  <v-container fluid class="pa-0">
    <SearchUsers />
  </v-container>
</template>

<script>
import SearchUsers from '../components/SearchUsers';

export default {
  name: 'Search',
  components: {
    SearchUsers,
  },
};
</script>
