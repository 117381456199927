<!-- Sub-component to display a single question and its answers -->
<template>
  <v-container fluid>
    <v-row dense>
      <router-link
        :to="{
          path: '/setup/questioneditor/questions',
          query: { query: String(question.questionId) },
        }"
        target="_blank"
      >
        <h3>Question {{ question.questionId }}: {{ question.question }}</h3>
      </router-link>
    </v-row>
    <v-row dense>
      <v-col>
        <v-simple-table fixed-header dense>
          <template #default>
            <thead>
              <tr>
                <th class="text-left" width="50px" />
                <th class="text-left">Id</th>
                <th class="text-left" width="40%">Answer</th>
                <th class="text-left" width="40%">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="answer in question.answerChoices"
                :key="answer.answerId"
                :class="getClass(answer)"
              >
                <td width="50px">
                  <v-icon v-if="answer.response"> mdi-check </v-icon>
                </td>
                <td>{{ answer.answerId }}</td>
                <td width="40%">
                  {{ answer.answer }}
                </td>
                <td width="40%">
                  <div v-if="answer.response">
                    {{ answer.response.attributes.value }}
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'UserQuestion',
  props: ['question'],
  data: function () {
    return {};
  },
  methods: {
    getClass(answer) {
      if (answer.response) return 'font-weight-bold';
      else return '';
    },
  },
};
</script>

<style scoped></style>
