<template>
  <v-container fluid>
    <v-layout class="display-1 py-4">
      Manage Employer Program Pricing
    </v-layout>
    <EmployerProgramPricingList />
  </v-container>
</template>

<script>
import EmployerProgramPricingList from '@/components/EmployerProgramPricingList';

export default {
  name: 'EmployerProgramPricingEditor',
  components: { EmployerProgramPricingList },
};
</script>

<style scoped></style>
