<template>
  <v-container fluid>
    <v-layout class="display-1 py-4"> Audits </v-layout>
    <audit-log-viewer />
  </v-container>
</template>

<script>
import AuditLogViewer from '@/components/AuditLogViewer';

export default {
  name: 'Audits',
  components: { AuditLogViewer },
};
</script>

<style scoped></style>
