<template>
  <v-card :loading="loading">
    <v-card-title v-if="editedPartner">
      <span class="headline"
        >Edit Partner: {{ editedPartner.attributes.name }}</span
      >
      <v-spacer />
      <v-img
        v-if="editedPartner.attributes.primaryIconURL"
        :src="editedPartner.attributes.primaryIconURL"
        max-height="30"
        max-width="100"
        contain
      />
    </v-card-title>
    <v-card-text v-if="editedPartner">
      <v-progress-circular v-if="saving" indeterminate color="primary" />
      <v-stepper v-model="mode" non-linear>
        <v-stepper-header>
          <v-stepper-step :complete="done" step="1" editable>
            Fields
          </v-stepper-step>
          <v-divider />
          <v-stepper-step :complete="done" step="2" editable>
            Flags
          </v-stepper-step>
          <v-divider />
          <v-stepper-step :complete="done" step="3" editable>
            Image/Icon
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-row>
              <v-col cols="6">
                <div v-for="item in textFields1" :key="item.field">
                  <v-text-field
                    v-model="editedPartner.attributes[item.field]"
                    :label="item.name"
                  />
                </div>
              </v-col>
              <v-col cols="6">
                <div v-for="item in textFields2" :key="item.field">
                  <v-text-field
                    v-model="editedPartner.attributes[item.field]"
                    :label="item.name"
                  />
                </div>
              </v-col>
            </v-row>
            <v-card-actions class="mt-4">
              <v-btn
                large
                depressed
                color="primary"
                class="mr-2"
                text
                @click="cancel"
              >
                Cancel
              </v-btn>
              <v-btn large depressed color="primary" @click="mode = '2'">
                Next
              </v-btn>
            </v-card-actions>
          </v-stepper-content>
          <v-stepper-content step="2">
            <div v-for="item in booleanFields" :key="item.field">
              <v-switch
                v-model="editedPartner.attributes[item.field]"
                :label="item.name"
                dense
                class="mt-0 mb-0 pt-0 pb-0"
              />
            </div>
            <v-card-actions class="mt-4">
              <v-btn
                large
                depressed
                color="primary"
                class="mr-2"
                text
                @click="cancel"
              >
                Cancel
              </v-btn>
              <v-btn
                large
                depressed
                color="primary"
                class="mr-2"
                text
                @click="mode = '1'"
              >
                Previous
              </v-btn>
              <v-btn large depressed color="primary" @click="mode = '3'">
                Next
              </v-btn>
            </v-card-actions>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-row>
              <v-col cols="10">
                <div v-for="item in imageFields" :key="item.field">
                  <v-text-field
                    v-model="editedPartner.attributes[item.field]"
                    :label="item.name"
                    append-outer-icon="mdi-paperclip"
                    @click:append-outer="item.editItemDialog = true"
                  />
                  <amazon-s3-uploader
                    v-model="item.editItemDialog"
                    :edit-item="editedPartner.attributes"
                    :image-field="item.field"
                    @setUrlAttribute="setUrlAttribute"
                  />
                </div>
              </v-col>
            </v-row>
            <v-card-actions class="mt-4">
              <v-btn
                large
                depressed
                color="primary"
                class="mr-2"
                text
                @click="cancel"
              >
                Cancel
              </v-btn>
              <v-btn
                large
                depressed
                color="primary"
                class="mr-2"
                text
                @click="mode = '2'"
              >
                Previous
              </v-btn>
              <v-spacer />
              <v-btn large depressed color="primary" @click="save">
                Submit
              </v-btn>
            </v-card-actions>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>

<script>
import { OneDropApi } from '../onedrop-api';
import { ConsoleLog } from '../onedrop-utils';
import AmazonS3Uploader from '@/components/AmazonS3Uploader';

export default {
  name: 'PartnerDetailEdit',
  components: { AmazonS3Uploader },
  props: ['partner'],
  data: function () {
    return {
      editedPartner: null,
      mode: '1',
      done: false,
      saving: false,
      allContent: [],
      contentPrefix: '',
      loading: false,
      textFields1: [
        { name: 'Name', field: 'name' },
        { name: 'API Slug', field: 'apiSlug' },
        { name: 'Tag', field: 'tag' },
        { name: 'Support Email', field: 'supportEmail' },
        { name: 'Partner Group Tag', field: 'partnerGroupTag' },
        { name: 'Signup Code Prefix', field: 'signupCodePrefix' },
        { name: 'Terms and Conditions', field: 'termsAndConditions' },
        {
          name: 'Coaching Terms and Conditions',
          field: 'coachingTermsAndConditions',
        },
        { name: 'Default Chat Language', field: 'defaultChatLanguage' },
      ],
      textFields2: [
        {
          name: 'Coaching Logo Onboarding Custom',
          field: 'coachingLogoOnboardingCustom',
        },
        { name: 'Coaching Privacy Policy', field: 'coachingPrivacyPolicy' },
        { name: 'Coaching Dashboard Title', field: 'coachingDashboardTitle' },
        {
          name: 'Coaching Dashboard Subtitle',
          field: 'coachingDashboardSubtitle',
        },
        {
          name: 'Chat ReadOnly Shopify Slug',
          field: 'chatReadOnlyShopifySlug',
        },
        {
          name: 'Chat ReadOnly External Url',
          field: 'chatReadOnlyExternalUrl',
        },
        { name: 'Coach Privacy Policy Url', field: 'coachPrivacyPolicyUrl' },
        {
          name: 'Coaching App Additional Fields',
          field: 'coachingAppAdditionalFields',
        },
      ],
      booleanFields: [
        { name: 'Employer', field: 'employer' },
        { name: 'Archived', field: 'archived' },
        { name: 'Coaching Enabled', field: 'coachingEnabled' },
        { name: 'Premium Allowed', field: 'premiumAllowed' },
        { name: 'Tracks Enabled', field: 'tracksEnabled' },
        { name: 'First Name Enabled', field: 'firstNameEnabled' },
        { name: 'Last Name Enabled', field: 'lastNameEnabled' },
        { name: 'Required Email Signin', field: 'requiredEmailSignin' },
        { name: 'Is Test Partner', field: 'isTestPartner' },
        { name: 'Check Eligibility', field: 'checkEligibility' },
        {
          name: 'Allow Dependent Eligibility',
          field: 'allowDependentEligibility',
        },
        { name: 'Include European Union', field: 'includeEuropeanUnion' },
        { name: 'Reorder Full SKU', field: 'reorderFullSku' },
      ],
      imageFields: [
        {
          name: 'Primary Icon URL',
          field: 'primaryIconURL',
          editItemDialog: false,
        },
        {
          name: 'Secondary Icon URL',
          field: 'secondaryIconURL',
          editItemDialog: false,
        },
        {
          name: 'Primary Icon URL Settings',
          field: 'primaryIconURLSettings',
          editItemDialog: false,
        },
        {
          name: 'Secondary Icon URL Settings',
          field: 'secondaryIconURLSettings',
          editItemDialog: false,
        },
        {
          name: 'Settings Primary Icon URL',
          field: 'settingsPrimaryIconURL',
          editItemDialog: false,
        },
        {
          name: 'Settings Secondary Icon URL',
          field: 'settingsSecondaryIconURL',
          editItemDialog: false,
        },
      ],
      contentFields1: [
        'validationSuccessDetailInfo',
        'validationFailureDuplicateTitle',
        'validationFailureDuplicateDetail',
        'coachCustom1',
        'coachCustom2',
        'coachCustom3',
        'primaryMotivationCustom',
        'buttonMeetCoachCustom',
        'successTitle',
        'chatReadOnlyButtonText',
        'chatReadOnlyCallToAction',
        'chatReadOnlyMessage',
      ],
      contentFields2: [
        'coachingDashboardTitle',
        'coachingDashboardSubtitle',
        'coachingWelcomeIntro',
        'coachingWelcomeBulletTwoTitle',
        'coachingWelcomeBulletOneText',
        'coachingWelcomeBulletOneTitle',
        'coachingWelcomeBulletTwoText',
        'coachingWelcomeBulletThreeText',
        'coachingWelcomeBulletThreeTitle',
        'coachingFormText',
        'termsOfServiceText',
        'termsOfServiceHTML',
      ],
    };
  },
  computed: {
    contentFields: function () {
      if (this.contentFields1)
        return this.contentFields1.concat(this.contentFields2);
      else return this.contentFields1;
    },
  },
  watch: {
    partner: function (n) {
      this.partnerChanged(n);
    },
  },
  mounted: function () {
    this.partnerChanged(this.partner);
  },
  created: function () {
    this.partnerChanged(this.partner);
  },
  methods: {
    getPrefix: function (p) {
      return p ? `partner.${p.id}.` : null;
    },
    setUrlAttribute(attributeKey, newVal) {
      this.editedPartner.attributes[attributeKey] = newVal;
    },
    cancel: function () {
      this.mode = '1';
      this.done = false;
      this.$emit('cancel');
    },
    save: function () {
      let o = {};
      let pAttrs = this.partner.attributes;
      // Copy attributes that have changed
      for (let k in pAttrs) {
        if (pAttrs[k] !== this.editedPartner.attributes[k]) {
          o[k] = this.editedPartner.attributes[k];
        }
      }
      for (let k in this.editedPartner.attributes) {
        if (pAttrs[k] !== this.editedPartner.attributes[k]) {
          o[k] = this.editedPartner.attributes[k];
        }
      }
      if (Object.keys(o).length > 0) {
        let p = Object.assign({}, this.editedPartner);
        p.attributes = o;
        let body = {
          data: [p],
        };
        let id = this.partner.id;
        ConsoleLog(`Saving: ${JSON.stringify(body)}`);
        OneDropApi.patchResource(
          'partner',
          id,
          body,
          () => {
            // eslint-disable-next-line vue/no-mutating-props
            for (let k in o) this.partner.attributes[k] = o[k];
            this.saving = false;
            this.done = true;
            this.$emit('saved', this.partner);
          },
          (error) => {
            ConsoleLog(`Error saving partner ${error}`);
            this.saving = false;
            this.onApiError(error);
          }
        );
      }
    },
    partnerChanged: function (p) {
      // We store content locally since both editors
      // will use the same content
      let prefix = this.getPrefix(p);
      this.loading = true;
      this.allContent = [];
      this.editedPartner = p ? JSON.parse(JSON.stringify(p)) : p;
      this.mode = '1';
      this.done = false;
      if (prefix && prefix !== this.contentPrefix) {
        this.contentPrefix = prefix;
        OneDropApi.getContentForPrefix(
          prefix,
          (result) => {
            ConsoleLog(
              `partnerChanged: Got content for prefix ${this.contentPrefix}`
            );
            this.allContent = result.data;
            this.loading = false;
          },
          (error) => {
            ConsoleLog(
              `Failed to get content for prefix ${this.contentPrefix}`
            );
            ConsoleLog(error);
            this.loading = false;
            this.emptyMessage = `Failed to get content with error ${error}`;
          }
        );
      } else if (this.prefix != this.contentPrefix) this.contentPrefix = prefix;
    },
  },
};
</script>

<style scoped></style>
